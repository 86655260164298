var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mt-role_admin row justify-content-center" },
    [
      _c("div", { staticClass: "col-md-9" }, [
        _c(
          "h1",
          {
            staticClass: "display-4",
            domProps: { textContent: _vm._s(_vm.$t("home.title")) },
          },
          [_vm._v("Welcome, Java Hipster!")]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "lead",
            domProps: { textContent: _vm._s(_vm.$t("home.subtitle")) },
          },
          [_vm._v("This is your homepage")]
        ),
        _vm._v(" "),
        _c("div", [
          _vm.authenticated
            ? _c("div", { staticClass: "alert alert-success" }, [
                _vm.username
                  ? _c(
                      "span",
                      {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("home.logged.message", {
                              username: _vm.username,
                            })
                          ),
                        },
                      },
                      [
                        _vm._v(
                          'You are logged in as user "' +
                            _vm._s(_vm.username) +
                            '"'
                        ),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.authenticated
            ? _c("div", { staticClass: "alert alert-warning" }, [
                _c(
                  "span",
                  {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("global.messages.info.authenticated.prefix")
                      ),
                    },
                  },
                  [_vm._v("If you want to ")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "alert-link",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("global.messages.info.authenticated.link")
                      ),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openLogin()
                      },
                    },
                  },
                  [_vm._v("sign in")]
                ),
                _c(
                  "span",
                  {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("global.messages.info.authenticated.suffix")
                      ),
                    },
                  },
                  [
                    _vm._v(", you can try the default accounts:"),
                    _c("br"),
                    _vm._v(
                      '- Administrator (login="admin" and password="admin") '
                    ),
                    _c("br"),
                    _vm._v(
                      '- User (login="user" and\n          password="user").'
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.authenticated
            ? _c(
                "div",
                { staticClass: "alert alert-warning" },
                [
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("global.messages.info.register.noaccount")
                        ),
                      },
                    },
                    [_vm._v("You don't have an account yet?")]
                  ),
                  _vm._v(" \n        "),
                  _c(
                    "router-link",
                    {
                      staticClass: "alert-link",
                      attrs: { to: "/register" },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("global.messages.info.register.link")
                        ),
                      },
                    },
                    [_vm._v("Register a new account")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
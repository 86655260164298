import axios from 'axios';

export default class CheckDataService {
  public getCheckData(userId: string): Promise<any> {
    return axios.get('api/account/check-data/' + userId);
  }

  public processCheckData(userId: string, account: any): Promise<any> {
    return axios.put('api/registro-completo/' + userId, account);
  }
}

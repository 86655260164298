var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("label", { attrs: { for: _vm.id } }, [
        _vm._v("\n    " + _vm._s(_vm.label) + " "),
        _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
        _vm._v(" "),
        _vm.tooltip
          ? _c(
              "span",
              [
                _c("b-icon", {
                  attrs: { id: _vm.id + "tooltip", icon: "question-circle" },
                }),
                _vm._v(" "),
                _c("b-tooltip", {
                  attrs: {
                    target: _vm.id + "tooltip",
                    title: _vm.tooltip,
                    placement: "right",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("b-form-select", {
        attrs: {
          id: _vm.id,
          options: _vm.options,
          "value-field": "id",
          "text-field": "nombre",
          placeholder: _vm.placeholder,
          state: _vm.valid,
          disabled: _vm.readonly,
        },
        on: {
          change: function ($event) {
            return _vm.cambio($event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "first",
            fn: function () {
              return [
                _c(
                  "b-form-select-option",
                  { attrs: { value: undefined, disabled: "" } },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("commonComponent.selectOne.firstOption")
                        ),
                      },
                    }),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.selectedId,
          callback: function ($$v) {
            _vm.selectedId = $$v
          },
          expression: "selectedId",
        },
      }),
      _vm._v(" "),
      _c(
        "b-form-invalid-feedback",
        [
          _c("validations-commons", {
            attrs: {
              requiredValue: _vm.validationsCommons.requiredValue,
              requiredMessage: _vm.validationsCommons.requiredMessage,
              invalidValue: _vm.validationsCommons.invalidValue,
              invalidMessage: _vm.validationsCommons.invalidMessage,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.description
        ? _c("small", { staticClass: "form-text text-muted" }, [
            _vm._v(_vm._s(_vm.description)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("ribbon"),
      _vm._v(" "),
      !_vm.hasAnyAuthority("ROLE_ADMIN") && !_vm.authenticated
        ? _c(
            "div",
            { staticClass: "conacyt-login-container app-container" },
            [
              _c("jhi-navbar"),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "column login-container" }, [
                _c(
                  "div",
                  { staticClass: "login-wrapper box-container" },
                  [_c("router-view")],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-modal",
                { attrs: { id: "login-page", "hide-footer": "", lazy: "" } },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        slot: "modal-title",
                        "data-cy": "loginTitle",
                        id: "login-title",
                      },
                      domProps: { textContent: _vm._s(_vm.$t("login.title")) },
                      slot: "modal-title",
                    },
                    [_vm._v("Sign in")]
                  ),
                  _vm._v(" "),
                  _c("login-form"),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "container-fluid admin-container" },
            [_c("router-view")],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass: "column bg-alt",
        staticStyle: {
          "background-image": "url('../content/images/loginbg.svg')",
        },
      },
      [
        _c("div", { staticClass: "welcome-panel" }, [
          _c("div", { staticClass: "welcome-panel__title" }, [
            _c("img", {
              attrs: {
                src: require("../content/images/logotipo.svg"),
                alt: "Rizoma",
              },
            }),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
import InputTextComponent from '@/components/input-text/input-text.vue';
import InputCheckboxComponent from '@/components/input-checkbox/input-checkbox.vue';
import InputDateComponent from '@/components/input-date/input-date.vue';
import InputDateOpenComponent from '@/components/input-date-open/input-date-open.vue';
import InputSelectOneComponent from '@/components/input-select-one/input-select-one.vue';
import ValidationsCommonsComponent from '@/components/validations-commons/validations-commons.vue';

export function initBaseComponents(vue) {
  vue.component('input-text', InputTextComponent);
  vue.component('input-checkbox', InputCheckboxComponent);
  vue.component('input-date', InputDateComponent);
  vue.component('input-date-open', InputDateOpenComponent);
  vue.component('input-select-one', InputSelectOneComponent);
  vue.component('validations-commons', ValidationsCommonsComponent);
}

import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import PropertyCommons from '../commons/property.model';

@Component
export default class InputCheckboxComponent extends mixins(PropertyCommons) {
  @Prop({ required: true })
  public value: boolean;

  get selection(): boolean {
    return this.value;
  }

  set selection(newValue: boolean) {
    this.$emit('input', newValue);
  }

  cambio(e) {
    this.$emit('change', e);
  }
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("label", { attrs: { for: _vm.id } }, [
        _vm._v("\n    " + _vm._s(_vm.label) + " "),
        _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
        _vm._v(" "),
        _vm.tooltip
          ? _c(
              "span",
              [
                _c("b-icon", {
                  attrs: { id: _vm.id + "tooltip", icon: "question-circle" },
                }),
                _vm._v(" "),
                _c("b-tooltip", {
                  attrs: {
                    target: _vm.id + "tooltip",
                    title: _vm.tooltip,
                    placement: "right",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("b-form-input", {
        attrs: {
          placeholder: _vm.placeholder,
          id: _vm.id,
          state: _vm.valid,
          trim: "",
          readonly: _vm.readonly,
          required: _vm.required,
          type: _vm.type,
          formatter: _vm.formatter,
        },
        on: {
          change: function ($event) {
            return _vm.cambio($event)
          },
          blur: function ($event) {
            return _vm.blur(_vm.text)
          },
        },
        nativeOn: {
          keyup: function ($event) {
            return _vm.keyup(_vm.text)
          },
        },
        model: {
          value: _vm.text,
          callback: function ($$v) {
            _vm.text = $$v
          },
          expression: "text",
        },
      }),
      _vm._v(" "),
      _c(
        "b-form-invalid-feedback",
        [
          _c("validations-commons", {
            attrs: {
              requiredValue: _vm.validationsCommons.requiredValue,
              requiredMessage: _vm.validationsCommons.requiredMessage,
              minValue: _vm.validationsCommons.minValue,
              minMessage: _vm.validationsCommons.minMessage,
              maxValue: _vm.validationsCommons.maxValue,
              maxMessage: _vm.validationsCommons.maxMessage,
              regexMessage: _vm.validationsCommons.regexMessage,
              regexValue: _vm.validationsCommons.regexValue,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.description
        ? _c("small", { staticClass: "form-text text-muted" }, [
            _vm._v(_vm._s(_vm.description)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-form-checkbox",
        {
          attrs: { id: _vm.id },
          on: {
            change: function ($event) {
              return _vm.cambio($event)
            },
          },
          model: {
            value: _vm.selection,
            callback: function ($$v) {
              _vm.selection = $$v
            },
            expression: "selection",
          },
        },
        [
          _c("label", { attrs: { for: _vm.id } }, [
            _c("strong", [_vm._v(_vm._s(_vm.label))]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
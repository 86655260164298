import Vue from 'vue';

export default class LoginService {
  public openLogin(instance: Vue): void {
    instance.$emit('bv::show::modal', 'login-page');
  }

  public login() {
    const rizomaUrl = sessionStorage.getItem('rizoma-url');

    // If you have configured multiple OIDC providers, then, you can update this URL to /login.
    // It will show a Spring Security generated login page with links to configured OIDC providers.
    //loc.href = `${loc.protocol}//${loc.hostname}${port}/oauth2/authorization/oidc`;
    window.location.href = `${rizomaUrl}oauth2/authorization/oidc`;
  }
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    !_vm.hasAnyAuthority("ROLE_ADMIN") && !_vm.authenticated
      ? _c(
          "div",
          [
            _vm.authenticationError
              ? _c(
                  "b-alert",
                  {
                    attrs: {
                      show: "",
                      "data-cy": "loginError",
                      variant: "danger",
                    },
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("login.messages.error.authentication")
                      ),
                    },
                  },
                  [
                    _c("strong", [_vm._v("Failed to sign in!")]),
                    _vm._v(
                      " Please check your credentials and try again.\n    "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "h1",
              {
                staticClass: "mt-5",
                attrs: { id: "check-data-title" },
                domProps: { textContent: _vm._s(_vm.$t("login.title")) },
              },
              [_vm._v("Bienvenido a Rizoma")]
            ),
            _vm._v(" "),
            _c(
              "h4",
              { domProps: { textContent: _vm._s(_vm.$t("login.title2")) } },
              [_vm._v("Iniciar sesión")]
            ),
            _vm._v(" "),
            _c(
              "b-form",
              {
                staticClass: "mb-3",
                attrs: { role: "form" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.doLogin()
                  },
                },
              },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("global.form['username.label']"),
                      "label-for": "username",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "username",
                        type: "text",
                        name: "username",
                        autofocus: "",
                        placeholder: _vm.$t(
                          "global.form['username.placeholder']"
                        ),
                        "data-cy": "username",
                      },
                      model: {
                        value: _vm.login,
                        callback: function ($$v) {
                          _vm.login = $$v
                        },
                        expression: "login",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("login.form.password"),
                      "label-for": "password",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "password",
                        type: "password",
                        name: "password",
                        placeholder: _vm.$t(
                          "login.form['password.placeholder']"
                        ),
                        "data-cy": "password",
                      },
                      model: {
                        value: _vm.password,
                        callback: function ($$v) {
                          _vm.password = $$v
                        },
                        expression: "password",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group float-right mb-3" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          id: "rememberMe",
                          name: "rememberMe",
                          checked: "",
                        },
                        model: {
                          value: _vm.rememberMe,
                          callback: function ($$v) {
                            _vm.rememberMe = $$v
                          },
                          expression: "rememberMe",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("login.form.rememberme")
                              ),
                            },
                          },
                          [_vm._v("Remember me")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-link",
                      {
                        staticClass: "alert-link mt-3 d-block",
                        attrs: {
                          to: "/account/reset/request",
                          "data-cy": "forgetYourPasswordSelector",
                        },
                        domProps: {
                          textContent: _vm._s(_vm.$t("login.password.forgot")),
                        },
                      },
                      [_vm._v("Did you forget your password?")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "mt-3 float-right",
                        attrs: {
                          "data-cy": "submit",
                          type: "submit",
                          variant: "primary",
                        },
                        domProps: {
                          textContent: _vm._s(_vm.$t("login.form.button")),
                        },
                      },
                      [_vm._v("Sign in")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "clearfix" }),
            _vm._v(" "),
            _c("div", { staticClass: "border-top mt-4 mb-4" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-3 text-center" },
              [
                _c(
                  "span",
                  {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("global.messages.info.register.noaccount")
                      ),
                    },
                  },
                  [_vm._v("You don't have an account yet?")]
                ),
                _vm._v(" "),
                _c(
                  "b-link",
                  {
                    staticClass: "alert-link",
                    attrs: { to: "/register" },
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("global.messages.info.register.link")
                      ),
                    },
                  },
                  [_vm._v("Register a new account")]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c("div", [_c("home")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
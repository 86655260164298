import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import PropertyCommons from '../commons/property.model';

@Component
export default class DatePropertyModel extends mixins(PropertyCommons) {
  @Prop({ required: true })
  public value!: string;

  formatted!: string;

  selected!: string;

  @Prop({ default: '' })
  minDate: string;

  @Prop({ default: '' })
  maxDate: string;

  get dateValue(): string {
    return this.value;
  }

  set dateValue(newValue: string) {
    this.$emit('input', newValue);
  }

  cambio(e) {
    this.$emit('change', e);
  }

  onContext(ctx) {
    this.formatted = ctx.selectedFormatted;
    this.selected = ctx.selectedYMD;
  }

}
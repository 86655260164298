import axios from 'axios';

export default class RegisterService {
  public processRegistration(account: any): Promise<any> {
    return axios.post('api/register', account);
  }

  public userValidate(login: any): Promise<any> {
    return axios.get(`api/validacion-usuario/${login.login}`);
  }

  public curpValidate(curp: string): Promise<any> {
    return axios.get(`api/validacion-curp/${curp}`);
  }
}

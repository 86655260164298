/* tslint:disable */
/* eslint-disable */
/**
 * SaciApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AreaGeoestadisticaEstatal
 */
export interface AreaGeoestadisticaEstatal {
  /**
   *
   * @type {string}
   * @memberof AreaGeoestadisticaEstatal
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AreaGeoestadisticaEstatal
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface AreaGeoestadisticasMunicipal
 */
export interface AreaGeoestadisticasMunicipal {
  /**
   *
   * @type {string}
   * @memberof AreaGeoestadisticasMunicipal
   */
  id_estado: string;
  /**
   *
   * @type {string}
   * @memberof AreaGeoestadisticasMunicipal
   */
  nombre: string;
  /**
   *
   * @type {string}
   * @memberof AreaGeoestadisticasMunicipal
   */
  id: string;
}
/**
 *
 * @export
 * @interface AsentamientosHumanos
 */
export interface AsentamientosHumanos {
  /**
   *
   * @type {string}
   * @memberof AsentamientosHumanos
   */
  id_localidad: string;
  /**
   *
   * @type {string}
   * @memberof AsentamientosHumanos
   */
  id_municipio: string;
  /**
   *
   * @type {string}
   * @memberof AsentamientosHumanos
   */
  id_estado: string;
  /**
   *
   * @type {string}
   * @memberof AsentamientosHumanos
   */
  nombre: string;
  /**
   *
   * @type {string}
   * @memberof AsentamientosHumanos
   */
  id: string;
}
/**
 *
 * @export
 * @interface BaseCartografica
 */
export interface BaseCartografica {
  /**
   *
   * @type {number}
   * @memberof BaseCartografica
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof BaseCartografica
   */
  clave_tipo_asentamiento: string;
  /**
   *
   * @type {string}
   * @memberof BaseCartografica
   */
  clave_tipo_vialidad: string;
  /**
   *
   * @type {string}
   * @memberof BaseCartografica
   */
  descripcion: string;
}
/**
 *
 * @export
 * @interface CatAccion
 */
export interface CatAccion {
  /**
   *
   * @type {number}
   * @memberof CatAccion
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatAccion
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatActividadEconomica
 */
export interface CatActividadEconomica {
  /**
   *
   * @type {number}
   * @memberof CatActividadEconomica
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatActividadEconomica
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatAdministracion
 */
export interface CatAdministracion {
  /**
   *
   * @type {string}
   * @memberof CatAdministracion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatAdministracion
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatAmbito
 */
export interface CatAmbito {
  /**
   *
   * @type {string}
   * @memberof CatAmbito
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatAmbito
   */
  nombre?: string | null;
}
/**
 *
 * @export
 * @interface CatApoyoObtener
 */
export interface CatApoyoObtener {
  /**
   *
   * @type {number}
   * @memberof CatApoyoObtener
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatApoyoObtener
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatAppNvl1
 */
export interface CatAppNvl1 {
  /**
   *
   * @type {number}
   * @memberof CatAppNvl1
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatAppNvl1
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatAppNvl2
 */
export interface CatAppNvl2 {
  /**
   *
   * @type {number}
   * @memberof CatAppNvl2
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatAppNvl2
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatAppNvl3
 */
export interface CatAppNvl3 {
  /**
   *
   * @type {number}
   * @memberof CatAppNvl3
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatAppNvl3
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatAreaConocimiento
 */
export interface CatAreaConocimiento {
  /**
   *
   * @type {number}
   * @memberof CatAreaConocimiento
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatAreaConocimiento
   */
  clave?: string;
  /**
   *
   * @type {string}
   * @memberof CatAreaConocimiento
   */
  nombre?: string;
  /**
   *
   * @type {string}
   * @memberof CatAreaConocimiento
   */
  version?: string;
}
/**
 *
 * @export
 * @interface CatAreaConocimientoCb
 */
export interface CatAreaConocimientoCb {
  /**
   *
   * @type {number}
   * @memberof CatAreaConocimientoCb
   */
  id_area_conocimiento_cb?: number;
  /**
   *
   * @type {string}
   * @memberof CatAreaConocimientoCb
   */
  cve_area_conocimiento_cb: string;
  /**
   *
   * @type {string}
   * @memberof CatAreaConocimientoCb
   */
  desc_area_conocimiento_cb: string;
  /**
   *
   * @type {string}
   * @memberof CatAreaConocimientoCb
   */
  fecha_alta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatAreaConocimientoCb
   */
  fecha_modificacion?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CatAreaConocimientoCb
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatAreaConocimientoCb
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatAreaConocimientoCb
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatAreaDesarrollo
 */
export interface CatAreaDesarrollo {
  /**
   *
   * @type {number}
   * @memberof CatAreaDesarrollo
   */
  id_area_desarrollo?: number;
  /**
   *
   * @type {string}
   * @memberof CatAreaDesarrollo
   */
  cve_area_desarrollo: string;
  /**
   *
   * @type {string}
   * @memberof CatAreaDesarrollo
   */
  desc_area_desarrollo: string;
  /**
   *
   * @type {string}
   * @memberof CatAreaDesarrollo
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatAreaDesarrollo
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatAreaDesarrollo
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatAreaDesarrollo
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatAreaDesarrollo
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatAreaEstrategPeciti
 */
export interface CatAreaEstrategPeciti {
  /**
   *
   * @type {number}
   * @memberof CatAreaEstrategPeciti
   */
  id_area_estrateg_peciti?: number;
  /**
   *
   * @type {string}
   * @memberof CatAreaEstrategPeciti
   */
  cve_area_estrateg_peciti: string;
  /**
   *
   * @type {string}
   * @memberof CatAreaEstrategPeciti
   */
  desc_area_estrateg_peciti: string;
  /**
   *
   * @type {string}
   * @memberof CatAreaEstrategPeciti
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatAreaEstrategPeciti
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatAreaEstrategPeciti
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatAreaEstrategPeciti
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatAreaEstrategPeciti
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatAreaTematica
 */
export interface CatAreaTematica {
  /**
   *
   * @type {number}
   * @memberof CatAreaTematica
   */
  id_area_tematica?: number;
  /**
   *
   * @type {string}
   * @memberof CatAreaTematica
   */
  cve_area_tematica: string;
  /**
   *
   * @type {string}
   * @memberof CatAreaTematica
   */
  desc_clave_tematica?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatAreaTematica
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatAreaTematica
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatAreaTematica
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatAreaTematica
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatAreaTematica
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatBanco
 */
export interface CatBanco {
  /**
   *
   * @type {number}
   * @memberof CatBanco
   */
  id_banco?: number;
  /**
   *
   * @type {number}
   * @memberof CatBanco
   */
  id_pais: number;
  /**
   *
   * @type {string}
   * @memberof CatBanco
   */
  cve_banco: string;
  /**
   *
   * @type {string}
   * @memberof CatBanco
   */
  desc_banco?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatBanco
   */
  ind_tipo_banco?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatBanco
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatBanco
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatBanco
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatBanco
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatBanco
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatCalificacionRubro
 */
export interface CatCalificacionRubro {
  /**
   *
   * @type {number}
   * @memberof CatCalificacionRubro
   */
  id_calificacion_rubro?: number;
  /**
   *
   * @type {string}
   * @memberof CatCalificacionRubro
   */
  cve_calificacion_rubro?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatCalificacionRubro
   */
  desc_calificacion_rubro?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatCalificacionRubro
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatCalificacionRubro
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatCalificacionRubro
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatCalificacionRubro
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatCalificacionRubro
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatCampo
 */
export interface CatCampo {
  /**
   *
   * @type {number}
   * @memberof CatCampo
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatCampo
   */
  clave?: string;
  /**
   *
   * @type {string}
   * @memberof CatCampo
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatCampo
   */
  id_area_conocimiento?: number;
}
/**
 *
 * @export
 * @interface CatCampoMovimiento
 */
export interface CatCampoMovimiento {
  /**
   *
   * @type {number}
   * @memberof CatCampoMovimiento
   */
  id_campo_movimiento?: number;
  /**
   *
   * @type {number}
   * @memberof CatCampoMovimiento
   */
  id_cl_tipo_campo: number;
  /**
   *
   * @type {string}
   * @memberof CatCampoMovimiento
   */
  cve_campo_movimiento: string;
  /**
   *
   * @type {string}
   * @memberof CatCampoMovimiento
   */
  desc_campo_movimiento: string;
  /**
   *
   * @type {string}
   * @memberof CatCampoMovimiento
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatCampoMovimiento
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatCampoMovimiento
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatCampoMovimiento
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatCampoMovimiento
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatCampoPlantilla
 */
export interface CatCampoPlantilla {
  /**
   *
   * @type {number}
   * @memberof CatCampoPlantilla
   */
  id_campo_plantilla?: number;
  /**
   *
   * @type {number}
   * @memberof CatCampoPlantilla
   */
  id_tipo_instrumento: number;
  /**
   *
   * @type {number}
   * @memberof CatCampoPlantilla
   */
  id_cl_tipo_campo: number;
  /**
   *
   * @type {string}
   * @memberof CatCampoPlantilla
   */
  cve_campo_plantilla: string;
  /**
   *
   * @type {string}
   * @memberof CatCampoPlantilla
   */
  desc_campo_plantilla: string;
  /**
   *
   * @type {string}
   * @memberof CatCampoPlantilla
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatCampoPlantilla
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatCampoPlantilla
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatCampoPlantilla
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatCampoPlantilla
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatCapaOrigen
 */
export interface CatCapaOrigen {
  /**
   *
   * @type {number}
   * @memberof CatCapaOrigen
   */
  id_capa_origen?: number;
  /**
   *
   * @type {string}
   * @memberof CatCapaOrigen
   */
  cve_capa_origen?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatCapaOrigen
   */
  desc_capa_origen?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatCapaOrigen
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatCapaOrigen
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {string}
   * @memberof CatCapaOrigen
   */
  ind_activo: string;
  /**
   *
   * @type {string}
   * @memberof CatCapaOrigen
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatCapaOrigen
   */
  usuario_modificacion: string;
}
/**
 *
 * @export
 * @interface CatCaracteristicaContacto
 */
export interface CatCaracteristicaContacto {
  /**
   *
   * @type {string}
   * @memberof CatCaracteristicaContacto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatCaracteristicaContacto
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatCaracteristicaContacto
   */
  id_tipo_contacto?: number;
}
/**
 *
 * @export
 * @interface CatCargoPuestoSni
 */
export interface CatCargoPuestoSni {
  /**
   *
   * @type {number}
   * @memberof CatCargoPuestoSni
   */
  id_cargo_puesto_sni?: number;
  /**
   *
   * @type {string}
   * @memberof CatCargoPuestoSni
   */
  cve_cargo_puesto_sni?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatCargoPuestoSni
   */
  desc_cargo_puesto_sni?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatCargoPuestoSni
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatCargoPuestoSni
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatCargoPuestoSni
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatCargoPuestoSni
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatCargoPuestoSni
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatCatalogoDetalle
 */
export interface CatCatalogoDetalle {
  /**
   *
   * @type {string}
   * @memberof CatCatalogoDetalle
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatCatalogoDetalle
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatCatalogoDetalle
   */
  id_cat_catalogo_maestro?: number;
}
/**
 *
 * @export
 * @interface CatCatalogoMaestro
 */
export interface CatCatalogoMaestro {
  /**
   *
   * @type {number}
   * @memberof CatCatalogoMaestro
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatCatalogoMaestro
   */
  clave?: string;
  /**
   *
   * @type {string}
   * @memberof CatCatalogoMaestro
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatCausaRechazoSni
 */
export interface CatCausaRechazoSni {
  /**
   *
   * @type {number}
   * @memberof CatCausaRechazoSni
   */
  id_causa_rechazo_sni?: number;
  /**
   *
   * @type {string}
   * @memberof CatCausaRechazoSni
   */
  cve_causa_rechazo_sni?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatCausaRechazoSni
   */
  desc_causa_rechazo_sni?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatCausaRechazoSni
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatCausaRechazoSni
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatCausaRechazoSni
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatCausaRechazoSni
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatCausaRechazoSni
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatClasificacionPatente
 */
export interface CatClasificacionPatente {
  /**
   *
   * @type {string}
   * @memberof CatClasificacionPatente
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatClasificacionPatente
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatCodigosPostales
 */
export interface CatCodigosPostales {
  /**
   *
   * @type {string}
   * @memberof CatCodigosPostales
   */
  id_codigo_postal?: string;
  /**
   *
   * @type {string}
   * @memberof CatCodigosPostales
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatCodigosPostales
   */
  id_tipo_asentamiento?: number;
}
/**
 *
 * @export
 * @interface CatCodigosPostalesFilter
 */
export interface CatCodigosPostalesFilter {
  /**
   *
   * @type {string}
   * @memberof CatCodigosPostalesFilter
   */
  id_municipio?: string;
  /**
   *
   * @type {string}
   * @memberof CatCodigosPostalesFilter
   */
  id_entidad?: string;
  /**
   *
   * @type {string}
   * @memberof CatCodigosPostalesFilter
   */
  nombre?: string;
  /**
   *
   * @type {string}
   * @memberof CatCodigosPostalesFilter
   */
  id_tipo_asentamiento?: string;
  /**
   *
   * @type {string}
   * @memberof CatCodigosPostalesFilter
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CatComponente
 */
export interface CatComponente {
  /**
   *
   * @type {number}
   * @memberof CatComponente
   */
  id_componente?: number;
  /**
   *
   * @type {string}
   * @memberof CatComponente
   */
  cve_componente?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatComponente
   */
  desc_componente?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatComponente
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatComponente
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatComponente
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatComponente
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatComponente
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatComponenteApps
 */
export interface CatComponenteApps {
  /**
   *
   * @type {number}
   * @memberof CatComponenteApps
   */
  id_componente?: number;
  /**
   *
   * @type {string}
   * @memberof CatComponenteApps
   */
  cve_componente?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatComponenteApps
   */
  desc_componente?: string | null;
  /**
   *
   * @type {number}
   * @memberof CatComponenteApps
   */
  liberacion: number;
  /**
   *
   * @type {string}
   * @memberof CatComponenteApps
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatComponenteApps
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatComponenteApps
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatComponenteApps
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatComponenteApps
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatComponenteDocto
 */
export interface CatComponenteDocto {
  /**
   *
   * @type {number}
   * @memberof CatComponenteDocto
   */
  id_componente_docto?: number;
  /**
   *
   * @type {string}
   * @memberof CatComponenteDocto
   */
  cve_componente_docto?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatComponenteDocto
   */
  desc_componente_docto?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatComponenteDocto
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatComponenteDocto
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatComponenteDocto
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatComponenteDocto
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatComponenteDocto
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatComponenteHistorico
 */
export interface CatComponenteHistorico {
  /**
   *
   * @type {number}
   * @memberof CatComponenteHistorico
   */
  id_componente_historico?: number;
  /**
   *
   * @type {string}
   * @memberof CatComponenteHistorico
   */
  cve_componente_historico?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatComponenteHistorico
   */
  desc_componente_historico?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatComponenteHistorico
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatComponenteHistorico
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatComponenteHistorico
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatComponenteHistorico
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatComponenteHistorico
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatComponenteLog
 */
export interface CatComponenteLog {
  /**
   *
   * @type {number}
   * @memberof CatComponenteLog
   */
  id_componente_log?: number;
  /**
   *
   * @type {string}
   * @memberof CatComponenteLog
   */
  cve_componente_log?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatComponenteLog
   */
  desc_componente_log?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatComponenteLog
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatComponenteLog
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatComponenteLog
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatComponenteLog
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatComponenteLog
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatConceptoPago
 */
export interface CatConceptoPago {
  /**
   *
   * @type {number}
   * @memberof CatConceptoPago
   */
  id_concepto_pago?: number;
  /**
   *
   * @type {string}
   * @memberof CatConceptoPago
   */
  cve_concepto_pago: string;
  /**
   *
   * @type {string}
   * @memberof CatConceptoPago
   */
  desc_concepto_pago: string;
  /**
   *
   * @type {string}
   * @memberof CatConceptoPago
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatConceptoPago
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatConceptoPago
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatConceptoPago
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatConceptoPago
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatConceptoPlanNegocio
 */
export interface CatConceptoPlanNegocio {
  /**
   *
   * @type {number}
   * @memberof CatConceptoPlanNegocio
   */
  id_concepto_plan_negocio?: number;
  /**
   *
   * @type {string}
   * @memberof CatConceptoPlanNegocio
   */
  cve_concepto_plan_negocio?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatConceptoPlanNegocio
   */
  desc_concepto_plan_negocio?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatConceptoPlanNegocio
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatConceptoPlanNegocio
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatConceptoPlanNegocio
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatConceptoPlanNegocio
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatConceptoPlanNegocio
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatConsejoCertificador
 */
export interface CatConsejoCertificador {
  /**
   *
   * @type {string}
   * @memberof CatConsejoCertificador
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatConsejoCertificador
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatControlUi
 */
export interface CatControlUi {
  /**
   *
   * @type {number}
   * @memberof CatControlUi
   */
  id_control_ui?: number;
  /**
   *
   * @type {string}
   * @memberof CatControlUi
   */
  cve_control_ui?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatControlUi
   */
  desc_control_ui?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatControlUi
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatControlUi
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatControlUi
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatControlUi
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatControlUi
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatCvepreCartera
 */
export interface CatCvepreCartera {
  /**
   *
   * @type {number}
   * @memberof CatCvepreCartera
   */
  id_cvepre_cartera?: number;
  /**
   *
   * @type {string}
   * @memberof CatCvepreCartera
   */
  cve_cvepre_cartera: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreCartera
   */
  desc_cvepre_cartera: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreCartera
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreCartera
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatCvepreCartera
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatCvepreCartera
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreCartera
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatCvepreCuenta
 */
export interface CatCvepreCuenta {
  /**
   *
   * @type {number}
   * @memberof CatCvepreCuenta
   */
  id_cvepre_cuenta?: number;
  /**
   *
   * @type {string}
   * @memberof CatCvepreCuenta
   */
  cve_cvepre_cuenta: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreCuenta
   */
  desc_cvepre_cuenta: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreCuenta
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreCuenta
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatCvepreCuenta
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatCvepreCuenta
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreCuenta
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatCvepreDepto
 */
export interface CatCvepreDepto {
  /**
   *
   * @type {number}
   * @memberof CatCvepreDepto
   */
  id_cvepre_depto?: number;
  /**
   *
   * @type {string}
   * @memberof CatCvepreDepto
   */
  cve_cvepre_depto: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreDepto
   */
  desc_cvepre_depto: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreDepto
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreDepto
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatCvepreDepto
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatCvepreDepto
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreDepto
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatCvepreGeog
 */
export interface CatCvepreGeog {
  /**
   *
   * @type {number}
   * @memberof CatCvepreGeog
   */
  id_cvepre_geog?: number;
  /**
   *
   * @type {string}
   * @memberof CatCvepreGeog
   */
  cve_cvepre_geog: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreGeog
   */
  desc_cvepre_geog: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreGeog
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreGeog
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatCvepreGeog
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatCvepreGeog
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreGeog
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatCvepreGpofnal
 */
export interface CatCvepreGpofnal {
  /**
   *
   * @type {number}
   * @memberof CatCvepreGpofnal
   */
  id_cvepre_gpofuncional?: number;
  /**
   *
   * @type {string}
   * @memberof CatCvepreGpofnal
   */
  cve_cvepre_gpofuncional: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreGpofnal
   */
  desc_cvepre_gpofuncional: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreGpofnal
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreGpofnal
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatCvepreGpofnal
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatCvepreGpofnal
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreGpofnal
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatCvepreProducto
 */
export interface CatCvepreProducto {
  /**
   *
   * @type {number}
   * @memberof CatCvepreProducto
   */
  id_cvepre_producto?: number;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProducto
   */
  cve_cvepre_producto: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProducto
   */
  desc_cvepre_producto: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProducto
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProducto
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatCvepreProducto
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProducto
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProducto
   */
  usuario_modificacion: string;
}
/**
 *
 * @export
 * @interface CatCvepreProginter
 */
export interface CatCvepreProginter {
  /**
   *
   * @type {number}
   * @memberof CatCvepreProginter
   */
  id_cvepre_proginter?: number;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProginter
   */
  cve_cvepre_proginter: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProginter
   */
  desc_cvepre_proginter: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProginter
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProginter
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatCvepreProginter
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProginter
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProginter
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatCvepreProgpresup
 */
export interface CatCvepreProgpresup {
  /**
   *
   * @type {number}
   * @memberof CatCvepreProgpresup
   */
  id_cvepre_progpresup?: number;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProgpresup
   */
  cve_cvepre_progpresup: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProgpresup
   */
  desc_cvepre_progpresup: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProgpresup
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProgpresup
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatCvepreProgpresup
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProgpresup
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreProgpresup
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatCveprePrograma
 */
export interface CatCveprePrograma {
  /**
   *
   * @type {number}
   * @memberof CatCveprePrograma
   */
  id_cvepre_programa?: number;
  /**
   *
   * @type {string}
   * @memberof CatCveprePrograma
   */
  cve_cvepre_programa: string;
  /**
   *
   * @type {string}
   * @memberof CatCveprePrograma
   */
  desc_cvepre_programa: string;
  /**
   *
   * @type {string}
   * @memberof CatCveprePrograma
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatCveprePrograma
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatCveprePrograma
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatCveprePrograma
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatCveprePrograma
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatCvepreUnidejec
 */
export interface CatCvepreUnidejec {
  /**
   *
   * @type {number}
   * @memberof CatCvepreUnidejec
   */
  id_cvepre_unidejec?: number;
  /**
   *
   * @type {string}
   * @memberof CatCvepreUnidejec
   */
  cve_cvepre_unidejec: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreUnidejec
   */
  desc_cvepre_unidejec: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreUnidejec
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreUnidejec
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatCvepreUnidejec
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatCvepreUnidejec
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatCvepreUnidejec
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatDefinicionError
 */
export interface CatDefinicionError {
  /**
   *
   * @type {number}
   * @memberof CatDefinicionError
   */
  id_definicion_error?: number;
  /**
   *
   * @type {number}
   * @memberof CatDefinicionError
   */
  id_tipo_error: number;
  /**
   *
   * @type {number}
   * @memberof CatDefinicionError
   */
  id_funcionalidad: number;
  /**
   *
   * @type {number}
   * @memberof CatDefinicionError
   */
  id_capa_origen: number;
  /**
   *
   * @type {string}
   * @memberof CatDefinicionError
   */
  codigo: string;
  /**
   *
   * @type {string}
   * @memberof CatDefinicionError
   */
  desc_definicion_error: string;
  /**
   *
   * @type {string}
   * @memberof CatDefinicionError
   */
  causa: string;
  /**
   *
   * @type {string}
   * @memberof CatDefinicionError
   */
  resolucion: string;
  /**
   *
   * @type {string}
   * @memberof CatDefinicionError
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatDefinicionError
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatDefinicionError
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatDefinicionError
   */
  ind_persiste_param: string;
  /**
   *
   * @type {string}
   * @memberof CatDefinicionError
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatDefinicionError
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatDefinicionLogUi
 */
export interface CatDefinicionLogUi {
  /**
   *
   * @type {number}
   * @memberof CatDefinicionLogUi
   */
  id_definicion_log_ui?: number;
  /**
   *
   * @type {number}
   * @memberof CatDefinicionLogUi
   */
  id_funcionalidad: number;
  /**
   *
   * @type {number}
   * @memberof CatDefinicionLogUi
   */
  id_log_ui: number;
  /**
   *
   * @type {number}
   * @memberof CatDefinicionLogUi
   */
  id_tipo_evento_ui: number;
  /**
   *
   * @type {string}
   * @memberof CatDefinicionLogUi
   */
  cve_definicion_log_ui: string;
  /**
   *
   * @type {string}
   * @memberof CatDefinicionLogUi
   */
  desc_definicion_log_ui: string;
  /**
   *
   * @type {string}
   * @memberof CatDefinicionLogUi
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatDefinicionLogUi
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatDefinicionLogUi
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatDefinicionLogUi
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatDefinicionLogUi
   */
  usuario_modificacion: string;
}
/**
 *
 * @export
 * @interface CatDerechoTransito
 */
export interface CatDerechoTransito {
  /**
   *
   * @type {string}
   * @memberof CatDerechoTransito
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatDerechoTransito
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatDetalleInfra
 */
export interface CatDetalleInfra {
  /**
   *
   * @type {number}
   * @memberof CatDetalleInfra
   */
  id_detalle_infra?: number;
  /**
   *
   * @type {string}
   * @memberof CatDetalleInfra
   */
  cve_detalle_infra: string;
  /**
   *
   * @type {string}
   * @memberof CatDetalleInfra
   */
  desc_detalle_infra: string;
  /**
   *
   * @type {string}
   * @memberof CatDetalleInfra
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatDetalleInfra
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatDetalleInfra
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatDetalleInfra
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatDetalleInfra
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatDiaNolabConacyt
 */
export interface CatDiaNolabConacyt {
  /**
   *
   * @type {number}
   * @memberof CatDiaNolabConacyt
   */
  id_dia_nolab_conacyt?: number;
  /**
   *
   * @type {string}
   * @memberof CatDiaNolabConacyt
   */
  cve_dia_nolab_conacyt: string;
  /**
   *
   * @type {string}
   * @memberof CatDiaNolabConacyt
   */
  desc_dia_nolab_conacyt: string;
  /**
   *
   * @type {string}
   * @memberof CatDiaNolabConacyt
   */
  fecha?: string;
  /**
   *
   * @type {string}
   * @memberof CatDiaNolabConacyt
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatDiaNolabConacyt
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatDiaNolabConacyt
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatDiaNolabConacyt
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatDiaNolabConacyt
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatDictamenEvaluacion
 */
export interface CatDictamenEvaluacion {
  /**
   *
   * @type {string}
   * @memberof CatDictamenEvaluacion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatDictamenEvaluacion
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatDirecc10082021155950000
 */
export interface CatDirecc10082021155950000 {
  /**
   *
   * @type {number}
   * @memberof CatDirecc10082021155950000
   */
  id_direccion_conacyt?: number;
  /**
   *
   * @type {number}
   * @memberof CatDirecc10082021155950000
   */
  id_direccion_conacyt_padre: number;
  /**
   *
   * @type {string}
   * @memberof CatDirecc10082021155950000
   */
  cve_direccion_conacyt: string;
  /**
   *
   * @type {string}
   * @memberof CatDirecc10082021155950000
   */
  desc_direccion_conacyt?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatDirecc10082021155950000
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatDirecc10082021155950000
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatDirecc10082021155950000
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatDirecc10082021155950000
   */
  correo_direccion_conacyt?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatDirecc10082021155950000
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatDirecc10082021155950000
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatDisciplina
 */
export interface CatDisciplina {
  /**
   *
   * @type {number}
   * @memberof CatDisciplina
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatDisciplina
   */
  clave?: string;
  /**
   *
   * @type {string}
   * @memberof CatDisciplina
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatDisciplina
   */
  id_campo?: number;
}
/**
 *
 * @export
 * @interface CatDistincionConacyt
 */
export interface CatDistincionConacyt {
  /**
   *
   * @type {string}
   * @memberof CatDistincionConacyt
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatDistincionConacyt
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatEspecialidadMedica
 */
export interface CatEspecialidadMedica {
  /**
   *
   * @type {string}
   * @memberof CatEspecialidadMedica
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatEspecialidadMedica
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatEspecialidadMedica
   */
  id_consejo_certificador?: number;
}
/**
 *
 * @export
 * @interface CatEstadoCivil
 */
export interface CatEstadoCivil {
  /**
   *
   * @type {string}
   * @memberof CatEstadoCivil
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstadoCivil
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatEstadoPatente
 */
export interface CatEstadoPatente {
  /**
   *
   * @type {string}
   * @memberof CatEstadoPatente
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstadoPatente
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatEstadoTesis
 */
export interface CatEstadoTesis {
  /**
   *
   * @type {string}
   * @memberof CatEstadoTesis
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstadoTesis
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatEstatusAcademico
 */
export interface CatEstatusAcademico {
  /**
   *
   * @type {string}
   * @memberof CatEstatusAcademico
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusAcademico
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatEstatusBeca
 */
export interface CatEstatusBeca {
  /**
   *
   * @type {number}
   * @memberof CatEstatusBeca
   */
  id_estatus_beca?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusBeca
   */
  cve_estatus_beca: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusBeca
   */
  desc_estatus_beca?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusBeca
   */
  fecha_alta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusBeca
   */
  fecha_modificacion?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusBeca
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusBeca
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusBeca
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusCert
 */
export interface CatEstatusCert {
  /**
   *
   * @type {number}
   * @memberof CatEstatusCert
   */
  id_estatus_cert?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusCert
   */
  cve_estatus_cert: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusCert
   */
  desc_estatus_cert: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusCert
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusCert
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusCert
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusCert
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusCert
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusConvocatoria
 */
export interface CatEstatusConvocatoria {
  /**
   *
   * @type {number}
   * @memberof CatEstatusConvocatoria
   */
  id_estatus_convocatoria?: number;
  /**
   *
   * @type {number}
   * @memberof CatEstatusConvocatoria
   */
  id_tipo_instrumento: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusConvocatoria
   */
  cve_estatus_convocatoria: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusConvocatoria
   */
  desc_estatus_convocatoria: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusConvocatoria
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusConvocatoria
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusConvocatoria
   */
  ind_activo?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusConvocatoria
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusConvocatoria
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusCtaBancaria
 */
export interface CatEstatusCtaBancaria {
  /**
   *
   * @type {number}
   * @memberof CatEstatusCtaBancaria
   */
  id_estatus_cta_bancaria?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusCtaBancaria
   */
  cve_estatus_cta_bancaria: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusCtaBancaria
   */
  desc_estatus_cta_bancaria?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusCtaBancaria
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusCtaBancaria
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusCtaBancaria
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusCtaBancaria
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusCtaBancaria
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusDocumento
 */
export interface CatEstatusDocumento {
  /**
   *
   * @type {number}
   * @memberof CatEstatusDocumento
   */
  id_estatus_documento?: number;
  /**
   *
   * @type {number}
   * @memberof CatEstatusDocumento
   */
  id_tipo_instrumento: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusDocumento
   */
  cve_estatus_documento: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusDocumento
   */
  desc_estatus_documento?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusDocumento
   */
  fecha_alta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusDocumento
   */
  fecha_modificacion?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusDocumento
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusDocumento
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusDocumento
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusEtapa
 */
export interface CatEstatusEtapa {
  /**
   *
   * @type {number}
   * @memberof CatEstatusEtapa
   */
  id_estatus_etapa?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusEtapa
   */
  cve_estatus_etapa?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusEtapa
   */
  desc_estatus_etapa: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusEtapa
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusEtapa
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusEtapa
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusEtapa
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusEtapa
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusEvaluacion
 */
export interface CatEstatusEvaluacion {
  /**
   *
   * @type {number}
   * @memberof CatEstatusEvaluacion
   */
  id_estatus_evaluacion?: number;
  /**
   *
   * @type {number}
   * @memberof CatEstatusEvaluacion
   */
  id_tipo_instrumento: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusEvaluacion
   */
  cve_estatus_eval_individual?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusEvaluacion
   */
  desc_estatus_eval_individual: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusEvaluacion
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusEvaluacion
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusEvaluacion
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusEvaluacion
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusEvaluacion
   */
  usuario_modificacion: string;
}
/**
 *
 * @export
 * @interface CatEstatusFirma
 */
export interface CatEstatusFirma {
  /**
   *
   * @type {number}
   * @memberof CatEstatusFirma
   */
  id_estatus_firma?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusFirma
   */
  cve_estatus_firma: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusFirma
   */
  desc_estatus_firma?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusFirma
   */
  fecha_alta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusFirma
   */
  fecha_modificacion?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusFirma
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusFirma
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusFirma
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusFormalizacion
 */
export interface CatEstatusFormalizacion {
  /**
   *
   * @type {number}
   * @memberof CatEstatusFormalizacion
   */
  id_estatus_formalizacion?: number;
  /**
   *
   * @type {number}
   * @memberof CatEstatusFormalizacion
   */
  id_tipo_instrumento: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusFormalizacion
   */
  cve_estatus_formalizacion?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusFormalizacion
   */
  desc_estatus_formalizacion?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusFormalizacion
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusFormalizacion
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusFormalizacion
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusFormalizacion
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusFormalizacion
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusGrupo
 */
export interface CatEstatusGrupo {
  /**
   *
   * @type {number}
   * @memberof CatEstatusGrupo
   */
  id_estatus_grupo?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusGrupo
   */
  cve_estatus_grupo: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusGrupo
   */
  desc_estatus_grupo?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusGrupo
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusGrupo
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusGrupo
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusGrupo
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusGrupo
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusInstrumento
 */
export interface CatEstatusInstrumento {
  /**
   *
   * @type {number}
   * @memberof CatEstatusInstrumento
   */
  id_estatus_instrumento?: number;
  /**
   *
   * @type {number}
   * @memberof CatEstatusInstrumento
   */
  id_tipo_instrumento: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusInstrumento
   */
  cve_estatus_instrumento: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusInstrumento
   */
  desc_estatus_instrumento?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusInstrumento
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusInstrumento
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusInstrumento
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusInstrumento
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusInstrumento
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusIntegrGrupo
 */
export interface CatEstatusIntegrGrupo {
  /**
   *
   * @type {number}
   * @memberof CatEstatusIntegrGrupo
   */
  id_estatus_integr_grupo?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusIntegrGrupo
   */
  cve_estatus_integr_grupo: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusIntegrGrupo
   */
  desc_estatus_integr_grupo?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusIntegrGrupo
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusIntegrGrupo
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusIntegrGrupo
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusIntegrGrupo
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusIntegrGrupo
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusInvcatapps
 */
export interface CatEstatusInvcatapps {
  /**
   *
   * @type {number}
   * @memberof CatEstatusInvcatapps
   */
  id_estatus_invcatapps?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusInvcatapps
   */
  cve_estatus_invcatapps?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusInvcatapps
   */
  desc_estatus_invcatapps?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusInvcatapps
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusInvcatapps
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusInvcatapps
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusInvcatapps
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusInvcatapps
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusMigratorio
 */
export interface CatEstatusMigratorio {
  /**
   *
   * @type {string}
   * @memberof CatEstatusMigratorio
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMigratorio
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatEstatusMovBeca
 */
export interface CatEstatusMovBeca {
  /**
   *
   * @type {number}
   * @memberof CatEstatusMovBeca
   */
  id_estatus_mov_beca?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovBeca
   */
  cve_estatus_mov_beca: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovBeca
   */
  desc_estatus_mov_beca: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovBeca
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovBeca
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusMovBeca
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovBeca
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovBeca
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusMovGasto
 */
export interface CatEstatusMovGasto {
  /**
   *
   * @type {number}
   * @memberof CatEstatusMovGasto
   */
  id_estatus_mov_gasto?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovGasto
   */
  cve_estatus_mov_gasto: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovGasto
   */
  desc_estatus_mov_gasto?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovGasto
   */
  fecha_alta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovGasto
   */
  fecha_modificacion?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusMovGasto
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovGasto
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovGasto
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusMovSni
 */
export interface CatEstatusMovSni {
  /**
   *
   * @type {number}
   * @memberof CatEstatusMovSni
   */
  id_estatus_mov_sni?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovSni
   */
  cve_estatus_mov_sni: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovSni
   */
  desc_estatus_mov_sni: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovSni
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovSni
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusMovSni
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovSni
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusMovSni
   */
  usuario_modificacion: string;
}
/**
 *
 * @export
 * @interface CatEstatusNotificacion
 */
export interface CatEstatusNotificacion {
  /**
   *
   * @type {number}
   * @memberof CatEstatusNotificacion
   */
  id_estatus_notificacion?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusNotificacion
   */
  cve_estatus_notificacion: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusNotificacion
   */
  desc_estatus_notificacion: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusNotificacion
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusNotificacion
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusNotificacion
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusNotificacion
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusNotificacion
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusPago
 */
export interface CatEstatusPago {
  /**
   *
   * @type {number}
   * @memberof CatEstatusPago
   */
  id_estatus_pago?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusPago
   */
  cve_estatus_pago: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusPago
   */
  desc_estatus_pago: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusPago
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusPago
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusPago
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusPago
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusPago
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusPersona
 */
export interface CatEstatusPersona {
  /**
   *
   * @type {number}
   * @memberof CatEstatusPersona
   */
  id_estatus_persona?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusPersona
   */
  cve_estatus_persona: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusPersona
   */
  desc_estatus_persona?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusPersona
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusPersona
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusPersona
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusPersona
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusPersona
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusProcesoPago
 */
export interface CatEstatusProcesoPago {
  /**
   *
   * @type {number}
   * @memberof CatEstatusProcesoPago
   */
  id_estatus_proceso_pago?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusProcesoPago
   */
  cve_estatus_proceso_pago?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusProcesoPago
   */
  desc_estatus_proceso_pago?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusProcesoPago
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusProcesoPago
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusProcesoPago
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusProcesoPago
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusProcesoPago
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusProyecto
 */
export interface CatEstatusProyecto {
  /**
   *
   * @type {number}
   * @memberof CatEstatusProyecto
   */
  id_estatus_proyecto?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusProyecto
   */
  cve_estatus_proyecto: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusProyecto
   */
  desc_estatus_proyecto?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusProyecto
   */
  fecha_alta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusProyecto
   */
  fecha_modificacion?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusProyecto
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusProyecto
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusProyecto
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusRcea
 */
export interface CatEstatusRcea {
  /**
   *
   * @type {number}
   * @memberof CatEstatusRcea
   */
  id_estatus_rcea?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusRcea
   */
  cve_estatus_rcea: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusRcea
   */
  desc_estatus_rcea: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusRcea
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusRcea
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusRcea
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusRcea
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusRcea
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusReniecyt
 */
export interface CatEstatusReniecyt {
  /**
   *
   * @type {number}
   * @memberof CatEstatusReniecyt
   */
  id_estatus_reniecyt?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusReniecyt
   */
  cve_estatus_reniecyt: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusReniecyt
   */
  desc_estatus_reniecyt?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusReniecyt
   */
  fecha_alta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusReniecyt
   */
  fecha_modificacion?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusReniecyt
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusReniecyt
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusReniecyt
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusReniecytOpe
 */
export interface CatEstatusReniecytOpe {
  /**
   *
   * @type {number}
   * @memberof CatEstatusReniecytOpe
   */
  id_estatus_reniecyt_ope?: number;
  /**
   *
   * @type {number}
   * @memberof CatEstatusReniecytOpe
   */
  id_estatus_reniecyt: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusReniecytOpe
   */
  cve_estatus_reniecyt_ope?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusReniecytOpe
   */
  desc_estatus_reniecyt_ope?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusReniecytOpe
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusReniecytOpe
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusReniecytOpe
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusReniecytOpe
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusReniecytOpe
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusSni
 */
export interface CatEstatusSni {
  /**
   *
   * @type {number}
   * @memberof CatEstatusSni
   */
  id_estatus_sni?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSni
   */
  cve_estatus_sni?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSni
   */
  desc_estatus_sni: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSni
   */
  fecha_alta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSni
   */
  fecha_modificacion?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusSni
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSni
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSni
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusSolAyudante
 */
export interface CatEstatusSolAyudante {
  /**
   *
   * @type {number}
   * @memberof CatEstatusSolAyudante
   */
  id_estatus_sol_ayudante?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSolAyudante
   */
  cve_estatus_sol_ayudante?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSolAyudante
   */
  desc_estatus_sol_ayudante: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSolAyudante
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSolAyudante
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusSolAyudante
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSolAyudante
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSolAyudante
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstatusSolicitud
 */
export interface CatEstatusSolicitud {
  /**
   *
   * @type {number}
   * @memberof CatEstatusSolicitud
   */
  id_estatus_solicitud?: number;
  /**
   *
   * @type {number}
   * @memberof CatEstatusSolicitud
   */
  id_tipo_instrumento: number;
  /**
   *
   * @type {number}
   * @memberof CatEstatusSolicitud
   */
  id_etapa_operacion: number;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSolicitud
   */
  cve_estatus_solicitud: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSolicitud
   */
  desc_estatus_solicitud?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSolicitud
   */
  fecha_alta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSolicitud
   */
  fecha_modificacion?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CatEstatusSolicitud
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSolicitud
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstatusSolicitud
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEstructuraBeca
 */
export interface CatEstructuraBeca {
  /**
   *
   * @type {number}
   * @memberof CatEstructuraBeca
   */
  id_estructura_beca?: number;
  /**
   *
   * @type {string}
   * @memberof CatEstructuraBeca
   */
  id_estructura_beca_padre: string;
  /**
   *
   * @type {string}
   * @memberof CatEstructuraBeca
   */
  cve_estructura_beca: string;
  /**
   *
   * @type {string}
   * @memberof CatEstructuraBeca
   */
  desc_estructura_beca: string;
  /**
   *
   * @type {string}
   * @memberof CatEstructuraBeca
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEstructuraBeca
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEstructuraBeca
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEstructuraBeca
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEstructuraBeca
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEtapaDesarrollo
 */
export interface CatEtapaDesarrollo {
  /**
   *
   * @type {string}
   * @memberof CatEtapaDesarrollo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatEtapaDesarrollo
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatEtapaEvaluacion
 */
export interface CatEtapaEvaluacion {
  /**
   *
   * @type {number}
   * @memberof CatEtapaEvaluacion
   */
  id_etapa_evaluacion?: number;
  /**
   *
   * @type {number}
   * @memberof CatEtapaEvaluacion
   */
  id_tipo_instrumento: number;
  /**
   *
   * @type {string}
   * @memberof CatEtapaEvaluacion
   */
  cve_etapa_evaluacion: string;
  /**
   *
   * @type {string}
   * @memberof CatEtapaEvaluacion
   */
  desc_etapa_evaluacion: string;
  /**
   *
   * @type {string}
   * @memberof CatEtapaEvaluacion
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEtapaEvaluacion
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEtapaEvaluacion
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEtapaEvaluacion
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEtapaEvaluacion
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEtapaOperacion
 */
export interface CatEtapaOperacion {
  /**
   *
   * @type {number}
   * @memberof CatEtapaOperacion
   */
  id_etapa_operacion?: number;
  /**
   *
   * @type {string}
   * @memberof CatEtapaOperacion
   */
  cve_etapa_operacion: string;
  /**
   *
   * @type {string}
   * @memberof CatEtapaOperacion
   */
  desc_etapa_operacion: string;
  /**
   *
   * @type {string}
   * @memberof CatEtapaOperacion
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEtapaOperacion
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEtapaOperacion
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEtapaOperacion
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEtapaOperacion
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEventoHistorico
 */
export interface CatEventoHistorico {
  /**
   *
   * @type {number}
   * @memberof CatEventoHistorico
   */
  id_evento_historico?: number;
  /**
   *
   * @type {number}
   * @memberof CatEventoHistorico
   */
  id_grupo_historico: number;
  /**
   *
   * @type {string}
   * @memberof CatEventoHistorico
   */
  desc_evento_historico: string;
  /**
   *
   * @type {string}
   * @memberof CatEventoHistorico
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEventoHistorico
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEventoHistorico
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEventoHistorico
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEventoHistorico
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatEventoNotificacion
 */
export interface CatEventoNotificacion {
  /**
   *
   * @type {number}
   * @memberof CatEventoNotificacion
   */
  id_evento_notificacion?: number;
  /**
   *
   * @type {number}
   * @memberof CatEventoNotificacion
   */
  id_componente: number;
  /**
   *
   * @type {string}
   * @memberof CatEventoNotificacion
   */
  cve_evento_notificacion: string;
  /**
   *
   * @type {string}
   * @memberof CatEventoNotificacion
   */
  desc_evento_notificacion: string;
  /**
   *
   * @type {string}
   * @memberof CatEventoNotificacion
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatEventoNotificacion
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatEventoNotificacion
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatEventoNotificacion
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatEventoNotificacion
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatExtranjeraPais
 */
export interface CatExtranjeraPais {
  /**
   *
   * @type {number}
   * @memberof CatExtranjeraPais
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatExtranjeraPais
   */
  clave?: string;
  /**
   *
   * @type {string}
   * @memberof CatExtranjeraPais
   */
  nombre?: string;
  /**
   *
   * @type {string}
   * @memberof CatExtranjeraPais
   */
  id_tipo_institucion?: string;
  /**
   *
   * @type {string}
   * @memberof CatExtranjeraPais
   */
  id_pais?: string;
}
/**
 *
 * @export
 * @interface CatFiguraJuridica
 */
export interface CatFiguraJuridica {
  /**
   *
   * @type {number}
   * @memberof CatFiguraJuridica
   */
  id_figura_juridica?: number;
  /**
   *
   * @type {string}
   * @memberof CatFiguraJuridica
   */
  cve_figura_juridica: string;
  /**
   *
   * @type {string}
   * @memberof CatFiguraJuridica
   */
  desc_figura_juridica: string;
  /**
   *
   * @type {string}
   * @memberof CatFiguraJuridica
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatFiguraJuridica
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatFiguraJuridica
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatFiguraJuridica
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatFiguraJuridica
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatFormaCfg
 */
export interface CatFormaCfg {
  /**
   *
   * @type {number}
   * @memberof CatFormaCfg
   */
  id_cat_forma?: number;
  /**
   *
   * @type {number}
   * @memberof CatFormaCfg
   */
  id_cat_tipo_forma: number;
  /**
   *
   * @type {string}
   * @memberof CatFormaCfg
   */
  nombre: string;
  /**
   *
   * @type {string}
   * @memberof CatFormaCfg
   */
  descripcion: string;
  /**
   *
   * @type {boolean}
   * @memberof CatFormaCfg
   */
  activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatFormaCfg
   */
  titulo: string;
  /**
   *
   * @type {string}
   * @memberof CatFormaCfg
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatFormaCfg
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {number}
   * @memberof CatFormaCfg
   */
  carga_grid: number;
  /**
   *
   * @type {string}
   * @memberof CatFormaCfg
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatFormaCfg
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatFormaPagoSni
 */
export interface CatFormaPagoSni {
  /**
   *
   * @type {number}
   * @memberof CatFormaPagoSni
   */
  id_forma_pago_sni?: number;
  /**
   *
   * @type {string}
   * @memberof CatFormaPagoSni
   */
  cve_forma_pago_sni: string;
  /**
   *
   * @type {string}
   * @memberof CatFormaPagoSni
   */
  desc_forma_pago_sni: string;
  /**
   *
   * @type {string}
   * @memberof CatFormaPagoSni
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatFormaPagoSni
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatFormaPagoSni
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatFormaPagoSni
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatFormaPagoSni
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatFormaTitulacion
 */
export interface CatFormaTitulacion {
  /**
   *
   * @type {string}
   * @memberof CatFormaTitulacion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatFormaTitulacion
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatFormatoCfg
 */
export interface CatFormatoCfg {
  /**
   *
   * @type {number}
   * @memberof CatFormatoCfg
   */
  id_cat_formato?: number;
  /**
   *
   * @type {string}
   * @memberof CatFormatoCfg
   */
  formato: string;
  /**
   *
   * @type {string}
   * @memberof CatFormatoCfg
   */
  nombre: string;
  /**
   *
   * @type {string}
   * @memberof CatFormatoCfg
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatFormatoCfg
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatFormatoCfg
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatFormatoCfg
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatFormatoCfg
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatFormatoNotificacion
 */
export interface CatFormatoNotificacion {
  /**
   *
   * @type {number}
   * @memberof CatFormatoNotificacion
   */
  id_formato_notificacion?: number;
  /**
   *
   * @type {string}
   * @memberof CatFormatoNotificacion
   */
  cve_formato_notificacion: string;
  /**
   *
   * @type {string}
   * @memberof CatFormatoNotificacion
   */
  desc_formato_notificacion: string;
  /**
   *
   * @type {string}
   * @memberof CatFormatoNotificacion
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatFormatoNotificacion
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatFormatoNotificacion
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatFormatoNotificacion
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatFormatoNotificacion
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatFuncionSni
 */
export interface CatFuncionSni {
  /**
   *
   * @type {number}
   * @memberof CatFuncionSni
   */
  id_funcion_sni?: number;
  /**
   *
   * @type {string}
   * @memberof CatFuncionSni
   */
  cve_funcion_sni: string;
  /**
   *
   * @type {string}
   * @memberof CatFuncionSni
   */
  desc_funcion_sni: string;
  /**
   *
   * @type {string}
   * @memberof CatFuncionSni
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatFuncionSni
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatFuncionSni
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatFuncionSni
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatFuncionSni
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatFuncionalidad
 */
export interface CatFuncionalidad {
  /**
   *
   * @type {number}
   * @memberof CatFuncionalidad
   */
  id_funcionalidad?: number;
  /**
   *
   * @type {number}
   * @memberof CatFuncionalidad
   */
  id_componente_log: number;
  /**
   *
   * @type {string}
   * @memberof CatFuncionalidad
   */
  cve_funcionalidad: string;
  /**
   *
   * @type {string}
   * @memberof CatFuncionalidad
   */
  desc_funcionalidad: string;
  /**
   *
   * @type {string}
   * @memberof CatFuncionalidad
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatFuncionalidad
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatFuncionalidad
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatFuncionalidad
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatFuncionalidad
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatFuncionalidadFirma
 */
export interface CatFuncionalidadFirma {
  /**
   *
   * @type {number}
   * @memberof CatFuncionalidadFirma
   */
  id_funcionalidad_firma?: number;
  /**
   *
   * @type {number}
   * @memberof CatFuncionalidadFirma
   */
  id_componente: number;
  /**
   *
   * @type {number}
   * @memberof CatFuncionalidadFirma
   */
  id_servicio: number;
  /**
   *
   * @type {string}
   * @memberof CatFuncionalidadFirma
   */
  cve_funcionalidad_firma: string;
  /**
   *
   * @type {string}
   * @memberof CatFuncionalidadFirma
   */
  desc_funcionalidad_firma: string;
  /**
   *
   * @type {string}
   * @memberof CatFuncionalidadFirma
   */
  nombre_operacion: string;
  /**
   *
   * @type {string}
   * @memberof CatFuncionalidadFirma
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatFuncionalidadFirma
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatFuncionalidadFirma
   */
  ind_activo: boolean;
  /**
   *
   * @type {string}
   * @memberof CatFuncionalidadFirma
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatFuncionalidadFirma
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatFundamentoRcea
 */
export interface CatFundamentoRcea {
  /**
   *
   * @type {number}
   * @memberof CatFundamentoRcea
   */
  id_fundamento_rcea?: number;
  /**
   *
   * @type {string}
   * @memberof CatFundamentoRcea
   */
  cve_fundamento_rcea: string;
  /**
   *
   * @type {string}
   * @memberof CatFundamentoRcea
   */
  desc_fundamento_rcea: string;
  /**
   *
   * @type {string}
   * @memberof CatFundamentoRcea
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatFundamentoRcea
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatFundamentoRcea
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatFundamentoRcea
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatFundamentoRcea
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatGenero
 */
export interface CatGenero {
  /**
   *
   * @type {string}
   * @memberof CatGenero
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatGenero
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatIdentificaAutor
 */
export interface CatIdentificaAutor {
  /**
   *
   * @type {string}
   * @memberof CatIdentificaAutor
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatIdentificaAutor
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatIdioma
 */
export interface CatIdioma {
  /**
   *
   * @type {string}
   * @memberof CatIdioma
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatIdioma
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatInstD05152020194521000
 */
export interface CatInstD05152020194521000 {
  /**
   *
   * @type {number}
   * @memberof CatInstD05152020194521000
   */
  id_inst_departamento?: number;
  /**
   *
   * @type {number}
   * @memberof CatInstD05152020194521000
   */
  id_inst_subdependencia: number;
  /**
   *
   * @type {number}
   * @memberof CatInstD05152020194521000
   */
  id_entidad: number;
  /**
   *
   * @type {string}
   * @memberof CatInstD05152020194521000
   */
  cve_inst_departamento: string;
  /**
   *
   * @type {string}
   * @memberof CatInstD05152020194521000
   */
  desc_inst_departamento: string;
  /**
   *
   * @type {string}
   * @memberof CatInstD05152020194521000
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatInstD05152020194521000
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatInstD05152020194521000
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatInstD05152020194521000
   */
  ind_aplica_pago: string;
  /**
   *
   * @type {string}
   * @memberof CatInstD05152020194521000
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatInstD05152020194521000
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatInstD05152020194609000
 */
export interface CatInstD05152020194609000 {
  /**
   *
   * @type {number}
   * @memberof CatInstD05152020194609000
   */
  id_inst_dependencia: number;
  /**
   *
   * @type {number}
   * @memberof CatInstD05152020194609000
   */
  id_institucion: number;
  /**
   *
   * @type {number}
   * @memberof CatInstD05152020194609000
   */
  id_entidad: number;
  /**
   *
   * @type {string}
   * @memberof CatInstD05152020194609000
   */
  cve_inst_dependencia: string;
  /**
   *
   * @type {string}
   * @memberof CatInstD05152020194609000
   */
  desc_inst_dependencia: string;
  /**
   *
   * @type {string}
   * @memberof CatInstD05152020194609000
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatInstD05152020194609000
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {string}
   * @memberof CatInstD05152020194609000
   */
  ind_activo: string;
  /**
   *
   * @type {string}
   * @memberof CatInstD05152020194609000
   */
  ind_aplica_pago: string;
  /**
   *
   * @type {string}
   * @memberof CatInstD05152020194609000
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatInstD05152020194609000
   */
  usuario_modificacion: string;
}
/**
 *
 * @export
 * @interface CatInstExtBeca
 */
export interface CatInstExtBeca {
  /**
   *
   * @type {number}
   * @memberof CatInstExtBeca
   */
  id_inst_ext_beca?: number;
  /**
   *
   * @type {number}
   * @memberof CatInstExtBeca
   */
  id_pais: number;
  /**
   *
   * @type {number}
   * @memberof CatInstExtBeca
   */
  id_inst_ext_beca_padre?: number | null;
  /**
   *
   * @type {number}
   * @memberof CatInstExtBeca
   */
  id_institucion?: number | null;
  /**
   *
   * @type {string}
   * @memberof CatInstExtBeca
   */
  cve_inst_ext_beca?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatInstExtBeca
   */
  desc_inst_ext_beca: string;
  /**
   *
   * @type {string}
   * @memberof CatInstExtBeca
   */
  ranking?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatInstExtBeca
   */
  convenio?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatInstExtBeca
   */
  ind_subsede: string;
  /**
   *
   * @type {string}
   * @memberof CatInstExtBeca
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatInstExtBeca
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatInstExtBeca
   */
  ind_activo?: boolean;
  /**
   *
   * @type {number}
   * @memberof CatInstExtBeca
   */
  id_inst_ext_beca_n5?: number | null;
  /**
   *
   * @type {string}
   * @memberof CatInstExtBeca
   */
  desc_inst_ext_beca_n5?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatInstExtBeca
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatInstExtBeca
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatInstS05152020194703000
 */
export interface CatInstS05152020194703000 {
  /**
   *
   * @type {number}
   * @memberof CatInstS05152020194703000
   */
  id_inst_subdependencia?: number;
  /**
   *
   * @type {number}
   * @memberof CatInstS05152020194703000
   */
  id_inst_dependencia: number;
  /**
   *
   * @type {number}
   * @memberof CatInstS05152020194703000
   */
  id_entidad: number;
  /**
   *
   * @type {string}
   * @memberof CatInstS05152020194703000
   */
  cve_inst_subdependencia?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatInstS05152020194703000
   */
  desc_inst_subdependencia: string;
  /**
   *
   * @type {string}
   * @memberof CatInstS05152020194703000
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatInstS05152020194703000
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatInstS05152020194703000
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatInstS05152020194703000
   */
  ind_aplica_pago?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatInstS05152020194703000
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatInstS05152020194703000
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatInstitucion
 */
export interface CatInstitucion {
  /**
   *
   * @type {string}
   * @memberof CatInstitucion
   */
  id_institucion?: string;
  /**
   *
   * @type {string}
   * @memberof CatInstitucion
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatInstitucion
   */
  id_institucion_padre?: number;
  /**
   *
   * @type {number}
   * @memberof CatInstitucion
   */
  id_tipo_institucion?: number;
  /**
   *
   * @type {number}
   * @memberof CatInstitucion
   */
  id_pais?: number;
  /**
   *
   * @type {number}
   * @memberof CatInstitucion
   */
  id_entidad?: number;
  /**
   *
   * @type {number}
   * @memberof CatInstitucion
   */
  id_entidad_ubicacion?: number;
  /**
   *
   * @type {number}
   * @memberof CatInstitucion
   */
  id_municipio?: number;
  /**
   *
   * @type {number}
   * @memberof CatInstitucion
   */
  id_localidad?: number;
  /**
   *
   * @type {number}
   * @memberof CatInstitucion
   */
  id_tipo_inst_nivel_uno?: number;
  /**
   *
   * @type {number}
   * @memberof CatInstitucion
   */
  id_tipo_inst_nivel_dos?: number;
  /**
   *
   * @type {number}
   * @memberof CatInstitucion
   */
  id_tipo_inst_nivel_tres?: number;
}
/**
 *
 * @export
 * @interface CatLenguaIndigena
 */
export interface CatLenguaIndigena {
  /**
   *
   * @type {string}
   * @memberof CatLenguaIndigena
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatLenguaIndigena
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatListRespPregunta
 */
export interface CatListRespPregunta {
  /**
   *
   * @type {number}
   * @memberof CatListRespPregunta
   */
  id_list_resp_pregunta?: number;
  /**
   *
   * @type {number}
   * @memberof CatListRespPregunta
   */
  id_pregunta_respuesta: number;
  /**
   *
   * @type {string}
   * @memberof CatListRespPregunta
   */
  cve_list_resp_pregunta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatListRespPregunta
   */
  desc_list_resp_pregunta?: string | null;
  /**
   *
   * @type {number}
   * @memberof CatListRespPregunta
   */
  orden?: number | null;
  /**
   *
   * @type {number}
   * @memberof CatListRespPregunta
   */
  puntaje?: number | null;
  /**
   *
   * @type {string}
   * @memberof CatListRespPregunta
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatListRespPregunta
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatListRespPregunta
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatListRespPregunta
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatListRespPregunta
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatListaReportesSni
 */
export interface CatListaReportesSni {
  /**
   *
   * @type {number}
   * @memberof CatListaReportesSni
   */
  id_lista_reportes_sni?: number;
  /**
   *
   * @type {string}
   * @memberof CatListaReportesSni
   */
  cve_lista_reportes_sni: string;
  /**
   *
   * @type {string}
   * @memberof CatListaReportesSni
   */
  desc_lista_reportes_sni: string;
  /**
   *
   * @type {string}
   * @memberof CatListaReportesSni
   */
  nombre_lista_reportes: string;
  /**
   *
   * @type {string}
   * @memberof CatListaReportesSni
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatListaReportesSni
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatListaReportesSni
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatListaReportesSni
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatListaReportesSni
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatListaValor
 */
export interface CatListaValor {
  /**
   *
   * @type {number}
   * @memberof CatListaValor
   */
  id_lista_valor?: number;
  /**
   *
   * @type {number}
   * @memberof CatListaValor
   */
  id_operacion: number;
  /**
   *
   * @type {string}
   * @memberof CatListaValor
   */
  cve_lista_valor: string;
  /**
   *
   * @type {string}
   * @memberof CatListaValor
   */
  desc_lista_valor?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatListaValor
   */
  nombre_lista_valor: string;
  /**
   *
   * @type {string}
   * @memberof CatListaValor
   */
  nombre_llave?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatListaValor
   */
  nombre_valor: string;
  /**
   *
   * @type {string}
   * @memberof CatListaValor
   */
  nombre_servicio?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatListaValor
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatListaValor
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatListaValor
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatListaValor
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatListaValor
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatListaValorCfg
 */
export interface CatListaValorCfg {
  /**
   *
   * @type {number}
   * @memberof CatListaValorCfg
   */
  id_cat_lista_valor?: number;
  /**
   *
   * @type {string}
   * @memberof CatListaValorCfg
   */
  nombre_campo: string;
  /**
   *
   * @type {string}
   * @memberof CatListaValorCfg
   */
  campo_llave: string;
  /**
   *
   * @type {string}
   * @memberof CatListaValorCfg
   */
  descripcion: string;
  /**
   *
   * @type {boolean}
   * @memberof CatListaValorCfg
   */
  activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatListaValorCfg
   */
  nombre_servicio: string;
  /**
   *
   * @type {string}
   * @memberof CatListaValorCfg
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatListaValorCfg
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {string}
   * @memberof CatListaValorCfg
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatListaValorCfg
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatMarcoCfg
 */
export interface CatMarcoCfg {
  /**
   *
   * @type {number}
   * @memberof CatMarcoCfg
   */
  id_cat_marco?: number;
  /**
   *
   * @type {number}
   * @memberof CatMarcoCfg
   */
  id_cat_marco_padre: number;
  /**
   *
   * @type {number}
   * @memberof CatMarcoCfg
   */
  id_cat_forma: number;
  /**
   *
   * @type {number}
   * @memberof CatMarcoCfg
   */
  posicion: number;
  /**
   *
   * @type {string}
   * @memberof CatMarcoCfg
   */
  nombre_servicio: string;
  /**
   *
   * @type {string}
   * @memberof CatMarcoCfg
   */
  nombre_plantilla: string;
  /**
   *
   * @type {string}
   * @memberof CatMarcoCfg
   */
  atributo: string;
  /**
   *
   * @type {string}
   * @memberof CatMarcoCfg
   */
  titulo: string;
  /**
   *
   * @type {string}
   * @memberof CatMarcoCfg
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatMarcoCfg
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {string}
   * @memberof CatMarcoCfg
   */
  cve_cat_marco?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CatMarcoCfg
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatMarcoCfg
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatMarcoCfg
   */
  usuario_modificacion: string;
}
/**
 *
 * @export
 * @interface CatMargen
 */
export interface CatMargen {
  /**
   *
   * @type {string}
   * @memberof CatMargen
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatMargen
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatMenuCfg
 */
export interface CatMenuCfg {
  /**
   *
   * @type {number}
   * @memberof CatMenuCfg
   */
  id_cat_menu?: number;
  /**
   *
   * @type {number}
   * @memberof CatMenuCfg
   */
  id_cat_forma: number;
  /**
   *
   * @type {number}
   * @memberof CatMenuCfg
   */
  id_cat_menu_padre: number;
  /**
   *
   * @type {string}
   * @memberof CatMenuCfg
   */
  nombre: string;
  /**
   *
   * @type {string}
   * @memberof CatMenuCfg
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatMenuCfg
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {number}
   * @memberof CatMenuCfg
   */
  cve_proceso?: number | null;
  /**
   *
   * @type {number}
   * @memberof CatMenuCfg
   */
  cve_operacion?: number | null;
  /**
   *
   * @type {string}
   * @memberof CatMenuCfg
   */
  url?: string | null;
  /**
   *
   * @type {number}
   * @memberof CatMenuCfg
   */
  posicion: number;
  /**
   *
   * @type {boolean}
   * @memberof CatMenuCfg
   */
  activo?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof CatMenuCfg
   */
  cve_cat_menu?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatMenuCfg
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatMenuCfg
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatModalidad
 */
export interface CatModalidad {
  /**
   *
   * @type {number}
   * @memberof CatModalidad
   */
  id_modalidad?: number;
  /**
   *
   * @type {number}
   * @memberof CatModalidad
   */
  id_tipo_instrumento: number;
  /**
   *
   * @type {string}
   * @memberof CatModalidad
   */
  cve_modalidad: string;
  /**
   *
   * @type {string}
   * @memberof CatModalidad
   */
  desc_modalidad?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatModalidad
   */
  fecha_alta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatModalidad
   */
  fecha_modificacion?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CatModalidad
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatModalidad
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatModalidad
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatModalidadFondo
 */
export interface CatModalidadFondo {
  /**
   *
   * @type {number}
   * @memberof CatModalidadFondo
   */
  id_modalidad_fondo?: number;
  /**
   *
   * @type {string}
   * @memberof CatModalidadFondo
   */
  cve_modalidad_fondo: string;
  /**
   *
   * @type {string}
   * @memberof CatModalidadFondo
   */
  desc_modalidad_fondo?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatModalidadFondo
   */
  fecha_alta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatModalidadFondo
   */
  fecha_modificacion?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CatModalidadFondo
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatModalidadFondo
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatModalidadFondo
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatModalidadFosec
 */
export interface CatModalidadFosec {
  /**
   *
   * @type {number}
   * @memberof CatModalidadFosec
   */
  id_modalidad_fosec?: number;
  /**
   *
   * @type {string}
   * @memberof CatModalidadFosec
   */
  cve_modalidad_fosec: string;
  /**
   *
   * @type {string}
   * @memberof CatModalidadFosec
   */
  desc_modalidad_fosec?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatModalidadFosec
   */
  fecha_alta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatModalidadFosec
   */
  fecha_modificacion?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CatModalidadFosec
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatModalidadFosec
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatModalidadFosec
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatMomentoEvento
 */
export interface CatMomentoEvento {
  /**
   *
   * @type {number}
   * @memberof CatMomentoEvento
   */
  id_momento_evento?: number;
  /**
   *
   * @type {string}
   * @memberof CatMomentoEvento
   */
  cve_momento_evento?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatMomentoEvento
   */
  desc_momento_evento?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatMomentoEvento
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatMomentoEvento
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatMomentoEvento
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatMomentoEvento
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatMomentoEvento
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatMoneda
 */
export interface CatMoneda {
  /**
   *
   * @type {number}
   * @memberof CatMoneda
   */
  id_moneda?: number;
  /**
   *
   * @type {number}
   * @memberof CatMoneda
   */
  id_pais: number;
  /**
   *
   * @type {string}
   * @memberof CatMoneda
   */
  cve_moneda: string;
  /**
   *
   * @type {string}
   * @memberof CatMoneda
   */
  desc_moneda?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatMoneda
   */
  desc_corta_moneda: string;
  /**
   *
   * @type {string}
   * @memberof CatMoneda
   */
  fecha_alta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatMoneda
   */
  fecha_modificacion?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CatMoneda
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatMoneda
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatMoneda
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatMotivacioSni
 */
export interface CatMotivacioSni {
  /**
   *
   * @type {number}
   * @memberof CatMotivacioSni
   */
  id_motivacion_sni?: number;
  /**
   *
   * @type {number}
   * @memberof CatMotivacioSni
   */
  id_nivel_sni: number;
  /**
   *
   * @type {number}
   * @memberof CatMotivacioSni
   */
  id_tipo_solicitud_sni: number;
  /**
   *
   * @type {number}
   * @memberof CatMotivacioSni
   */
  id_cl_grp_mot_sni: number;
  /**
   *
   * @type {number}
   * @memberof CatMotivacioSni
   */
  id_area_conocimiento: number;
  /**
   *
   * @type {string}
   * @memberof CatMotivacioSni
   */
  cve_motivacion_sni?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatMotivacioSni
   */
  desc_motivacion_sni?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatMotivacioSni
   */
  ind_valoracion?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatMotivacioSni
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatMotivacioSni
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatMotivacioSni
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatMotivacioSni
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatMotivacioSni
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatMotivoBajaAyuSni
 */
export interface CatMotivoBajaAyuSni {
  /**
   *
   * @type {number}
   * @memberof CatMotivoBajaAyuSni
   */
  id_motivo_baja_ayu_sni?: number;
  /**
   *
   * @type {string}
   * @memberof CatMotivoBajaAyuSni
   */
  cve_motivo_baja_ayu_sni?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatMotivoBajaAyuSni
   */
  desc_motivo_baja_ayu_sni?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatMotivoBajaAyuSni
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatMotivoBajaAyuSni
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatMotivoBajaAyuSni
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatMotivoBajaAyuSni
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatMotivoBajaAyuSni
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatMotivoRechazo
 */
export interface CatMotivoRechazo {
  /**
   *
   * @type {number}
   * @memberof CatMotivoRechazo
   */
  id_motivo_rechazo?: number;
  /**
   *
   * @type {number}
   * @memberof CatMotivoRechazo
   */
  id_tipo_grupo: number;
  /**
   *
   * @type {string}
   * @memberof CatMotivoRechazo
   */
  cve_motivo_rechazo: string;
  /**
   *
   * @type {string}
   * @memberof CatMotivoRechazo
   */
  desc_motivo_rechazo: string;
  /**
   *
   * @type {string}
   * @memberof CatMotivoRechazo
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatMotivoRechazo
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatMotivoRechazo
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatMotivoRechazo
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatMotivoRechazo
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatMotivoRechazoInd
 */
export interface CatMotivoRechazoInd {
  /**
   *
   * @type {number}
   * @memberof CatMotivoRechazoInd
   */
  id_motivo_rechazo_ind?: number;
  /**
   *
   * @type {string}
   * @memberof CatMotivoRechazoInd
   */
  cve_motivo_rechazo_ind: string;
  /**
   *
   * @type {string}
   * @memberof CatMotivoRechazoInd
   */
  desc_motivo_rechazo_ind: string;
  /**
   *
   * @type {string}
   * @memberof CatMotivoRechazoInd
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatMotivoRechazoInd
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatMotivoRechazoInd
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatMotivoRechazoInd
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatMotivoRechazoInd
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatMunicipioFomix
 */
export interface CatMunicipioFomix {
  /**
   *
   * @type {number}
   * @memberof CatMunicipioFomix
   */
  id_municipio_fomix?: number;
  /**
   *
   * @type {number}
   * @memberof CatMunicipioFomix
   */
  id_municipio: number;
  /**
   *
   * @type {string}
   * @memberof CatMunicipioFomix
   */
  cve_municipio: string;
  /**
   *
   * @type {string}
   * @memberof CatMunicipioFomix
   */
  nombre_municipio: string;
  /**
   *
   * @type {string}
   * @memberof CatMunicipioFomix
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatMunicipioFomix
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatMunicipioFomix
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatMunicipioFomix
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatMunicipioFomix
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatNacionalPublico
 */
export interface CatNacionalPublico {
  /**
   *
   * @type {number}
   * @memberof CatNacionalPublico
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatNacionalPublico
   */
  clave?: string;
  /**
   *
   * @type {string}
   * @memberof CatNacionalPublico
   */
  nombre?: string;
  /**
   *
   * @type {string}
   * @memberof CatNacionalPublico
   */
  id_tipo_institucion?: string;
  /**
   *
   * @type {string}
   * @memberof CatNacionalPublico
   */
  id_pais?: string;
  /**
   *
   * @type {string}
   * @memberof CatNacionalPublico
   */
  id_entidad?: string;
  /**
   *
   * @type {string}
   * @memberof CatNacionalPublico
   */
  id_municipio?: string;
  /**
   *
   * @type {string}
   * @memberof CatNacionalPublico
   */
  id_tipo_inst_nivel_uno?: string;
  /**
   *
   * @type {string}
   * @memberof CatNacionalPublico
   */
  id_tipo_inst_nivel_dos?: string;
}
/**
 *
 * @export
 * @interface CatNacionalidad
 */
export interface CatNacionalidad {
  /**
   *
   * @type {string}
   * @memberof CatNacionalidad
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatNacionalidad
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatNacionalidadRenapo
 */
export interface CatNacionalidadRenapo {
  /**
   *
   * @type {number}
   * @memberof CatNacionalidadRenapo
   */
  id_nacionalidad_renapo?: number;
  /**
   *
   * @type {number}
   * @memberof CatNacionalidadRenapo
   */
  id_nacionalidad: number;
  /**
   *
   * @type {string}
   * @memberof CatNacionalidadRenapo
   */
  cve_nacionalidad_renapo: string;
  /**
   *
   * @type {string}
   * @memberof CatNacionalidadRenapo
   */
  desc_nacionalidad_renapo: string;
  /**
   *
   * @type {string}
   * @memberof CatNacionalidadRenapo
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatNacionalidadRenapo
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {string}
   * @memberof CatNacionalidadRenapo
   */
  ind_activo: string;
  /**
   *
   * @type {string}
   * @memberof CatNacionalidadRenapo
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatNacionalidadRenapo
   */
  usuario_modificacion: string;
}
/**
 *
 * @export
 * @interface CatNivelAcademico
 */
export interface CatNivelAcademico {
  /**
   *
   * @type {string}
   * @memberof CatNivelAcademico
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatNivelAcademico
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatNivelAuditoria
 */
export interface CatNivelAuditoria {
  /**
   *
   * @type {number}
   * @memberof CatNivelAuditoria
   */
  id_nivel_auditoria?: number;
  /**
   *
   * @type {string}
   * @memberof CatNivelAuditoria
   */
  cve_nivel_auditoria?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatNivelAuditoria
   */
  desc_nivel_auditoria?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatNivelAuditoria
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatNivelAuditoria
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatNivelAuditoria
   */
  ind_activo: boolean;
  /**
   *
   * @type {string}
   * @memberof CatNivelAuditoria
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatNivelAuditoria
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatNivelDominioLd
 */
export interface CatNivelDominioLd {
  /**
   *
   * @type {string}
   * @memberof CatNivelDominioLd
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatNivelDominioLd
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatNivelPnpc
 */
export interface CatNivelPnpc {
  /**
   *
   * @type {number}
   * @memberof CatNivelPnpc
   */
  id_nivel_pnpc?: number;
  /**
   *
   * @type {string}
   * @memberof CatNivelPnpc
   */
  cve_nivel_pnpc: string;
  /**
   *
   * @type {string}
   * @memberof CatNivelPnpc
   */
  desc_nivel_pnpc: string;
  /**
   *
   * @type {string}
   * @memberof CatNivelPnpc
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatNivelPnpc
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatNivelPnpc
   */
  ind_activo: boolean;
  /**
   *
   * @type {string}
   * @memberof CatNivelPnpc
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatNivelPnpc
   */
  usuario_modificacion: string;
}
/**
 *
 * @export
 * @interface CatNivelSni
 */
export interface CatNivelSni {
  /**
   *
   * @type {number}
   * @memberof CatNivelSni
   */
  id_nivel_sni?: number;
  /**
   *
   * @type {string}
   * @memberof CatNivelSni
   */
  cve_nivel_sni: string;
  /**
   *
   * @type {string}
   * @memberof CatNivelSni
   */
  desc_nivel_sni: string;
  /**
   *
   * @type {string}
   * @memberof CatNivelSni
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatNivelSni
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatNivelSni
   */
  ind_activo: boolean;
  /**
   *
   * @type {string}
   * @memberof CatNivelSni
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatNivelSni
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatOcdeClase
 */
export interface CatOcdeClase {
  /**
   *
   * @type {number}
   * @memberof CatOcdeClase
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatOcdeClase
   */
  clave: string;
  /**
   *
   * @type {string}
   * @memberof CatOcdeClase
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatOcdeClase
   */
  id_ocde_grupo?: number;
}
/**
 *
 * @export
 * @interface CatOcdeDivision
 */
export interface CatOcdeDivision {
  /**
   *
   * @type {number}
   * @memberof CatOcdeDivision
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatOcdeDivision
   */
  clave: string;
  /**
   *
   * @type {string}
   * @memberof CatOcdeDivision
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatOcdeDivision
   */
  id_ocde_sector?: number;
}
/**
 *
 * @export
 * @interface CatOcdeGrupo
 */
export interface CatOcdeGrupo {
  /**
   *
   * @type {number}
   * @memberof CatOcdeGrupo
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatOcdeGrupo
   */
  clave: string;
  /**
   *
   * @type {string}
   * @memberof CatOcdeGrupo
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatOcdeGrupo
   */
  id_ocde_division?: number;
}
/**
 *
 * @export
 * @interface CatOcdeSector
 */
export interface CatOcdeSector {
  /**
   *
   * @type {number}
   * @memberof CatOcdeSector
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatOcdeSector
   */
  clave: string;
  /**
   *
   * @type {string}
   * @memberof CatOcdeSector
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatOcupacion
 */
export interface CatOcupacion {
  /**
   *
   * @type {number}
   * @memberof CatOcupacion
   */
  id_ocupacion?: number;
  /**
   *
   * @type {string}
   * @memberof CatOcupacion
   */
  cve_ocupacion: string;
  /**
   *
   * @type {string}
   * @memberof CatOcupacion
   */
  desc_ocupacion: string;
  /**
   *
   * @type {string}
   * @memberof CatOcupacion
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatOcupacion
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatOcupacion
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatOcupacion
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatOcupacion
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatOperacionPago
 */
export interface CatOperacionPago {
  /**
   *
   * @type {number}
   * @memberof CatOperacionPago
   */
  id_operacion_pago?: number;
  /**
   *
   * @type {string}
   * @memberof CatOperacionPago
   */
  cve_operacion_pago?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatOperacionPago
   */
  desc_operacion_pago?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatOperacionPago
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatOperacionPago
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatOperacionPago
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatOperacionPago
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatOperacionPago
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatOperadorComparacion
 */
export interface CatOperadorComparacion {
  /**
   *
   * @type {number}
   * @memberof CatOperadorComparacion
   */
  id_operador_comparacion?: number;
  /**
   *
   * @type {string}
   * @memberof CatOperadorComparacion
   */
  cve_operador_comparacion: string;
  /**
   *
   * @type {string}
   * @memberof CatOperadorComparacion
   */
  desc_operador_comparacion: string;
  /**
   *
   * @type {string}
   * @memberof CatOperadorComparacion
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatOperadorComparacion
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatOperadorComparacion
   */
  ind_activo: boolean;
  /**
   *
   * @type {string}
   * @memberof CatOperadorComparacion
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatOperadorComparacion
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatOrigenPago
 */
export interface CatOrigenPago {
  /**
   *
   * @type {number}
   * @memberof CatOrigenPago
   */
  id_origen_pago?: number;
  /**
   *
   * @type {string}
   * @memberof CatOrigenPago
   */
  cve_origen_pago: string;
  /**
   *
   * @type {string}
   * @memberof CatOrigenPago
   */
  desc_origen_pago: string;
  /**
   *
   * @type {string}
   * @memberof CatOrigenPago
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatOrigenPago
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatOrigenPago
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatOrigenPago
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatOrigenPago
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatOrigenPresupuesto
 */
export interface CatOrigenPresupuesto {
  /**
   *
   * @type {number}
   * @memberof CatOrigenPresupuesto
   */
  id_origen_presupuesto?: number;
  /**
   *
   * @type {string}
   * @memberof CatOrigenPresupuesto
   */
  cve_origen_presupuesto: string;
  /**
   *
   * @type {string}
   * @memberof CatOrigenPresupuesto
   */
  desc_origen_presupuesto: string;
  /**
   *
   * @type {string}
   * @memberof CatOrigenPresupuesto
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatOrigenPresupuesto
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatOrigenPresupuesto
   */
  ind_activo: boolean;
  /**
   *
   * @type {string}
   * @memberof CatOrigenPresupuesto
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatOrigenPresupuesto
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatOrigenReporte
 */
export interface CatOrigenReporte {
  /**
   *
   * @type {string}
   * @memberof CatOrigenReporte
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatOrigenReporte
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatPais
 */
export interface CatPais {
  /**
   *
   * @type {string}
   * @memberof CatPais
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatPais
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatPalabraClave
 */
export interface CatPalabraClave {
  /**
   *
   * @type {number}
   * @memberof CatPalabraClave
   */
  id_palabra_clave?: number;
  /**
   *
   * @type {number}
   * @memberof CatPalabraClave
   */
  id_formato_notificacion: number;
  /**
   *
   * @type {string}
   * @memberof CatPalabraClave
   */
  cve_palabra_clave: string;
  /**
   *
   * @type {string}
   * @memberof CatPalabraClave
   */
  desc_palabra_clave: string;
  /**
   *
   * @type {string}
   * @memberof CatPalabraClave
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatPalabraClave
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatPalabraClave
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatPalabraClave
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatPalabraClave
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatParametroConvSni
 */
export interface CatParametroConvSni {
  /**
   *
   * @type {number}
   * @memberof CatParametroConvSni
   */
  id_parametro_conv_sni?: number;
  /**
   *
   * @type {number}
   * @memberof CatParametroConvSni
   */
  id_cl_tipo_parametro: number;
  /**
   *
   * @type {string}
   * @memberof CatParametroConvSni
   */
  cve_parametro_conv_sni: string;
  /**
   *
   * @type {string}
   * @memberof CatParametroConvSni
   */
  desc_parametro_conv_sni: string;
  /**
   *
   * @type {string}
   * @memberof CatParametroConvSni
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatParametroConvSni
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatParametroConvSni
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatParametroConvSni
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatParametroConvSni
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatParametrosSeguridad
 */
export interface CatParametrosSeguridad {
  /**
   *
   * @type {number}
   * @memberof CatParametrosSeguridad
   */
  id_parametros_seguridad?: number;
  /**
   *
   * @type {string}
   * @memberof CatParametrosSeguridad
   */
  cve_parametro_seguridad: string;
  /**
   *
   * @type {string}
   * @memberof CatParametrosSeguridad
   */
  desc_parametro_seguridad: string;
  /**
   *
   * @type {string}
   * @memberof CatParametrosSeguridad
   */
  valor: string;
  /**
   *
   * @type {string}
   * @memberof CatParametrosSeguridad
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatParametrosSeguridad
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatParametrosSeguridad
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatParametrosSeguridad
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatParametrosSeguridad
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatPartDivulgacion
 */
export interface CatPartDivulgacion {
  /**
   *
   * @type {string}
   * @memberof CatPartDivulgacion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatPartDivulgacion
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatPeriodoLectivo
 */
export interface CatPeriodoLectivo {
  /**
   *
   * @type {number}
   * @memberof CatPeriodoLectivo
   */
  id_periodo_lectivo?: number;
  /**
   *
   * @type {string}
   * @memberof CatPeriodoLectivo
   */
  cve_periodo_lectivo: string;
  /**
   *
   * @type {string}
   * @memberof CatPeriodoLectivo
   */
  desc_periodo_lectivo: string;
  /**
   *
   * @type {string}
   * @memberof CatPeriodoLectivo
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatPeriodoLectivo
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatPeriodoLectivo
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatPeriodoLectivo
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatPeriodoLectivo
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatPoderOtorgado
 */
export interface CatPoderOtorgado {
  /**
   *
   * @type {number}
   * @memberof CatPoderOtorgado
   */
  id_poder_otorgado?: number;
  /**
   *
   * @type {string}
   * @memberof CatPoderOtorgado
   */
  cve_poder_otorgado: string;
  /**
   *
   * @type {string}
   * @memberof CatPoderOtorgado
   */
  desc_poder_otorgado?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatPoderOtorgado
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatPoderOtorgado
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatPoderOtorgado
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatPoderOtorgado
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatPoderOtorgado
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatPregunta
 */
export interface CatPregunta {
  /**
   *
   * @type {number}
   * @memberof CatPregunta
   */
  id_pregunta?: number;
  /**
   *
   * @type {number}
   * @memberof CatPregunta
   */
  id_tipo_instrumento: number;
  /**
   *
   * @type {string}
   * @memberof CatPregunta
   */
  cve_pregunta: string;
  /**
   *
   * @type {string}
   * @memberof CatPregunta
   */
  desc_pregunta: string;
  /**
   *
   * @type {string}
   * @memberof CatPregunta
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatPregunta
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatPregunta
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatPregunta
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatPregunta
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatPreguntaRespuesta
 */
export interface CatPreguntaRespuesta {
  /**
   *
   * @type {number}
   * @memberof CatPreguntaRespuesta
   */
  id_pregunta_respuesta?: number;
  /**
   *
   * @type {number}
   * @memberof CatPreguntaRespuesta
   */
  id_pregunta: number;
  /**
   *
   * @type {number}
   * @memberof CatPreguntaRespuesta
   */
  id_tipo_respuesta_eval: number;
  /**
   *
   * @type {string}
   * @memberof CatPreguntaRespuesta
   */
  cve_pregunta_respuesta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatPreguntaRespuesta
   */
  desc_pregunta_respuesta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatPreguntaRespuesta
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatPreguntaRespuesta
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatPreguntaRespuesta
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatPreguntaRespuesta
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatPreguntaRespuesta
   */
  usuario_modificacion: string;
}
/**
 *
 * @export
 * @interface CatProceso
 */
export interface CatProceso {
  /**
   *
   * @type {number}
   * @memberof CatProceso
   */
  id_proceso?: number;
  /**
   *
   * @type {string}
   * @memberof CatProceso
   */
  cve_proceso: string;
  /**
   *
   * @type {string}
   * @memberof CatProceso
   */
  desc_proceso?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatProceso
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatProceso
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatProceso
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatProceso
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatProceso
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatProductoDivulgacion
 */
export interface CatProductoDivulgacion {
  /**
   *
   * @type {string}
   * @memberof CatProductoDivulgacion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatProductoDivulgacion
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatProductoEvaluacion
 */
export interface CatProductoEvaluacion {
  /**
   *
   * @type {string}
   * @memberof CatProductoEvaluacion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatProductoEvaluacion
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatPuebloIndigena
 */
export interface CatPuebloIndigena {
  /**
   *
   * @type {string}
   * @memberof CatPuebloIndigena
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatPuebloIndigena
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatRecomdsEvalFomix
 */
export interface CatRecomdsEvalFomix {
  /**
   *
   * @type {number}
   * @memberof CatRecomdsEvalFomix
   */
  id_recomds_eval_fomix?: number;
  /**
   *
   * @type {number}
   * @memberof CatRecomdsEvalFomix
   */
  id_cl_tipo_recomendacion: number;
  /**
   *
   * @type {string}
   * @memberof CatRecomdsEvalFomix
   */
  cve_recomds_eval_fomix?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatRecomdsEvalFomix
   */
  desc_recomds_eval_fomix?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatRecomdsEvalFomix
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatRecomdsEvalFomix
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatRecomdsEvalFomix
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatRecomdsEvalFomix
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatRecomdsEvalFomix
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatRecomendacionRcea
 */
export interface CatRecomendacionRcea {
  /**
   *
   * @type {number}
   * @memberof CatRecomendacionRcea
   */
  id_recomendacion_rcea?: number;
  /**
   *
   * @type {string}
   * @memberof CatRecomendacionRcea
   */
  cve_recomendacion_rcea?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatRecomendacionRcea
   */
  desc_recomendacion_rcea?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatRecomendacionRcea
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatRecomendacionRcea
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatRecomendacionRcea
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatRecomendacionRcea
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatRecomendacionRcea
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatRedTematicaConacyt
 */
export interface CatRedTematicaConacyt {
  /**
   *
   * @type {string}
   * @memberof CatRedTematicaConacyt
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatRedTematicaConacyt
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatRolIntegrantGrpTrab
 */
export interface CatRolIntegrantGrpTrab {
  /**
   *
   * @type {number}
   * @memberof CatRolIntegrantGrpTrab
   */
  id_rol_integrant_grp_trab?: number;
  /**
   *
   * @type {number}
   * @memberof CatRolIntegrantGrpTrab
   */
  id_tipo_grupo_trabajo: number;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegrantGrpTrab
   */
  cve_rol_integrant_grp_trab: string;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegrantGrpTrab
   */
  desc_rol_integrant_grp_trab: string;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegrantGrpTrab
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegrantGrpTrab
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatRolIntegrantGrpTrab
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegrantGrpTrab
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegrantGrpTrab
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatRolIntegranteEval
 */
export interface CatRolIntegranteEval {
  /**
   *
   * @type {number}
   * @memberof CatRolIntegranteEval
   */
  id_rol_integrante_eval?: number;
  /**
   *
   * @type {number}
   * @memberof CatRolIntegranteEval
   */
  id_tipo_instrumento: number;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegranteEval
   */
  cve_rol_integrante_eval: string;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegranteEval
   */
  desc_rol_integrante_eval: string;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegranteEval
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegranteEval
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatRolIntegranteEval
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegranteEval
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegranteEval
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatRolIntegranteGrupo
 */
export interface CatRolIntegranteGrupo {
  /**
   *
   * @type {number}
   * @memberof CatRolIntegranteGrupo
   */
  id_rol_integrante_grupo?: number;
  /**
   *
   * @type {number}
   * @memberof CatRolIntegranteGrupo
   */
  id_tipo_grupo: number;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegranteGrupo
   */
  cve_rol_integrante_grupo: string;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegranteGrupo
   */
  desc_rol_integrante_grupo: string;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegranteGrupo
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegranteGrupo
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatRolIntegranteGrupo
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegranteGrupo
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatRolIntegranteGrupo
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatRolPersFisProgPnpc
 */
export interface CatRolPersFisProgPnpc {
  /**
   *
   * @type {number}
   * @memberof CatRolPersFisProgPnpc
   */
  id_rol_pers_fis_prog_pnpc?: number;
  /**
   *
   * @type {string}
   * @memberof CatRolPersFisProgPnpc
   */
  cve_rol_pers_fis_prog_pnpc: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersFisProgPnpc
   */
  desc_rol_pers_fis_prog_pnpc: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersFisProgPnpc
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersFisProgPnpc
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatRolPersFisProgPnpc
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatRolPersFisProgPnpc
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersFisProgPnpc
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatRolPersonaFisBeca
 */
export interface CatRolPersonaFisBeca {
  /**
   *
   * @type {number}
   * @memberof CatRolPersonaFisBeca
   */
  id_rol_persona_fis_beca?: number;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaFisBeca
   */
  cve_rol_persona_fis_beca: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaFisBeca
   */
  desc_rol_persona_fis_beca: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaFisBeca
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaFisBeca
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatRolPersonaFisBeca
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaFisBeca
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaFisBeca
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatRolPersonaFisFondo
 */
export interface CatRolPersonaFisFondo {
  /**
   *
   * @type {number}
   * @memberof CatRolPersonaFisFondo
   */
  id_rol_persona_fis_fondo?: number;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaFisFondo
   */
  cve_rol_persona_fis_fondo: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaFisFondo
   */
  desc_rol_persona_fis_fondo: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaFisFondo
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaFisFondo
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatRolPersonaFisFondo
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaFisFondo
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaFisFondo
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatRolPersonaMorFondo
 */
export interface CatRolPersonaMorFondo {
  /**
   *
   * @type {number}
   * @memberof CatRolPersonaMorFondo
   */
  id_rol_persona_mor_fondo?: number;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaMorFondo
   */
  cve_rol_persona_mor_fondo: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaMorFondo
   */
  desc_rol_persona_mor_fondo: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaMorFondo
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaMorFondo
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatRolPersonaMorFondo
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaMorFondo
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonaMorFondo
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatRolPersonalBeca
 */
export interface CatRolPersonalBeca {
  /**
   *
   * @type {number}
   * @memberof CatRolPersonalBeca
   */
  id_rol_personal_beca?: number;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonalBeca
   */
  cve_rol_personal_beca: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonalBeca
   */
  desc_rol_personal_beca: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonalBeca
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonalBeca
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatRolPersonalBeca
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonalBeca
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatRolPersonalBeca
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatRubro
 */
export interface CatRubro {
  /**
   *
   * @type {number}
   * @memberof CatRubro
   */
  id_rubro?: number;
  /**
   *
   * @type {number}
   * @memberof CatRubro
   */
  id_tipo_instrumento: number;
  /**
   *
   * @type {number}
   * @memberof CatRubro
   */
  id_tipo_gasto: number;
  /**
   *
   * @type {string}
   * @memberof CatRubro
   */
  cve_rubro: string;
  /**
   *
   * @type {string}
   * @memberof CatRubro
   */
  desc_rubro: string;
  /**
   *
   * @type {string}
   * @memberof CatRubro
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatRubro
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatRubro
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatRubro
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatRubro
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatRubroCalificacion
 */
export interface CatRubroCalificacion {
  /**
   *
   * @type {number}
   * @memberof CatRubroCalificacion
   */
  id_rubro_calificacion?: number;
  /**
   *
   * @type {string}
   * @memberof CatRubroCalificacion
   */
  cve_rubro_calificacion: string;
  /**
   *
   * @type {string}
   * @memberof CatRubroCalificacion
   */
  desc_rubro_calificacion: string;
  /**
   *
   * @type {string}
   * @memberof CatRubroCalificacion
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatRubroCalificacion
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatRubroCalificacion
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatRubroCalificacion
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatRubroCalificacion
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatScianClase
 */
export interface CatScianClase {
  /**
   *
   * @type {number}
   * @memberof CatScianClase
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatScianClase
   */
  clave: string;
  /**
   *
   * @type {string}
   * @memberof CatScianClase
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatScianClase
   */
  id_scian_grupo?: number;
}
/**
 *
 * @export
 * @interface CatScianDivision
 */
export interface CatScianDivision {
  /**
   *
   * @type {number}
   * @memberof CatScianDivision
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatScianDivision
   */
  clave: string;
  /**
   *
   * @type {string}
   * @memberof CatScianDivision
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatScianDivision
   */
  id_scian_sector?: number;
}
/**
 *
 * @export
 * @interface CatScianGrupo
 */
export interface CatScianGrupo {
  /**
   *
   * @type {number}
   * @memberof CatScianGrupo
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatScianGrupo
   */
  clave: string;
  /**
   *
   * @type {string}
   * @memberof CatScianGrupo
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatScianGrupo
   */
  id_scian_division?: number;
}
/**
 *
 * @export
 * @interface CatScianSector
 */
export interface CatScianSector {
  /**
   *
   * @type {number}
   * @memberof CatScianSector
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatScianSector
   */
  clave: string;
  /**
   *
   * @type {string}
   * @memberof CatScianSector
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatScianSubclase
 */
export interface CatScianSubclase {
  /**
   *
   * @type {number}
   * @memberof CatScianSubclase
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatScianSubclase
   */
  clave: string;
  /**
   *
   * @type {string}
   * @memberof CatScianSubclase
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatScianSubclase
   */
  id_scian_clase?: number;
}
/**
 *
 * @export
 * @interface CatSeccionCvu
 */
export interface CatSeccionCvu {
  /**
   *
   * @type {number}
   * @memberof CatSeccionCvu
   */
  id_seccion_cvu?: number;
  /**
   *
   * @type {string}
   * @memberof CatSeccionCvu
   */
  cve_seccion_cvu: string;
  /**
   *
   * @type {string}
   * @memberof CatSeccionCvu
   */
  desc_seccion_cvu: string;
  /**
   *
   * @type {string}
   * @memberof CatSeccionCvu
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatSeccionCvu
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatSeccionCvu
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatSeccionCvu
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatSeccionCvu
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatSeccionSldBeca
 */
export interface CatSeccionSldBeca {
  /**
   *
   * @type {number}
   * @memberof CatSeccionSldBeca
   */
  id_seccion_sld_beca?: number;
  /**
   *
   * @type {number}
   * @memberof CatSeccionSldBeca
   */
  id_tipo_instrumento: number;
  /**
   *
   * @type {string}
   * @memberof CatSeccionSldBeca
   */
  cve_seccion_sld_beca: string;
  /**
   *
   * @type {string}
   * @memberof CatSeccionSldBeca
   */
  desc_seccion_sld_beca: string;
  /**
   *
   * @type {string}
   * @memberof CatSeccionSldBeca
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatSeccionSldBeca
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatSeccionSldBeca
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatSeccionSldBeca
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatSeccionSldBeca
   */
  usuario_modificacion: string;
}
/**
 *
 * @export
 * @interface CatSectorApf
 */
export interface CatSectorApf {
  /**
   *
   * @type {number}
   * @memberof CatSectorApf
   */
  id_sector_apf?: number;
  /**
   *
   * @type {string}
   * @memberof CatSectorApf
   */
  cve_sector_apf: string;
  /**
   *
   * @type {string}
   * @memberof CatSectorApf
   */
  desc_sector_apf: string;
  /**
   *
   * @type {string}
   * @memberof CatSectorApf
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatSectorApf
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatSectorApf
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatSectorApf
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatSectorApf
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatSectorDivulgacion
 */
export interface CatSectorDivulgacion {
  /**
   *
   * @type {string}
   * @memberof CatSectorDivulgacion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatSectorDivulgacion
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatServicio
 */
export interface CatServicio {
  /**
   *
   * @type {number}
   * @memberof CatServicio
   */
  id_servicio?: number;
  /**
   *
   * @type {string}
   * @memberof CatServicio
   */
  cve_servicio?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatServicio
   */
  desc_servicio: string;
  /**
   *
   * @type {string}
   * @memberof CatServicio
   */
  ruta_servicio?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatServicio
   */
  wsdl?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatServicio
   */
  wadl: string;
  /**
   *
   * @type {string}
   * @memberof CatServicio
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatServicio
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatServicio
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatServicio
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatServicio
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatSubDisciplina
 */
export interface CatSubDisciplina {
  /**
   *
   * @type {number}
   * @memberof CatSubDisciplina
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatSubDisciplina
   */
  clave?: string;
  /**
   *
   * @type {string}
   * @memberof CatSubDisciplina
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatSubDisciplina
   */
  id_disciplina?: number;
}
/**
 *
 * @export
 * @interface CatSubmodalidadFosec
 */
export interface CatSubmodalidadFosec {
  /**
   *
   * @type {number}
   * @memberof CatSubmodalidadFosec
   */
  id_submodalidad_fosec: number;
  /**
   *
   * @type {number}
   * @memberof CatSubmodalidadFosec
   */
  id_modalidad_fosec: number;
  /**
   *
   * @type {string}
   * @memberof CatSubmodalidadFosec
   */
  cve_submodalidad_fosec: string;
  /**
   *
   * @type {string}
   * @memberof CatSubmodalidadFosec
   */
  desc_submodalidad_fosec: string;
  /**
   *
   * @type {string}
   * @memberof CatSubmodalidadFosec
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatSubmodalidadFosec
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {string}
   * @memberof CatSubmodalidadFosec
   */
  ind_activo: string;
  /**
   *
   * @type {string}
   * @memberof CatSubmodalidadFosec
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatSubmodalidadFosec
   */
  usuario_modificacion: string;
}
/**
 *
 * @export
 * @interface CatSubseccionCvu
 */
export interface CatSubseccionCvu {
  /**
   *
   * @type {number}
   * @memberof CatSubseccionCvu
   */
  id_subseccion_cvu?: number;
  /**
   *
   * @type {number}
   * @memberof CatSubseccionCvu
   */
  id_seccion_cvu: number;
  /**
   *
   * @type {string}
   * @memberof CatSubseccionCvu
   */
  cve_subseccion_cvu: string;
  /**
   *
   * @type {string}
   * @memberof CatSubseccionCvu
   */
  desc_subseccion_cvu: string;
  /**
   *
   * @type {string}
   * @memberof CatSubseccionCvu
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatSubseccionCvu
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatSubseccionCvu
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatSubseccionCvu
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatSubseccionCvu
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTamanioEmpresa
 */
export interface CatTamanioEmpresa {
  /**
   *
   * @type {number}
   * @memberof CatTamanioEmpresa
   */
  id_tamanio_empresa?: number;
  /**
   *
   * @type {string}
   * @memberof CatTamanioEmpresa
   */
  cve_tamanio_empresa: string;
  /**
   *
   * @type {string}
   * @memberof CatTamanioEmpresa
   */
  desc_tamanio_empresa: string;
  /**
   *
   * @type {string}
   * @memberof CatTamanioEmpresa
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTamanioEmpresa
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTamanioEmpresa
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTamanioEmpresa
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTamanioEmpresa
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTerminoGenerico
 */
export interface CatTerminoGenerico {
  /**
   *
   * @type {string}
   * @memberof CatTerminoGenerico
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTerminoGenerico
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoActDivulgacion
 */
export interface CatTipoActDivulgacion {
  /**
   *
   * @type {string}
   * @memberof CatTipoActDivulgacion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoActDivulgacion
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoAntecedente
 */
export interface CatTipoAntecedente {
  /**
   *
   * @type {number}
   * @memberof CatTipoAntecedente
   */
  id_tipo_antecedente?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoAntecedente
   */
  cve_tipo_antecedente?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoAntecedente
   */
  desc_tipo_antecedente?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoAntecedente
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoAntecedente
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoAntecedente
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoAntecedente
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoAntecedente
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoApoyo
 */
export interface CatTipoApoyo {
  /**
   *
   * @type {number}
   * @memberof CatTipoApoyo
   */
  id_tipo_apoyo?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoApoyo
   */
  cve_tipo_apoyo?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoApoyo
   */
  desc_tipo_apoyo?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoApoyo
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoApoyo
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoApoyo
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoApoyo
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoApoyo
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoAsentamiento
 */
export interface CatTipoAsentamiento {
  /**
   *
   * @type {string}
   * @memberof CatTipoAsentamiento
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoAsentamiento
   */
  nombre?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoCalificacion
 */
export interface CatTipoCalificacion {
  /**
   *
   * @type {number}
   * @memberof CatTipoCalificacion
   */
  id_tipo_calificacion?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoCalificacion
   */
  cve_tipo_calificacion?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoCalificacion
   */
  desc_tipo_calificacion?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoCalificacion
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoCalificacion
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoCalificacion
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoCalificacion
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoCalificacion
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoCambio
 */
export interface CatTipoCambio {
  /**
   *
   * @type {number}
   * @memberof CatTipoCambio
   */
  id_tipo_cambio?: number;
  /**
   *
   * @type {number}
   * @memberof CatTipoCambio
   */
  id_moneda: number;
  /**
   *
   * @type {number}
   * @memberof CatTipoCambio
   */
  anio: number;
  /**
   *
   * @type {number}
   * @memberof CatTipoCambio
   */
  valor_diario?: number | null;
  /**
   *
   * @type {number}
   * @memberof CatTipoCambio
   */
  valor_mensual?: number | null;
  /**
   *
   * @type {number}
   * @memberof CatTipoCambio
   */
  valor_anual?: number | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoCambio
   */
  fecha_cambio?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoCambio
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoCambio
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoCambio
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoCambio
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoCambio
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoColaborador
 */
export interface CatTipoColaborador {
  /**
   *
   * @type {number}
   * @memberof CatTipoColaborador
   */
  id_tipo_colaborador?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoColaborador
   */
  cve_tipo_colaborador: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoColaborador
   */
  desc_tipo_colaborador: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoColaborador
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoColaborador
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoColaborador
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoColaborador
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoColaborador
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoContacto
 */
export interface CatTipoContacto {
  /**
   *
   * @type {string}
   * @memberof CatTipoContacto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoContacto
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoContribucion
 */
export interface CatTipoContribucion {
  /**
   *
   * @type {number}
   * @memberof CatTipoContribucion
   */
  id_tipo_contribucion?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoContribucion
   */
  cve_tipo_contribucion?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoContribucion
   */
  desc_tipo_contribucion?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoContribucion
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoContribucion
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoContribucion
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoContribucion
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoContribucion
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoConvenio
 */
export interface CatTipoConvenio {
  /**
   *
   * @type {number}
   * @memberof CatTipoConvenio
   */
  id_tipo_convenio?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoConvenio
   */
  cve_tipo_convenio: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoConvenio
   */
  nombre: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoConvenio
   */
  desc_tipo_convenio: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoConvenio
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoConvenio
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoConvenio
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoConvenio
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoConvenio
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoDesarrollo
 */
export interface CatTipoDesarrollo {
  /**
   *
   * @type {string}
   * @memberof CatTipoDesarrollo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoDesarrollo
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoDiscapacidad
 */
export interface CatTipoDiscapacidad {
  /**
   *
   * @type {string}
   * @memberof CatTipoDiscapacidad
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoDiscapacidad
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoDoctoRespaldo
 */
export interface CatTipoDoctoRespaldo {
  /**
   *
   * @type {string}
   * @memberof CatTipoDoctoRespaldo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoDoctoRespaldo
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoDocumento
 */
export interface CatTipoDocumento {
  /**
   *
   * @type {number}
   * @memberof CatTipoDocumento
   */
  id_tipo_documento?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoDocumento
   */
  cve_tipo_documento: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoDocumento
   */
  desc_tipo_documento: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoDocumento
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoDocumento
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoDocumento
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoDocumento
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoDocumento
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoDomicilio
 */
export interface CatTipoDomicilio {
  /**
   *
   * @type {string}
   * @memberof CatTipoDomicilio
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoDomicilio
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoEmpNivelDos
 */
export interface CatTipoEmpNivelDos {
  /**
   *
   * @type {number}
   * @memberof CatTipoEmpNivelDos
   */
  id_tipo_emp_nivel_dos?: number;
  /**
   *
   * @type {number}
   * @memberof CatTipoEmpNivelDos
   */
  id_tipo_emp_nivel_uno: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelDos
   */
  cve_tipo_emp_nivel_dos: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelDos
   */
  desc_tipo_emp_nivel_dos: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelDos
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelDos
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoEmpNivelDos
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelDos
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelDos
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoEmpNivelTres
 */
export interface CatTipoEmpNivelTres {
  /**
   *
   * @type {number}
   * @memberof CatTipoEmpNivelTres
   */
  id_tipo_emp_nivel_tres?: number;
  /**
   *
   * @type {number}
   * @memberof CatTipoEmpNivelTres
   */
  id_tipo_emp_nivel_dos: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelTres
   */
  cve_tipo_emp_nivel_tres: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelTres
   */
  desc_tipo_emp_nivel_tres: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelTres
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelTres
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoEmpNivelTres
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelTres
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelTres
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoEmpNivelUno
 */
export interface CatTipoEmpNivelUno {
  /**
   *
   * @type {number}
   * @memberof CatTipoEmpNivelUno
   */
  id_tipo_emp_nivel_uno?: number;
  /**
   *
   * @type {number}
   * @memberof CatTipoEmpNivelUno
   */
  id_tipo_empresa: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelUno
   */
  cve_tipo_emp_nivel_uno: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelUno
   */
  desc_tipo_emp_nivel_uno: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelUno
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelUno
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoEmpNivelUno
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelUno
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpNivelUno
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoEmpresa
 */
export interface CatTipoEmpresa {
  /**
   *
   * @type {number}
   * @memberof CatTipoEmpresa
   */
  id_tipo_empresa?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpresa
   */
  cve_tipo_empresa: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpresa
   */
  desc_tipo_empresa: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpresa
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpresa
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoEmpresa
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpresa
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEmpresa
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoEntidadPersona
 */
export interface CatTipoEntidadPersona {
  /**
   *
   * @type {number}
   * @memberof CatTipoEntidadPersona
   */
  id_tipo_entidad_persona?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoEntidadPersona
   */
  cve_tipo_entidad_persona: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEntidadPersona
   */
  desc_tipo_entidad_persona: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEntidadPersona
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEntidadPersona
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoEntidadPersona
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoEntidadPersona
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEntidadPersona
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoError
 */
export interface CatTipoError {
  /**
   *
   * @type {number}
   * @memberof CatTipoError
   */
  id_tipo_error?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoError
   */
  cve_tipo_error: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoError
   */
  desc_tipo_error?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoError
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoError
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoError
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoError
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoError
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoEstancia
 */
export interface CatTipoEstancia {
  /**
   *
   * @type {string}
   * @memberof CatTipoEstancia
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEstancia
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoEvaluacion
 */
export interface CatTipoEvaluacion {
  /**
   *
   * @type {string}
   * @memberof CatTipoEvaluacion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEvaluacion
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoEventoUi
 */
export interface CatTipoEventoUi {
  /**
   *
   * @type {number}
   * @memberof CatTipoEventoUi
   */
  id_tipo_evento_ui?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoEventoUi
   */
  cve_tipo_evento_ui: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEventoUi
   */
  desc_tipo_evento_ui?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoEventoUi
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEventoUi
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoEventoUi
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoEventoUi
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoEventoUi
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoFamiliar
 */
export interface CatTipoFamiliar {
  /**
   *
   * @type {number}
   * @memberof CatTipoFamiliar
   */
  id_tipo_familiar?: number;
  /**
   *
   * @type {number}
   * @memberof CatTipoFamiliar
   */
  id_tipo_instrumento: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoFamiliar
   */
  cve_tipo_familiar: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoFamiliar
   */
  desc_tipo_familiar: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoFamiliar
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoFamiliar
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoFamiliar
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoFamiliar
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoFamiliar
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoFondo
 */
export interface CatTipoFondo {
  /**
   *
   * @type {string}
   * @memberof CatTipoFondo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoFondo
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoFormaCfg
 */
export interface CatTipoFormaCfg {
  /**
   *
   * @type {number}
   * @memberof CatTipoFormaCfg
   */
  id_cat_tipo_forma?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoFormaCfg
   */
  nombre: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoFormaCfg
   */
  descripcion?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoFormaCfg
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoFormaCfg
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoFormaCfg
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoFormaCfg
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoFormaCfg
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoFormacion
 */
export interface CatTipoFormacion {
  /**
   *
   * @type {string}
   * @memberof CatTipoFormacion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoFormacion
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoGasto
 */
export interface CatTipoGasto {
  /**
   *
   * @type {number}
   * @memberof CatTipoGasto
   */
  id_tipo_gasto?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoGasto
   */
  cve_tipo_gasto: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoGasto
   */
  desc_tipo_gasto: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoGasto
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoGasto
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoGasto
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoGasto
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoGasto
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoGrupo
 */
export interface CatTipoGrupo {
  /**
   *
   * @type {number}
   * @memberof CatTipoGrupo
   */
  id_tipo_grupo?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoGrupo
   */
  cve_tipo_grupo: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoGrupo
   */
  desc_tipo_grupo: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoGrupo
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoGrupo
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoGrupo
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoGrupo
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoGrupo
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoGrupoTrabajo
 */
export interface CatTipoGrupoTrabajo {
  /**
   *
   * @type {number}
   * @memberof CatTipoGrupoTrabajo
   */
  id_tipo_grupo_trabajo?: number;
  /**
   *
   * @type {number}
   * @memberof CatTipoGrupoTrabajo
   */
  id_tipo_instrumento: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoGrupoTrabajo
   */
  cve_tipo_grupo_trabajo: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoGrupoTrabajo
   */
  desc_tipo_grupo_trabajo: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoGrupoTrabajo
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoGrupoTrabajo
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoGrupoTrabajo
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoGrupoTrabajo
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoGrupoTrabajo
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoImpacto
 */
export interface CatTipoImpacto {
  /**
   *
   * @type {number}
   * @memberof CatTipoImpacto
   */
  id_tipo_impacto?: number;
  /**
   *
   * @type {number}
   * @memberof CatTipoImpacto
   */
  id_tipo_instrumento: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoImpacto
   */
  cve_tipo_impacto: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoImpacto
   */
  desc_tipo_impacto: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoImpacto
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoImpacto
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoImpacto
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoImpacto
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoImpacto
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoInfraestructura
 */
export interface CatTipoInfraestructura {
  /**
   *
   * @type {number}
   * @memberof CatTipoInfraestructura
   */
  id_tipo_infraestructura?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoInfraestructura
   */
  cve_tipo_infraestructura: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInfraestructura
   */
  desc_tipo_infraestructura: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInfraestructura
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInfraestructura
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoInfraestructura
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoInfraestructura
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInfraestructura
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoInnovacionOslo
 */
export interface CatTipoInnovacionOslo {
  /**
   *
   * @type {string}
   * @memberof CatTipoInnovacionOslo
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInnovacionOslo
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoInstNivelDos
 */
export interface CatTipoInstNivelDos {
  /**
   *
   * @type {string}
   * @memberof CatTipoInstNivelDos
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInstNivelDos
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatTipoInstNivelDos
   */
  id_tipo_inst_nivel_uno?: number;
}
/**
 *
 * @export
 * @interface CatTipoInstNivelTres
 */
export interface CatTipoInstNivelTres {
  /**
   *
   * @type {string}
   * @memberof CatTipoInstNivelTres
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof CatTipoInstNivelTres
   */
  id_tipo_inst_nivel_dos?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoInstNivelTres
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoInstNivelUno
 */
export interface CatTipoInstNivelUno {
  /**
   *
   * @type {string}
   * @memberof CatTipoInstNivelUno
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInstNivelUno
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatTipoInstNivelUno
   */
  id_tipo_institucion?: number;
}
/**
 *
 * @export
 * @interface CatTipoInstitucion
 */
export interface CatTipoInstitucion {
  /**
   *
   * @type {string}
   * @memberof CatTipoInstitucion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInstitucion
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoInstrumento
 */
export interface CatTipoInstrumento {
  /**
   *
   * @type {number}
   * @memberof CatTipoInstrumento
   */
  id_tipo_instrumento?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoInstrumento
   */
  cve_tipo_instrumento: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInstrumento
   */
  desc_tipo_instrumento?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoInstrumento
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInstrumento
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoInstrumento
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoInstrumento
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInstrumento
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoInvestigacion
 */
export interface CatTipoInvestigacion {
  /**
   *
   * @type {number}
   * @memberof CatTipoInvestigacion
   */
  id_tipo_investigacion?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoInvestigacion
   */
  cve_tipo_investigacion: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInvestigacion
   */
  desc_tipo_investigacion: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInvestigacion
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInvestigacion
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoInvestigacion
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoInvestigacion
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInvestigacion
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoInvestigacionGen
 */
export interface CatTipoInvestigacionGen {
  /**
   *
   * @type {number}
   * @memberof CatTipoInvestigacionGen
   */
  id_tipo_investigacion_gen?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoInvestigacionGen
   */
  cve_tipo_investigacion_gen: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInvestigacionGen
   */
  desc_tipo_investigacion_gen: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInvestigacionGen
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInvestigacionGen
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoInvestigacionGen
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoInvestigacionGen
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoInvestigacionGen
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoMedioDivulgacion
 */
export interface CatTipoMedioDivulgacion {
  /**
   *
   * @type {string}
   * @memberof CatTipoMedioDivulgacion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoMedioDivulgacion
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoMotivo
 */
export interface CatTipoMotivo {
  /**
   *
   * @type {number}
   * @memberof CatTipoMotivo
   */
  id_tipo_motivo?: number;
  /**
   *
   * @type {number}
   * @memberof CatTipoMotivo
   */
  id_cl_ubicacion: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoMotivo
   */
  cve_tipo_motivo: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoMotivo
   */
  desc_tipo_motivo: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoMotivo
   */
  desclarga_tipo_motivo: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoMotivo
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoMotivo
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoMotivo
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoMotivo
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoMotivo
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoMovimiento
 */
export interface CatTipoMovimiento {
  /**
   *
   * @type {number}
   * @memberof CatTipoMovimiento
   */
  id_tipo_movimiento?: number;
  /**
   *
   * @type {number}
   * @memberof CatTipoMovimiento
   */
  id_cl_ubicacion: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoMovimiento
   */
  cve_tipo_movimiento: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoMovimiento
   */
  desc_tipo_movimiento: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoMovimiento
   */
  desclarga_tipo_movimiento: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoMovimiento
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoMovimiento
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoMovimiento
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoMovimiento
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoMovimiento
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoMovimientoSni
 */
export interface CatTipoMovimientoSni {
  /**
   *
   * @type {number}
   * @memberof CatTipoMovimientoSni
   */
  id_tipo_movimiento_sni?: number;
  /**
   *
   * @type {number}
   * @memberof CatTipoMovimientoSni
   */
  id_cl_remuneracion: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoMovimientoSni
   */
  cve_tipo_movimiento_sni: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoMovimientoSni
   */
  desc_tipo_movimiento_sni: string;
  /**
   *
   * @type {number}
   * @memberof CatTipoMovimientoSni
   */
  orden_precedencia?: number | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoMovimientoSni
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoMovimientoSni
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoMovimientoSni
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoMovimientoSni
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoMovimientoSni
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoNotificacion
 */
export interface CatTipoNotificacion {
  /**
   *
   * @type {number}
   * @memberof CatTipoNotificacion
   */
  id_tipo_notificacion?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoNotificacion
   */
  cve_tipo_notificacion: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoNotificacion
   */
  desc_tipo_notificacion: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoNotificacion
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoNotificacion
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoNotificacion
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoNotificacion
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoNotificacion
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoObjetoSeguridad
 */
export interface CatTipoObjetoSeguridad {
  /**
   *
   * @type {number}
   * @memberof CatTipoObjetoSeguridad
   */
  id_tipo_objeto_seguridad?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoObjetoSeguridad
   */
  cve_tipo_objeto_seguridad: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoObjetoSeguridad
   */
  desc_tipo_objeto_seguridad?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoObjetoSeguridad
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoObjetoSeguridad
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoObjetoSeguridad
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoObjetoSeguridad
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoObjetoSeguridad
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoPantalla
 */
export interface CatTipoPantalla {
  /**
   *
   * @type {number}
   * @memberof CatTipoPantalla
   */
  id_tipo_pantalla?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoPantalla
   */
  cve_tipo_pantalla: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPantalla
   */
  desc_tipo_pantalla?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoPantalla
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPantalla
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoPantalla
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoPantalla
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPantalla
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoPartCongreso
 */
export interface CatTipoPartCongreso {
  /**
   *
   * @type {string}
   * @memberof CatTipoPartCongreso
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPartCongreso
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoParticipacion
 */
export interface CatTipoParticipacion {
  /**
   *
   * @type {string}
   * @memberof CatTipoParticipacion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoParticipacion
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoParticipante
 */
export interface CatTipoParticipante {
  /**
   *
   * @type {string}
   * @memberof CatTipoParticipante
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoParticipante
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoPatente
 */
export interface CatTipoPatente {
  /**
   *
   * @type {string}
   * @memberof CatTipoPatente
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPatente
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoPeriodo
 */
export interface CatTipoPeriodo {
  /**
   *
   * @type {number}
   * @memberof CatTipoPeriodo
   */
  id_tipo_periodo?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoPeriodo
   */
  cve_tipo_periodo: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPeriodo
   */
  desc_tipo_periodo?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoPeriodo
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPeriodo
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoPeriodo
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoPeriodo
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPeriodo
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoPertenEtnica
 */
export interface CatTipoPertenEtnica {
  /**
   *
   * @type {string}
   * @memberof CatTipoPertenEtnica
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPertenEtnica
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoPoder
 */
export interface CatTipoPoder {
  /**
   *
   * @type {number}
   * @memberof CatTipoPoder
   */
  id_tipo_poder?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoPoder
   */
  cve_tipo_poder?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoPoder
   */
  desc_tipo_poder?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoPoder
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPoder
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoPoder
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoPoder
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPoder
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoPrgRcea
 */
export interface CatTipoPrgRcea {
  /**
   *
   * @type {number}
   * @memberof CatTipoPrgRcea
   */
  id_tipo_prg_rcea?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoPrgRcea
   */
  cve_tipo_prg_rcea: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPrgRcea
   */
  desc_tipo_prg_rcea: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPrgRcea
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPrgRcea
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoPrgRcea
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoPrgRcea
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPrgRcea
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoPrgSni
 */
export interface CatTipoPrgSni {
  /**
   *
   * @type {number}
   * @memberof CatTipoPrgSni
   */
  id_tipo_prg_sni?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoPrgSni
   */
  cve_tipo_prg_sni: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPrgSni
   */
  desc_tipo_prg_sni: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPrgSni
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPrgSni
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoPrgSni
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoPrgSni
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPrgSni
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoProducto
 */
export interface CatTipoProducto {
  /**
   *
   * @type {number}
   * @memberof CatTipoProducto
   */
  id_tipo_producto?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoProducto
   */
  cve_tipo_producto?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoProducto
   */
  desc_tipo_producto?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoProducto
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoProducto
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoProducto
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoProducto
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoProducto
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoProteccion
 */
export interface CatTipoProteccion {
  /**
   *
   * @type {string}
   * @memberof CatTipoProteccion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoProteccion
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoProyecto
 */
export interface CatTipoProyecto {
  /**
   *
   * @type {string}
   * @memberof CatTipoProyecto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoProyecto
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoPublicacion
 */
export interface CatTipoPublicacion {
  /**
   *
   * @type {string}
   * @memberof CatTipoPublicacion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoPublicacion
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoRelacionPersona
 */
export interface CatTipoRelacionPersona {
  /**
   *
   * @type {number}
   * @memberof CatTipoRelacionPersona
   */
  id_tipo_relacion_persona?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoRelacionPersona
   */
  cve_tipo_relacion_persona?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoRelacionPersona
   */
  desc_tipo_relacion_persona?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoRelacionPersona
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoRelacionPersona
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoRelacionPersona
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoRelacionPersona
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoRelacionPersona
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoRespuestaEval
 */
export interface CatTipoRespuestaEval {
  /**
   *
   * @type {number}
   * @memberof CatTipoRespuestaEval
   */
  id_tipo_respuesta_eval?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoRespuestaEval
   */
  cve_tipo_respuesta_eval?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoRespuestaEval
   */
  desc_tipo_respuesta_eval?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoRespuestaEval
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoRespuestaEval
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoRespuestaEval
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoRespuestaEval
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoRespuestaEval
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoReunion
 */
export interface CatTipoReunion {
  /**
   *
   * @type {number}
   * @memberof CatTipoReunion
   */
  id_tipo_reunion?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoReunion
   */
  cve_tipo_reunion: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoReunion
   */
  desc_tipo_reunion: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoReunion
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoReunion
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoReunion
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoReunion
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoReunion
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoRiesgo
 */
export interface CatTipoRiesgo {
  /**
   *
   * @type {number}
   * @memberof CatTipoRiesgo
   */
  id_tipo_riesgo?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoRiesgo
   */
  cve_tipo_riesgo?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoRiesgo
   */
  desc_tipo_riesgo?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoRiesgo
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoRiesgo
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoRiesgo
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoRiesgo
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoRiesgo
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoSoftware
 */
export interface CatTipoSoftware {
  /**
   *
   * @type {string}
   * @memberof CatTipoSoftware
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoSoftware
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoSolicitudSni
 */
export interface CatTipoSolicitudSni {
  /**
   *
   * @type {number}
   * @memberof CatTipoSolicitudSni
   */
  id_tipo_solicitud_sni?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoSolicitudSni
   */
  cve_tipo_solicitud_sni?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoSolicitudSni
   */
  desc_tipo_solicitud_sni?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoSolicitudSni
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoSolicitudSni
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoSolicitudSni
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoSolicitudSni
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoSolicitudSni
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoTarea
 */
export interface CatTipoTarea {
  /**
   *
   * @type {number}
   * @memberof CatTipoTarea
   */
  id_tipo_tarea?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoTarea
   */
  cve_tipo_tarea: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoTarea
   */
  desc_tipo_tarea?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoTarea
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoTarea
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoTarea
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoTarea
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoTarea
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoTramite
 */
export interface CatTipoTramite {
  /**
   *
   * @type {number}
   * @memberof CatTipoTramite
   */
  id_tipo_tramite?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoTramite
   */
  cve_tipo_tramite: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoTramite
   */
  desc_tipo_tramite: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoTramite
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoTramite
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoTramite
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoTramite
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoTramite
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoTransferencia
 */
export interface CatTipoTransferencia {
  /**
   *
   * @type {string}
   * @memberof CatTipoTransferencia
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoTransferencia
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoTrayectoriaProf
 */
export interface CatTipoTrayectoriaProf {
  /**
   *
   * @type {string}
   * @memberof CatTipoTrayectoriaProf
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoTrayectoriaProf
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoV02042021235338000
 */
export interface CatTipoV02042021235338000 {
  /**
   *
   * @type {number}
   * @memberof CatTipoV02042021235338000
   */
  id_tipo_vigencia?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoV02042021235338000
   */
  cve_tipo_vigencia?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoV02042021235338000
   */
  desc_tipo_vigencia?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoV02042021235338000
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoV02042021235338000
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoV02042021235338000
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoV02042021235338000
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoV02042021235338000
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatTipoVialidad
 */
export interface CatTipoVialidad {
  /**
   *
   * @type {string}
   * @memberof CatTipoVialidad
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoVialidad
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface CatTipoWidget
 */
export interface CatTipoWidget {
  /**
   *
   * @type {number}
   * @memberof CatTipoWidget
   */
  id_tipo_widget?: number;
  /**
   *
   * @type {string}
   * @memberof CatTipoWidget
   */
  cve_tipo_widget?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoWidget
   */
  desc_tipo_widget?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatTipoWidget
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoWidget
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatTipoWidget
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatTipoWidget
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatTipoWidget
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatUma
 */
export interface CatUma {
  /**
   *
   * @type {number}
   * @memberof CatUma
   */
  id_uma?: number;
  /**
   *
   * @type {number}
   * @memberof CatUma
   */
  anio: number;
  /**
   *
   * @type {number}
   * @memberof CatUma
   */
  valor_diario: number;
  /**
   *
   * @type {number}
   * @memberof CatUma
   */
  valor_mensual?: number | null;
  /**
   *
   * @type {number}
   * @memberof CatUma
   */
  valor_anual?: number | null;
  /**
   *
   * @type {string}
   * @memberof CatUma
   */
  fecha_alta?: string | null;
  /**
   *
   * @type {string}
   * @memberof CatUma
   */
  fecha_modificacion?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CatUma
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatUma
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatUma
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatValorDetalleInfra
 */
export interface CatValorDetalleInfra {
  /**
   *
   * @type {number}
   * @memberof CatValorDetalleInfra
   */
  id_valor_detalle_infra?: number;
  /**
   *
   * @type {number}
   * @memberof CatValorDetalleInfra
   */
  id_detalle_infra: number;
  /**
   *
   * @type {string}
   * @memberof CatValorDetalleInfra
   */
  cve_valor_detalle_infra: string;
  /**
   *
   * @type {string}
   * @memberof CatValorDetalleInfra
   */
  desc_valor_detalle_infra: string;
  /**
   *
   * @type {string}
   * @memberof CatValorDetalleInfra
   */
  fecha_alta?: string;
  /**
   *
   * @type {string}
   * @memberof CatValorDetalleInfra
   */
  fecha_modificacion?: string;
  /**
   *
   * @type {boolean}
   * @memberof CatValorDetalleInfra
   */
  ind_activo?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatValorDetalleInfra
   */
  usuario_alta: string;
  /**
   *
   * @type {string}
   * @memberof CatValorDetalleInfra
   */
  usuario_modificacion?: string | null;
}
/**
 *
 * @export
 * @interface CatVarAutodenominacion
 */
export interface CatVarAutodenominacion {
  /**
   *
   * @type {string}
   * @memberof CatVarAutodenominacion
   */
  id_var_autodenominacion?: string;
  /**
   *
   * @type {string}
   * @memberof CatVarAutodenominacion
   */
  nombre: string;
  /**
   *
   * @type {number}
   * @memberof CatVarAutodenominacion
   */
  id_pueblo_indigena?: number;
}
/**
 *
 * @export
 * @interface CatVialidad
 */
export interface CatVialidad {
  /**
   *
   * @type {number}
   * @memberof CatVialidad
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CatVialidad
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface GetAdministraciones200Response
 */
export interface GetAdministraciones200Response {
  /**
   *
   * @type {Array<CatAdministracion>}
   * @memberof GetAdministraciones200Response
   */
  results?: Array<CatAdministracion>;
}
/**
 *
 * @export
 * @interface GetAmbitos200Response
 */
export interface GetAmbitos200Response {
  /**
   *
   * @type {Array<CatAmbito>}
   * @memberof GetAmbitos200Response
   */
  results?: Array<CatAmbito>;
}
/**
 *
 * @export
 * @interface GetAreaConocimiento200Response
 */
export interface GetAreaConocimiento200Response {
  /**
   *
   * @type {Array<CatAreaConocimiento>}
   * @memberof GetAreaConocimiento200Response
   */
  results?: Array<CatAreaConocimiento>;
}
/**
 *
 * @export
 * @interface GetAreaGeoestadisticaEstatal200Response
 */
export interface GetAreaGeoestadisticaEstatal200Response {
  /**
   *
   * @type {Array<AreaGeoestadisticaEstatal>}
   * @memberof GetAreaGeoestadisticaEstatal200Response
   */
  results?: Array<AreaGeoestadisticaEstatal>;
}
/**
 *
 * @export
 * @interface GetAsentamientosHumanosEstados200Response
 */
export interface GetAsentamientosHumanosEstados200Response {
  /**
   *
   * @type {Array<AsentamientosHumanos>}
   * @memberof GetAsentamientosHumanosEstados200Response
   */
  results?: Array<AsentamientosHumanos>;
}
/**
 *
 * @export
 * @interface GetCampos200Response
 */
export interface GetCampos200Response {
  /**
   *
   * @type {Array<CatCampo>}
   * @memberof GetCampos200Response
   */
  results?: Array<CatCampo>;
}
/**
 *
 * @export
 * @interface GetCaracteristContactos200Response
 */
export interface GetCaracteristContactos200Response {
  /**
   *
   * @type {Array<CatCaracteristicaContacto>}
   * @memberof GetCaracteristContactos200Response
   */
  results?: Array<CatCaracteristicaContacto>;
}
/**
 *
 * @export
 * @interface GetCatClasificacionPatentes200Response
 */
export interface GetCatClasificacionPatentes200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatClasificacionPatentes200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatClasificacionPatentes200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatClasificacionPatentes200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatClasificacionPatente>}
   * @memberof GetCatClasificacionPatentes200Response
   */
  results?: Array<CatClasificacionPatente>;
}
/**
 *
 * @export
 * @interface GetCatDefinicionLogUis200Response
 */
export interface GetCatDefinicionLogUis200Response {
  /**
   *
   * @type {Array<CatDefinicionLogUi>}
   * @memberof GetCatDefinicionLogUis200Response
   */
  results?: Array<CatDefinicionLogUi>;
}
/**
 *
 * @export
 * @interface GetCatDiaNolabConacyts200Response
 */
export interface GetCatDiaNolabConacyts200Response {
  /**
   *
   * @type {Array<CatDiaNolabConacyt>}
   * @memberof GetCatDiaNolabConacyts200Response
   */
  results?: Array<CatDiaNolabConacyt>;
}
/**
 *
 * @export
 * @interface GetCatEstadoPatentes200Response
 */
export interface GetCatEstadoPatentes200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatEstadoPatentes200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatEstadoPatentes200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatEstadoPatentes200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatEstadoPatente>}
   * @memberof GetCatEstadoPatentes200Response
   */
  results?: Array<CatEstadoPatente>;
}
/**
 *
 * @export
 * @interface GetCatEstadoTesis200Response
 */
export interface GetCatEstadoTesis200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatEstadoTesis200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatEstadoTesis200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatEstadoTesis200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatEstadoTesis>}
   * @memberof GetCatEstadoTesis200Response
   */
  results?: Array<CatEstadoTesis>;
}
/**
 *
 * @export
 * @interface GetCatEstatusMovGastos200Response
 */
export interface GetCatEstatusMovGastos200Response {
  /**
   *
   * @type {Array<CatEstatusMovGasto>}
   * @memberof GetCatEstatusMovGastos200Response
   */
  results?: Array<CatEstatusMovGasto>;
}
/**
 *
 * @export
 * @interface GetCatEstatusNotificacions200Response
 */
export interface GetCatEstatusNotificacions200Response {
  /**
   *
   * @type {Array<CatEstatusNotificacion>}
   * @memberof GetCatEstatusNotificacions200Response
   */
  results?: Array<CatEstatusNotificacion>;
}
/**
 *
 * @export
 * @interface GetCatEtapaDesarrollos200Response
 */
export interface GetCatEtapaDesarrollos200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatEtapaDesarrollos200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatEtapaDesarrollos200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatEtapaDesarrollos200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatEtapaDesarrollo>}
   * @memberof GetCatEtapaDesarrollos200Response
   */
  results?: Array<CatEtapaDesarrollo>;
}
/**
 *
 * @export
 * @interface GetCatFormatoNotificacions200Response
 */
export interface GetCatFormatoNotificacions200Response {
  /**
   *
   * @type {Array<CatFormatoNotificacion>}
   * @memberof GetCatFormatoNotificacions200Response
   */
  results?: Array<CatFormatoNotificacion>;
}
/**
 *
 * @export
 * @interface GetCatListRespPreguntas200Response
 */
export interface GetCatListRespPreguntas200Response {
  /**
   *
   * @type {Array<CatListRespPregunta>}
   * @memberof GetCatListRespPreguntas200Response
   */
  results?: Array<CatListRespPregunta>;
}
/**
 *
 * @export
 * @interface GetCatMomentoEventos200Response
 */
export interface GetCatMomentoEventos200Response {
  /**
   *
   * @type {Array<CatMomentoEvento>}
   * @memberof GetCatMomentoEventos200Response
   */
  results?: Array<CatMomentoEvento>;
}
/**
 *
 * @export
 * @interface GetCatNivelPnpcs200Response
 */
export interface GetCatNivelPnpcs200Response {
  /**
   *
   * @type {Array<CatNivelPnpc>}
   * @memberof GetCatNivelPnpcs200Response
   */
  results?: Array<CatNivelPnpc>;
}
/**
 *
 * @export
 * @interface GetCatOperacionPagos200Response
 */
export interface GetCatOperacionPagos200Response {
  /**
   *
   * @type {Array<CatOperacionPago>}
   * @memberof GetCatOperacionPagos200Response
   */
  results?: Array<CatOperacionPago>;
}
/**
 *
 * @export
 * @interface GetCatOperadorComparacions200Response
 */
export interface GetCatOperadorComparacions200Response {
  /**
   *
   * @type {Array<CatOperadorComparacion>}
   * @memberof GetCatOperadorComparacions200Response
   */
  results?: Array<CatOperadorComparacion>;
}
/**
 *
 * @export
 * @interface GetCatOrigenReportes200Response
 */
export interface GetCatOrigenReportes200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatOrigenReportes200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatOrigenReportes200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatOrigenReportes200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatOrigenReporte>}
   * @memberof GetCatOrigenReportes200Response
   */
  results?: Array<CatOrigenReporte>;
}
/**
 *
 * @export
 * @interface GetCatPalabraClaves200Response
 */
export interface GetCatPalabraClaves200Response {
  /**
   *
   * @type {Array<CatPalabraClave>}
   * @memberof GetCatPalabraClaves200Response
   */
  results?: Array<CatPalabraClave>;
}
/**
 *
 * @export
 * @interface GetCatParametrosSeguridads200Response
 */
export interface GetCatParametrosSeguridads200Response {
  /**
   *
   * @type {Array<CatParametrosSeguridad>}
   * @memberof GetCatParametrosSeguridads200Response
   */
  results?: Array<CatParametrosSeguridad>;
}
/**
 *
 * @export
 * @interface GetCatPartDivulgacions200Response
 */
export interface GetCatPartDivulgacions200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatPartDivulgacions200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatPartDivulgacions200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatPartDivulgacions200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatPartDivulgacion>}
   * @memberof GetCatPartDivulgacions200Response
   */
  results?: Array<CatPartDivulgacion>;
}
/**
 *
 * @export
 * @interface GetCatRecomdsEvalFomixs200Response
 */
export interface GetCatRecomdsEvalFomixs200Response {
  /**
   *
   * @type {Array<CatRecomdsEvalFomix>}
   * @memberof GetCatRecomdsEvalFomixs200Response
   */
  results?: Array<CatRecomdsEvalFomix>;
}
/**
 *
 * @export
 * @interface GetCatRolPersFisProgPnpcs200Response
 */
export interface GetCatRolPersFisProgPnpcs200Response {
  /**
   *
   * @type {Array<CatRolPersFisProgPnpc>}
   * @memberof GetCatRolPersFisProgPnpcs200Response
   */
  results?: Array<CatRolPersFisProgPnpc>;
}
/**
 *
 * @export
 * @interface GetCatSectorDivulgacions200Response
 */
export interface GetCatSectorDivulgacions200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatSectorDivulgacions200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatSectorDivulgacions200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatSectorDivulgacions200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatSectorDivulgacion>}
   * @memberof GetCatSectorDivulgacions200Response
   */
  results?: Array<CatSectorDivulgacion>;
}
/**
 *
 * @export
 * @interface GetCatTipoActDivulgacions200Response
 */
export interface GetCatTipoActDivulgacions200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatTipoActDivulgacions200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoActDivulgacions200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoActDivulgacions200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatTipoActDivulgacion>}
   * @memberof GetCatTipoActDivulgacions200Response
   */
  results?: Array<CatTipoActDivulgacion>;
}
/**
 *
 * @export
 * @interface GetCatTipoDesarrollos200Response
 */
export interface GetCatTipoDesarrollos200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatTipoDesarrollos200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoDesarrollos200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoDesarrollos200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatTipoDesarrollo>}
   * @memberof GetCatTipoDesarrollos200Response
   */
  results?: Array<CatTipoDesarrollo>;
}
/**
 *
 * @export
 * @interface GetCatTipoDoctoRespaldos200Response
 */
export interface GetCatTipoDoctoRespaldos200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatTipoDoctoRespaldos200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoDoctoRespaldos200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoDoctoRespaldos200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatTipoDoctoRespaldo>}
   * @memberof GetCatTipoDoctoRespaldos200Response
   */
  results?: Array<CatTipoDoctoRespaldo>;
}
/**
 *
 * @export
 * @interface GetCatTipoEventoUis200Response
 */
export interface GetCatTipoEventoUis200Response {
  /**
   *
   * @type {Array<CatTipoEventoUi>}
   * @memberof GetCatTipoEventoUis200Response
   */
  results?: Array<CatTipoEventoUi>;
}
/**
 *
 * @export
 * @interface GetCatTipoFondos200Response
 */
export interface GetCatTipoFondos200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatTipoFondos200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoFondos200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoFondos200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatTipoFondo>}
   * @memberof GetCatTipoFondos200Response
   */
  results?: Array<CatTipoFondo>;
}
/**
 *
 * @export
 * @interface GetCatTipoInnovacionOslos200Response
 */
export interface GetCatTipoInnovacionOslos200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatTipoInnovacionOslos200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoInnovacionOslos200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoInnovacionOslos200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatTipoInnovacionOslo>}
   * @memberof GetCatTipoInnovacionOslos200Response
   */
  results?: Array<CatTipoInnovacionOslo>;
}
/**
 *
 * @export
 * @interface GetCatTipoInstNivelTres200Response
 */
export interface GetCatTipoInstNivelTres200Response {
  /**
   *
   * @type {Array<CatTipoInstNivelTres>}
   * @memberof GetCatTipoInstNivelTres200Response
   */
  results?: Array<CatTipoInstNivelTres>;
}
/**
 *
 * @export
 * @interface GetCatTipoMedioDivulgacions200Response
 */
export interface GetCatTipoMedioDivulgacions200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatTipoMedioDivulgacions200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoMedioDivulgacions200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoMedioDivulgacions200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatTipoMedioDivulgacion>}
   * @memberof GetCatTipoMedioDivulgacions200Response
   */
  results?: Array<CatTipoMedioDivulgacion>;
}
/**
 *
 * @export
 * @interface GetCatTipoNotificacions200Response
 */
export interface GetCatTipoNotificacions200Response {
  /**
   *
   * @type {Array<CatTipoNotificacion>}
   * @memberof GetCatTipoNotificacions200Response
   */
  results?: Array<CatTipoNotificacion>;
}
/**
 *
 * @export
 * @interface GetCatTipoPartCongresos200Response
 */
export interface GetCatTipoPartCongresos200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatTipoPartCongresos200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoPartCongresos200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoPartCongresos200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatTipoPartCongreso>}
   * @memberof GetCatTipoPartCongresos200Response
   */
  results?: Array<CatTipoPartCongreso>;
}
/**
 *
 * @export
 * @interface GetCatTipoParticipacions200Response
 */
export interface GetCatTipoParticipacions200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatTipoParticipacions200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoParticipacions200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoParticipacions200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatTipoParticipacion>}
   * @memberof GetCatTipoParticipacions200Response
   */
  results?: Array<CatTipoParticipacion>;
}
/**
 *
 * @export
 * @interface GetCatTipoParticipantes200Response
 */
export interface GetCatTipoParticipantes200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatTipoParticipantes200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoParticipantes200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoParticipantes200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatTipoParticipante>}
   * @memberof GetCatTipoParticipantes200Response
   */
  results?: Array<CatTipoParticipante>;
}
/**
 *
 * @export
 * @interface GetCatTipoPatentes200Response
 */
export interface GetCatTipoPatentes200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatTipoPatentes200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoPatentes200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoPatentes200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatTipoPatente>}
   * @memberof GetCatTipoPatentes200Response
   */
  results?: Array<CatTipoPatente>;
}
/**
 *
 * @export
 * @interface GetCatTipoProteccions200Response
 */
export interface GetCatTipoProteccions200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatTipoProteccions200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoProteccions200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoProteccions200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatTipoProteccion>}
   * @memberof GetCatTipoProteccions200Response
   */
  results?: Array<CatTipoProteccion>;
}
/**
 *
 * @export
 * @interface GetCatTipoPublicacions200Response
 */
export interface GetCatTipoPublicacions200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatTipoPublicacions200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoPublicacions200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoPublicacions200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatTipoPublicacion>}
   * @memberof GetCatTipoPublicacions200Response
   */
  results?: Array<CatTipoPublicacion>;
}
/**
 *
 * @export
 * @interface GetCatTipoRiesgos200Response
 */
export interface GetCatTipoRiesgos200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatTipoRiesgos200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoRiesgos200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoRiesgos200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatTipoRiesgo>}
   * @memberof GetCatTipoRiesgos200Response
   */
  results?: Array<CatTipoRiesgo>;
}
/**
 *
 * @export
 * @interface GetCatTipoSoftwares200Response
 */
export interface GetCatTipoSoftwares200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatTipoSoftwares200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoSoftwares200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoSoftwares200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatTipoSoftware>}
   * @memberof GetCatTipoSoftwares200Response
   */
  results?: Array<CatTipoSoftware>;
}
/**
 *
 * @export
 * @interface GetCatTipoTransferencias200Response
 */
export interface GetCatTipoTransferencias200Response {
  /**
   *
   * @type {number}
   * @memberof GetCatTipoTransferencias200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoTransferencias200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCatTipoTransferencias200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatTipoTransferencia>}
   * @memberof GetCatTipoTransferencias200Response
   */
  results?: Array<CatTipoTransferencia>;
}
/**
 *
 * @export
 * @interface GetCatalogoDetalles200Response
 */
export interface GetCatalogoDetalles200Response {
  /**
   *
   * @type {Array<CatCatalogoDetalle>}
   * @memberof GetCatalogoDetalles200Response
   */
  results?: Array<CatCatalogoDetalle>;
}
/**
 *
 * @export
 * @interface GetCatalogoMaestros200Response
 */
export interface GetCatalogoMaestros200Response {
  /**
   *
   * @type {Array<CatCatalogoMaestro>}
   * @memberof GetCatalogoMaestros200Response
   */
  results?: Array<CatCatalogoMaestro>;
}
/**
 *
 * @export
 * @interface GetClaveCveCatNacionalPublico200Response
 */
export interface GetClaveCveCatNacionalPublico200Response {
  /**
   *
   * @type {Array<CatNacionalPublico>}
   * @memberof GetClaveCveCatNacionalPublico200Response
   */
  results?: Array<CatNacionalPublico>;
}
/**
 *
 * @export
 * @interface GetCodigosPostales200Response
 */
export interface GetCodigosPostales200Response {
  /**
   *
   * @type {Array<CatCodigosPostales>}
   * @memberof GetCodigosPostales200Response
   */
  results?: Array<CatCodigosPostales>;
}
/**
 *
 * @export
 * @interface GetConsejoCertificadores200Response
 */
export interface GetConsejoCertificadores200Response {
  /**
   *
   * @type {Array<CatConsejoCertificador>}
   * @memberof GetConsejoCertificadores200Response
   */
  results?: Array<CatConsejoCertificador>;
}
/**
 *
 * @export
 * @interface GetCveCatMotivacioSnis200Response
 */
export interface GetCveCatMotivacioSnis200Response {
  /**
   *
   * @type {number}
   * @memberof GetCveCatMotivacioSnis200Response
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCveCatMotivacioSnis200Response
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetCveCatMotivacioSnis200Response
   */
  previous?: string | null;
  /**
   *
   * @type {Array<CatMotivacioSni>}
   * @memberof GetCveCatMotivacioSnis200Response
   */
  results?: Array<CatMotivacioSni>;
}
/**
 *
 * @export
 * @interface GetDerechoTransitos200Response
 */
export interface GetDerechoTransitos200Response {
  /**
   *
   * @type {Array<CatDerechoTransito>}
   * @memberof GetDerechoTransitos200Response
   */
  results?: Array<CatDerechoTransito>;
}
/**
 *
 * @export
 * @interface GetDictamenEvaluaciones200Response
 */
export interface GetDictamenEvaluaciones200Response {
  /**
   *
   * @type {Array<CatDictamenEvaluacion>}
   * @memberof GetDictamenEvaluaciones200Response
   */
  results?: Array<CatDictamenEvaluacion>;
}
/**
 *
 * @export
 * @interface GetDisciplinas200Response
 */
export interface GetDisciplinas200Response {
  /**
   *
   * @type {Array<CatDisciplina>}
   * @memberof GetDisciplinas200Response
   */
  results?: Array<CatDisciplina>;
}
/**
 *
 * @export
 * @interface GetDistincionesConacyt200Response
 */
export interface GetDistincionesConacyt200Response {
  /**
   *
   * @type {Array<CatDistincionConacyt>}
   * @memberof GetDistincionesConacyt200Response
   */
  results?: Array<CatDistincionConacyt>;
}
/**
 *
 * @export
 * @interface GetEspecialidadesMedicas200Response
 */
export interface GetEspecialidadesMedicas200Response {
  /**
   *
   * @type {Array<CatEspecialidadMedica>}
   * @memberof GetEspecialidadesMedicas200Response
   */
  results?: Array<CatEspecialidadMedica>;
}
/**
 *
 * @export
 * @interface GetEstadoCiviles200Response
 */
export interface GetEstadoCiviles200Response {
  /**
   *
   * @type {Array<CatEstadoCivil>}
   * @memberof GetEstadoCiviles200Response
   */
  results?: Array<CatEstadoCivil>;
}
/**
 *
 * @export
 * @interface GetEstatusAcademicos200Response
 */
export interface GetEstatusAcademicos200Response {
  /**
   *
   * @type {Array<CatEstatusAcademico>}
   * @memberof GetEstatusAcademicos200Response
   */
  results?: Array<CatEstatusAcademico>;
}
/**
 *
 * @export
 * @interface GetFormaTitulaciones200Response
 */
export interface GetFormaTitulaciones200Response {
  /**
   *
   * @type {Array<CatFormaTitulacion>}
   * @memberof GetFormaTitulaciones200Response
   */
  results?: Array<CatFormaTitulacion>;
}
/**
 *
 * @export
 * @interface GetGeneros200Response
 */
export interface GetGeneros200Response {
  /**
   *
   * @type {Array<CatGenero>}
   * @memberof GetGeneros200Response
   */
  results?: Array<CatGenero>;
}
/**
 *
 * @export
 * @interface GetIdentificaAutores200Response
 */
export interface GetIdentificaAutores200Response {
  /**
   *
   * @type {Array<CatIdentificaAutor>}
   * @memberof GetIdentificaAutores200Response
   */
  results?: Array<CatIdentificaAutor>;
}
/**
 *
 * @export
 * @interface GetIdiomas200Response
 */
export interface GetIdiomas200Response {
  /**
   *
   * @type {Array<CatIdioma>}
   * @memberof GetIdiomas200Response
   */
  results?: Array<CatIdioma>;
}
/**
 *
 * @export
 * @interface GetInstituciones200Response
 */
export interface GetInstituciones200Response {
  /**
   *
   * @type {Array<CatInstitucion>}
   * @memberof GetInstituciones200Response
   */
  results?: Array<CatInstitucion>;
}
/**
 *
 * @export
 * @interface GetLenguaIndigenas200Response
 */
export interface GetLenguaIndigenas200Response {
  /**
   *
   * @type {Array<CatLenguaIndigena>}
   * @memberof GetLenguaIndigenas200Response
   */
  results?: Array<CatLenguaIndigena>;
}
/**
 *
 * @export
 * @interface GetLocalidadesMunicipiosEstados200Response
 */
export interface GetLocalidadesMunicipiosEstados200Response {
  /**
   *
   * @type {Array<LocalidadGeoestadistica>}
   * @memberof GetLocalidadesMunicipiosEstados200Response
   */
  results?: Array<LocalidadGeoestadistica>;
}
/**
 *
 * @export
 * @interface GetMargenes200Response
 */
export interface GetMargenes200Response {
  /**
   *
   * @type {Array<CatMargen>}
   * @memberof GetMargenes200Response
   */
  results?: Array<CatMargen>;
}
/**
 *
 * @export
 * @interface GetMunicipiosEstado200Response
 */
export interface GetMunicipiosEstado200Response {
  /**
   *
   * @type {Array<AreaGeoestadisticasMunicipal>}
   * @memberof GetMunicipiosEstado200Response
   */
  results?: Array<AreaGeoestadisticasMunicipal>;
}
/**
 *
 * @export
 * @interface GetNacionalidades200Response
 */
export interface GetNacionalidades200Response {
  /**
   *
   * @type {Array<CatNacionalidad>}
   * @memberof GetNacionalidades200Response
   */
  results?: Array<CatNacionalidad>;
}
/**
 *
 * @export
 * @interface GetNivelesAcademicos200Response
 */
export interface GetNivelesAcademicos200Response {
  /**
   *
   * @type {Array<CatNivelAcademico>}
   * @memberof GetNivelesAcademicos200Response
   */
  results?: Array<CatNivelAcademico>;
}
/**
 *
 * @export
 * @interface GetNivelesDominiosLd200Response
 */
export interface GetNivelesDominiosLd200Response {
  /**
   *
   * @type {Array<CatNivelDominioLd>}
   * @memberof GetNivelesDominiosLd200Response
   */
  results?: Array<CatNivelDominioLd>;
}
/**
 *
 * @export
 * @interface GetOcdeClasesOcdeGrupos200Response
 */
export interface GetOcdeClasesOcdeGrupos200Response {
  /**
   *
   * @type {Array<CatOcdeClase>}
   * @memberof GetOcdeClasesOcdeGrupos200Response
   */
  results?: Array<CatOcdeClase>;
}
/**
 *
 * @export
 * @interface GetOcdeDivisionesOcdeSector200Response
 */
export interface GetOcdeDivisionesOcdeSector200Response {
  /**
   *
   * @type {Array<CatOcdeDivision>}
   * @memberof GetOcdeDivisionesOcdeSector200Response
   */
  results?: Array<CatOcdeDivision>;
}
/**
 *
 * @export
 * @interface GetOcdeGruposOcdeDivisiones200Response
 */
export interface GetOcdeGruposOcdeDivisiones200Response {
  /**
   *
   * @type {Array<CatOcdeGrupo>}
   * @memberof GetOcdeGruposOcdeDivisiones200Response
   */
  results?: Array<CatOcdeGrupo>;
}
/**
 *
 * @export
 * @interface GetOcdeSectores200Response
 */
export interface GetOcdeSectores200Response {
  /**
   *
   * @type {Array<CatOcdeSector>}
   * @memberof GetOcdeSectores200Response
   */
  results?: Array<CatOcdeSector>;
}
/**
 *
 * @export
 * @interface GetPaises200Response
 */
export interface GetPaises200Response {
  /**
   *
   * @type {Array<CatPais>}
   * @memberof GetPaises200Response
   */
  results?: Array<CatPais>;
}
/**
 *
 * @export
 * @interface GetProductoEvaluaciones200Response
 */
export interface GetProductoEvaluaciones200Response {
  /**
   *
   * @type {Array<CatProductoEvaluacion>}
   * @memberof GetProductoEvaluaciones200Response
   */
  results?: Array<CatProductoEvaluacion>;
}
/**
 *
 * @export
 * @interface GetPueblosIndigenas200Response
 */
export interface GetPueblosIndigenas200Response {
  /**
   *
   * @type {Array<CatPuebloIndigena>}
   * @memberof GetPueblosIndigenas200Response
   */
  results?: Array<CatPuebloIndigena>;
}
/**
 *
 * @export
 * @interface GetScianClases200Response
 */
export interface GetScianClases200Response {
  /**
   *
   * @type {Array<CatScianClase>}
   * @memberof GetScianClases200Response
   */
  results?: Array<CatScianClase>;
}
/**
 *
 * @export
 * @interface GetScianDivisiones200Response
 */
export interface GetScianDivisiones200Response {
  /**
   *
   * @type {Array<CatScianDivision>}
   * @memberof GetScianDivisiones200Response
   */
  results?: Array<CatScianDivision>;
}
/**
 *
 * @export
 * @interface GetScianGrupos200Response
 */
export interface GetScianGrupos200Response {
  /**
   *
   * @type {Array<CatScianGrupo>}
   * @memberof GetScianGrupos200Response
   */
  results?: Array<CatScianGrupo>;
}
/**
 *
 * @export
 * @interface GetScianSectores200Response
 */
export interface GetScianSectores200Response {
  /**
   *
   * @type {Array<CatScianSector>}
   * @memberof GetScianSectores200Response
   */
  results?: Array<CatScianSector>;
}
/**
 *
 * @export
 * @interface GetScianSubclases200Response
 */
export interface GetScianSubclases200Response {
  /**
   *
   * @type {Array<CatScianSubclase>}
   * @memberof GetScianSubclases200Response
   */
  results?: Array<CatScianSubclase>;
}
/**
 *
 * @export
 * @interface GetSubDisciplinas200Response
 */
export interface GetSubDisciplinas200Response {
  /**
   *
   * @type {Array<CatSubDisciplina>}
   * @memberof GetSubDisciplinas200Response
   */
  results?: Array<CatSubDisciplina>;
}
/**
 *
 * @export
 * @interface GetTerminoGenericos200Response
 */
export interface GetTerminoGenericos200Response {
  /**
   *
   * @type {Array<CatTerminoGenerico>}
   * @memberof GetTerminoGenericos200Response
   */
  results?: Array<CatTerminoGenerico>;
}
/**
 *
 * @export
 * @interface GetTipoAsentamientos200Response
 */
export interface GetTipoAsentamientos200Response {
  /**
   *
   * @type {Array<CatTipoAsentamiento>}
   * @memberof GetTipoAsentamientos200Response
   */
  results?: Array<CatTipoAsentamiento>;
}
/**
 *
 * @export
 * @interface GetTipoContactos200Response
 */
export interface GetTipoContactos200Response {
  /**
   *
   * @type {Array<CatTipoContacto>}
   * @memberof GetTipoContactos200Response
   */
  results?: Array<CatTipoContacto>;
}
/**
 *
 * @export
 * @interface GetTipoDiscapacidades200Response
 */
export interface GetTipoDiscapacidades200Response {
  /**
   *
   * @type {Array<CatTipoDiscapacidad>}
   * @memberof GetTipoDiscapacidades200Response
   */
  results?: Array<CatTipoDiscapacidad>;
}
/**
 *
 * @export
 * @interface GetTipoEstancias200Response
 */
export interface GetTipoEstancias200Response {
  /**
   *
   * @type {Array<CatTipoEstancia>}
   * @memberof GetTipoEstancias200Response
   */
  results?: Array<CatTipoEstancia>;
}
/**
 *
 * @export
 * @interface GetTipoEvaluaciones200Response
 */
export interface GetTipoEvaluaciones200Response {
  /**
   *
   * @type {Array<CatTipoEvaluacion>}
   * @memberof GetTipoEvaluaciones200Response
   */
  results?: Array<CatTipoEvaluacion>;
}
/**
 *
 * @export
 * @interface GetTipoFormaciones200Response
 */
export interface GetTipoFormaciones200Response {
  /**
   *
   * @type {Array<CatTipoFormacion>}
   * @memberof GetTipoFormaciones200Response
   */
  results?: Array<CatTipoFormacion>;
}
/**
 *
 * @export
 * @interface GetTipoInstNivelDos200Response
 */
export interface GetTipoInstNivelDos200Response {
  /**
   *
   * @type {Array<CatTipoInstNivelDos>}
   * @memberof GetTipoInstNivelDos200Response
   */
  results?: Array<CatTipoInstNivelDos>;
}
/**
 *
 * @export
 * @interface GetTipoInstituciones200Response
 */
export interface GetTipoInstituciones200Response {
  /**
   *
   * @type {Array<CatTipoInstitucion>}
   * @memberof GetTipoInstituciones200Response
   */
  results?: Array<CatTipoInstitucion>;
}
/**
 *
 * @export
 * @interface GetTipoPertenEtnicas200Response
 */
export interface GetTipoPertenEtnicas200Response {
  /**
   *
   * @type {Array<CatTipoPertenEtnica>}
   * @memberof GetTipoPertenEtnicas200Response
   */
  results?: Array<CatTipoPertenEtnica>;
}
/**
 *
 * @export
 * @interface GetTipoTrayectoriasProf200Response
 */
export interface GetTipoTrayectoriasProf200Response {
  /**
   *
   * @type {Array<CatTipoTrayectoriaProf>}
   * @memberof GetTipoTrayectoriasProf200Response
   */
  results?: Array<CatTipoTrayectoriaProf>;
}
/**
 *
 * @export
 * @interface GetTipoVialidades200Response
 */
export interface GetTipoVialidades200Response {
  /**
   *
   * @type {Array<CatTipoVialidad>}
   * @memberof GetTipoVialidades200Response
   */
  results?: Array<CatTipoVialidad>;
}
/**
 *
 * @export
 * @interface GetTiposInstNivelUno200Response
 */
export interface GetTiposInstNivelUno200Response {
  /**
   *
   * @type {Array<CatTipoInstNivelUno>}
   * @memberof GetTiposInstNivelUno200Response
   */
  results?: Array<CatTipoInstNivelUno>;
}
/**
 *
 * @export
 * @interface GetVarAutodenominaciones200Response
 */
export interface GetVarAutodenominaciones200Response {
  /**
   *
   * @type {Array<CatVarAutodenominacion>}
   * @memberof GetVarAutodenominaciones200Response
   */
  results?: Array<CatVarAutodenominacion>;
}
/**
 *
 * @export
 * @interface GetVialidadesLocaliidadesMunicipiosEstados200Response
 */
export interface GetVialidadesLocaliidadesMunicipiosEstados200Response {
  /**
   *
   * @type {Array<Vialidades>}
   * @memberof GetVialidadesLocaliidadesMunicipiosEstados200Response
   */
  results?: Array<Vialidades>;
}
/**
 *
 * @export
 * @interface LocalidadGeoestadistica
 */
export interface LocalidadGeoestadistica {
  /**
   *
   * @type {string}
   * @memberof LocalidadGeoestadistica
   */
  id_municipio: string;
  /**
   *
   * @type {string}
   * @memberof LocalidadGeoestadistica
   */
  id_estado: string;
  /**
   *
   * @type {string}
   * @memberof LocalidadGeoestadistica
   */
  nombre: string;
  /**
   *
   * @type {string}
   * @memberof LocalidadGeoestadistica
   */
  id: string;
}
/**
 *
 * @export
 * @interface Vialidades
 */
export interface Vialidades {
  /**
   *
   * @type {string}
   * @memberof Vialidades
   */
  id_localidad: string;
  /**
   *
   * @type {string}
   * @memberof Vialidades
   */
  id_municipio: string;
  /**
   *
   * @type {string}
   * @memberof Vialidades
   */
  id_estado: string;
  /**
   *
   * @type {string}
   * @memberof Vialidades
   */
  nombre: string;
  /**
   *
   * @type {string}
   * @memberof Vialidades
   */
  id: string;
}

/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdministraciones: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/administraciones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idAmbito
     * @param {string} idVialidad A unique integer value identifying this cat_vialidad.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAmbitoCatVialidadId: async (
      idAmbito: string,
      idVialidad: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idAmbito' is not null or undefined
      assertParamExists('getAmbitoCatVialidadId', 'idAmbito', idAmbito);
      // verify required parameter 'idVialidad' is not null or undefined
      assertParamExists('getAmbitoCatVialidadId', 'idVialidad', idVialidad);
      const localVarPath = `/api/v1/ambito/{id_ambito}/vialidad/{id_vialidad}`
        .replace(`{${'id_ambito'}}`, encodeURIComponent(String(idAmbito)))
        .replace(`{${'id_vialidad'}}`, encodeURIComponent(String(idVialidad)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAmbitos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/ambitos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAreaConocimiento: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/areas-conocimiento`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAreaGeoestadisticaEstatal: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/estados`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAsentamientosHumanosEstados: async (
      claveAreaGe: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'claveAreaGe' is not null or undefined
      assertParamExists('getAsentamientosHumanosEstados', 'claveAreaGe', claveAreaGe);
      const localVarPath = `/api/v1/estados/{clave_area_ge}/inegi-asentamientos`.replace(
        `{${'clave_area_ge'}}`,
        encodeURIComponent(String(claveAreaGe))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveLocalidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAsentamientosHumanosLocalidadesMunicipiosEstados: async (
      claveAreaGe: string,
      claveAreaGm: string,
      claveLocalidad: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'claveAreaGe' is not null or undefined
      assertParamExists('getAsentamientosHumanosLocalidadesMunicipiosEstados', 'claveAreaGe', claveAreaGe);
      // verify required parameter 'claveAreaGm' is not null or undefined
      assertParamExists('getAsentamientosHumanosLocalidadesMunicipiosEstados', 'claveAreaGm', claveAreaGm);
      // verify required parameter 'claveLocalidad' is not null or undefined
      assertParamExists('getAsentamientosHumanosLocalidadesMunicipiosEstados', 'claveLocalidad', claveLocalidad);
      const localVarPath = `/api/v1/estados/{clave_area_ge}/municipios/{clave_area_gm}/localidades/{clave_localidad}/inegi-asentamientos`
        .replace(`{${'clave_area_ge'}}`, encodeURIComponent(String(claveAreaGe)))
        .replace(`{${'clave_area_gm'}}`, encodeURIComponent(String(claveAreaGm)))
        .replace(`{${'clave_localidad'}}`, encodeURIComponent(String(claveLocalidad)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAsentamientosHumanosMunicipiosEstados: async (
      claveAreaGe: string,
      claveAreaGm: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'claveAreaGe' is not null or undefined
      assertParamExists('getAsentamientosHumanosMunicipiosEstados', 'claveAreaGe', claveAreaGe);
      // verify required parameter 'claveAreaGm' is not null or undefined
      assertParamExists('getAsentamientosHumanosMunicipiosEstados', 'claveAreaGm', claveAreaGm);
      const localVarPath = `/api/v1/estados/{clave_area_ge}/municipios/{clave_area_gm}/inegi-asentamientos`
        .replace(`{${'clave_area_ge'}}`, encodeURIComponent(String(claveAreaGe)))
        .replace(`{${'clave_area_gm'}}`, encodeURIComponent(String(claveAreaGm)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBaseCartograficas: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/inegi-cartografica/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} claveAsentamiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBaseCartograficasAsentamientos: async (
      claveAsentamiento: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'claveAsentamiento' is not null or undefined
      assertParamExists('getBaseCartograficasAsentamientos', 'claveAsentamiento', claveAsentamiento);
      const localVarPath = `/api/v1/inegi-asentamientos/{clave_asentamiento}/inegi-cartografica/`.replace(
        `{${'clave_asentamiento'}}`,
        encodeURIComponent(String(claveAsentamiento))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCampos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/campos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCaracteristContactos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/caracterist-contactos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoContacto
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCaracteristContactosTipoContactos: async (
      idTipoContacto: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoContacto' is not null or undefined
      assertParamExists('getCaracteristContactosTipoContactos', 'idTipoContacto', idTipoContacto);
      const localVarPath = `/api/v1/tipo-contactos/{id_tipo_contacto}/caracterist-contactos`.replace(
        `{${'id_tipo_contacto'}}`,
        encodeURIComponent(String(idTipoContacto))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveAreaConocimiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatCamposAreasConocimiento: async (
      cveAreaConocimiento: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveAreaConocimiento' is not null or undefined
      assertParamExists('getCatCamposAreasConocimiento', 'cveAreaConocimiento', cveAreaConocimiento);
      const localVarPath = `/api/v1/areas-conocimiento/{cve_area_conocimiento}/campos`.replace(
        `{${'cve_area_conocimiento'}}`,
        encodeURIComponent(String(cveAreaConocimiento))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatClasificacionPatentes: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/clasificaciones-patente`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} codigo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatCodigosPostalesFilter: async (
      codigo: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'codigo' is not null or undefined
      assertParamExists('getCatCodigosPostalesFilter', 'codigo', codigo);
      const localVarPath = `/api/v1/cpostales/{codigo}`.replace(`{${'codigo'}}`, encodeURIComponent(String(codigo)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatDefinicionLogUis: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/definiciones-log-ui`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatDiaNolabConacyts: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dias-nolab-conacyt`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idCampo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatDisciplinasCampos: async (
      idCampo: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idCampo' is not null or undefined
      assertParamExists('getCatDisciplinasCampos', 'idCampo', idCampo);
      const localVarPath = `/api/v1/campos/{id_campo}/disciplinas`.replace(`{${'id_campo'}}`, encodeURIComponent(String(idCampo)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatEstadoPatentes: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/estados-patentes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatEstadoTesis: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/estados-tesis`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatEstatusMigratorios: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/estatus-migratorios`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatEstatusMovGastos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/estatus-mov-gastos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatEstatusNotificacions: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/estatus-notificaciones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatEtapaDesarrollos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/etapas-desarrollo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatFormatoNotificacions: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/formatos-notificacion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoInstNivelTres
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatInstitucions: async (
      idTipoInstNivelTres: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoInstNivelTres' is not null or undefined
      assertParamExists('getCatInstitucions', 'idTipoInstNivelTres', idTipoInstNivelTres);
      const localVarPath = `/api/v1/tipos-inst-nivel-tres/{id_tipo_inst_nivel_tres}/instituciones`.replace(
        `{${'id_tipo_inst_nivel_tres'}}`,
        encodeURIComponent(String(idTipoInstNivelTres))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatListRespPreguntas: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/list-resp-preguntas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatMomentoEventos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/momentos-evento`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatNivelPnpcs: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/niveles-pnpc`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatOperacionPagos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/operaciones-pago`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatOperadorComparacions: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/operadores-comparacion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatOrigenReportes: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/origenes-reporte`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idFormatoNotificacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatPalabraClaves: async (
      idFormatoNotificacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idFormatoNotificacion' is not null or undefined
      assertParamExists('getCatPalabraClaves', 'idFormatoNotificacion', idFormatoNotificacion);
      const localVarPath = `/api/v1/formatos-notificacion/{id_formato_notificacion}/palabras-clave`.replace(
        `{${'id_formato_notificacion'}}`,
        encodeURIComponent(String(idFormatoNotificacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatParametrosSeguridads: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/parametros-seguridad`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatPartDivulgacions: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/part-divulgaciones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatProductoDivulgacions: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/productos-divulgacion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatRecomdsEvalFomixs: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/recomds-eval-fomix`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatRedTematicaConacyts: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/redes-tematica-conacyt`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatRolPersFisProgPnpcs: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/roles-pers-fis-prog-pnpc`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatSectorDivulgacions: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/sectores-divulgacion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoActDivulgacions: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-act-divulgacion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoDesarrollos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-desarrollo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoDoctoRespaldos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/docto-respaldos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoDomicilios: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-domicilio`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoEventoUis: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-evento-ui`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoFondos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-fondo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoInnovacionOslos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-innovacion-oslo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoInstNivelTres: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-inst-nivel-tres`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoMedioDivulgacions: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-medio-divulgacion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoNotificacions: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-notificacion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoPartCongresos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-part-congreso`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoParticipacions: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-participacion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoParticipante
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoParticipante: async (
      cveTipoParticipante: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoParticipante' is not null or undefined
      assertParamExists('getCatTipoParticipante', 'cveTipoParticipante', cveTipoParticipante);
      const localVarPath = `/api/v1/tipos-participante/{cve_tipo_participante}`.replace(
        `{${'cve_tipo_participante'}}`,
        encodeURIComponent(String(cveTipoParticipante))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoParticipantes: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-participante`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoPatentes: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-patente`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoProteccions: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-proteccion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoProyectos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-proyecto`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoPublicacions: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-publicacion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoRiesgos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-riesgo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoSoftwares: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-software`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoTransferencias: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-transferencia`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoInstNivelDos
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTiposInstNivelTres: async (
      idTipoInstNivelDos: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoInstNivelDos' is not null or undefined
      assertParamExists('getCatTiposInstNivelTres', 'idTipoInstNivelDos', idTipoInstNivelDos);
      const localVarPath = `/api/v1/tipos-inst-nivel-dos/{id_tipo_inst_nivel_dos}/tipos-inst-nivel-tres`.replace(
        `{${'id_tipo_inst_nivel_dos'}}`,
        encodeURIComponent(String(idTipoInstNivelDos))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogoDetalles: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/catalogos-detalle`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idCatCatalogoMaestro
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogoDetallesCatalogosMaestro: async (
      idCatCatalogoMaestro: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idCatCatalogoMaestro' is not null or undefined
      assertParamExists('getCatalogoDetallesCatalogosMaestro', 'idCatCatalogoMaestro', idCatCatalogoMaestro);
      const localVarPath = `/api/v1/catalogos-maestros/{id_cat_catalogo_maestro}/catalogos-detalle`.replace(
        `{${'id_cat_catalogo_maestro'}}`,
        encodeURIComponent(String(idCatCatalogoMaestro))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogoMaestros: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/catalogos-maestros`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveClasificacionPatente
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClaveCatClasificacionPatente: async (
      cveClasificacionPatente: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveClasificacionPatente' is not null or undefined
      assertParamExists('getClaveCatClasificacionPatente', 'cveClasificacionPatente', cveClasificacionPatente);
      const localVarPath = `/api/v1/clasificaciones-patente/{cve_clasificacion_patente}`.replace(
        `{${'cve_clasificacion_patente'}}`,
        encodeURIComponent(String(cveClasificacionPatente))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveEstadoPatente
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClaveCatEstadoPatente: async (
      cveEstadoPatente: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveEstadoPatente' is not null or undefined
      assertParamExists('getClaveCatEstadoPatente', 'cveEstadoPatente', cveEstadoPatente);
      const localVarPath = `/api/v1/estados-patentes/{cve_estado_patente}`.replace(
        `{${'cve_estado_patente'}}`,
        encodeURIComponent(String(cveEstadoPatente))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveEtapaDesarrollo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClaveCatEtapaDesarrollo: async (
      cveEtapaDesarrollo: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveEtapaDesarrollo' is not null or undefined
      assertParamExists('getClaveCatEtapaDesarrollo', 'cveEtapaDesarrollo', cveEtapaDesarrollo);
      const localVarPath = `/api/v1/etapas-desarrollo/{cve_etapa_desarrollo}`.replace(
        `{${'cve_etapa_desarrollo'}}`,
        encodeURIComponent(String(cveEtapaDesarrollo))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cvePais
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClaveCatExtranjeraPais: async (
      cvePais: string,
      page?: number,
      size?: number,
      search?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cvePais' is not null or undefined
      assertParamExists('getClaveCatExtranjeraPais', 'cvePais', cvePais);
      const localVarPath = `/api/v1/extranjera-pais/{cve_pais}`.replace(`{${'cve_pais'}}`, encodeURIComponent(String(cvePais)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoProteccion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClaveCatTipoProteccion: async (
      cveTipoProteccion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoProteccion' is not null or undefined
      assertParamExists('getClaveCatTipoProteccion', 'cveTipoProteccion', cveTipoProteccion);
      const localVarPath = `/api/v1/tipos-proteccion/{cve_tipo_proteccion}`.replace(
        `{${'cve_tipo_proteccion'}}`,
        encodeURIComponent(String(cveTipoProteccion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoInstNivelUno
     * @param {string} cveTipoInstNivelDos
     * @param {string} cveEntidad
     * @param {string} cveMunicipio
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClaveCveCatNacionalPublico: async (
      cveTipoInstNivelUno: string,
      cveTipoInstNivelDos: string,
      cveEntidad: string,
      cveMunicipio: string,
      page?: number,
      size?: number,
      search?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoInstNivelUno' is not null or undefined
      assertParamExists('getClaveCveCatNacionalPublico', 'cveTipoInstNivelUno', cveTipoInstNivelUno);
      // verify required parameter 'cveTipoInstNivelDos' is not null or undefined
      assertParamExists('getClaveCveCatNacionalPublico', 'cveTipoInstNivelDos', cveTipoInstNivelDos);
      // verify required parameter 'cveEntidad' is not null or undefined
      assertParamExists('getClaveCveCatNacionalPublico', 'cveEntidad', cveEntidad);
      // verify required parameter 'cveMunicipio' is not null or undefined
      assertParamExists('getClaveCveCatNacionalPublico', 'cveMunicipio', cveMunicipio);
      const localVarPath = `/api/v1/nacional-publico/{cve_tipo_inst_nivel_uno}/{cve_tipo_inst_nivel_dos}/{cve_entidad}/{cve_municipio}`
        .replace(`{${'cve_tipo_inst_nivel_uno'}}`, encodeURIComponent(String(cveTipoInstNivelUno)))
        .replace(`{${'cve_tipo_inst_nivel_dos'}}`, encodeURIComponent(String(cveTipoInstNivelDos)))
        .replace(`{${'cve_entidad'}}`, encodeURIComponent(String(cveEntidad)))
        .replace(`{${'cve_municipio'}}`, encodeURIComponent(String(cveMunicipio)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCodigosPostales: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/codigos-postales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoAsentamiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCodigosPostalesTipoAsentamientos: async (
      cveTipoAsentamiento: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoAsentamiento' is not null or undefined
      assertParamExists('getCodigosPostalesTipoAsentamientos', 'cveTipoAsentamiento', cveTipoAsentamiento);
      const localVarPath = `/api/v1/tipo-asentamientos/{cve_tipo_asentamiento}/codigos-postales`.replace(
        `{${'cve_tipo_asentamiento'}}`,
        encodeURIComponent(String(cveTipoAsentamiento))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConsejoCertificadores: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/consejos-certificadores`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveAreaConocimiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveCatMotivacioSnis: async (
      cveAreaConocimiento: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveAreaConocimiento' is not null or undefined
      assertParamExists('getCveCatMotivacioSnis', 'cveAreaConocimiento', cveAreaConocimiento);
      const localVarPath = `/api/v1/areas-conocimiento/{cve_area_conocimiento}/motivaciones-sni`.replace(
        `{${'cve_area_conocimiento'}}`,
        encodeURIComponent(String(cveAreaConocimiento))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoInstNivelUno
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveCatNacionalPublico: async (
      cveTipoInstNivelUno: string,
      page?: number,
      size?: number,
      search?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoInstNivelUno' is not null or undefined
      assertParamExists('getCveCatNacionalPublico', 'cveTipoInstNivelUno', cveTipoInstNivelUno);
      const localVarPath = `/api/v1/nacional-publico/{cve_tipo_inst_nivel_uno}`.replace(
        `{${'cve_tipo_inst_nivel_uno'}}`,
        encodeURIComponent(String(cveTipoInstNivelUno))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoDesarrollo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveCatTipoDesarrollo: async (
      cveTipoDesarrollo: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoDesarrollo' is not null or undefined
      assertParamExists('getCveCatTipoDesarrollo', 'cveTipoDesarrollo', cveTipoDesarrollo);
      const localVarPath = `/api/v1/tipos-desarrollo/{cve_tipo_desarrollo}`.replace(
        `{${'cve_tipo_desarrollo'}}`,
        encodeURIComponent(String(cveTipoDesarrollo))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoInnovacionOslo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveCatTipoInnovacionOslo: async (
      cveTipoInnovacionOslo: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoInnovacionOslo' is not null or undefined
      assertParamExists('getCveCatTipoInnovacionOslo', 'cveTipoInnovacionOslo', cveTipoInnovacionOslo);
      const localVarPath = `/api/v1/tipos-innovacion-oslo/{cve_tipo_innovacion_oslo}`.replace(
        `{${'cve_tipo_innovacion_oslo'}}`,
        encodeURIComponent(String(cveTipoInnovacionOslo))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoPatente
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveCatTipoPatente: async (
      cveTipoPatente: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoPatente' is not null or undefined
      assertParamExists('getCveCatTipoPatente', 'cveTipoPatente', cveTipoPatente);
      const localVarPath = `/api/v1/tipos-patente/{cve_tipo_patente}`.replace(
        `{${'cve_tipo_patente'}}`,
        encodeURIComponent(String(cveTipoPatente))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoSoftware
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveCatTipoSoftware: async (
      cveTipoSoftware: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoSoftware' is not null or undefined
      assertParamExists('getCveCatTipoSoftware', 'cveTipoSoftware', cveTipoSoftware);
      const localVarPath = `/api/v1/tipos-software/{cve_tipo_software}`.replace(
        `{${'cve_tipo_software'}}`,
        encodeURIComponent(String(cveTipoSoftware))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoTransferencia
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveCatTipoTransferencia: async (
      cveTipoTransferencia: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoTransferencia' is not null or undefined
      assertParamExists('getCveCatTipoTransferencia', 'cveTipoTransferencia', cveTipoTransferencia);
      const localVarPath = `/api/v1/tipos-transferencia/{cve_tipo_transferencia}`.replace(
        `{${'cve_tipo_transferencia'}}`,
        encodeURIComponent(String(cveTipoTransferencia))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoInstNivelUno
     * @param {string} cveTipoInstNivelDos
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveClaveCatNacionalPublico: async (
      cveTipoInstNivelUno: string,
      cveTipoInstNivelDos: string,
      page?: number,
      size?: number,
      search?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoInstNivelUno' is not null or undefined
      assertParamExists('getCveClaveCatNacionalPublico', 'cveTipoInstNivelUno', cveTipoInstNivelUno);
      // verify required parameter 'cveTipoInstNivelDos' is not null or undefined
      assertParamExists('getCveClaveCatNacionalPublico', 'cveTipoInstNivelDos', cveTipoInstNivelDos);
      const localVarPath = `/api/v1/nacional-publico/{cve_tipo_inst_nivel_uno}/{cve_tipo_inst_nivel_dos}`
        .replace(`{${'cve_tipo_inst_nivel_uno'}}`, encodeURIComponent(String(cveTipoInstNivelUno)))
        .replace(`{${'cve_tipo_inst_nivel_dos'}}`, encodeURIComponent(String(cveTipoInstNivelDos)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveAreaConocimiento
     * @param {string} idMotivacionSni A unique integer value identifying this cat_motivacion_sni.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveIdCatMotivacioSnis: async (
      cveAreaConocimiento: string,
      idMotivacionSni: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveAreaConocimiento' is not null or undefined
      assertParamExists('getCveIdCatMotivacioSnis', 'cveAreaConocimiento', cveAreaConocimiento);
      // verify required parameter 'idMotivacionSni' is not null or undefined
      assertParamExists('getCveIdCatMotivacioSnis', 'idMotivacionSni', idMotivacionSni);
      const localVarPath = `/api/v1/areas-conocimiento/{cve_area_conocimiento}/motivaciones-sni/{id_motivacion_sni}`
        .replace(`{${'cve_area_conocimiento'}}`, encodeURIComponent(String(cveAreaConocimiento)))
        .replace(`{${'id_motivacion_sni'}}`, encodeURIComponent(String(idMotivacionSni)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDerechoTransitos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/derechos-transito`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDictamenEvaluaciones: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dictamenes-evaluaciones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDisciplinas: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/disciplinas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDistincionesConacyt: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/distinciones-conacyt`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEspecialidadesMedicas: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/especialidades-medicas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idConsejoCertificador
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEspecialidadesMedicasConsejosCertificadores: async (
      idConsejoCertificador: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idConsejoCertificador' is not null or undefined
      assertParamExists('getEspecialidadesMedicasConsejosCertificadores', 'idConsejoCertificador', idConsejoCertificador);
      const localVarPath = `/api/v1/consejos-certificadores/{id_consejo_certificador}/especialidades-medicas`.replace(
        `{${'id_consejo_certificador'}}`,
        encodeURIComponent(String(idConsejoCertificador))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEstadoCiviles: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/estados-civiles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEstatusAcademicos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/estatus-academicos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFormaTitulaciones: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/formas-titulacion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGeneros: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/generos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveAdministracion A unique integer value identifying this cat_administracion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdAdministraciones: async (
      cveAdministracion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveAdministracion' is not null or undefined
      assertParamExists('getIdAdministraciones', 'cveAdministracion', cveAdministracion);
      const localVarPath = `/api/v1/administraciones/{cve_administracion}`.replace(
        `{${'cve_administracion'}}`,
        encodeURIComponent(String(cveAdministracion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveAmbito A unique integer value identifying this cat_ambito.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdAmbitos: async (cveAmbito: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'cveAmbito' is not null or undefined
      assertParamExists('getIdAmbitos', 'cveAmbito', cveAmbito);
      const localVarPath = `/api/v1/ambitos/{cve_ambito}`.replace(`{${'cve_ambito'}}`, encodeURIComponent(String(cveAmbito)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} indVersion A unique integer value identifying this cat_area_conocimiento.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdAreaConocimiento: async (
      indVersion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'indVersion' is not null or undefined
      assertParamExists('getIdAreaConocimiento', 'indVersion', indVersion);
      const localVarPath = `/api/v1/areas-conocimiento/{ind_version}`.replace(`{${'ind_version'}}`, encodeURIComponent(String(indVersion)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} claveAreaGev
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdAreaGeoestadisticaEstatal: async (
      claveAreaGev: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'claveAreaGev' is not null or undefined
      assertParamExists('getIdAreaGeoestadisticaEstatal', 'claveAreaGev', claveAreaGev);
      const localVarPath = `/api/v1/estados/{clave_area_gev}`.replace(`{${'clave_area_gev'}}`, encodeURIComponent(String(claveAreaGev)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAsentamiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdAsentamientosHumanosEstados: async (
      claveAreaGe: string,
      claveAsentamiento: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'claveAreaGe' is not null or undefined
      assertParamExists('getIdAsentamientosHumanosEstados', 'claveAreaGe', claveAreaGe);
      // verify required parameter 'claveAsentamiento' is not null or undefined
      assertParamExists('getIdAsentamientosHumanosEstados', 'claveAsentamiento', claveAsentamiento);
      const localVarPath = `/api/v1/estados/{clave_area_ge}/inegi-asentamientos/{clave_asentamiento}`
        .replace(`{${'clave_area_ge'}}`, encodeURIComponent(String(claveAreaGe)))
        .replace(`{${'clave_asentamiento'}}`, encodeURIComponent(String(claveAsentamiento)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveLocalidad
     * @param {string} claveAsentamiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdAsentamientosHumanosLocalidadesMunicipiosEstados: async (
      claveAreaGe: string,
      claveAreaGm: string,
      claveLocalidad: string,
      claveAsentamiento: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'claveAreaGe' is not null or undefined
      assertParamExists('getIdAsentamientosHumanosLocalidadesMunicipiosEstados', 'claveAreaGe', claveAreaGe);
      // verify required parameter 'claveAreaGm' is not null or undefined
      assertParamExists('getIdAsentamientosHumanosLocalidadesMunicipiosEstados', 'claveAreaGm', claveAreaGm);
      // verify required parameter 'claveLocalidad' is not null or undefined
      assertParamExists('getIdAsentamientosHumanosLocalidadesMunicipiosEstados', 'claveLocalidad', claveLocalidad);
      // verify required parameter 'claveAsentamiento' is not null or undefined
      assertParamExists('getIdAsentamientosHumanosLocalidadesMunicipiosEstados', 'claveAsentamiento', claveAsentamiento);
      const localVarPath =
        `/api/v1/estados/{clave_area_ge}/municipios/{clave_area_gm}/localidades/{clave_localidad}/inegi-asentamientos/{clave_asentamiento}`
          .replace(`{${'clave_area_ge'}}`, encodeURIComponent(String(claveAreaGe)))
          .replace(`{${'clave_area_gm'}}`, encodeURIComponent(String(claveAreaGm)))
          .replace(`{${'clave_localidad'}}`, encodeURIComponent(String(claveLocalidad)))
          .replace(`{${'clave_asentamiento'}}`, encodeURIComponent(String(claveAsentamiento)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveAsentamiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdAsentamientosHumanosMunicipiosEstados: async (
      claveAreaGe: string,
      claveAreaGm: string,
      claveAsentamiento: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'claveAreaGe' is not null or undefined
      assertParamExists('getIdAsentamientosHumanosMunicipiosEstados', 'claveAreaGe', claveAreaGe);
      // verify required parameter 'claveAreaGm' is not null or undefined
      assertParamExists('getIdAsentamientosHumanosMunicipiosEstados', 'claveAreaGm', claveAreaGm);
      // verify required parameter 'claveAsentamiento' is not null or undefined
      assertParamExists('getIdAsentamientosHumanosMunicipiosEstados', 'claveAsentamiento', claveAsentamiento);
      const localVarPath = `/api/v1/estados/{clave_area_ge}/municipios/{clave_area_gm}/inegi-asentamientos/{clave_asentamiento}`
        .replace(`{${'clave_area_ge'}}`, encodeURIComponent(String(claveAreaGe)))
        .replace(`{${'clave_area_gm'}}`, encodeURIComponent(String(claveAreaGm)))
        .replace(`{${'clave_asentamiento'}}`, encodeURIComponent(String(claveAsentamiento)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveCampo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCampos: async (cveCampo: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'cveCampo' is not null or undefined
      assertParamExists('getIdCampos', 'cveCampo', cveCampo);
      const localVarPath = `/api/v1/campos/{cve_campo}`.replace(`{${'cve_campo'}}`, encodeURIComponent(String(cveCampo)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveCaracteristContacto
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCaracteristContactos: async (
      cveCaracteristContacto: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveCaracteristContacto' is not null or undefined
      assertParamExists('getIdCaracteristContactos', 'cveCaracteristContacto', cveCaracteristContacto);
      const localVarPath = `/api/v1/caracterist-contactos/{cve_caracterist_contacto}`.replace(
        `{${'cve_caracterist_contacto'}}`,
        encodeURIComponent(String(cveCaracteristContacto))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoContacto
     * @param {string} cveCaracteristContacto A unique integer value identifying this cat_caracterist_contacto.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCaracteristContactosTipoContactos: async (
      idTipoContacto: string,
      cveCaracteristContacto: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoContacto' is not null or undefined
      assertParamExists('getIdCaracteristContactosTipoContactos', 'idTipoContacto', idTipoContacto);
      // verify required parameter 'cveCaracteristContacto' is not null or undefined
      assertParamExists('getIdCaracteristContactosTipoContactos', 'cveCaracteristContacto', cveCaracteristContacto);
      const localVarPath = `/api/v1/tipo-contactos/{id_tipo_contacto}/caracterist-contactos/{cve_caracterist_contacto}`
        .replace(`{${'id_tipo_contacto'}}`, encodeURIComponent(String(idTipoContacto)))
        .replace(`{${'cve_caracterist_contacto'}}`, encodeURIComponent(String(cveCaracteristContacto)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveAreaConocimiento
     * @param {string} idCampo A unique integer value identifying this cat_campo.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatCamposAreasConocimiento: async (
      cveAreaConocimiento: string,
      idCampo: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveAreaConocimiento' is not null or undefined
      assertParamExists('getIdCatCamposAreasConocimiento', 'cveAreaConocimiento', cveAreaConocimiento);
      // verify required parameter 'idCampo' is not null or undefined
      assertParamExists('getIdCatCamposAreasConocimiento', 'idCampo', idCampo);
      const localVarPath = `/api/v1/areas-conocimiento/{cve_area_conocimiento}/campos/{id_campo}`
        .replace(`{${'cve_area_conocimiento'}}`, encodeURIComponent(String(cveAreaConocimiento)))
        .replace(`{${'id_campo'}}`, encodeURIComponent(String(idCampo)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idDefinicionLogUi
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatDefinicionLogUi: async (
      idDefinicionLogUi: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idDefinicionLogUi' is not null or undefined
      assertParamExists('getIdCatDefinicionLogUi', 'idDefinicionLogUi', idDefinicionLogUi);
      const localVarPath = `/api/v1/definiciones-log-ui/{id_definicion_log_ui}`.replace(
        `{${'id_definicion_log_ui'}}`,
        encodeURIComponent(String(idDefinicionLogUi))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveEstatusMigratorio
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatEstatusMigratorio: async (
      cveEstatusMigratorio: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveEstatusMigratorio' is not null or undefined
      assertParamExists('getIdCatEstatusMigratorio', 'cveEstatusMigratorio', cveEstatusMigratorio);
      const localVarPath = `/api/v1/estatus-migratorios/{cve_estatus_migratorio}`.replace(
        `{${'cve_estatus_migratorio'}}`,
        encodeURIComponent(String(cveEstatusMigratorio))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idInstitucion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatInstituciones: async (
      idInstitucion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idInstitucion' is not null or undefined
      assertParamExists('getIdCatInstituciones', 'idInstitucion', idInstitucion);
      const localVarPath = `/api/v1/instituciones/{id_institucion}`.replace(
        `{${'id_institucion'}}`,
        encodeURIComponent(String(idInstitucion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveOrigenReporte
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatOrigenReporte: async (
      cveOrigenReporte: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveOrigenReporte' is not null or undefined
      assertParamExists('getIdCatOrigenReporte', 'cveOrigenReporte', cveOrigenReporte);
      const localVarPath = `/api/v1/origenes-reporte/{cve_origen_reporte}`.replace(
        `{${'cve_origen_reporte'}}`,
        encodeURIComponent(String(cveOrigenReporte))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idPalabraClave
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatPalabraClave: async (
      idPalabraClave: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idPalabraClave' is not null or undefined
      assertParamExists('getIdCatPalabraClave', 'idPalabraClave', idPalabraClave);
      const localVarPath = `/api/v1/palabras-clave/{id_palabra_clave}`.replace(
        `{${'id_palabra_clave'}}`,
        encodeURIComponent(String(idPalabraClave))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveProductoDivulgacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatProductoDivulgacion: async (
      cveProductoDivulgacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveProductoDivulgacion' is not null or undefined
      assertParamExists('getIdCatProductoDivulgacion', 'cveProductoDivulgacion', cveProductoDivulgacion);
      const localVarPath = `/api/v1/productos-divulgacion/{cve_producto_divulgacion}`.replace(
        `{${'cve_producto_divulgacion'}}`,
        encodeURIComponent(String(cveProductoDivulgacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveRedTematicaConacyt
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatRedTematicaConacyt: async (
      cveRedTematicaConacyt: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveRedTematicaConacyt' is not null or undefined
      assertParamExists('getIdCatRedTematicaConacyt', 'cveRedTematicaConacyt', cveRedTematicaConacyt);
      const localVarPath = `/api/v1/redes-tematica-conacyt/{cve_red_tematica_conacyt}`.replace(
        `{${'cve_red_tematica_conacyt'}}`,
        encodeURIComponent(String(cveRedTematicaConacyt))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoDoctoRespaldo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatTipoDoctoRespaldo: async (
      cveTipoDoctoRespaldo: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoDoctoRespaldo' is not null or undefined
      assertParamExists('getIdCatTipoDoctoRespaldo', 'cveTipoDoctoRespaldo', cveTipoDoctoRespaldo);
      const localVarPath = `/api/v1/docto-respaldos/{cve_tipo_docto_respaldo}`.replace(
        `{${'cve_tipo_docto_respaldo'}}`,
        encodeURIComponent(String(cveTipoDoctoRespaldo))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoDomicilio
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatTipoDomicilio: async (
      cveTipoDomicilio: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoDomicilio' is not null or undefined
      assertParamExists('getIdCatTipoDomicilio', 'cveTipoDomicilio', cveTipoDomicilio);
      const localVarPath = `/api/v1/tipos-domicilio/{cve_tipo_domicilio}`.replace(
        `{${'cve_tipo_domicilio'}}`,
        encodeURIComponent(String(cveTipoDomicilio))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoFondo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatTipoFondo: async (
      cveTipoFondo: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoFondo' is not null or undefined
      assertParamExists('getIdCatTipoFondo', 'cveTipoFondo', cveTipoFondo);
      const localVarPath = `/api/v1/tipos-fondo/{cve_tipo_fondo}`.replace(
        `{${'cve_tipo_fondo'}}`,
        encodeURIComponent(String(cveTipoFondo))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoInstNivelTres
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatTipoInstNivelTres: async (
      cveTipoInstNivelTres: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoInstNivelTres' is not null or undefined
      assertParamExists('getIdCatTipoInstNivelTres', 'cveTipoInstNivelTres', cveTipoInstNivelTres);
      const localVarPath = `/api/v1/tipos-inst-nivel-tres/{cve_tipo_inst_nivel_tres}`.replace(
        `{${'cve_tipo_inst_nivel_tres'}}`,
        encodeURIComponent(String(cveTipoInstNivelTres))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoProyecto
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatTipoProyecto: async (
      cveTipoProyecto: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoProyecto' is not null or undefined
      assertParamExists('getIdCatTipoProyecto', 'cveTipoProyecto', cveTipoProyecto);
      const localVarPath = `/api/v1/tipos-proyecto/{cve_tipo_proyecto}`.replace(
        `{${'cve_tipo_proyecto'}}`,
        encodeURIComponent(String(cveTipoProyecto))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idAmbito
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatVialidads: async (idAmbito: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'idAmbito' is not null or undefined
      assertParamExists('getIdCatVialidads', 'idAmbito', idAmbito);
      const localVarPath = `/api/v1/ambitos/{id_ambito}/vialidad/`.replace(`{${'id_ambito'}}`, encodeURIComponent(String(idAmbito)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} clave
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatalogoDetalles: async (clave: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'clave' is not null or undefined
      assertParamExists('getIdCatalogoDetalles', 'clave', clave);
      const localVarPath = `/api/v1/catalogos-detalle/{clave}`.replace(`{${'clave'}}`, encodeURIComponent(String(clave)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idCatCatalogoMaestro
     * @param {string} clave A unique integer value identifying this cat_catalogo_detalle.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatalogoDetallesCatalogosMaestro: async (
      idCatCatalogoMaestro: string,
      clave: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idCatCatalogoMaestro' is not null or undefined
      assertParamExists('getIdCatalogoDetallesCatalogosMaestro', 'idCatCatalogoMaestro', idCatCatalogoMaestro);
      // verify required parameter 'clave' is not null or undefined
      assertParamExists('getIdCatalogoDetallesCatalogosMaestro', 'clave', clave);
      const localVarPath = `/api/v1/catalogos-maestros/{id_cat_catalogo_maestro}/catalogos-detalle/{clave}`
        .replace(`{${'id_cat_catalogo_maestro'}}`, encodeURIComponent(String(idCatCatalogoMaestro)))
        .replace(`{${'clave'}}`, encodeURIComponent(String(clave)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} nombre A unique integer value identifying this cat_catalogo_maestro.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatalogoMaestros: async (nombre: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'nombre' is not null or undefined
      assertParamExists('getIdCatalogoMaestros', 'nombre', nombre);
      const localVarPath = `/api/v1/catalogos-maestros/{nombre}`.replace(`{${'nombre'}}`, encodeURIComponent(String(nombre)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveCodigoPostal
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCodigosPostales: async (
      cveCodigoPostal: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveCodigoPostal' is not null or undefined
      assertParamExists('getIdCodigosPostales', 'cveCodigoPostal', cveCodigoPostal);
      const localVarPath = `/api/v1/codigos-postales/{cve_codigo_postal}`.replace(
        `{${'cve_codigo_postal'}}`,
        encodeURIComponent(String(cveCodigoPostal))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoAsentamiento
     * @param {string} cveCodigoPostal A unique integer value identifying this cat_codigos_postales.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCodigosPostalesTipoAsentamientos: async (
      cveTipoAsentamiento: string,
      cveCodigoPostal: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoAsentamiento' is not null or undefined
      assertParamExists('getIdCodigosPostalesTipoAsentamientos', 'cveTipoAsentamiento', cveTipoAsentamiento);
      // verify required parameter 'cveCodigoPostal' is not null or undefined
      assertParamExists('getIdCodigosPostalesTipoAsentamientos', 'cveCodigoPostal', cveCodigoPostal);
      const localVarPath = `/api/v1/tipo-asentamientos/{cve_tipo_asentamiento}/codigos-postales/{cve_codigo_postal}`
        .replace(`{${'cve_tipo_asentamiento'}}`, encodeURIComponent(String(cveTipoAsentamiento)))
        .replace(`{${'cve_codigo_postal'}}`, encodeURIComponent(String(cveCodigoPostal)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveConsejoCertificador A unique integer value identifying this cat_consejo_certificador.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdConsejoCertificadores: async (
      cveConsejoCertificador: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveConsejoCertificador' is not null or undefined
      assertParamExists('getIdConsejoCertificadores', 'cveConsejoCertificador', cveConsejoCertificador);
      const localVarPath = `/api/v1/consejos-certificadores/{cve_consejo_certificador}`.replace(
        `{${'cve_consejo_certificador'}}`,
        encodeURIComponent(String(cveConsejoCertificador))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveDerechoTransito A unique integer value identifying this cat_derecho_transito.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdDerechoTransitos: async (
      cveDerechoTransito: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveDerechoTransito' is not null or undefined
      assertParamExists('getIdDerechoTransitos', 'cveDerechoTransito', cveDerechoTransito);
      const localVarPath = `/api/v1/derechos-transito/{cve_derecho_transito}`.replace(
        `{${'cve_derecho_transito'}}`,
        encodeURIComponent(String(cveDerechoTransito))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idDiaNolabConacyt A unique integer value identifying this cat_dia_nolab_conacyt.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdDiaNolabConacyt: async (
      idDiaNolabConacyt: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idDiaNolabConacyt' is not null or undefined
      assertParamExists('getIdDiaNolabConacyt', 'idDiaNolabConacyt', idDiaNolabConacyt);
      const localVarPath = `/api/v1/dias-nolab-conacyt/{id_dia_nolab_conacyt}`.replace(
        `{${'id_dia_nolab_conacyt'}}`,
        encodeURIComponent(String(idDiaNolabConacyt))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveDictamenEvaluacion A unique integer value identifying this cat_dictamen_evaluacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdDictamenEvaluaciones: async (
      cveDictamenEvaluacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveDictamenEvaluacion' is not null or undefined
      assertParamExists('getIdDictamenEvaluaciones', 'cveDictamenEvaluacion', cveDictamenEvaluacion);
      const localVarPath = `/api/v1/dictamenes-evaluaciones/{cve_dictamen_evaluacion}`.replace(
        `{${'cve_dictamen_evaluacion'}}`,
        encodeURIComponent(String(cveDictamenEvaluacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idDisciplina
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdDisciplina: async (idDisciplina: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'idDisciplina' is not null or undefined
      assertParamExists('getIdDisciplina', 'idDisciplina', idDisciplina);
      const localVarPath = `/api/v1/disciplinas/{id_disciplina}`.replace(`{${'id_disciplina'}}`, encodeURIComponent(String(idDisciplina)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idCampo
     * @param {string} idDisciplina A unique integer value identifying this cat_disciplina.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdDisciplinasCampos: async (
      idCampo: string,
      idDisciplina: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idCampo' is not null or undefined
      assertParamExists('getIdDisciplinasCampos', 'idCampo', idCampo);
      // verify required parameter 'idDisciplina' is not null or undefined
      assertParamExists('getIdDisciplinasCampos', 'idDisciplina', idDisciplina);
      const localVarPath = `/api/v1/campos/{id_campo}/disciplinas/{id_disciplina}`
        .replace(`{${'id_campo'}}`, encodeURIComponent(String(idCampo)))
        .replace(`{${'id_disciplina'}}`, encodeURIComponent(String(idDisciplina)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveDistincionConacyt A unique integer value identifying this cat_distincion_conacyt.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdDistincionesConacyt: async (
      cveDistincionConacyt: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveDistincionConacyt' is not null or undefined
      assertParamExists('getIdDistincionesConacyt', 'cveDistincionConacyt', cveDistincionConacyt);
      const localVarPath = `/api/v1/distinciones-conacyt/{cve_distincion_conacyt}`.replace(
        `{${'cve_distincion_conacyt'}}`,
        encodeURIComponent(String(cveDistincionConacyt))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idEspecialidadMedica
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdEspecialidadesMedicas: async (
      idEspecialidadMedica: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idEspecialidadMedica' is not null or undefined
      assertParamExists('getIdEspecialidadesMedicas', 'idEspecialidadMedica', idEspecialidadMedica);
      const localVarPath = `/api/v1/especialidades-medicas/{id_especialidad_medica}`.replace(
        `{${'id_especialidad_medica'}}`,
        encodeURIComponent(String(idEspecialidadMedica))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idConsejoCertificador
     * @param {string} idEspecialidadMedica A unique integer value identifying this cat_especialidad_medica.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdEspecialidadesMedicasConsejosCertificadores: async (
      idConsejoCertificador: string,
      idEspecialidadMedica: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idConsejoCertificador' is not null or undefined
      assertParamExists('getIdEspecialidadesMedicasConsejosCertificadores', 'idConsejoCertificador', idConsejoCertificador);
      // verify required parameter 'idEspecialidadMedica' is not null or undefined
      assertParamExists('getIdEspecialidadesMedicasConsejosCertificadores', 'idEspecialidadMedica', idEspecialidadMedica);
      const localVarPath = `/api/v1/consejos-certificadores/{id_consejo_certificador}/especialidades-medicas/{id_especialidad_medica}`
        .replace(`{${'id_consejo_certificador'}}`, encodeURIComponent(String(idConsejoCertificador)))
        .replace(`{${'id_especialidad_medica'}}`, encodeURIComponent(String(idEspecialidadMedica)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveEstadoCivil A unique integer value identifying this cat_estado_civil.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdEstadoCiviles: async (
      cveEstadoCivil: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveEstadoCivil' is not null or undefined
      assertParamExists('getIdEstadoCiviles', 'cveEstadoCivil', cveEstadoCivil);
      const localVarPath = `/api/v1/estados-civiles/{cve_estado_civil}`.replace(
        `{${'cve_estado_civil'}}`,
        encodeURIComponent(String(cveEstadoCivil))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveEstadoTesis
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdEstadoTesis: async (
      cveEstadoTesis: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveEstadoTesis' is not null or undefined
      assertParamExists('getIdEstadoTesis', 'cveEstadoTesis', cveEstadoTesis);
      const localVarPath = `/api/v1/estados-tesis/{cve_estado_tesis}`.replace(
        `{${'cve_estado_tesis'}}`,
        encodeURIComponent(String(cveEstadoTesis))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveEstatusAcademico A unique integer value identifying this cat_estatus_academico.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdEstatusAcademicos: async (
      cveEstatusAcademico: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveEstatusAcademico' is not null or undefined
      assertParamExists('getIdEstatusAcademicos', 'cveEstatusAcademico', cveEstatusAcademico);
      const localVarPath = `/api/v1/estatus-academicos/{cve_estatus_academico}`.replace(
        `{${'cve_estatus_academico'}}`,
        encodeURIComponent(String(cveEstatusAcademico))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idEstatusMovGasto A unique integer value identifying this cat_estatus_mov_gasto.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdEstatusMovGasto: async (
      idEstatusMovGasto: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idEstatusMovGasto' is not null or undefined
      assertParamExists('getIdEstatusMovGasto', 'idEstatusMovGasto', idEstatusMovGasto);
      const localVarPath = `/api/v1/estatus-mov-gastos/{id_estatus_mov_gasto}`.replace(
        `{${'id_estatus_mov_gasto'}}`,
        encodeURIComponent(String(idEstatusMovGasto))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idEstatusNotificacion A unique integer value identifying this cat_estatus_notificacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdEstatusNotificacion: async (
      idEstatusNotificacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idEstatusNotificacion' is not null or undefined
      assertParamExists('getIdEstatusNotificacion', 'idEstatusNotificacion', idEstatusNotificacion);
      const localVarPath = `/api/v1/estatus-notificaciones/{id_estatus_notificacion}`.replace(
        `{${'id_estatus_notificacion'}}`,
        encodeURIComponent(String(idEstatusNotificacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveFormaTitulacion A unique integer value identifying this cat_forma_titulacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdFormaTitulaciones: async (
      cveFormaTitulacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveFormaTitulacion' is not null or undefined
      assertParamExists('getIdFormaTitulaciones', 'cveFormaTitulacion', cveFormaTitulacion);
      const localVarPath = `/api/v1/formas-titulacion/{cve_forma_titulacion}`.replace(
        `{${'cve_forma_titulacion'}}`,
        encodeURIComponent(String(cveFormaTitulacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idFormatoNotificacion A unique integer value identifying this cat_formato_notificacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdFormatoNotificacion: async (
      idFormatoNotificacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idFormatoNotificacion' is not null or undefined
      assertParamExists('getIdFormatoNotificacion', 'idFormatoNotificacion', idFormatoNotificacion);
      const localVarPath = `/api/v1/formatos-notificacion/{id_formato_notificacion}`.replace(
        `{${'id_formato_notificacion'}}`,
        encodeURIComponent(String(idFormatoNotificacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveGenero A unique integer value identifying this cat_genero.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdGeneros: async (cveGenero: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'cveGenero' is not null or undefined
      assertParamExists('getIdGeneros', 'cveGenero', cveGenero);
      const localVarPath = `/api/v1/generos/{cve_genero}`.replace(`{${'cve_genero'}}`, encodeURIComponent(String(cveGenero)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveIdentificaAutor A unique integer value identifying this cat_identifica_autor.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdIdentificaAutores: async (
      cveIdentificaAutor: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveIdentificaAutor' is not null or undefined
      assertParamExists('getIdIdentificaAutores', 'cveIdentificaAutor', cveIdentificaAutor);
      const localVarPath = `/api/v1/identifica-autores/{cve_identifica_autor}`.replace(
        `{${'cve_identifica_autor'}}`,
        encodeURIComponent(String(cveIdentificaAutor))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveIdioma A unique integer value identifying this cat_idioma.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdIdiomas: async (cveIdioma: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'cveIdioma' is not null or undefined
      assertParamExists('getIdIdiomas', 'cveIdioma', cveIdioma);
      const localVarPath = `/api/v1/idiomas/{cve_idioma}`.replace(`{${'cve_idioma'}}`, encodeURIComponent(String(cveIdioma)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idEntidad
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdInstitucionesEstados: async (
      idEntidad: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idEntidad' is not null or undefined
      assertParamExists('getIdInstitucionesEstados', 'idEntidad', idEntidad);
      // verify required parameter 'cveInstitucion' is not null or undefined
      assertParamExists('getIdInstitucionesEstados', 'cveInstitucion', cveInstitucion);
      const localVarPath = `/api/v1/estados/{id_entidad}/instituciones/{cve_institucion}`
        .replace(`{${'id_entidad'}}`, encodeURIComponent(String(idEntidad)))
        .replace(`{${'cve_institucion'}}`, encodeURIComponent(String(cveInstitucion)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idLocalidad
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdInstitucionesLocalidades: async (
      idLocalidad: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idLocalidad' is not null or undefined
      assertParamExists('getIdInstitucionesLocalidades', 'idLocalidad', idLocalidad);
      // verify required parameter 'cveInstitucion' is not null or undefined
      assertParamExists('getIdInstitucionesLocalidades', 'cveInstitucion', cveInstitucion);
      const localVarPath = `/api/v1/localidades/{id_localidad}/instituciones/{cve_institucion}`
        .replace(`{${'id_localidad'}}`, encodeURIComponent(String(idLocalidad)))
        .replace(`{${'cve_institucion'}}`, encodeURIComponent(String(cveInstitucion)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idMunicipio
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdInstitucionesMunicipios: async (
      idMunicipio: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idMunicipio' is not null or undefined
      assertParamExists('getIdInstitucionesMunicipios', 'idMunicipio', idMunicipio);
      // verify required parameter 'cveInstitucion' is not null or undefined
      assertParamExists('getIdInstitucionesMunicipios', 'cveInstitucion', cveInstitucion);
      const localVarPath = `/api/v1/municipios/{id_municipio}/instituciones/{cve_institucion}`
        .replace(`{${'id_municipio'}}`, encodeURIComponent(String(idMunicipio)))
        .replace(`{${'cve_institucion'}}`, encodeURIComponent(String(cveInstitucion)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cvePais
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdInstitucionesPaises: async (
      cvePais: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cvePais' is not null or undefined
      assertParamExists('getIdInstitucionesPaises', 'cvePais', cvePais);
      // verify required parameter 'cveInstitucion' is not null or undefined
      assertParamExists('getIdInstitucionesPaises', 'cveInstitucion', cveInstitucion);
      const localVarPath = `/api/v1/paises/{cve_pais}/instituciones/{cve_institucion}`
        .replace(`{${'cve_pais'}}`, encodeURIComponent(String(cvePais)))
        .replace(`{${'cve_institucion'}}`, encodeURIComponent(String(cveInstitucion)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoInstNivelDos
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdInstitucionesTipoInstNivelDos: async (
      idTipoInstNivelDos: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoInstNivelDos' is not null or undefined
      assertParamExists('getIdInstitucionesTipoInstNivelDos', 'idTipoInstNivelDos', idTipoInstNivelDos);
      // verify required parameter 'cveInstitucion' is not null or undefined
      assertParamExists('getIdInstitucionesTipoInstNivelDos', 'cveInstitucion', cveInstitucion);
      const localVarPath = `/api/v1/tipo-inst-nivel-dos/{id_tipo_inst_nivel_dos}/instituciones/{cve_institucion}`
        .replace(`{${'id_tipo_inst_nivel_dos'}}`, encodeURIComponent(String(idTipoInstNivelDos)))
        .replace(`{${'cve_institucion'}}`, encodeURIComponent(String(cveInstitucion)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoInstNivelTres
     * @param {string} idInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdInstitucionesTipoInstNivelTres: async (
      idTipoInstNivelTres: string,
      idInstitucion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoInstNivelTres' is not null or undefined
      assertParamExists('getIdInstitucionesTipoInstNivelTres', 'idTipoInstNivelTres', idTipoInstNivelTres);
      // verify required parameter 'idInstitucion' is not null or undefined
      assertParamExists('getIdInstitucionesTipoInstNivelTres', 'idInstitucion', idInstitucion);
      const localVarPath = `/api/v1/tipo-inst-nivel-tres/{id_tipo_inst_nivel_tres}/instituciones/{id_institucion}`
        .replace(`{${'id_tipo_inst_nivel_tres'}}`, encodeURIComponent(String(idTipoInstNivelTres)))
        .replace(`{${'id_institucion'}}`, encodeURIComponent(String(idInstitucion)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoInstNivelUno
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdInstitucionesTipoInstNivelUno: async (
      idTipoInstNivelUno: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoInstNivelUno' is not null or undefined
      assertParamExists('getIdInstitucionesTipoInstNivelUno', 'idTipoInstNivelUno', idTipoInstNivelUno);
      // verify required parameter 'cveInstitucion' is not null or undefined
      assertParamExists('getIdInstitucionesTipoInstNivelUno', 'cveInstitucion', cveInstitucion);
      const localVarPath = `/api/v1/tipo-inst-nivel-uno/{id_tipo_inst_nivel_uno}/instituciones/{cve_institucion}`
        .replace(`{${'id_tipo_inst_nivel_uno'}}`, encodeURIComponent(String(idTipoInstNivelUno)))
        .replace(`{${'cve_institucion'}}`, encodeURIComponent(String(cveInstitucion)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoInstitucion
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdInstitucionesTipoInstituciones: async (
      idTipoInstitucion: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoInstitucion' is not null or undefined
      assertParamExists('getIdInstitucionesTipoInstituciones', 'idTipoInstitucion', idTipoInstitucion);
      // verify required parameter 'cveInstitucion' is not null or undefined
      assertParamExists('getIdInstitucionesTipoInstituciones', 'cveInstitucion', cveInstitucion);
      const localVarPath = `/api/v1/tipo-instituciones/{id_tipo_institucion}/instituciones/{cve_institucion}`
        .replace(`{${'id_tipo_institucion'}}`, encodeURIComponent(String(idTipoInstitucion)))
        .replace(`{${'cve_institucion'}}`, encodeURIComponent(String(cveInstitucion)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveLenguaIndigena A unique integer value identifying this cat_lengua_indigena.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdLenguaIndigenas: async (
      cveLenguaIndigena: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveLenguaIndigena' is not null or undefined
      assertParamExists('getIdLenguaIndigenas', 'cveLenguaIndigena', cveLenguaIndigena);
      const localVarPath = `/api/v1/lenguas-indigenas/{cve_lengua_indigena}`.replace(
        `{${'cve_lengua_indigena'}}`,
        encodeURIComponent(String(cveLenguaIndigena))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveLocalidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdLocalidadesMunicipiosEstados: async (
      claveAreaGe: string,
      claveAreaGm: string,
      claveLocalidad: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'claveAreaGe' is not null or undefined
      assertParamExists('getIdLocalidadesMunicipiosEstados', 'claveAreaGe', claveAreaGe);
      // verify required parameter 'claveAreaGm' is not null or undefined
      assertParamExists('getIdLocalidadesMunicipiosEstados', 'claveAreaGm', claveAreaGm);
      // verify required parameter 'claveLocalidad' is not null or undefined
      assertParamExists('getIdLocalidadesMunicipiosEstados', 'claveLocalidad', claveLocalidad);
      const localVarPath = `/api/v1/estados/{clave_area_ge}/municipios/{clave_area_gm}/localidades/{clave_localidad}`
        .replace(`{${'clave_area_ge'}}`, encodeURIComponent(String(claveAreaGe)))
        .replace(`{${'clave_area_gm'}}`, encodeURIComponent(String(claveAreaGm)))
        .replace(`{${'clave_localidad'}}`, encodeURIComponent(String(claveLocalidad)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveMargen A unique integer value identifying this cat_margen.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdMargenes: async (cveMargen: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'cveMargen' is not null or undefined
      assertParamExists('getIdMargenes', 'cveMargen', cveMargen);
      const localVarPath = `/api/v1/margenes/{cve_margen}`.replace(`{${'cve_margen'}}`, encodeURIComponent(String(cveMargen)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idMomentoEvento A unique integer value identifying this cat_momento_evento.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdMomentoEvento: async (
      idMomentoEvento: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idMomentoEvento' is not null or undefined
      assertParamExists('getIdMomentoEvento', 'idMomentoEvento', idMomentoEvento);
      const localVarPath = `/api/v1/momentos-evento/{id_momento_evento}`.replace(
        `{${'id_momento_evento'}}`,
        encodeURIComponent(String(idMomentoEvento))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdMunicipiosEstado: async (
      claveAreaGe: string,
      claveAreaGm: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'claveAreaGe' is not null or undefined
      assertParamExists('getIdMunicipiosEstado', 'claveAreaGe', claveAreaGe);
      // verify required parameter 'claveAreaGm' is not null or undefined
      assertParamExists('getIdMunicipiosEstado', 'claveAreaGm', claveAreaGm);
      const localVarPath = `/api/v1/estados/{clave_area_ge}/municipios/{clave_area_gm}`
        .replace(`{${'clave_area_ge'}}`, encodeURIComponent(String(claveAreaGe)))
        .replace(`{${'clave_area_gm'}}`, encodeURIComponent(String(claveAreaGm)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveNacionalidad A unique integer value identifying this cat_nacionalidad.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdNacionalidades: async (
      cveNacionalidad: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveNacionalidad' is not null or undefined
      assertParamExists('getIdNacionalidades', 'cveNacionalidad', cveNacionalidad);
      const localVarPath = `/api/v1/nacionalidades/{cve_nacionalidad}`.replace(
        `{${'cve_nacionalidad'}}`,
        encodeURIComponent(String(cveNacionalidad))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idNivelPnpc A unique integer value identifying this cat_nivel_pnpc.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdNivelPnpc: async (idNivelPnpc: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'idNivelPnpc' is not null or undefined
      assertParamExists('getIdNivelPnpc', 'idNivelPnpc', idNivelPnpc);
      const localVarPath = `/api/v1/niveles-pnpc/{id_nivel_pnpc}`.replace(`{${'id_nivel_pnpc'}}`, encodeURIComponent(String(idNivelPnpc)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveNivelAcademico A unique integer value identifying this cat_nivel_academico.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdNivelesAcademicos: async (
      cveNivelAcademico: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveNivelAcademico' is not null or undefined
      assertParamExists('getIdNivelesAcademicos', 'cveNivelAcademico', cveNivelAcademico);
      const localVarPath = `/api/v1/niveles-academicos/{cve_nivel_academico}`.replace(
        `{${'cve_nivel_academico'}}`,
        encodeURIComponent(String(cveNivelAcademico))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveNivelDominioLd A unique integer value identifying this cat_nivel_dominio_ld.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdNivelesDominiosLd: async (
      cveNivelDominioLd: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveNivelDominioLd' is not null or undefined
      assertParamExists('getIdNivelesDominiosLd', 'cveNivelDominioLd', cveNivelDominioLd);
      const localVarPath = `/api/v1/niveles-dominios-ld/{cve_nivel_dominio_ld}`.replace(
        `{${'cve_nivel_dominio_ld'}}`,
        encodeURIComponent(String(cveNivelDominioLd))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idOcdeGrupo
     * @param {string} idOcdeClase A unique integer value identifying this cat_ocde_clase.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdOcdeClasesOcdeGrupos: async (
      idOcdeGrupo: string,
      idOcdeClase: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idOcdeGrupo' is not null or undefined
      assertParamExists('getIdOcdeClasesOcdeGrupos', 'idOcdeGrupo', idOcdeGrupo);
      // verify required parameter 'idOcdeClase' is not null or undefined
      assertParamExists('getIdOcdeClasesOcdeGrupos', 'idOcdeClase', idOcdeClase);
      const localVarPath = `/api/v1/ocde-grupos/{id_ocde_grupo}/ocde-clases/{id_ocde_clase}`
        .replace(`{${'id_ocde_grupo'}}`, encodeURIComponent(String(idOcdeGrupo)))
        .replace(`{${'id_ocde_clase'}}`, encodeURIComponent(String(idOcdeClase)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idOcdeDivision
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdOcdeDivisiones: async (
      idOcdeDivision: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idOcdeDivision' is not null or undefined
      assertParamExists('getIdOcdeDivisiones', 'idOcdeDivision', idOcdeDivision);
      const localVarPath = `/api/v1/ocde-divisiones/{id_ocde_division}`.replace(
        `{${'id_ocde_division'}}`,
        encodeURIComponent(String(idOcdeDivision))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idOcdeSector
     * @param {string} idOcdeDivision A unique integer value identifying this cat_ocde_division.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdOcdeDivisionesOcdeSector: async (
      idOcdeSector: string,
      idOcdeDivision: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idOcdeSector' is not null or undefined
      assertParamExists('getIdOcdeDivisionesOcdeSector', 'idOcdeSector', idOcdeSector);
      // verify required parameter 'idOcdeDivision' is not null or undefined
      assertParamExists('getIdOcdeDivisionesOcdeSector', 'idOcdeDivision', idOcdeDivision);
      const localVarPath = `/api/v1/ocde-sectores/{id_ocde_sector}/ocde-divisiones/{id_ocde_division}`
        .replace(`{${'id_ocde_sector'}}`, encodeURIComponent(String(idOcdeSector)))
        .replace(`{${'id_ocde_division'}}`, encodeURIComponent(String(idOcdeDivision)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idOcdeGrupo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdOcdeGrupos: async (idOcdeGrupo: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'idOcdeGrupo' is not null or undefined
      assertParamExists('getIdOcdeGrupos', 'idOcdeGrupo', idOcdeGrupo);
      const localVarPath = `/api/v1/ocde-grupos/{id_ocde_grupo}`.replace(`{${'id_ocde_grupo'}}`, encodeURIComponent(String(idOcdeGrupo)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idOcdeDivision
     * @param {string} idOcdeGrupo A unique integer value identifying this cat_ocde_grupo.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdOcdeGruposOcdeDivisiones: async (
      idOcdeDivision: string,
      idOcdeGrupo: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idOcdeDivision' is not null or undefined
      assertParamExists('getIdOcdeGruposOcdeDivisiones', 'idOcdeDivision', idOcdeDivision);
      // verify required parameter 'idOcdeGrupo' is not null or undefined
      assertParamExists('getIdOcdeGruposOcdeDivisiones', 'idOcdeGrupo', idOcdeGrupo);
      const localVarPath = `/api/v1/ocde-divisiones/{id_ocde_division}/ocde-grupos/{id_ocde_grupo}`
        .replace(`{${'id_ocde_division'}}`, encodeURIComponent(String(idOcdeDivision)))
        .replace(`{${'id_ocde_grupo'}}`, encodeURIComponent(String(idOcdeGrupo)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idOcdeSector A unique integer value identifying this cat_ocde_sector.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdOcdeSectores: async (
      idOcdeSector: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idOcdeSector' is not null or undefined
      assertParamExists('getIdOcdeSectores', 'idOcdeSector', idOcdeSector);
      const localVarPath = `/api/v1/ocde-sectores/{id_ocde_sector}`.replace(
        `{${'id_ocde_sector'}}`,
        encodeURIComponent(String(idOcdeSector))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idOperacionPago A unique integer value identifying this cat_operacion_pago.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdOperacionPago: async (
      idOperacionPago: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idOperacionPago' is not null or undefined
      assertParamExists('getIdOperacionPago', 'idOperacionPago', idOperacionPago);
      const localVarPath = `/api/v1/operaciones-pago/{id_operacion_pago}`.replace(
        `{${'id_operacion_pago'}}`,
        encodeURIComponent(String(idOperacionPago))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idOperadorComparacion A unique integer value identifying this cat_operador_comparacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdOperadorComparacion: async (
      idOperadorComparacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idOperadorComparacion' is not null or undefined
      assertParamExists('getIdOperadorComparacion', 'idOperadorComparacion', idOperadorComparacion);
      const localVarPath = `/api/v1/operadores-comparacion/{id_operador_comparacion}`.replace(
        `{${'id_operador_comparacion'}}`,
        encodeURIComponent(String(idOperadorComparacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cvePais A unique integer value identifying this cat_pais.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdPaises: async (cvePais: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'cvePais' is not null or undefined
      assertParamExists('getIdPaises', 'cvePais', cvePais);
      const localVarPath = `/api/v1/paises/{cve_pais}`.replace(`{${'cve_pais'}}`, encodeURIComponent(String(cvePais)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idFormatoNotificacion
     * @param {string} idPalabraClave A unique integer value identifying this cat_palabra_clave.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdPalabraClave: async (
      idFormatoNotificacion: string,
      idPalabraClave: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idFormatoNotificacion' is not null or undefined
      assertParamExists('getIdPalabraClave', 'idFormatoNotificacion', idFormatoNotificacion);
      // verify required parameter 'idPalabraClave' is not null or undefined
      assertParamExists('getIdPalabraClave', 'idPalabraClave', idPalabraClave);
      const localVarPath = `/api/v1/formatos-notificacion/{id_formato_notificacion}/palabras-clave/{id_palabra_clave}`
        .replace(`{${'id_formato_notificacion'}}`, encodeURIComponent(String(idFormatoNotificacion)))
        .replace(`{${'id_palabra_clave'}}`, encodeURIComponent(String(idPalabraClave)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idParametrosSeguridad A unique integer value identifying this cat_parametros_seguridad.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdParametrosSeguridad: async (
      idParametrosSeguridad: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idParametrosSeguridad' is not null or undefined
      assertParamExists('getIdParametrosSeguridad', 'idParametrosSeguridad', idParametrosSeguridad);
      const localVarPath = `/api/v1/parametros-seguridad/{id_parametros_seguridad}`.replace(
        `{${'id_parametros_seguridad'}}`,
        encodeURIComponent(String(idParametrosSeguridad))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cvePartDivulgacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdPartDivulgacion: async (
      cvePartDivulgacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cvePartDivulgacion' is not null or undefined
      assertParamExists('getIdPartDivulgacion', 'cvePartDivulgacion', cvePartDivulgacion);
      const localVarPath = `/api/v1/part-divulgaciones/{cve_part_divulgacion}`.replace(
        `{${'cve_part_divulgacion'}}`,
        encodeURIComponent(String(cvePartDivulgacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveProductoEvaluacion A unique integer value identifying this cat_producto_evaluacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdProductoEvaluaciones: async (
      cveProductoEvaluacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveProductoEvaluacion' is not null or undefined
      assertParamExists('getIdProductoEvaluaciones', 'cveProductoEvaluacion', cveProductoEvaluacion);
      const localVarPath = `/api/v1/producto-evaluaciones/{cve_producto_evaluacion}`.replace(
        `{${'cve_producto_evaluacion'}}`,
        encodeURIComponent(String(cveProductoEvaluacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cvePuebloIndigena A unique integer value identifying this cat_pueblo_indigena.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdPueblosIndigenas: async (
      cvePuebloIndigena: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cvePuebloIndigena' is not null or undefined
      assertParamExists('getIdPueblosIndigenas', 'cvePuebloIndigena', cvePuebloIndigena);
      const localVarPath = `/api/v1/pueblos-indigenas/{cve_pueblo_indigena}`.replace(
        `{${'cve_pueblo_indigena'}}`,
        encodeURIComponent(String(cvePuebloIndigena))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idRecomdsEvalFomix A unique integer value identifying this cat_recomds_eval_fomix.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdRecomdsEvalFomix: async (
      idRecomdsEvalFomix: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idRecomdsEvalFomix' is not null or undefined
      assertParamExists('getIdRecomdsEvalFomix', 'idRecomdsEvalFomix', idRecomdsEvalFomix);
      const localVarPath = `/api/v1/recomds-eval-fomix/{id_recomds_eval_fomix}`.replace(
        `{${'id_recomds_eval_fomix'}}`,
        encodeURIComponent(String(idRecomdsEvalFomix))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idRolPersFisProgPnpc A unique integer value identifying this cat_rol_pers_fis_prog_pnpc.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdRolPersFisProgPnpc: async (
      idRolPersFisProgPnpc: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idRolPersFisProgPnpc' is not null or undefined
      assertParamExists('getIdRolPersFisProgPnpc', 'idRolPersFisProgPnpc', idRolPersFisProgPnpc);
      const localVarPath = `/api/v1/roles-pers-fis-prog-pnpc/{id_rol_pers_fis_prog_pnpc}`.replace(
        `{${'id_rol_pers_fis_prog_pnpc'}}`,
        encodeURIComponent(String(idRolPersFisProgPnpc))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idScianGrupo
     * @param {string} idScianClase A unique integer value identifying this cat_scian_clase.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdScianClasesScianGrupos: async (
      idScianGrupo: string,
      idScianClase: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idScianGrupo' is not null or undefined
      assertParamExists('getIdScianClasesScianGrupos', 'idScianGrupo', idScianGrupo);
      // verify required parameter 'idScianClase' is not null or undefined
      assertParamExists('getIdScianClasesScianGrupos', 'idScianClase', idScianClase);
      const localVarPath = `/api/v1/scian-grupos/{id_scian_grupo}/scian-clases/{id_scian_clase}`
        .replace(`{${'id_scian_grupo'}}`, encodeURIComponent(String(idScianGrupo)))
        .replace(`{${'id_scian_clase'}}`, encodeURIComponent(String(idScianClase)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idScianSector
     * @param {string} idScianDivision A unique integer value identifying this cat_scian_division.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdScianDivisionesScianSectores: async (
      idScianSector: string,
      idScianDivision: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idScianSector' is not null or undefined
      assertParamExists('getIdScianDivisionesScianSectores', 'idScianSector', idScianSector);
      // verify required parameter 'idScianDivision' is not null or undefined
      assertParamExists('getIdScianDivisionesScianSectores', 'idScianDivision', idScianDivision);
      const localVarPath = `/api/v1/scian-sectores/{id_scian_sector}/scian-divisiones/{id_scian_division}`
        .replace(`{${'id_scian_sector'}}`, encodeURIComponent(String(idScianSector)))
        .replace(`{${'id_scian_division'}}`, encodeURIComponent(String(idScianDivision)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idScianGrupo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdScianGrupos: async (
      idScianGrupo: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idScianGrupo' is not null or undefined
      assertParamExists('getIdScianGrupos', 'idScianGrupo', idScianGrupo);
      const localVarPath = `/api/v1/scian-grupos/{id_scian_grupo}`.replace(
        `{${'id_scian_grupo'}}`,
        encodeURIComponent(String(idScianGrupo))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idScianDivision
     * @param {string} idScianGrupo A unique integer value identifying this cat_scian_grupo.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdScianGruposScianDivisiones: async (
      idScianDivision: string,
      idScianGrupo: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idScianDivision' is not null or undefined
      assertParamExists('getIdScianGruposScianDivisiones', 'idScianDivision', idScianDivision);
      // verify required parameter 'idScianGrupo' is not null or undefined
      assertParamExists('getIdScianGruposScianDivisiones', 'idScianGrupo', idScianGrupo);
      const localVarPath = `/api/v1/scian-divisiones/{id_scian_division}/scian-grupos/{id_scian_grupo}`
        .replace(`{${'id_scian_division'}}`, encodeURIComponent(String(idScianDivision)))
        .replace(`{${'id_scian_grupo'}}`, encodeURIComponent(String(idScianGrupo)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idScianSector A unique integer value identifying this cat_scian_sector.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdScianSectores: async (
      idScianSector: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idScianSector' is not null or undefined
      assertParamExists('getIdScianSectores', 'idScianSector', idScianSector);
      const localVarPath = `/api/v1/scian-sectores/{id_scian_sector}`.replace(
        `{${'id_scian_sector'}}`,
        encodeURIComponent(String(idScianSector))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idScianSubclase
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdScianSubclases: async (
      idScianSubclase: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idScianSubclase' is not null or undefined
      assertParamExists('getIdScianSubclases', 'idScianSubclase', idScianSubclase);
      const localVarPath = `/api/v1/scian-subclases/{id_scian_subclase}`.replace(
        `{${'id_scian_subclase'}}`,
        encodeURIComponent(String(idScianSubclase))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idScianClase
     * @param {string} idScianSubclase A unique integer value identifying this cat_scian_subclase.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdScianSubclasesScianClases: async (
      idScianClase: string,
      idScianSubclase: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idScianClase' is not null or undefined
      assertParamExists('getIdScianSubclasesScianClases', 'idScianClase', idScianClase);
      // verify required parameter 'idScianSubclase' is not null or undefined
      assertParamExists('getIdScianSubclasesScianClases', 'idScianSubclase', idScianSubclase);
      const localVarPath = `/api/v1/scian-clases/{id_scian_clase}/scian-subclases/{id_scian_subclase}`
        .replace(`{${'id_scian_clase'}}`, encodeURIComponent(String(idScianClase)))
        .replace(`{${'id_scian_subclase'}}`, encodeURIComponent(String(idScianSubclase)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveSectorDivulgacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdSectorDivulgacion: async (
      cveSectorDivulgacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveSectorDivulgacion' is not null or undefined
      assertParamExists('getIdSectorDivulgacion', 'cveSectorDivulgacion', cveSectorDivulgacion);
      const localVarPath = `/api/v1/sectores-divulgacion/{cve_sector_divulgacion}`.replace(
        `{${'cve_sector_divulgacion'}}`,
        encodeURIComponent(String(cveSectorDivulgacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idSubdisciplina
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdSubDisciplinas: async (
      idSubdisciplina: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idSubdisciplina' is not null or undefined
      assertParamExists('getIdSubDisciplinas', 'idSubdisciplina', idSubdisciplina);
      const localVarPath = `/api/v1/subdisciplinas/{id_subdisciplina}`.replace(
        `{${'id_subdisciplina'}}`,
        encodeURIComponent(String(idSubdisciplina))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idDisciplina
     * @param {string} idSubdisciplina A unique integer value identifying this cat_subdisciplina.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdSubDisciplinasDisciplinas: async (
      idDisciplina: string,
      idSubdisciplina: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idDisciplina' is not null or undefined
      assertParamExists('getIdSubDisciplinasDisciplinas', 'idDisciplina', idDisciplina);
      // verify required parameter 'idSubdisciplina' is not null or undefined
      assertParamExists('getIdSubDisciplinasDisciplinas', 'idSubdisciplina', idSubdisciplina);
      const localVarPath = `/api/v1/disciplinas/{id_disciplina}/subdisciplinas/{id_subdisciplina}`
        .replace(`{${'id_disciplina'}}`, encodeURIComponent(String(idDisciplina)))
        .replace(`{${'id_subdisciplina'}}`, encodeURIComponent(String(idSubdisciplina)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTerminoGenerico A unique integer value identifying this cat_termino_generico.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTerminoGenericos: async (
      cveTerminoGenerico: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTerminoGenerico' is not null or undefined
      assertParamExists('getIdTerminoGenericos', 'cveTerminoGenerico', cveTerminoGenerico);
      const localVarPath = `/api/v1/terminos-genericos/{cve_termino_generico}`.replace(
        `{${'cve_termino_generico'}}`,
        encodeURIComponent(String(cveTerminoGenerico))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoActDivulgacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoActDivulgacion: async (
      cveTipoActDivulgacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoActDivulgacion' is not null or undefined
      assertParamExists('getIdTipoActDivulgacion', 'cveTipoActDivulgacion', cveTipoActDivulgacion);
      const localVarPath = `/api/v1/tipos-act-divulgacion/{cve_tipo_act_divulgacion}`.replace(
        `{${'cve_tipo_act_divulgacion'}}`,
        encodeURIComponent(String(cveTipoActDivulgacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoAsentamiento A unique integer value identifying this cat_tipo_asentamiento.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoAsentamientos: async (
      cveTipoAsentamiento: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoAsentamiento' is not null or undefined
      assertParamExists('getIdTipoAsentamientos', 'cveTipoAsentamiento', cveTipoAsentamiento);
      const localVarPath = `/api/v1/tipo-asentamientos/{cve_tipo_asentamiento}`.replace(
        `{${'cve_tipo_asentamiento'}}`,
        encodeURIComponent(String(cveTipoAsentamiento))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoContacto A unique integer value identifying this cat_tipo_contacto.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoContactos: async (
      cveTipoContacto: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoContacto' is not null or undefined
      assertParamExists('getIdTipoContactos', 'cveTipoContacto', cveTipoContacto);
      const localVarPath = `/api/v1/tipo-contactos/{cve_tipo_contacto}`.replace(
        `{${'cve_tipo_contacto'}}`,
        encodeURIComponent(String(cveTipoContacto))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoDiscapacidad A unique integer value identifying this cat_tipo_discapacidad.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoDiscapacidades: async (
      cveTipoDiscapacidad: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoDiscapacidad' is not null or undefined
      assertParamExists('getIdTipoDiscapacidades', 'cveTipoDiscapacidad', cveTipoDiscapacidad);
      const localVarPath = `/api/v1/tipo-discapacidades/{cve_tipo_discapacidad}`.replace(
        `{${'cve_tipo_discapacidad'}}`,
        encodeURIComponent(String(cveTipoDiscapacidad))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoEstancia A unique integer value identifying this cat_tipo_estancia.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoEstancias: async (
      cveTipoEstancia: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoEstancia' is not null or undefined
      assertParamExists('getIdTipoEstancias', 'cveTipoEstancia', cveTipoEstancia);
      const localVarPath = `/api/v1/tipos-estancias/{cve_tipo_estancia}`.replace(
        `{${'cve_tipo_estancia'}}`,
        encodeURIComponent(String(cveTipoEstancia))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoEvaluacion A unique integer value identifying this cat_tipo_evaluacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoEvaluaciones: async (
      cveTipoEvaluacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoEvaluacion' is not null or undefined
      assertParamExists('getIdTipoEvaluaciones', 'cveTipoEvaluacion', cveTipoEvaluacion);
      const localVarPath = `/api/v1/tipos-evaluaciones/{cve_tipo_evaluacion}`.replace(
        `{${'cve_tipo_evaluacion'}}`,
        encodeURIComponent(String(cveTipoEvaluacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoEventoUi A unique integer value identifying this cat_tipo_evento_ui.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoEventoUi: async (
      idTipoEventoUi: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoEventoUi' is not null or undefined
      assertParamExists('getIdTipoEventoUi', 'idTipoEventoUi', idTipoEventoUi);
      const localVarPath = `/api/v1/tipos-evento-ui/{id_tipo_evento_ui}`.replace(
        `{${'id_tipo_evento_ui'}}`,
        encodeURIComponent(String(idTipoEventoUi))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoFormacion A unique integer value identifying this cat_tipo_formacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoFormaciones: async (
      cveTipoFormacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoFormacion' is not null or undefined
      assertParamExists('getIdTipoFormaciones', 'cveTipoFormacion', cveTipoFormacion);
      const localVarPath = `/api/v1/tipos-formaciones/{cve_tipo_formacion}`.replace(
        `{${'cve_tipo_formacion'}}`,
        encodeURIComponent(String(cveTipoFormacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoInstNivelDos
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoInstNivelDos: async (
      cveTipoInstNivelDos: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoInstNivelDos' is not null or undefined
      assertParamExists('getIdTipoInstNivelDos', 'cveTipoInstNivelDos', cveTipoInstNivelDos);
      const localVarPath = `/api/v1/tipos-inst-nivel-dos/{cve_tipo_inst_nivel_dos}`.replace(
        `{${'cve_tipo_inst_nivel_dos'}}`,
        encodeURIComponent(String(cveTipoInstNivelDos))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoInstNivelUno
     * @param {string} cveTipoInstNivelDos A unique integer value identifying this cat_tipo_inst_nivel_dos.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoInstNivelDosTipoInstNivelUno: async (
      idTipoInstNivelUno: string,
      cveTipoInstNivelDos: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoInstNivelUno' is not null or undefined
      assertParamExists('getIdTipoInstNivelDosTipoInstNivelUno', 'idTipoInstNivelUno', idTipoInstNivelUno);
      // verify required parameter 'cveTipoInstNivelDos' is not null or undefined
      assertParamExists('getIdTipoInstNivelDosTipoInstNivelUno', 'cveTipoInstNivelDos', cveTipoInstNivelDos);
      const localVarPath = `/api/v1/tipos-inst-nivel-uno/{id_tipo_inst_nivel_uno}/tipos-inst-nivel-dos/{cve_tipo_inst_nivel_dos}`
        .replace(`{${'id_tipo_inst_nivel_uno'}}`, encodeURIComponent(String(idTipoInstNivelUno)))
        .replace(`{${'cve_tipo_inst_nivel_dos'}}`, encodeURIComponent(String(cveTipoInstNivelDos)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoInstitucion
     * @param {string} cveTipoInstNivelUno A unique integer value identifying this cat_tipo_inst_nivel_uno.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoInstNivelUnoTipoInstituciones: async (
      idTipoInstitucion: string,
      cveTipoInstNivelUno: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoInstitucion' is not null or undefined
      assertParamExists('getIdTipoInstNivelUnoTipoInstituciones', 'idTipoInstitucion', idTipoInstitucion);
      // verify required parameter 'cveTipoInstNivelUno' is not null or undefined
      assertParamExists('getIdTipoInstNivelUnoTipoInstituciones', 'cveTipoInstNivelUno', cveTipoInstNivelUno);
      const localVarPath = `/api/v1/tipo-instituciones/{id_tipo_institucion}/tipos-inst-nivel-uno/{cve_tipo_inst_nivel_uno}`
        .replace(`{${'id_tipo_institucion'}}`, encodeURIComponent(String(idTipoInstitucion)))
        .replace(`{${'cve_tipo_inst_nivel_uno'}}`, encodeURIComponent(String(cveTipoInstNivelUno)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoInstitucion A unique integer value identifying this cat_tipo_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoInstituciones: async (
      cveTipoInstitucion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoInstitucion' is not null or undefined
      assertParamExists('getIdTipoInstituciones', 'cveTipoInstitucion', cveTipoInstitucion);
      const localVarPath = `/api/v1/tipo-instituciones/{cve_tipo_institucion}`.replace(
        `{${'cve_tipo_institucion'}}`,
        encodeURIComponent(String(cveTipoInstitucion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoMedioDivulgacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoMedioDivulgacion: async (
      cveTipoMedioDivulgacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoMedioDivulgacion' is not null or undefined
      assertParamExists('getIdTipoMedioDivulgacion', 'cveTipoMedioDivulgacion', cveTipoMedioDivulgacion);
      const localVarPath = `/api/v1/tipos-medio-divulgacion/{cve_tipo_medio_divulgacion}`.replace(
        `{${'cve_tipo_medio_divulgacion'}}`,
        encodeURIComponent(String(cveTipoMedioDivulgacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoNotificacion A unique integer value identifying this cat_tipo_notificacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoNotificacion: async (
      idTipoNotificacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoNotificacion' is not null or undefined
      assertParamExists('getIdTipoNotificacion', 'idTipoNotificacion', idTipoNotificacion);
      const localVarPath = `/api/v1/tipos-notificacion/{id_tipo_notificacion}`.replace(
        `{${'id_tipo_notificacion'}}`,
        encodeURIComponent(String(idTipoNotificacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoPartCongreso
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoPartCongreso: async (
      cveTipoPartCongreso: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoPartCongreso' is not null or undefined
      assertParamExists('getIdTipoPartCongreso', 'cveTipoPartCongreso', cveTipoPartCongreso);
      const localVarPath = `/api/v1/tipos-part-congreso/{cve_tipo_part_congreso}`.replace(
        `{${'cve_tipo_part_congreso'}}`,
        encodeURIComponent(String(cveTipoPartCongreso))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoParticipacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoParticipacion7: async (
      cveTipoParticipacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoParticipacion' is not null or undefined
      assertParamExists('getIdTipoParticipacion7', 'cveTipoParticipacion', cveTipoParticipacion);
      const localVarPath = `/api/v1/tipos-participacion/{cve_tipo_participacion}`.replace(
        `{${'cve_tipo_participacion'}}`,
        encodeURIComponent(String(cveTipoParticipacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoPertenEtnica A unique integer value identifying this cat_tipo_perten_etnica.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoPertenEtnicas: async (
      cveTipoPertenEtnica: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoPertenEtnica' is not null or undefined
      assertParamExists('getIdTipoPertenEtnicas', 'cveTipoPertenEtnica', cveTipoPertenEtnica);
      const localVarPath = `/api/v1/tipo-perten-etnicas/{cve_tipo_perten_etnica}`.replace(
        `{${'cve_tipo_perten_etnica'}}`,
        encodeURIComponent(String(cveTipoPertenEtnica))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoPublicacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoPublicacion: async (
      cveTipoPublicacion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoPublicacion' is not null or undefined
      assertParamExists('getIdTipoPublicacion', 'cveTipoPublicacion', cveTipoPublicacion);
      const localVarPath = `/api/v1/tipos-publicacion/{cve_tipo_publicacion}`.replace(
        `{${'cve_tipo_publicacion'}}`,
        encodeURIComponent(String(cveTipoPublicacion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoRiesgo A unique integer value identifying this cat_tipo_riesgo.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoRiesgo: async (idTipoRiesgo: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'idTipoRiesgo' is not null or undefined
      assertParamExists('getIdTipoRiesgo', 'idTipoRiesgo', idTipoRiesgo);
      const localVarPath = `/api/v1/tipos-riesgo/{id_tipo_riesgo}`.replace(
        `{${'id_tipo_riesgo'}}`,
        encodeURIComponent(String(idTipoRiesgo))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoTrayectoriaProf A unique integer value identifying this cat_tipo_trayectoria_prof.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoTrayectoriasProf: async (
      cveTipoTrayectoriaProf: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoTrayectoriaProf' is not null or undefined
      assertParamExists('getIdTipoTrayectoriasProf', 'cveTipoTrayectoriaProf', cveTipoTrayectoriaProf);
      const localVarPath = `/api/v1/tipos-trayectorias-prof/{cve_tipo_trayectoria_prof}`.replace(
        `{${'cve_tipo_trayectoria_prof'}}`,
        encodeURIComponent(String(cveTipoTrayectoriaProf))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoVialidad A unique integer value identifying this cat_tipo_vialidad.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoVialidades: async (
      cveTipoVialidad: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoVialidad' is not null or undefined
      assertParamExists('getIdTipoVialidades', 'cveTipoVialidad', cveTipoVialidad);
      const localVarPath = `/api/v1/tipo-vialidades/{cve_tipo_vialidad}`.replace(
        `{${'cve_tipo_vialidad'}}`,
        encodeURIComponent(String(cveTipoVialidad))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoInstNivelUno
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTiposInstNivelUno: async (
      cveTipoInstNivelUno: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoInstNivelUno' is not null or undefined
      assertParamExists('getIdTiposInstNivelUno', 'cveTipoInstNivelUno', cveTipoInstNivelUno);
      const localVarPath = `/api/v1/tipos-inst-nivel-uno/{cve_tipo_inst_nivel_uno}`.replace(
        `{${'cve_tipo_inst_nivel_uno'}}`,
        encodeURIComponent(String(cveTipoInstNivelUno))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveVariante
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdVarAutodenominaciones: async (
      cveVariante: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveVariante' is not null or undefined
      assertParamExists('getIdVarAutodenominaciones', 'cveVariante', cveVariante);
      const localVarPath = `/api/v1/var-autodenominaciones/{cve_variante}`.replace(
        `{${'cve_variante'}}`,
        encodeURIComponent(String(cveVariante))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idPuebloIndigena
     * @param {string} cveVariante A unique integer value identifying this cat_var_autodenominacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdVarAutodenominacionesPueblosIndigenas: async (
      idPuebloIndigena: string,
      cveVariante: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idPuebloIndigena' is not null or undefined
      assertParamExists('getIdVarAutodenominacionesPueblosIndigenas', 'idPuebloIndigena', idPuebloIndigena);
      // verify required parameter 'cveVariante' is not null or undefined
      assertParamExists('getIdVarAutodenominacionesPueblosIndigenas', 'cveVariante', cveVariante);
      const localVarPath = `/api/v1/pueblos-indigenas/{id_pueblo_indigena}/var-autodenominaciones/{cve_variante}`
        .replace(`{${'id_pueblo_indigena'}}`, encodeURIComponent(String(idPuebloIndigena)))
        .replace(`{${'cve_variante'}}`, encodeURIComponent(String(cveVariante)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveLocalidad
     * @param {string} claveVialidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdVialidadesLocaliidaadesMunicipiosEstados: async (
      claveAreaGe: string,
      claveAreaGm: string,
      claveLocalidad: string,
      claveVialidad: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'claveAreaGe' is not null or undefined
      assertParamExists('getIdVialidadesLocaliidaadesMunicipiosEstados', 'claveAreaGe', claveAreaGe);
      // verify required parameter 'claveAreaGm' is not null or undefined
      assertParamExists('getIdVialidadesLocaliidaadesMunicipiosEstados', 'claveAreaGm', claveAreaGm);
      // verify required parameter 'claveLocalidad' is not null or undefined
      assertParamExists('getIdVialidadesLocaliidaadesMunicipiosEstados', 'claveLocalidad', claveLocalidad);
      // verify required parameter 'claveVialidad' is not null or undefined
      assertParamExists('getIdVialidadesLocaliidaadesMunicipiosEstados', 'claveVialidad', claveVialidad);
      const localVarPath =
        `/api/v1/estados/{clave_area_ge}/municipios/{clave_area_gm}/localidades/{clave_localidad}/vialidades/{clave_vialidad}`
          .replace(`{${'clave_area_ge'}}`, encodeURIComponent(String(claveAreaGe)))
          .replace(`{${'clave_area_gm'}}`, encodeURIComponent(String(claveAreaGm)))
          .replace(`{${'clave_localidad'}}`, encodeURIComponent(String(claveLocalidad)))
          .replace(`{${'clave_vialidad'}}`, encodeURIComponent(String(claveVialidad)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdentificaAutores: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/identifica-autores`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoInstNivelTres
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdinstitucion: async (
      idTipoInstNivelTres: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoInstNivelTres' is not null or undefined
      assertParamExists('getIdinstitucion', 'idTipoInstNivelTres', idTipoInstNivelTres);
      // verify required parameter 'cveInstitucion' is not null or undefined
      assertParamExists('getIdinstitucion', 'cveInstitucion', cveInstitucion);
      const localVarPath = `/api/v1/tipos-inst-nivel-tres/{id_tipo_inst_nivel_tres}/instituciones/{cve_institucion}`
        .replace(`{${'id_tipo_inst_nivel_tres'}}`, encodeURIComponent(String(idTipoInstNivelTres)))
        .replace(`{${'cve_institucion'}}`, encodeURIComponent(String(cveInstitucion)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdiomas: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/idiomas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idFuncionalidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdsCatDefinicionLogUis: async (
      idFuncionalidad: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idFuncionalidad' is not null or undefined
      assertParamExists('getIdsCatDefinicionLogUis', 'idFuncionalidad', idFuncionalidad);
      const localVarPath = `/api/v1/funcionalidades/{id_funcionalidad}/definiciones-log-ui`.replace(
        `{${'id_funcionalidad'}}`,
        encodeURIComponent(String(idFuncionalidad))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstituciones: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/instituciones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idEntidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstitucionesEstaados: async (
      idEntidad: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idEntidad' is not null or undefined
      assertParamExists('getInstitucionesEstaados', 'idEntidad', idEntidad);
      const localVarPath = `/api/v1/estados/{id_entidad}/instituciones`.replace(`{${'id_entidad'}}`, encodeURIComponent(String(idEntidad)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idLocalidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstitucionesLocalidades: async (
      idLocalidad: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idLocalidad' is not null or undefined
      assertParamExists('getInstitucionesLocalidades', 'idLocalidad', idLocalidad);
      const localVarPath = `/api/v1/localidades/{id_localidad}/instituciones`.replace(
        `{${'id_localidad'}}`,
        encodeURIComponent(String(idLocalidad))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idMunicipio
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstitucionesMunicipios: async (
      idMunicipio: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idMunicipio' is not null or undefined
      assertParamExists('getInstitucionesMunicipios', 'idMunicipio', idMunicipio);
      const localVarPath = `/api/v1/municipios/{id_municipio}/instituciones`.replace(
        `{${'id_municipio'}}`,
        encodeURIComponent(String(idMunicipio))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idPais
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstitucionesPaises: async (
      idPais: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idPais' is not null or undefined
      assertParamExists('getInstitucionesPaises', 'idPais', idPais);
      const localVarPath = `/api/v1/paises/{id_pais}/instituciones`.replace(`{${'id_pais'}}`, encodeURIComponent(String(idPais)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoInstNivelDos
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstitucionesTipoInstNivelDos: async (
      idTipoInstNivelDos: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoInstNivelDos' is not null or undefined
      assertParamExists('getInstitucionesTipoInstNivelDos', 'idTipoInstNivelDos', idTipoInstNivelDos);
      const localVarPath = `/api/v1/tipo-inst-nivel-dos/{id_tipo_inst_nivel_dos}/instituciones`.replace(
        `{${'id_tipo_inst_nivel_dos'}}`,
        encodeURIComponent(String(idTipoInstNivelDos))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoInstNivelTres
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstitucionesTipoInstNivelTres: async (
      idTipoInstNivelTres: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoInstNivelTres' is not null or undefined
      assertParamExists('getInstitucionesTipoInstNivelTres', 'idTipoInstNivelTres', idTipoInstNivelTres);
      const localVarPath = `/api/v1/tipo-inst-nivel-tres/{id_tipo_inst_nivel_tres}/instituciones`.replace(
        `{${'id_tipo_inst_nivel_tres'}}`,
        encodeURIComponent(String(idTipoInstNivelTres))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoInstNivelUno
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstitucionesTipoInstNivelUno: async (
      idTipoInstNivelUno: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoInstNivelUno' is not null or undefined
      assertParamExists('getInstitucionesTipoInstNivelUno', 'idTipoInstNivelUno', idTipoInstNivelUno);
      const localVarPath = `/api/v1/tipo-inst-nivel-uno/{id_tipo_inst_nivel_uno}/instituciones`.replace(
        `{${'id_tipo_inst_nivel_uno'}}`,
        encodeURIComponent(String(idTipoInstNivelUno))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoInstitucion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstitucionesTipoInstituciones: async (
      idTipoInstitucion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoInstitucion' is not null or undefined
      assertParamExists('getInstitucionesTipoInstituciones', 'idTipoInstitucion', idTipoInstitucion);
      const localVarPath = `/api/v1/tipo-instituciones/{id_tipo_institucion}/instituciones`.replace(
        `{${'id_tipo_institucion'}}`,
        encodeURIComponent(String(idTipoInstitucion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLenguaIndigenas: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/lenguas-indigenas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocalidadesMunicipiosEstados: async (
      claveAreaGe: string,
      claveAreaGm: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'claveAreaGe' is not null or undefined
      assertParamExists('getLocalidadesMunicipiosEstados', 'claveAreaGe', claveAreaGe);
      // verify required parameter 'claveAreaGm' is not null or undefined
      assertParamExists('getLocalidadesMunicipiosEstados', 'claveAreaGm', claveAreaGm);
      const localVarPath = `/api/v1/estados/{clave_area_ge}/municipios/{clave_area_gm}/localidades`
        .replace(`{${'clave_area_ge'}}`, encodeURIComponent(String(claveAreaGe)))
        .replace(`{${'clave_area_gm'}}`, encodeURIComponent(String(claveAreaGm)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMargenes: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/margenes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMunicipiosEstado: async (
      claveAreaGe: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'claveAreaGe' is not null or undefined
      assertParamExists('getMunicipiosEstado', 'claveAreaGe', claveAreaGe);
      const localVarPath = `/api/v1/estados/{clave_area_ge}/municipios`.replace(
        `{${'clave_area_ge'}}`,
        encodeURIComponent(String(claveAreaGe))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNacionalidades: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/nacionalidades`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNivelesAcademicos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/niveles-academicos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNivelesDominiosLd: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/niveles-dominios-ld`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOcdeClases: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/ocde-clases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idOcdeGrupo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOcdeClasesOcdeGrupos: async (
      idOcdeGrupo: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idOcdeGrupo' is not null or undefined
      assertParamExists('getOcdeClasesOcdeGrupos', 'idOcdeGrupo', idOcdeGrupo);
      const localVarPath = `/api/v1/ocde-grupos/{id_ocde_grupo}/ocde-clases`.replace(
        `{${'id_ocde_grupo'}}`,
        encodeURIComponent(String(idOcdeGrupo))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOcdeDivisiones: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/ocde-divisiones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idOcdeSector
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOcdeDivisionesOcdeSector: async (
      idOcdeSector: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idOcdeSector' is not null or undefined
      assertParamExists('getOcdeDivisionesOcdeSector', 'idOcdeSector', idOcdeSector);
      const localVarPath = `/api/v1/ocde-sectores/{id_ocde_sector}/ocde-divisiones`.replace(
        `{${'id_ocde_sector'}}`,
        encodeURIComponent(String(idOcdeSector))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOcdeGrupos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/ocde-grupos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idOcdeDivision
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOcdeGruposOcdeDivisiones: async (
      idOcdeDivision: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idOcdeDivision' is not null or undefined
      assertParamExists('getOcdeGruposOcdeDivisiones', 'idOcdeDivision', idOcdeDivision);
      const localVarPath = `/api/v1/ocde-divisiones/{id_ocde_division}/ocde-grupos`.replace(
        `{${'id_ocde_division'}}`,
        encodeURIComponent(String(idOcdeDivision))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOcdeSectores: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/ocde-sectores`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaises: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/paises`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductoEvaluaciones: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/producto-evaluaciones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPueblosIndigenas: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/pueblos-indigenas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idPreguntaRespuesta
     * @param {string} idListRespPregunta A unique integer value identifying this cat_list_resp_pregunta.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRespPreguntas: async (
      idPreguntaRespuesta: string,
      idListRespPregunta: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idPreguntaRespuesta' is not null or undefined
      assertParamExists('getRespPreguntas', 'idPreguntaRespuesta', idPreguntaRespuesta);
      // verify required parameter 'idListRespPregunta' is not null or undefined
      assertParamExists('getRespPreguntas', 'idListRespPregunta', idListRespPregunta);
      const localVarPath = `/api/v1/preguntas-respuestas/{id_pregunta_respuesta}/list-resp-preguntas/{id_list_resp_pregunta}`
        .replace(`{${'id_pregunta_respuesta'}}`, encodeURIComponent(String(idPreguntaRespuesta)))
        .replace(`{${'id_list_resp_pregunta'}}`, encodeURIComponent(String(idListRespPregunta)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoEventoUi
     * @param {string} idDefinicionLogUi
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRetrieveCatDefinicionLogUi: async (
      idTipoEventoUi: string,
      idDefinicionLogUi: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoEventoUi' is not null or undefined
      assertParamExists('getRetrieveCatDefinicionLogUi', 'idTipoEventoUi', idTipoEventoUi);
      // verify required parameter 'idDefinicionLogUi' is not null or undefined
      assertParamExists('getRetrieveCatDefinicionLogUi', 'idDefinicionLogUi', idDefinicionLogUi);
      const localVarPath = `/api/v1/tipos-evento-ui/{id_tipo_evento_ui}/definicion-log-ui/{id_definicion_log_ui}/`
        .replace(`{${'id_tipo_evento_ui'}}`, encodeURIComponent(String(idTipoEventoUi)))
        .replace(`{${'id_definicion_log_ui'}}`, encodeURIComponent(String(idDefinicionLogUi)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianClases: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/scian-clases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idScianGrupo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianClasesScianGrupos: async (
      idScianGrupo: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idScianGrupo' is not null or undefined
      assertParamExists('getScianClasesScianGrupos', 'idScianGrupo', idScianGrupo);
      const localVarPath = `/api/v1/scian-grupos/{id_scian_grupo}/scian-clases`.replace(
        `{${'id_scian_grupo'}}`,
        encodeURIComponent(String(idScianGrupo))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianDivisiones: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/scian-divisiones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idScianSector
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianDivisionesScianSectores: async (
      idScianSector: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idScianSector' is not null or undefined
      assertParamExists('getScianDivisionesScianSectores', 'idScianSector', idScianSector);
      const localVarPath = `/api/v1/scian-sectores/{id_scian_sector}/scian-divisiones`.replace(
        `{${'id_scian_sector'}}`,
        encodeURIComponent(String(idScianSector))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianGrupos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/scian-grupos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idScianDivision
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianGruposScianDivisiones: async (
      idScianDivision: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idScianDivision' is not null or undefined
      assertParamExists('getScianGruposScianDivisiones', 'idScianDivision', idScianDivision);
      const localVarPath = `/api/v1/scian-divisiones/{id_scian_division}/scian-grupos`.replace(
        `{${'id_scian_division'}}`,
        encodeURIComponent(String(idScianDivision))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianSectores: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/scian-sectores`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianSubclases: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/scian-subclases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idScianClase
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianSubclasesScianClases: async (
      idScianClase: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idScianClase' is not null or undefined
      assertParamExists('getScianSubclasesScianClases', 'idScianClase', idScianClase);
      const localVarPath = `/api/v1/scian-clases/{id_scian_clase}/scian-subclases`.replace(
        `{${'id_scian_clase'}}`,
        encodeURIComponent(String(idScianClase))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubDisciplinas: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/subdisciplinas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idDisciplina
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubDisciplinasDisciplinas: async (
      idDisciplina: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idDisciplina' is not null or undefined
      assertParamExists('getSubDisciplinasDisciplinas', 'idDisciplina', idDisciplina);
      const localVarPath = `/api/v1/disciplinas/{id_disciplina}/subdisciplinas`.replace(
        `{${'id_disciplina'}}`,
        encodeURIComponent(String(idDisciplina))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTerminoGenericos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/terminos-genericos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoAsentamientos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipo-asentamientos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoContactos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipo-contactos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoDiscapacidades: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipo-discapacidades`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoEstancias: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-estancias`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoEvaluaciones: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-evaluaciones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoFormaciones: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-formaciones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoInstNivelDos
     * @param {string} idTipoInstNivelTres A unique integer value identifying this cat_tipo_inst_nivel_tres.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoIdsInstNivelTres: async (
      idTipoInstNivelDos: string,
      idTipoInstNivelTres: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoInstNivelDos' is not null or undefined
      assertParamExists('getTipoIdsInstNivelTres', 'idTipoInstNivelDos', idTipoInstNivelDos);
      // verify required parameter 'idTipoInstNivelTres' is not null or undefined
      assertParamExists('getTipoIdsInstNivelTres', 'idTipoInstNivelTres', idTipoInstNivelTres);
      const localVarPath = `/api/v1/tipos-inst-nivel-dos/{id_tipo_inst_nivel_dos}/tipos-inst-nivel-tres/{id_tipo_inst_nivel_tres}`
        .replace(`{${'id_tipo_inst_nivel_dos'}}`, encodeURIComponent(String(idTipoInstNivelDos)))
        .replace(`{${'id_tipo_inst_nivel_tres'}}`, encodeURIComponent(String(idTipoInstNivelTres)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoInstNivelDos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-inst-nivel-dos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoInstNivelUno
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoInstNivelDosTipoInstNivelUno: async (
      idTipoInstNivelUno: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoInstNivelUno' is not null or undefined
      assertParamExists('getTipoInstNivelDosTipoInstNivelUno', 'idTipoInstNivelUno', idTipoInstNivelUno);
      const localVarPath = `/api/v1/tipos-inst-nivel-uno/{id_tipo_inst_nivel_uno}/tipos-inst-nivel-dos`.replace(
        `{${'id_tipo_inst_nivel_uno'}}`,
        encodeURIComponent(String(idTipoInstNivelUno))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoInstitucion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoInstNivelUnoTipoInstituciones: async (
      idTipoInstitucion: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoInstitucion' is not null or undefined
      assertParamExists('getTipoInstNivelUnoTipoInstituciones', 'idTipoInstitucion', idTipoInstitucion);
      const localVarPath = `/api/v1/tipo-instituciones/{id_tipo_institucion}/tipos-inst-nivel-uno`.replace(
        `{${'id_tipo_institucion'}}`,
        encodeURIComponent(String(idTipoInstitucion))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoInstituciones: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipo-instituciones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoPertenEtnicas: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipo-perten-etnicas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoTrayectoriasProf: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-trayectorias-prof`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoVialidades: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipo-vialidades`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTiposInstNivelUno: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tipos-inst-nivel-uno`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVarAutodenominaciones: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/var-autodenominaciones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idPuebloIndigena
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVarAutodenominacionesPueblosIndigenas: async (
      idPuebloIndigena: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idPuebloIndigena' is not null or undefined
      assertParamExists('getVarAutodenominacionesPueblosIndigenas', 'idPuebloIndigena', idPuebloIndigena);
      const localVarPath = `/api/v1/pueblos-indigenas/{id_pueblo_indigena}/var-autodenominaciones`.replace(
        `{${'id_pueblo_indigena'}}`,
        encodeURIComponent(String(idPuebloIndigena))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveLocalidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVialidadesLocaliidadesMunicipiosEstados: async (
      claveAreaGe: string,
      claveAreaGm: string,
      claveLocalidad: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'claveAreaGe' is not null or undefined
      assertParamExists('getVialidadesLocaliidadesMunicipiosEstados', 'claveAreaGe', claveAreaGe);
      // verify required parameter 'claveAreaGm' is not null or undefined
      assertParamExists('getVialidadesLocaliidadesMunicipiosEstados', 'claveAreaGm', claveAreaGm);
      // verify required parameter 'claveLocalidad' is not null or undefined
      assertParamExists('getVialidadesLocaliidadesMunicipiosEstados', 'claveLocalidad', claveLocalidad);
      const localVarPath = `/api/v1/estados/{clave_area_ge}/municipios/{clave_area_gm}/localidades/{clave_localidad}/vialidades`
        .replace(`{${'clave_area_ge'}}`, encodeURIComponent(String(claveAreaGe)))
        .replace(`{${'clave_area_gm'}}`, encodeURIComponent(String(claveAreaGm)))
        .replace(`{${'clave_localidad'}}`, encodeURIComponent(String(claveLocalidad)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idFuncionalidad
     * @param {string} idDefinicionLogUi A unique integer value identifying this cat_definicion_log_ui.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsCatDefinicionLogUi: async (
      idFuncionalidad: string,
      idDefinicionLogUi: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idFuncionalidad' is not null or undefined
      assertParamExists('getsCatDefinicionLogUi', 'idFuncionalidad', idFuncionalidad);
      // verify required parameter 'idDefinicionLogUi' is not null or undefined
      assertParamExists('getsCatDefinicionLogUi', 'idDefinicionLogUi', idDefinicionLogUi);
      const localVarPath = `/api/v1/funcionalidades/{id_funcionalidad}/definiciones-log-ui/{id_definicion_log_ui}`
        .replace(`{${'id_funcionalidad'}}`, encodeURIComponent(String(idFuncionalidad)))
        .replace(`{${'id_definicion_log_ui'}}`, encodeURIComponent(String(idDefinicionLogUi)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoEventoUi
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsCatDefinicionLogUis: async (
      idTipoEventoUi: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoEventoUi' is not null or undefined
      assertParamExists('getsCatDefinicionLogUis', 'idTipoEventoUi', idTipoEventoUi);
      const localVarPath = `/api/v1/tipos-evento-ui/{id_tipo_evento_ui}/definiciones-log-ui/`.replace(
        `{${'id_tipo_evento_ui'}}`,
        encodeURIComponent(String(idTipoEventoUi))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idPreguntaRespuesta
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsCatListRespPreguntas: async (
      idPreguntaRespuesta: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idPreguntaRespuesta' is not null or undefined
      assertParamExists('getsCatListRespPreguntas', 'idPreguntaRespuesta', idPreguntaRespuesta);
      const localVarPath = `/api/v1/preguntas-respuestas/{id_pregunta_respuesta}/list-resp-preguntas`.replace(
        `{${'id_pregunta_respuesta'}}`,
        encodeURIComponent(String(idPreguntaRespuesta))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsCatPalabraClaves: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/palabras-clave`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cveTipoInstNivelUno
     * @param {string} cveTipoInstNivelDos
     * @param {string} cveEntidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsCveClaveCatNacionalPublico: async (
      cveTipoInstNivelUno: string,
      cveTipoInstNivelDos: string,
      cveEntidad: string,
      page?: number,
      size?: number,
      search?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cveTipoInstNivelUno' is not null or undefined
      assertParamExists('getsCveClaveCatNacionalPublico', 'cveTipoInstNivelUno', cveTipoInstNivelUno);
      // verify required parameter 'cveTipoInstNivelDos' is not null or undefined
      assertParamExists('getsCveClaveCatNacionalPublico', 'cveTipoInstNivelDos', cveTipoInstNivelDos);
      // verify required parameter 'cveEntidad' is not null or undefined
      assertParamExists('getsCveClaveCatNacionalPublico', 'cveEntidad', cveEntidad);
      const localVarPath = `/api/v1/nacional-publico/{cve_tipo_inst_nivel_uno}/{cve_tipo_inst_nivel_dos}/{cve_entidad}`
        .replace(`{${'cve_tipo_inst_nivel_uno'}}`, encodeURIComponent(String(cveTipoInstNivelUno)))
        .replace(`{${'cve_tipo_inst_nivel_dos'}}`, encodeURIComponent(String(cveTipoInstNivelDos)))
        .replace(`{${'cve_entidad'}}`, encodeURIComponent(String(cveEntidad)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoEventoUi
     * @param {string} idDefinicionLogUi A unique integer value identifying this cat_definicion_log_ui.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsDefinicionLogsUis: async (
      idTipoEventoUi: string,
      idDefinicionLogUi: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoEventoUi' is not null or undefined
      assertParamExists('getsDefinicionLogsUis', 'idTipoEventoUi', idTipoEventoUi);
      // verify required parameter 'idDefinicionLogUi' is not null or undefined
      assertParamExists('getsDefinicionLogsUis', 'idDefinicionLogUi', idDefinicionLogUi);
      const localVarPath = `/api/v1/tipos-evento-ui/{id_tipo_evento_ui}/definiciones-log-ui/{id_definicion_log_ui}`
        .replace(`{${'id_tipo_evento_ui'}}`, encodeURIComponent(String(idTipoEventoUi)))
        .replace(`{${'id_definicion_log_ui'}}`, encodeURIComponent(String(idDefinicionLogUi)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} idTipoEventoUi
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsIdCatDefinicionLogUis: async (
      idTipoEventoUi: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idTipoEventoUi' is not null or undefined
      assertParamExists('getsIdCatDefinicionLogUis', 'idTipoEventoUi', idTipoEventoUi);
      const localVarPath = `/api/v1/tipos-evento-ui/{id_tipo_evento_ui}/definicion-log-ui/`.replace(
        `{${'id_tipo_evento_ui'}}`,
        encodeURIComponent(String(idTipoEventoUi))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAdministraciones(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdministraciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAdministraciones(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idAmbito
     * @param {string} idVialidad A unique integer value identifying this cat_vialidad.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAmbitoCatVialidadId(
      idAmbito: string,
      idVialidad: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatVialidad>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAmbitoCatVialidadId(idAmbito, idVialidad, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAmbitos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAmbitos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAmbitos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAreaConocimiento(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAreaConocimiento200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAreaConocimiento(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAreaGeoestadisticaEstatal(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAreaGeoestadisticaEstatal200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAreaGeoestadisticaEstatal(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAsentamientosHumanosEstados(
      claveAreaGe: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAsentamientosHumanosEstados200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAsentamientosHumanosEstados(claveAreaGe, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveLocalidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAsentamientosHumanosLocalidadesMunicipiosEstados(
      claveAreaGe: string,
      claveAreaGm: string,
      claveLocalidad: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAsentamientosHumanosEstados200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAsentamientosHumanosLocalidadesMunicipiosEstados(
        claveAreaGe,
        claveAreaGm,
        claveLocalidad,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAsentamientosHumanosMunicipiosEstados(
      claveAreaGe: string,
      claveAreaGm: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAsentamientosHumanosEstados200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAsentamientosHumanosMunicipiosEstados(
        claveAreaGe,
        claveAreaGm,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBaseCartograficas(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BaseCartografica>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBaseCartograficas(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} claveAsentamiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBaseCartograficasAsentamientos(
      claveAsentamiento: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BaseCartografica>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBaseCartograficasAsentamientos(claveAsentamiento, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCampos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCampos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCampos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCaracteristContactos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCaracteristContactos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCaracteristContactos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoContacto
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCaracteristContactosTipoContactos(
      idTipoContacto: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCaracteristContactos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCaracteristContactosTipoContactos(idTipoContacto, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveAreaConocimiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatCamposAreasConocimiento(
      cveAreaConocimiento: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCampos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatCamposAreasConocimiento(cveAreaConocimiento, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatClasificacionPatentes(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatClasificacionPatentes200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatClasificacionPatentes(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} codigo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatCodigosPostalesFilter(
      codigo: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatCodigosPostalesFilter>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatCodigosPostalesFilter(codigo, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatDefinicionLogUis(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatDefinicionLogUis200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatDefinicionLogUis(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatDiaNolabConacyts(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatDiaNolabConacyts200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatDiaNolabConacyts(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idCampo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatDisciplinasCampos(
      idCampo: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDisciplinas200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatDisciplinasCampos(idCampo, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatEstadoPatentes(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatEstadoPatentes200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatEstadoPatentes(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatEstadoTesis(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatEstadoTesis200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatEstadoTesis(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatEstatusMigratorios(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatEstatusMigratorio>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatEstatusMigratorios(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatEstatusMovGastos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatEstatusMovGastos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatEstatusMovGastos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatEstatusNotificacions(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatEstatusNotificacions200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatEstatusNotificacions(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatEtapaDesarrollos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatEtapaDesarrollos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatEtapaDesarrollos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatFormatoNotificacions(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatFormatoNotificacions200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatFormatoNotificacions(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoInstNivelTres
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatInstitucions(
      idTipoInstNivelTres: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInstituciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatInstitucions(idTipoInstNivelTres, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatListRespPreguntas(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatListRespPreguntas200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatListRespPreguntas(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatMomentoEventos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatMomentoEventos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatMomentoEventos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatNivelPnpcs(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatNivelPnpcs200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatNivelPnpcs(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatOperacionPagos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatOperacionPagos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatOperacionPagos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatOperadorComparacions(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatOperadorComparacions200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatOperadorComparacions(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatOrigenReportes(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatOrigenReportes200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatOrigenReportes(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idFormatoNotificacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatPalabraClaves(
      idFormatoNotificacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatPalabraClaves200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatPalabraClaves(idFormatoNotificacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatParametrosSeguridads(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatParametrosSeguridads200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatParametrosSeguridads(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatPartDivulgacions(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatPartDivulgacions200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatPartDivulgacions(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatProductoDivulgacions(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatProductoDivulgacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatProductoDivulgacions(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatRecomdsEvalFomixs(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatRecomdsEvalFomixs200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatRecomdsEvalFomixs(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatRedTematicaConacyts(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatRedTematicaConacyt>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatRedTematicaConacyts(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatRolPersFisProgPnpcs(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatRolPersFisProgPnpcs200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatRolPersFisProgPnpcs(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatSectorDivulgacions(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatSectorDivulgacions200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatSectorDivulgacions(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoActDivulgacions(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoActDivulgacions200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoActDivulgacions(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoDesarrollos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoDesarrollos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoDesarrollos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoDoctoRespaldos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoDoctoRespaldos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoDoctoRespaldos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoDomicilios(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoDomicilio>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoDomicilios(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoEventoUis(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoEventoUis200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoEventoUis(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoFondos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoFondos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoFondos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoInnovacionOslos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoInnovacionOslos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoInnovacionOslos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoInstNivelTres(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoInstNivelTres200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoInstNivelTres(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoMedioDivulgacions(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoMedioDivulgacions200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoMedioDivulgacions(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoNotificacions(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoNotificacions200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoNotificacions(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoPartCongresos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoPartCongresos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoPartCongresos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoParticipacions(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoParticipacions200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoParticipacions(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoParticipante
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoParticipante(
      cveTipoParticipante: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoParticipante>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoParticipante(cveTipoParticipante, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoParticipantes(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoParticipantes200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoParticipantes(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoPatentes(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoPatentes200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoPatentes(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoProteccions(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoProteccions200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoProteccions(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoProyectos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoProyecto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoProyectos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoPublicacions(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoPublicacions200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoPublicacions(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoRiesgos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoRiesgos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoRiesgos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoSoftwares(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoSoftwares200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoSoftwares(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTipoTransferencias(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoTransferencias200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTipoTransferencias(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoInstNivelDos
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatTiposInstNivelTres(
      idTipoInstNivelDos: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatTipoInstNivelTres200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatTiposInstNivelTres(idTipoInstNivelDos, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatalogoDetalles(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatalogoDetalles200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatalogoDetalles(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idCatCatalogoMaestro
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatalogoDetallesCatalogosMaestro(
      idCatCatalogoMaestro: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatalogoDetalles200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatalogoDetallesCatalogosMaestro(
        idCatCatalogoMaestro,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatalogoMaestros(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatalogoMaestros200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatalogoMaestros(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveClasificacionPatente
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClaveCatClasificacionPatente(
      cveClasificacionPatente: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatClasificacionPatente>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClaveCatClasificacionPatente(
        cveClasificacionPatente,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveEstadoPatente
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClaveCatEstadoPatente(
      cveEstadoPatente: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatEstadoPatente>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClaveCatEstadoPatente(cveEstadoPatente, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveEtapaDesarrollo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClaveCatEtapaDesarrollo(
      cveEtapaDesarrollo: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatEtapaDesarrollo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClaveCatEtapaDesarrollo(cveEtapaDesarrollo, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cvePais
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClaveCatExtranjeraPais(
      cvePais: string,
      page?: number,
      size?: number,
      search?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatExtranjeraPais>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClaveCatExtranjeraPais(cvePais, page, size, search, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoProteccion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClaveCatTipoProteccion(
      cveTipoProteccion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoProteccion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClaveCatTipoProteccion(cveTipoProteccion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoInstNivelUno
     * @param {string} cveTipoInstNivelDos
     * @param {string} cveEntidad
     * @param {string} cveMunicipio
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClaveCveCatNacionalPublico(
      cveTipoInstNivelUno: string,
      cveTipoInstNivelDos: string,
      cveEntidad: string,
      cveMunicipio: string,
      page?: number,
      size?: number,
      search?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClaveCveCatNacionalPublico200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClaveCveCatNacionalPublico(
        cveTipoInstNivelUno,
        cveTipoInstNivelDos,
        cveEntidad,
        cveMunicipio,
        page,
        size,
        search,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCodigosPostales(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCodigosPostales200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCodigosPostales(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoAsentamiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCodigosPostalesTipoAsentamientos(
      cveTipoAsentamiento: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCodigosPostales200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCodigosPostalesTipoAsentamientos(
        cveTipoAsentamiento,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConsejoCertificadores(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsejoCertificadores200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getConsejoCertificadores(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveAreaConocimiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCveCatMotivacioSnis(
      cveAreaConocimiento: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCveCatMotivacioSnis200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCveCatMotivacioSnis(cveAreaConocimiento, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoInstNivelUno
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCveCatNacionalPublico(
      cveTipoInstNivelUno: string,
      page?: number,
      size?: number,
      search?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatNacionalPublico>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCveCatNacionalPublico(cveTipoInstNivelUno, page, size, search, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoDesarrollo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCveCatTipoDesarrollo(
      cveTipoDesarrollo: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoDesarrollo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCveCatTipoDesarrollo(cveTipoDesarrollo, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoInnovacionOslo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCveCatTipoInnovacionOslo(
      cveTipoInnovacionOslo: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoInnovacionOslo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCveCatTipoInnovacionOslo(cveTipoInnovacionOslo, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoPatente
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCveCatTipoPatente(
      cveTipoPatente: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoPatente>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCveCatTipoPatente(cveTipoPatente, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoSoftware
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCveCatTipoSoftware(
      cveTipoSoftware: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoSoftware>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCveCatTipoSoftware(cveTipoSoftware, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoTransferencia
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCveCatTipoTransferencia(
      cveTipoTransferencia: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoTransferencia>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCveCatTipoTransferencia(cveTipoTransferencia, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoInstNivelUno
     * @param {string} cveTipoInstNivelDos
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCveClaveCatNacionalPublico(
      cveTipoInstNivelUno: string,
      cveTipoInstNivelDos: string,
      page?: number,
      size?: number,
      search?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatNacionalPublico>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCveClaveCatNacionalPublico(
        cveTipoInstNivelUno,
        cveTipoInstNivelDos,
        page,
        size,
        search,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveAreaConocimiento
     * @param {string} idMotivacionSni A unique integer value identifying this cat_motivacion_sni.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCveIdCatMotivacioSnis(
      cveAreaConocimiento: string,
      idMotivacionSni: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatMotivacioSni>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCveIdCatMotivacioSnis(
        cveAreaConocimiento,
        idMotivacionSni,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDerechoTransitos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDerechoTransitos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDerechoTransitos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDictamenEvaluaciones(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDictamenEvaluaciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDictamenEvaluaciones(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDisciplinas(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDisciplinas200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDisciplinas(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDistincionesConacyt(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDistincionesConacyt200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDistincionesConacyt(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEspecialidadesMedicas(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEspecialidadesMedicas200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEspecialidadesMedicas(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idConsejoCertificador
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEspecialidadesMedicasConsejosCertificadores(
      idConsejoCertificador: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEspecialidadesMedicas200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEspecialidadesMedicasConsejosCertificadores(
        idConsejoCertificador,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEstadoCiviles(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEstadoCiviles200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEstadoCiviles(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEstatusAcademicos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEstatusAcademicos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEstatusAcademicos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFormaTitulaciones(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFormaTitulaciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFormaTitulaciones(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGeneros(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGeneros200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneros(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveAdministracion A unique integer value identifying this cat_administracion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdAdministraciones(
      cveAdministracion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatAdministracion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdAdministraciones(cveAdministracion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveAmbito A unique integer value identifying this cat_ambito.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdAmbitos(
      cveAmbito: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatAmbito>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdAmbitos(cveAmbito, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} indVersion A unique integer value identifying this cat_area_conocimiento.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdAreaConocimiento(
      indVersion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatAreaConocimiento>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdAreaConocimiento(indVersion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} claveAreaGev
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdAreaGeoestadisticaEstatal(
      claveAreaGev: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaGeoestadisticaEstatal>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdAreaGeoestadisticaEstatal(claveAreaGev, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAsentamiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdAsentamientosHumanosEstados(
      claveAreaGe: string,
      claveAsentamiento: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsentamientosHumanos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdAsentamientosHumanosEstados(
        claveAreaGe,
        claveAsentamiento,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveLocalidad
     * @param {string} claveAsentamiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdAsentamientosHumanosLocalidadesMunicipiosEstados(
      claveAreaGe: string,
      claveAreaGm: string,
      claveLocalidad: string,
      claveAsentamiento: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsentamientosHumanos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdAsentamientosHumanosLocalidadesMunicipiosEstados(
        claveAreaGe,
        claveAreaGm,
        claveLocalidad,
        claveAsentamiento,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveAsentamiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdAsentamientosHumanosMunicipiosEstados(
      claveAreaGe: string,
      claveAreaGm: string,
      claveAsentamiento: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AsentamientosHumanos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdAsentamientosHumanosMunicipiosEstados(
        claveAreaGe,
        claveAreaGm,
        claveAsentamiento,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveCampo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCampos(
      cveCampo: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatCampo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCampos(cveCampo, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveCaracteristContacto
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCaracteristContactos(
      cveCaracteristContacto: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatCaracteristicaContacto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCaracteristContactos(cveCaracteristContacto, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoContacto
     * @param {string} cveCaracteristContacto A unique integer value identifying this cat_caracterist_contacto.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCaracteristContactosTipoContactos(
      idTipoContacto: string,
      cveCaracteristContacto: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatCaracteristicaContacto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCaracteristContactosTipoContactos(
        idTipoContacto,
        cveCaracteristContacto,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveAreaConocimiento
     * @param {string} idCampo A unique integer value identifying this cat_campo.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatCamposAreasConocimiento(
      cveAreaConocimiento: string,
      idCampo: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatCampo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatCamposAreasConocimiento(
        cveAreaConocimiento,
        idCampo,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idDefinicionLogUi
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatDefinicionLogUi(
      idDefinicionLogUi: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatDefinicionLogUi>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatDefinicionLogUi(idDefinicionLogUi, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveEstatusMigratorio
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatEstatusMigratorio(
      cveEstatusMigratorio: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatEstatusMigratorio>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatEstatusMigratorio(cveEstatusMigratorio, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idInstitucion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatInstituciones(
      idInstitucion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatInstitucion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatInstituciones(idInstitucion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveOrigenReporte
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatOrigenReporte(
      cveOrigenReporte: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatOrigenReporte>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatOrigenReporte(cveOrigenReporte, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idPalabraClave
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatPalabraClave(
      idPalabraClave: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatPalabraClave>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatPalabraClave(idPalabraClave, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveProductoDivulgacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatProductoDivulgacion(
      cveProductoDivulgacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatProductoDivulgacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatProductoDivulgacion(cveProductoDivulgacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveRedTematicaConacyt
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatRedTematicaConacyt(
      cveRedTematicaConacyt: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatRedTematicaConacyt>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatRedTematicaConacyt(cveRedTematicaConacyt, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoDoctoRespaldo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatTipoDoctoRespaldo(
      cveTipoDoctoRespaldo: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoDoctoRespaldo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatTipoDoctoRespaldo(cveTipoDoctoRespaldo, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoDomicilio
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatTipoDomicilio(
      cveTipoDomicilio: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoDomicilio>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatTipoDomicilio(cveTipoDomicilio, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoFondo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatTipoFondo(
      cveTipoFondo: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoFondo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatTipoFondo(cveTipoFondo, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoInstNivelTres
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatTipoInstNivelTres(
      cveTipoInstNivelTres: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoInstNivelTres>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatTipoInstNivelTres(cveTipoInstNivelTres, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoProyecto
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatTipoProyecto(
      cveTipoProyecto: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoProyecto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatTipoProyecto(cveTipoProyecto, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idAmbito
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatVialidads(
      idAmbito: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatVialidad>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatVialidads(idAmbito, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} clave
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatalogoDetalles(
      clave: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatCatalogoDetalle>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatalogoDetalles(clave, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idCatCatalogoMaestro
     * @param {string} clave A unique integer value identifying this cat_catalogo_detalle.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatalogoDetallesCatalogosMaestro(
      idCatCatalogoMaestro: string,
      clave: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatCatalogoDetalle>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatalogoDetallesCatalogosMaestro(
        idCatCatalogoMaestro,
        clave,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} nombre A unique integer value identifying this cat_catalogo_maestro.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatalogoMaestros(
      nombre: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatCatalogoMaestro>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatalogoMaestros(nombre, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveCodigoPostal
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCodigosPostales(
      cveCodigoPostal: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatCodigosPostales>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCodigosPostales(cveCodigoPostal, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoAsentamiento
     * @param {string} cveCodigoPostal A unique integer value identifying this cat_codigos_postales.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCodigosPostalesTipoAsentamientos(
      cveTipoAsentamiento: string,
      cveCodigoPostal: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatCodigosPostales>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCodigosPostalesTipoAsentamientos(
        cveTipoAsentamiento,
        cveCodigoPostal,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveConsejoCertificador A unique integer value identifying this cat_consejo_certificador.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdConsejoCertificadores(
      cveConsejoCertificador: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatConsejoCertificador>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdConsejoCertificadores(cveConsejoCertificador, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveDerechoTransito A unique integer value identifying this cat_derecho_transito.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdDerechoTransitos(
      cveDerechoTransito: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatDerechoTransito>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdDerechoTransitos(cveDerechoTransito, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idDiaNolabConacyt A unique integer value identifying this cat_dia_nolab_conacyt.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdDiaNolabConacyt(
      idDiaNolabConacyt: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatDiaNolabConacyt>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdDiaNolabConacyt(idDiaNolabConacyt, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveDictamenEvaluacion A unique integer value identifying this cat_dictamen_evaluacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdDictamenEvaluaciones(
      cveDictamenEvaluacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatDictamenEvaluacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdDictamenEvaluaciones(cveDictamenEvaluacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idDisciplina
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdDisciplina(
      idDisciplina: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatDisciplina>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdDisciplina(idDisciplina, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idCampo
     * @param {string} idDisciplina A unique integer value identifying this cat_disciplina.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdDisciplinasCampos(
      idCampo: string,
      idDisciplina: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatDisciplina>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdDisciplinasCampos(idCampo, idDisciplina, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveDistincionConacyt A unique integer value identifying this cat_distincion_conacyt.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdDistincionesConacyt(
      cveDistincionConacyt: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatDistincionConacyt>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdDistincionesConacyt(cveDistincionConacyt, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idEspecialidadMedica
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdEspecialidadesMedicas(
      idEspecialidadMedica: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatEspecialidadMedica>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdEspecialidadesMedicas(idEspecialidadMedica, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idConsejoCertificador
     * @param {string} idEspecialidadMedica A unique integer value identifying this cat_especialidad_medica.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdEspecialidadesMedicasConsejosCertificadores(
      idConsejoCertificador: string,
      idEspecialidadMedica: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatEspecialidadMedica>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdEspecialidadesMedicasConsejosCertificadores(
        idConsejoCertificador,
        idEspecialidadMedica,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveEstadoCivil A unique integer value identifying this cat_estado_civil.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdEstadoCiviles(
      cveEstadoCivil: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatEstadoCivil>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdEstadoCiviles(cveEstadoCivil, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveEstadoTesis
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdEstadoTesis(
      cveEstadoTesis: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatEstadoTesis>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdEstadoTesis(cveEstadoTesis, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveEstatusAcademico A unique integer value identifying this cat_estatus_academico.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdEstatusAcademicos(
      cveEstatusAcademico: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatEstatusAcademico>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdEstatusAcademicos(cveEstatusAcademico, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idEstatusMovGasto A unique integer value identifying this cat_estatus_mov_gasto.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdEstatusMovGasto(
      idEstatusMovGasto: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatEstatusMovGasto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdEstatusMovGasto(idEstatusMovGasto, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idEstatusNotificacion A unique integer value identifying this cat_estatus_notificacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdEstatusNotificacion(
      idEstatusNotificacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatEstatusNotificacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdEstatusNotificacion(idEstatusNotificacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveFormaTitulacion A unique integer value identifying this cat_forma_titulacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdFormaTitulaciones(
      cveFormaTitulacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatFormaTitulacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdFormaTitulaciones(cveFormaTitulacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idFormatoNotificacion A unique integer value identifying this cat_formato_notificacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdFormatoNotificacion(
      idFormatoNotificacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatFormatoNotificacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdFormatoNotificacion(idFormatoNotificacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveGenero A unique integer value identifying this cat_genero.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdGeneros(
      cveGenero: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatGenero>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdGeneros(cveGenero, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveIdentificaAutor A unique integer value identifying this cat_identifica_autor.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdIdentificaAutores(
      cveIdentificaAutor: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatIdentificaAutor>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdIdentificaAutores(cveIdentificaAutor, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveIdioma A unique integer value identifying this cat_idioma.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdIdiomas(
      cveIdioma: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatIdioma>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdIdiomas(cveIdioma, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idEntidad
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdInstitucionesEstados(
      idEntidad: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatInstitucion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdInstitucionesEstados(idEntidad, cveInstitucion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idLocalidad
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdInstitucionesLocalidades(
      idLocalidad: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatInstitucion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdInstitucionesLocalidades(
        idLocalidad,
        cveInstitucion,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idMunicipio
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdInstitucionesMunicipios(
      idMunicipio: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatInstitucion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdInstitucionesMunicipios(
        idMunicipio,
        cveInstitucion,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cvePais
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdInstitucionesPaises(
      cvePais: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatInstitucion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdInstitucionesPaises(cvePais, cveInstitucion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoInstNivelDos
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdInstitucionesTipoInstNivelDos(
      idTipoInstNivelDos: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatInstitucion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdInstitucionesTipoInstNivelDos(
        idTipoInstNivelDos,
        cveInstitucion,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoInstNivelTres
     * @param {string} idInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdInstitucionesTipoInstNivelTres(
      idTipoInstNivelTres: string,
      idInstitucion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatInstitucion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdInstitucionesTipoInstNivelTres(
        idTipoInstNivelTres,
        idInstitucion,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoInstNivelUno
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdInstitucionesTipoInstNivelUno(
      idTipoInstNivelUno: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatInstitucion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdInstitucionesTipoInstNivelUno(
        idTipoInstNivelUno,
        cveInstitucion,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoInstitucion
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdInstitucionesTipoInstituciones(
      idTipoInstitucion: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatInstitucion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdInstitucionesTipoInstituciones(
        idTipoInstitucion,
        cveInstitucion,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveLenguaIndigena A unique integer value identifying this cat_lengua_indigena.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdLenguaIndigenas(
      cveLenguaIndigena: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatLenguaIndigena>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdLenguaIndigenas(cveLenguaIndigena, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveLocalidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdLocalidadesMunicipiosEstados(
      claveAreaGe: string,
      claveAreaGm: string,
      claveLocalidad: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalidadGeoestadistica>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdLocalidadesMunicipiosEstados(
        claveAreaGe,
        claveAreaGm,
        claveLocalidad,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveMargen A unique integer value identifying this cat_margen.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdMargenes(
      cveMargen: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatMargen>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdMargenes(cveMargen, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idMomentoEvento A unique integer value identifying this cat_momento_evento.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdMomentoEvento(
      idMomentoEvento: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatMomentoEvento>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdMomentoEvento(idMomentoEvento, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdMunicipiosEstado(
      claveAreaGe: string,
      claveAreaGm: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaGeoestadisticasMunicipal>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdMunicipiosEstado(claveAreaGe, claveAreaGm, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveNacionalidad A unique integer value identifying this cat_nacionalidad.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdNacionalidades(
      cveNacionalidad: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatNacionalidad>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdNacionalidades(cveNacionalidad, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idNivelPnpc A unique integer value identifying this cat_nivel_pnpc.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdNivelPnpc(
      idNivelPnpc: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatNivelPnpc>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdNivelPnpc(idNivelPnpc, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveNivelAcademico A unique integer value identifying this cat_nivel_academico.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdNivelesAcademicos(
      cveNivelAcademico: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatNivelAcademico>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdNivelesAcademicos(cveNivelAcademico, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveNivelDominioLd A unique integer value identifying this cat_nivel_dominio_ld.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdNivelesDominiosLd(
      cveNivelDominioLd: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatNivelDominioLd>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdNivelesDominiosLd(cveNivelDominioLd, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idOcdeGrupo
     * @param {string} idOcdeClase A unique integer value identifying this cat_ocde_clase.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdOcdeClasesOcdeGrupos(
      idOcdeGrupo: string,
      idOcdeClase: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatOcdeClase>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdOcdeClasesOcdeGrupos(idOcdeGrupo, idOcdeClase, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idOcdeDivision
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdOcdeDivisiones(
      idOcdeDivision: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatOcdeDivision>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdOcdeDivisiones(idOcdeDivision, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idOcdeSector
     * @param {string} idOcdeDivision A unique integer value identifying this cat_ocde_division.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdOcdeDivisionesOcdeSector(
      idOcdeSector: string,
      idOcdeDivision: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatOcdeDivision>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdOcdeDivisionesOcdeSector(
        idOcdeSector,
        idOcdeDivision,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idOcdeGrupo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdOcdeGrupos(
      idOcdeGrupo: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatOcdeGrupo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdOcdeGrupos(idOcdeGrupo, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idOcdeDivision
     * @param {string} idOcdeGrupo A unique integer value identifying this cat_ocde_grupo.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdOcdeGruposOcdeDivisiones(
      idOcdeDivision: string,
      idOcdeGrupo: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatOcdeGrupo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdOcdeGruposOcdeDivisiones(
        idOcdeDivision,
        idOcdeGrupo,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idOcdeSector A unique integer value identifying this cat_ocde_sector.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdOcdeSectores(
      idOcdeSector: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatOcdeSector>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdOcdeSectores(idOcdeSector, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idOperacionPago A unique integer value identifying this cat_operacion_pago.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdOperacionPago(
      idOperacionPago: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatOperacionPago>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdOperacionPago(idOperacionPago, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idOperadorComparacion A unique integer value identifying this cat_operador_comparacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdOperadorComparacion(
      idOperadorComparacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatOperadorComparacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdOperadorComparacion(idOperadorComparacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cvePais A unique integer value identifying this cat_pais.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdPaises(
      cvePais: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatPais>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdPaises(cvePais, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idFormatoNotificacion
     * @param {string} idPalabraClave A unique integer value identifying this cat_palabra_clave.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdPalabraClave(
      idFormatoNotificacion: string,
      idPalabraClave: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatPalabraClave>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdPalabraClave(
        idFormatoNotificacion,
        idPalabraClave,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idParametrosSeguridad A unique integer value identifying this cat_parametros_seguridad.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdParametrosSeguridad(
      idParametrosSeguridad: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatParametrosSeguridad>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdParametrosSeguridad(idParametrosSeguridad, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cvePartDivulgacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdPartDivulgacion(
      cvePartDivulgacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatPartDivulgacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdPartDivulgacion(cvePartDivulgacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveProductoEvaluacion A unique integer value identifying this cat_producto_evaluacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdProductoEvaluaciones(
      cveProductoEvaluacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatProductoEvaluacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdProductoEvaluaciones(cveProductoEvaluacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cvePuebloIndigena A unique integer value identifying this cat_pueblo_indigena.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdPueblosIndigenas(
      cvePuebloIndigena: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatPuebloIndigena>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdPueblosIndigenas(cvePuebloIndigena, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idRecomdsEvalFomix A unique integer value identifying this cat_recomds_eval_fomix.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdRecomdsEvalFomix(
      idRecomdsEvalFomix: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatRecomdsEvalFomix>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdRecomdsEvalFomix(idRecomdsEvalFomix, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idRolPersFisProgPnpc A unique integer value identifying this cat_rol_pers_fis_prog_pnpc.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdRolPersFisProgPnpc(
      idRolPersFisProgPnpc: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatRolPersFisProgPnpc>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdRolPersFisProgPnpc(idRolPersFisProgPnpc, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idScianGrupo
     * @param {string} idScianClase A unique integer value identifying this cat_scian_clase.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdScianClasesScianGrupos(
      idScianGrupo: string,
      idScianClase: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatScianClase>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdScianClasesScianGrupos(
        idScianGrupo,
        idScianClase,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idScianSector
     * @param {string} idScianDivision A unique integer value identifying this cat_scian_division.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdScianDivisionesScianSectores(
      idScianSector: string,
      idScianDivision: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatScianDivision>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdScianDivisionesScianSectores(
        idScianSector,
        idScianDivision,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idScianGrupo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdScianGrupos(
      idScianGrupo: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatScianGrupo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdScianGrupos(idScianGrupo, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idScianDivision
     * @param {string} idScianGrupo A unique integer value identifying this cat_scian_grupo.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdScianGruposScianDivisiones(
      idScianDivision: string,
      idScianGrupo: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatScianGrupo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdScianGruposScianDivisiones(
        idScianDivision,
        idScianGrupo,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idScianSector A unique integer value identifying this cat_scian_sector.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdScianSectores(
      idScianSector: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatScianSector>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdScianSectores(idScianSector, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idScianSubclase
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdScianSubclases(
      idScianSubclase: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatScianSubclase>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdScianSubclases(idScianSubclase, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idScianClase
     * @param {string} idScianSubclase A unique integer value identifying this cat_scian_subclase.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdScianSubclasesScianClases(
      idScianClase: string,
      idScianSubclase: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatScianSubclase>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdScianSubclasesScianClases(
        idScianClase,
        idScianSubclase,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveSectorDivulgacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdSectorDivulgacion(
      cveSectorDivulgacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatSectorDivulgacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdSectorDivulgacion(cveSectorDivulgacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idSubdisciplina
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdSubDisciplinas(
      idSubdisciplina: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatSubDisciplina>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdSubDisciplinas(idSubdisciplina, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idDisciplina
     * @param {string} idSubdisciplina A unique integer value identifying this cat_subdisciplina.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdSubDisciplinasDisciplinas(
      idDisciplina: string,
      idSubdisciplina: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatSubDisciplina>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdSubDisciplinasDisciplinas(
        idDisciplina,
        idSubdisciplina,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTerminoGenerico A unique integer value identifying this cat_termino_generico.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTerminoGenericos(
      cveTerminoGenerico: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTerminoGenerico>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTerminoGenericos(cveTerminoGenerico, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoActDivulgacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoActDivulgacion(
      cveTipoActDivulgacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoActDivulgacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoActDivulgacion(cveTipoActDivulgacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoAsentamiento A unique integer value identifying this cat_tipo_asentamiento.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoAsentamientos(
      cveTipoAsentamiento: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoAsentamiento>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoAsentamientos(cveTipoAsentamiento, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoContacto A unique integer value identifying this cat_tipo_contacto.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoContactos(
      cveTipoContacto: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoContacto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoContactos(cveTipoContacto, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoDiscapacidad A unique integer value identifying this cat_tipo_discapacidad.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoDiscapacidades(
      cveTipoDiscapacidad: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoDiscapacidad>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoDiscapacidades(cveTipoDiscapacidad, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoEstancia A unique integer value identifying this cat_tipo_estancia.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoEstancias(
      cveTipoEstancia: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoEstancia>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoEstancias(cveTipoEstancia, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoEvaluacion A unique integer value identifying this cat_tipo_evaluacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoEvaluaciones(
      cveTipoEvaluacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoEvaluacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoEvaluaciones(cveTipoEvaluacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoEventoUi A unique integer value identifying this cat_tipo_evento_ui.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoEventoUi(
      idTipoEventoUi: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoEventoUi>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoEventoUi(idTipoEventoUi, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoFormacion A unique integer value identifying this cat_tipo_formacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoFormaciones(
      cveTipoFormacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoFormacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoFormaciones(cveTipoFormacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoInstNivelDos
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoInstNivelDos(
      cveTipoInstNivelDos: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoInstNivelDos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoInstNivelDos(cveTipoInstNivelDos, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoInstNivelUno
     * @param {string} cveTipoInstNivelDos A unique integer value identifying this cat_tipo_inst_nivel_dos.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoInstNivelDosTipoInstNivelUno(
      idTipoInstNivelUno: string,
      cveTipoInstNivelDos: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoInstNivelDos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoInstNivelDosTipoInstNivelUno(
        idTipoInstNivelUno,
        cveTipoInstNivelDos,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoInstitucion
     * @param {string} cveTipoInstNivelUno A unique integer value identifying this cat_tipo_inst_nivel_uno.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoInstNivelUnoTipoInstituciones(
      idTipoInstitucion: string,
      cveTipoInstNivelUno: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoInstNivelUno>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoInstNivelUnoTipoInstituciones(
        idTipoInstitucion,
        cveTipoInstNivelUno,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoInstitucion A unique integer value identifying this cat_tipo_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoInstituciones(
      cveTipoInstitucion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoInstitucion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoInstituciones(cveTipoInstitucion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoMedioDivulgacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoMedioDivulgacion(
      cveTipoMedioDivulgacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoMedioDivulgacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoMedioDivulgacion(cveTipoMedioDivulgacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoNotificacion A unique integer value identifying this cat_tipo_notificacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoNotificacion(
      idTipoNotificacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoNotificacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoNotificacion(idTipoNotificacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoPartCongreso
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoPartCongreso(
      cveTipoPartCongreso: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoPartCongreso>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoPartCongreso(cveTipoPartCongreso, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoParticipacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoParticipacion7(
      cveTipoParticipacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoParticipacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoParticipacion7(cveTipoParticipacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoPertenEtnica A unique integer value identifying this cat_tipo_perten_etnica.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoPertenEtnicas(
      cveTipoPertenEtnica: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoPertenEtnica>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoPertenEtnicas(cveTipoPertenEtnica, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoPublicacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoPublicacion(
      cveTipoPublicacion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoPublicacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoPublicacion(cveTipoPublicacion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoRiesgo A unique integer value identifying this cat_tipo_riesgo.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoRiesgo(
      idTipoRiesgo: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoRiesgo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoRiesgo(idTipoRiesgo, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoTrayectoriaProf A unique integer value identifying this cat_tipo_trayectoria_prof.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoTrayectoriasProf(
      cveTipoTrayectoriaProf: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoTrayectoriaProf>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoTrayectoriasProf(cveTipoTrayectoriaProf, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoVialidad A unique integer value identifying this cat_tipo_vialidad.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTipoVialidades(
      cveTipoVialidad: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoVialidad>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTipoVialidades(cveTipoVialidad, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoInstNivelUno
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdTiposInstNivelUno(
      cveTipoInstNivelUno: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoInstNivelUno>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdTiposInstNivelUno(cveTipoInstNivelUno, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveVariante
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdVarAutodenominaciones(
      cveVariante: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatVarAutodenominacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdVarAutodenominaciones(cveVariante, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idPuebloIndigena
     * @param {string} cveVariante A unique integer value identifying this cat_var_autodenominacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdVarAutodenominacionesPueblosIndigenas(
      idPuebloIndigena: string,
      cveVariante: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatVarAutodenominacion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdVarAutodenominacionesPueblosIndigenas(
        idPuebloIndigena,
        cveVariante,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveLocalidad
     * @param {string} claveVialidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdVialidadesLocaliidaadesMunicipiosEstados(
      claveAreaGe: string,
      claveAreaGm: string,
      claveLocalidad: string,
      claveVialidad: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vialidades>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdVialidadesLocaliidaadesMunicipiosEstados(
        claveAreaGe,
        claveAreaGm,
        claveLocalidad,
        claveVialidad,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdentificaAutores(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetIdentificaAutores200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdentificaAutores(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoInstNivelTres
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdinstitucion(
      idTipoInstNivelTres: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatInstitucion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdinstitucion(idTipoInstNivelTres, cveInstitucion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdiomas(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetIdiomas200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdiomas(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idFuncionalidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdsCatDefinicionLogUis(
      idFuncionalidad: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatDefinicionLogUis200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdsCatDefinicionLogUis(idFuncionalidad, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInstituciones(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInstituciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInstituciones(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idEntidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInstitucionesEstaados(
      idEntidad: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInstituciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInstitucionesEstaados(idEntidad, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idLocalidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInstitucionesLocalidades(
      idLocalidad: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInstituciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInstitucionesLocalidades(idLocalidad, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idMunicipio
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInstitucionesMunicipios(
      idMunicipio: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInstituciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInstitucionesMunicipios(idMunicipio, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idPais
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInstitucionesPaises(
      idPais: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInstituciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInstitucionesPaises(idPais, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoInstNivelDos
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInstitucionesTipoInstNivelDos(
      idTipoInstNivelDos: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInstituciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInstitucionesTipoInstNivelDos(idTipoInstNivelDos, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoInstNivelTres
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInstitucionesTipoInstNivelTres(
      idTipoInstNivelTres: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInstituciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInstitucionesTipoInstNivelTres(idTipoInstNivelTres, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoInstNivelUno
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInstitucionesTipoInstNivelUno(
      idTipoInstNivelUno: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInstituciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInstitucionesTipoInstNivelUno(idTipoInstNivelUno, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoInstitucion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInstitucionesTipoInstituciones(
      idTipoInstitucion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInstituciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInstitucionesTipoInstituciones(idTipoInstitucion, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLenguaIndigenas(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLenguaIndigenas200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLenguaIndigenas(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLocalidadesMunicipiosEstados(
      claveAreaGe: string,
      claveAreaGm: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLocalidadesMunicipiosEstados200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLocalidadesMunicipiosEstados(
        claveAreaGe,
        claveAreaGm,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMargenes(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMargenes200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMargenes(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMunicipiosEstado(
      claveAreaGe: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMunicipiosEstado200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMunicipiosEstado(claveAreaGe, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNacionalidades(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNacionalidades200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNacionalidades(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNivelesAcademicos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNivelesAcademicos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNivelesAcademicos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNivelesDominiosLd(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNivelesDominiosLd200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNivelesDominiosLd(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOcdeClases(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOcdeClasesOcdeGrupos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOcdeClases(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idOcdeGrupo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOcdeClasesOcdeGrupos(
      idOcdeGrupo: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOcdeClasesOcdeGrupos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOcdeClasesOcdeGrupos(idOcdeGrupo, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOcdeDivisiones(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOcdeDivisionesOcdeSector200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOcdeDivisiones(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idOcdeSector
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOcdeDivisionesOcdeSector(
      idOcdeSector: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOcdeDivisionesOcdeSector200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOcdeDivisionesOcdeSector(idOcdeSector, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOcdeGrupos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOcdeGruposOcdeDivisiones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOcdeGrupos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idOcdeDivision
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOcdeGruposOcdeDivisiones(
      idOcdeDivision: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOcdeGruposOcdeDivisiones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOcdeGruposOcdeDivisiones(idOcdeDivision, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOcdeSectores(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOcdeSectores200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOcdeSectores(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPaises(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaises200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPaises(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductoEvaluaciones(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductoEvaluaciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductoEvaluaciones(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPueblosIndigenas(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPueblosIndigenas200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPueblosIndigenas(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idPreguntaRespuesta
     * @param {string} idListRespPregunta A unique integer value identifying this cat_list_resp_pregunta.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRespPreguntas(
      idPreguntaRespuesta: string,
      idListRespPregunta: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatListRespPregunta>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRespPreguntas(
        idPreguntaRespuesta,
        idListRespPregunta,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoEventoUi
     * @param {string} idDefinicionLogUi
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRetrieveCatDefinicionLogUi(
      idTipoEventoUi: string,
      idDefinicionLogUi: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatDefinicionLogUi>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRetrieveCatDefinicionLogUi(
        idTipoEventoUi,
        idDefinicionLogUi,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getScianClases(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScianClases200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getScianClases(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idScianGrupo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getScianClasesScianGrupos(
      idScianGrupo: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScianClases200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getScianClasesScianGrupos(idScianGrupo, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getScianDivisiones(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScianDivisiones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getScianDivisiones(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idScianSector
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getScianDivisionesScianSectores(
      idScianSector: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScianDivisiones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getScianDivisionesScianSectores(idScianSector, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getScianGrupos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScianGrupos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getScianGrupos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idScianDivision
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getScianGruposScianDivisiones(
      idScianDivision: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScianGrupos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getScianGruposScianDivisiones(idScianDivision, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getScianSectores(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScianSectores200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getScianSectores(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getScianSubclases(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScianSubclases200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getScianSubclases(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idScianClase
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getScianSubclasesScianClases(
      idScianClase: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScianSubclases200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getScianSubclasesScianClases(idScianClase, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubDisciplinas(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubDisciplinas200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSubDisciplinas(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idDisciplina
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubDisciplinasDisciplinas(
      idDisciplina: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubDisciplinas200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSubDisciplinasDisciplinas(idDisciplina, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTerminoGenericos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTerminoGenericos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTerminoGenericos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTipoAsentamientos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTipoAsentamientos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTipoAsentamientos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTipoContactos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTipoContactos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTipoContactos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTipoDiscapacidades(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTipoDiscapacidades200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTipoDiscapacidades(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTipoEstancias(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTipoEstancias200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTipoEstancias(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTipoEvaluaciones(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTipoEvaluaciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTipoEvaluaciones(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTipoFormaciones(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTipoFormaciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTipoFormaciones(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoInstNivelDos
     * @param {string} idTipoInstNivelTres A unique integer value identifying this cat_tipo_inst_nivel_tres.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTipoIdsInstNivelTres(
      idTipoInstNivelDos: string,
      idTipoInstNivelTres: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatTipoInstNivelTres>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTipoIdsInstNivelTres(
        idTipoInstNivelDos,
        idTipoInstNivelTres,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTipoInstNivelDos(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTipoInstNivelDos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTipoInstNivelDos(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoInstNivelUno
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTipoInstNivelDosTipoInstNivelUno(
      idTipoInstNivelUno: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTipoInstNivelDos200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTipoInstNivelDosTipoInstNivelUno(
        idTipoInstNivelUno,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoInstitucion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTipoInstNivelUnoTipoInstituciones(
      idTipoInstitucion: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTiposInstNivelUno200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTipoInstNivelUnoTipoInstituciones(
        idTipoInstitucion,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTipoInstituciones(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTipoInstituciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTipoInstituciones(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTipoPertenEtnicas(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTipoPertenEtnicas200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTipoPertenEtnicas(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTipoTrayectoriasProf(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTipoTrayectoriasProf200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTipoTrayectoriasProf(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTipoVialidades(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTipoVialidades200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTipoVialidades(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTiposInstNivelUno(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTiposInstNivelUno200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTiposInstNivelUno(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVarAutodenominaciones(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVarAutodenominaciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVarAutodenominaciones(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idPuebloIndigena
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVarAutodenominacionesPueblosIndigenas(
      idPuebloIndigena: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVarAutodenominaciones200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVarAutodenominacionesPueblosIndigenas(
        idPuebloIndigena,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveLocalidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVialidadesLocaliidadesMunicipiosEstados(
      claveAreaGe: string,
      claveAreaGm: string,
      claveLocalidad: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVialidadesLocaliidadesMunicipiosEstados200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVialidadesLocaliidadesMunicipiosEstados(
        claveAreaGe,
        claveAreaGm,
        claveLocalidad,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idFuncionalidad
     * @param {string} idDefinicionLogUi A unique integer value identifying this cat_definicion_log_ui.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getsCatDefinicionLogUi(
      idFuncionalidad: string,
      idDefinicionLogUi: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatDefinicionLogUi>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getsCatDefinicionLogUi(
        idFuncionalidad,
        idDefinicionLogUi,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoEventoUi
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getsCatDefinicionLogUis(
      idTipoEventoUi: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatDefinicionLogUis200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getsCatDefinicionLogUis(idTipoEventoUi, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idPreguntaRespuesta
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getsCatListRespPreguntas(
      idPreguntaRespuesta: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatListRespPreguntas200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getsCatListRespPreguntas(idPreguntaRespuesta, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getsCatPalabraClaves(
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatPalabraClaves200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getsCatPalabraClaves(page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} cveTipoInstNivelUno
     * @param {string} cveTipoInstNivelDos
     * @param {string} cveEntidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getsCveClaveCatNacionalPublico(
      cveTipoInstNivelUno: string,
      cveTipoInstNivelDos: string,
      cveEntidad: string,
      page?: number,
      size?: number,
      search?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatNacionalPublico>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getsCveClaveCatNacionalPublico(
        cveTipoInstNivelUno,
        cveTipoInstNivelDos,
        cveEntidad,
        page,
        size,
        search,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoEventoUi
     * @param {string} idDefinicionLogUi A unique integer value identifying this cat_definicion_log_ui.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getsDefinicionLogsUis(
      idTipoEventoUi: string,
      idDefinicionLogUi: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatDefinicionLogUi>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getsDefinicionLogsUis(
        idTipoEventoUi,
        idDefinicionLogUi,
        page,
        size,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} idTipoEventoUi
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getsIdCatDefinicionLogUis(
      idTipoEventoUi: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCatDefinicionLogUis200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getsIdCatDefinicionLogUis(idTipoEventoUi, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ApiApiFp(configuration);
  return {
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdministraciones(page?: number, size?: number, options?: any): AxiosPromise<GetAdministraciones200Response> {
      return localVarFp.getAdministraciones(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idAmbito
     * @param {string} idVialidad A unique integer value identifying this cat_vialidad.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAmbitoCatVialidadId(idAmbito: string, idVialidad: string, page?: number, size?: number, options?: any): AxiosPromise<CatVialidad> {
      return localVarFp.getAmbitoCatVialidadId(idAmbito, idVialidad, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAmbitos(page?: number, size?: number, options?: any): AxiosPromise<GetAmbitos200Response> {
      return localVarFp.getAmbitos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAreaConocimiento(page?: number, size?: number, options?: any): AxiosPromise<GetAreaConocimiento200Response> {
      return localVarFp.getAreaConocimiento(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAreaGeoestadisticaEstatal(page?: number, size?: number, options?: any): AxiosPromise<GetAreaGeoestadisticaEstatal200Response> {
      return localVarFp.getAreaGeoestadisticaEstatal(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAsentamientosHumanosEstados(
      claveAreaGe: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetAsentamientosHumanosEstados200Response> {
      return localVarFp.getAsentamientosHumanosEstados(claveAreaGe, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveLocalidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAsentamientosHumanosLocalidadesMunicipiosEstados(
      claveAreaGe: string,
      claveAreaGm: string,
      claveLocalidad: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetAsentamientosHumanosEstados200Response> {
      return localVarFp
        .getAsentamientosHumanosLocalidadesMunicipiosEstados(claveAreaGe, claveAreaGm, claveLocalidad, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAsentamientosHumanosMunicipiosEstados(
      claveAreaGe: string,
      claveAreaGm: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetAsentamientosHumanosEstados200Response> {
      return localVarFp
        .getAsentamientosHumanosMunicipiosEstados(claveAreaGe, claveAreaGm, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBaseCartograficas(page?: number, size?: number, options?: any): AxiosPromise<Array<BaseCartografica>> {
      return localVarFp.getBaseCartograficas(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} claveAsentamiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBaseCartograficasAsentamientos(
      claveAsentamiento: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<Array<BaseCartografica>> {
      return localVarFp.getBaseCartograficasAsentamientos(claveAsentamiento, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCampos(page?: number, size?: number, options?: any): AxiosPromise<GetCampos200Response> {
      return localVarFp.getCampos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCaracteristContactos(page?: number, size?: number, options?: any): AxiosPromise<GetCaracteristContactos200Response> {
      return localVarFp.getCaracteristContactos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoContacto
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCaracteristContactosTipoContactos(
      idTipoContacto: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetCaracteristContactos200Response> {
      return localVarFp.getCaracteristContactosTipoContactos(idTipoContacto, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveAreaConocimiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatCamposAreasConocimiento(
      cveAreaConocimiento: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetCampos200Response> {
      return localVarFp.getCatCamposAreasConocimiento(cveAreaConocimiento, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatClasificacionPatentes(page?: number, size?: number, options?: any): AxiosPromise<GetCatClasificacionPatentes200Response> {
      return localVarFp.getCatClasificacionPatentes(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} codigo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatCodigosPostalesFilter(codigo: string, page?: number, size?: number, options?: any): AxiosPromise<CatCodigosPostalesFilter> {
      return localVarFp.getCatCodigosPostalesFilter(codigo, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatDefinicionLogUis(page?: number, size?: number, options?: any): AxiosPromise<GetCatDefinicionLogUis200Response> {
      return localVarFp.getCatDefinicionLogUis(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatDiaNolabConacyts(page?: number, size?: number, options?: any): AxiosPromise<GetCatDiaNolabConacyts200Response> {
      return localVarFp.getCatDiaNolabConacyts(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idCampo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatDisciplinasCampos(idCampo: string, page?: number, size?: number, options?: any): AxiosPromise<GetDisciplinas200Response> {
      return localVarFp.getCatDisciplinasCampos(idCampo, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatEstadoPatentes(page?: number, size?: number, options?: any): AxiosPromise<GetCatEstadoPatentes200Response> {
      return localVarFp.getCatEstadoPatentes(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatEstadoTesis(page?: number, size?: number, options?: any): AxiosPromise<GetCatEstadoTesis200Response> {
      return localVarFp.getCatEstadoTesis(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatEstatusMigratorios(page?: number, size?: number, options?: any): AxiosPromise<CatEstatusMigratorio> {
      return localVarFp.getCatEstatusMigratorios(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatEstatusMovGastos(page?: number, size?: number, options?: any): AxiosPromise<GetCatEstatusMovGastos200Response> {
      return localVarFp.getCatEstatusMovGastos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatEstatusNotificacions(page?: number, size?: number, options?: any): AxiosPromise<GetCatEstatusNotificacions200Response> {
      return localVarFp.getCatEstatusNotificacions(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatEtapaDesarrollos(page?: number, size?: number, options?: any): AxiosPromise<GetCatEtapaDesarrollos200Response> {
      return localVarFp.getCatEtapaDesarrollos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatFormatoNotificacions(page?: number, size?: number, options?: any): AxiosPromise<GetCatFormatoNotificacions200Response> {
      return localVarFp.getCatFormatoNotificacions(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoInstNivelTres
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatInstitucions(
      idTipoInstNivelTres: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetInstituciones200Response> {
      return localVarFp.getCatInstitucions(idTipoInstNivelTres, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatListRespPreguntas(page?: number, size?: number, options?: any): AxiosPromise<GetCatListRespPreguntas200Response> {
      return localVarFp.getCatListRespPreguntas(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatMomentoEventos(page?: number, size?: number, options?: any): AxiosPromise<GetCatMomentoEventos200Response> {
      return localVarFp.getCatMomentoEventos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatNivelPnpcs(page?: number, size?: number, options?: any): AxiosPromise<GetCatNivelPnpcs200Response> {
      return localVarFp.getCatNivelPnpcs(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatOperacionPagos(page?: number, size?: number, options?: any): AxiosPromise<GetCatOperacionPagos200Response> {
      return localVarFp.getCatOperacionPagos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatOperadorComparacions(page?: number, size?: number, options?: any): AxiosPromise<GetCatOperadorComparacions200Response> {
      return localVarFp.getCatOperadorComparacions(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatOrigenReportes(page?: number, size?: number, options?: any): AxiosPromise<GetCatOrigenReportes200Response> {
      return localVarFp.getCatOrigenReportes(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idFormatoNotificacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatPalabraClaves(
      idFormatoNotificacion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetCatPalabraClaves200Response> {
      return localVarFp.getCatPalabraClaves(idFormatoNotificacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatParametrosSeguridads(page?: number, size?: number, options?: any): AxiosPromise<GetCatParametrosSeguridads200Response> {
      return localVarFp.getCatParametrosSeguridads(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatPartDivulgacions(page?: number, size?: number, options?: any): AxiosPromise<GetCatPartDivulgacions200Response> {
      return localVarFp.getCatPartDivulgacions(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatProductoDivulgacions(page?: number, size?: number, options?: any): AxiosPromise<CatProductoDivulgacion> {
      return localVarFp.getCatProductoDivulgacions(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatRecomdsEvalFomixs(page?: number, size?: number, options?: any): AxiosPromise<GetCatRecomdsEvalFomixs200Response> {
      return localVarFp.getCatRecomdsEvalFomixs(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatRedTematicaConacyts(page?: number, size?: number, options?: any): AxiosPromise<CatRedTematicaConacyt> {
      return localVarFp.getCatRedTematicaConacyts(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatRolPersFisProgPnpcs(page?: number, size?: number, options?: any): AxiosPromise<GetCatRolPersFisProgPnpcs200Response> {
      return localVarFp.getCatRolPersFisProgPnpcs(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatSectorDivulgacions(page?: number, size?: number, options?: any): AxiosPromise<GetCatSectorDivulgacions200Response> {
      return localVarFp.getCatSectorDivulgacions(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoActDivulgacions(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoActDivulgacions200Response> {
      return localVarFp.getCatTipoActDivulgacions(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoDesarrollos(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoDesarrollos200Response> {
      return localVarFp.getCatTipoDesarrollos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoDoctoRespaldos(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoDoctoRespaldos200Response> {
      return localVarFp.getCatTipoDoctoRespaldos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoDomicilios(page?: number, size?: number, options?: any): AxiosPromise<CatTipoDomicilio> {
      return localVarFp.getCatTipoDomicilios(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoEventoUis(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoEventoUis200Response> {
      return localVarFp.getCatTipoEventoUis(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoFondos(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoFondos200Response> {
      return localVarFp.getCatTipoFondos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoInnovacionOslos(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoInnovacionOslos200Response> {
      return localVarFp.getCatTipoInnovacionOslos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoInstNivelTres(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoInstNivelTres200Response> {
      return localVarFp.getCatTipoInstNivelTres(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoMedioDivulgacions(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoMedioDivulgacions200Response> {
      return localVarFp.getCatTipoMedioDivulgacions(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoNotificacions(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoNotificacions200Response> {
      return localVarFp.getCatTipoNotificacions(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoPartCongresos(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoPartCongresos200Response> {
      return localVarFp.getCatTipoPartCongresos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoParticipacions(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoParticipacions200Response> {
      return localVarFp.getCatTipoParticipacions(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoParticipante
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoParticipante(cveTipoParticipante: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoParticipante> {
      return localVarFp.getCatTipoParticipante(cveTipoParticipante, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoParticipantes(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoParticipantes200Response> {
      return localVarFp.getCatTipoParticipantes(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoPatentes(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoPatentes200Response> {
      return localVarFp.getCatTipoPatentes(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoProteccions(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoProteccions200Response> {
      return localVarFp.getCatTipoProteccions(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoProyectos(page?: number, size?: number, options?: any): AxiosPromise<CatTipoProyecto> {
      return localVarFp.getCatTipoProyectos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoPublicacions(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoPublicacions200Response> {
      return localVarFp.getCatTipoPublicacions(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoRiesgos(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoRiesgos200Response> {
      return localVarFp.getCatTipoRiesgos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoSoftwares(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoSoftwares200Response> {
      return localVarFp.getCatTipoSoftwares(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTipoTransferencias(page?: number, size?: number, options?: any): AxiosPromise<GetCatTipoTransferencias200Response> {
      return localVarFp.getCatTipoTransferencias(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoInstNivelDos
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatTiposInstNivelTres(
      idTipoInstNivelDos: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetCatTipoInstNivelTres200Response> {
      return localVarFp.getCatTiposInstNivelTres(idTipoInstNivelDos, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogoDetalles(page?: number, size?: number, options?: any): AxiosPromise<GetCatalogoDetalles200Response> {
      return localVarFp.getCatalogoDetalles(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idCatCatalogoMaestro
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogoDetallesCatalogosMaestro(
      idCatCatalogoMaestro: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetCatalogoDetalles200Response> {
      return localVarFp
        .getCatalogoDetallesCatalogosMaestro(idCatCatalogoMaestro, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogoMaestros(page?: number, size?: number, options?: any): AxiosPromise<GetCatalogoMaestros200Response> {
      return localVarFp.getCatalogoMaestros(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveClasificacionPatente
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClaveCatClasificacionPatente(
      cveClasificacionPatente: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatClasificacionPatente> {
      return localVarFp
        .getClaveCatClasificacionPatente(cveClasificacionPatente, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveEstadoPatente
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClaveCatEstadoPatente(cveEstadoPatente: string, page?: number, size?: number, options?: any): AxiosPromise<CatEstadoPatente> {
      return localVarFp.getClaveCatEstadoPatente(cveEstadoPatente, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveEtapaDesarrollo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClaveCatEtapaDesarrollo(cveEtapaDesarrollo: string, page?: number, size?: number, options?: any): AxiosPromise<CatEtapaDesarrollo> {
      return localVarFp.getClaveCatEtapaDesarrollo(cveEtapaDesarrollo, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cvePais
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClaveCatExtranjeraPais(
      cvePais: string,
      page?: number,
      size?: number,
      search?: string,
      options?: any
    ): AxiosPromise<CatExtranjeraPais> {
      return localVarFp.getClaveCatExtranjeraPais(cvePais, page, size, search, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoProteccion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClaveCatTipoProteccion(cveTipoProteccion: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoProteccion> {
      return localVarFp.getClaveCatTipoProteccion(cveTipoProteccion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoInstNivelUno
     * @param {string} cveTipoInstNivelDos
     * @param {string} cveEntidad
     * @param {string} cveMunicipio
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClaveCveCatNacionalPublico(
      cveTipoInstNivelUno: string,
      cveTipoInstNivelDos: string,
      cveEntidad: string,
      cveMunicipio: string,
      page?: number,
      size?: number,
      search?: string,
      options?: any
    ): AxiosPromise<GetClaveCveCatNacionalPublico200Response> {
      return localVarFp
        .getClaveCveCatNacionalPublico(cveTipoInstNivelUno, cveTipoInstNivelDos, cveEntidad, cveMunicipio, page, size, search, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCodigosPostales(page?: number, size?: number, options?: any): AxiosPromise<GetCodigosPostales200Response> {
      return localVarFp.getCodigosPostales(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoAsentamiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCodigosPostalesTipoAsentamientos(
      cveTipoAsentamiento: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetCodigosPostales200Response> {
      return localVarFp
        .getCodigosPostalesTipoAsentamientos(cveTipoAsentamiento, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConsejoCertificadores(page?: number, size?: number, options?: any): AxiosPromise<GetConsejoCertificadores200Response> {
      return localVarFp.getConsejoCertificadores(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveAreaConocimiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveCatMotivacioSnis(
      cveAreaConocimiento: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetCveCatMotivacioSnis200Response> {
      return localVarFp.getCveCatMotivacioSnis(cveAreaConocimiento, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoInstNivelUno
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveCatNacionalPublico(
      cveTipoInstNivelUno: string,
      page?: number,
      size?: number,
      search?: string,
      options?: any
    ): AxiosPromise<CatNacionalPublico> {
      return localVarFp
        .getCveCatNacionalPublico(cveTipoInstNivelUno, page, size, search, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoDesarrollo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveCatTipoDesarrollo(cveTipoDesarrollo: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoDesarrollo> {
      return localVarFp.getCveCatTipoDesarrollo(cveTipoDesarrollo, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoInnovacionOslo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveCatTipoInnovacionOslo(
      cveTipoInnovacionOslo: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatTipoInnovacionOslo> {
      return localVarFp.getCveCatTipoInnovacionOslo(cveTipoInnovacionOslo, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoPatente
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveCatTipoPatente(cveTipoPatente: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoPatente> {
      return localVarFp.getCveCatTipoPatente(cveTipoPatente, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoSoftware
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveCatTipoSoftware(cveTipoSoftware: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoSoftware> {
      return localVarFp.getCveCatTipoSoftware(cveTipoSoftware, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoTransferencia
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveCatTipoTransferencia(
      cveTipoTransferencia: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatTipoTransferencia> {
      return localVarFp.getCveCatTipoTransferencia(cveTipoTransferencia, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoInstNivelUno
     * @param {string} cveTipoInstNivelDos
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveClaveCatNacionalPublico(
      cveTipoInstNivelUno: string,
      cveTipoInstNivelDos: string,
      page?: number,
      size?: number,
      search?: string,
      options?: any
    ): AxiosPromise<CatNacionalPublico> {
      return localVarFp
        .getCveClaveCatNacionalPublico(cveTipoInstNivelUno, cveTipoInstNivelDos, page, size, search, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveAreaConocimiento
     * @param {string} idMotivacionSni A unique integer value identifying this cat_motivacion_sni.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCveIdCatMotivacioSnis(
      cveAreaConocimiento: string,
      idMotivacionSni: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatMotivacioSni> {
      return localVarFp
        .getCveIdCatMotivacioSnis(cveAreaConocimiento, idMotivacionSni, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDerechoTransitos(page?: number, size?: number, options?: any): AxiosPromise<GetDerechoTransitos200Response> {
      return localVarFp.getDerechoTransitos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDictamenEvaluaciones(page?: number, size?: number, options?: any): AxiosPromise<GetDictamenEvaluaciones200Response> {
      return localVarFp.getDictamenEvaluaciones(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDisciplinas(page?: number, size?: number, options?: any): AxiosPromise<GetDisciplinas200Response> {
      return localVarFp.getDisciplinas(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDistincionesConacyt(page?: number, size?: number, options?: any): AxiosPromise<GetDistincionesConacyt200Response> {
      return localVarFp.getDistincionesConacyt(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEspecialidadesMedicas(page?: number, size?: number, options?: any): AxiosPromise<GetEspecialidadesMedicas200Response> {
      return localVarFp.getEspecialidadesMedicas(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idConsejoCertificador
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEspecialidadesMedicasConsejosCertificadores(
      idConsejoCertificador: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetEspecialidadesMedicas200Response> {
      return localVarFp
        .getEspecialidadesMedicasConsejosCertificadores(idConsejoCertificador, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEstadoCiviles(page?: number, size?: number, options?: any): AxiosPromise<GetEstadoCiviles200Response> {
      return localVarFp.getEstadoCiviles(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEstatusAcademicos(page?: number, size?: number, options?: any): AxiosPromise<GetEstatusAcademicos200Response> {
      return localVarFp.getEstatusAcademicos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFormaTitulaciones(page?: number, size?: number, options?: any): AxiosPromise<GetFormaTitulaciones200Response> {
      return localVarFp.getFormaTitulaciones(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGeneros(page?: number, size?: number, options?: any): AxiosPromise<GetGeneros200Response> {
      return localVarFp.getGeneros(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveAdministracion A unique integer value identifying this cat_administracion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdAdministraciones(cveAdministracion: string, page?: number, size?: number, options?: any): AxiosPromise<CatAdministracion> {
      return localVarFp.getIdAdministraciones(cveAdministracion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveAmbito A unique integer value identifying this cat_ambito.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdAmbitos(cveAmbito: string, page?: number, size?: number, options?: any): AxiosPromise<CatAmbito> {
      return localVarFp.getIdAmbitos(cveAmbito, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} indVersion A unique integer value identifying this cat_area_conocimiento.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdAreaConocimiento(indVersion: string, page?: number, size?: number, options?: any): AxiosPromise<CatAreaConocimiento> {
      return localVarFp.getIdAreaConocimiento(indVersion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} claveAreaGev
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdAreaGeoestadisticaEstatal(
      claveAreaGev: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<AreaGeoestadisticaEstatal> {
      return localVarFp.getIdAreaGeoestadisticaEstatal(claveAreaGev, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAsentamiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdAsentamientosHumanosEstados(
      claveAreaGe: string,
      claveAsentamiento: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<AsentamientosHumanos> {
      return localVarFp
        .getIdAsentamientosHumanosEstados(claveAreaGe, claveAsentamiento, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveLocalidad
     * @param {string} claveAsentamiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdAsentamientosHumanosLocalidadesMunicipiosEstados(
      claveAreaGe: string,
      claveAreaGm: string,
      claveLocalidad: string,
      claveAsentamiento: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<AsentamientosHumanos> {
      return localVarFp
        .getIdAsentamientosHumanosLocalidadesMunicipiosEstados(
          claveAreaGe,
          claveAreaGm,
          claveLocalidad,
          claveAsentamiento,
          page,
          size,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveAsentamiento
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdAsentamientosHumanosMunicipiosEstados(
      claveAreaGe: string,
      claveAreaGm: string,
      claveAsentamiento: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<AsentamientosHumanos> {
      return localVarFp
        .getIdAsentamientosHumanosMunicipiosEstados(claveAreaGe, claveAreaGm, claveAsentamiento, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveCampo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCampos(cveCampo: string, page?: number, size?: number, options?: any): AxiosPromise<CatCampo> {
      return localVarFp.getIdCampos(cveCampo, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveCaracteristContacto
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCaracteristContactos(
      cveCaracteristContacto: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatCaracteristicaContacto> {
      return localVarFp.getIdCaracteristContactos(cveCaracteristContacto, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoContacto
     * @param {string} cveCaracteristContacto A unique integer value identifying this cat_caracterist_contacto.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCaracteristContactosTipoContactos(
      idTipoContacto: string,
      cveCaracteristContacto: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatCaracteristicaContacto> {
      return localVarFp
        .getIdCaracteristContactosTipoContactos(idTipoContacto, cveCaracteristContacto, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveAreaConocimiento
     * @param {string} idCampo A unique integer value identifying this cat_campo.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatCamposAreasConocimiento(
      cveAreaConocimiento: string,
      idCampo: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatCampo> {
      return localVarFp
        .getIdCatCamposAreasConocimiento(cveAreaConocimiento, idCampo, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idDefinicionLogUi
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatDefinicionLogUi(idDefinicionLogUi: string, page?: number, size?: number, options?: any): AxiosPromise<CatDefinicionLogUi> {
      return localVarFp.getIdCatDefinicionLogUi(idDefinicionLogUi, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveEstatusMigratorio
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatEstatusMigratorio(
      cveEstatusMigratorio: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatEstatusMigratorio> {
      return localVarFp.getIdCatEstatusMigratorio(cveEstatusMigratorio, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idInstitucion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatInstituciones(idInstitucion: string, page?: number, size?: number, options?: any): AxiosPromise<CatInstitucion> {
      return localVarFp.getIdCatInstituciones(idInstitucion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveOrigenReporte
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatOrigenReporte(cveOrigenReporte: string, page?: number, size?: number, options?: any): AxiosPromise<CatOrigenReporte> {
      return localVarFp.getIdCatOrigenReporte(cveOrigenReporte, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idPalabraClave
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatPalabraClave(idPalabraClave: string, page?: number, size?: number, options?: any): AxiosPromise<CatPalabraClave> {
      return localVarFp.getIdCatPalabraClave(idPalabraClave, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveProductoDivulgacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatProductoDivulgacion(
      cveProductoDivulgacion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatProductoDivulgacion> {
      return localVarFp.getIdCatProductoDivulgacion(cveProductoDivulgacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveRedTematicaConacyt
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatRedTematicaConacyt(
      cveRedTematicaConacyt: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatRedTematicaConacyt> {
      return localVarFp.getIdCatRedTematicaConacyt(cveRedTematicaConacyt, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoDoctoRespaldo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatTipoDoctoRespaldo(
      cveTipoDoctoRespaldo: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatTipoDoctoRespaldo> {
      return localVarFp.getIdCatTipoDoctoRespaldo(cveTipoDoctoRespaldo, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoDomicilio
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatTipoDomicilio(cveTipoDomicilio: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoDomicilio> {
      return localVarFp.getIdCatTipoDomicilio(cveTipoDomicilio, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoFondo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatTipoFondo(cveTipoFondo: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoFondo> {
      return localVarFp.getIdCatTipoFondo(cveTipoFondo, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoInstNivelTres
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatTipoInstNivelTres(
      cveTipoInstNivelTres: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatTipoInstNivelTres> {
      return localVarFp.getIdCatTipoInstNivelTres(cveTipoInstNivelTres, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoProyecto
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatTipoProyecto(cveTipoProyecto: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoProyecto> {
      return localVarFp.getIdCatTipoProyecto(cveTipoProyecto, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idAmbito
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatVialidads(idAmbito: string, page?: number, size?: number, options?: any): AxiosPromise<CatVialidad> {
      return localVarFp.getIdCatVialidads(idAmbito, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} clave
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatalogoDetalles(clave: string, page?: number, size?: number, options?: any): AxiosPromise<CatCatalogoDetalle> {
      return localVarFp.getIdCatalogoDetalles(clave, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idCatCatalogoMaestro
     * @param {string} clave A unique integer value identifying this cat_catalogo_detalle.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatalogoDetallesCatalogosMaestro(
      idCatCatalogoMaestro: string,
      clave: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatCatalogoDetalle> {
      return localVarFp
        .getIdCatalogoDetallesCatalogosMaestro(idCatCatalogoMaestro, clave, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} nombre A unique integer value identifying this cat_catalogo_maestro.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatalogoMaestros(nombre: string, page?: number, size?: number, options?: any): AxiosPromise<CatCatalogoMaestro> {
      return localVarFp.getIdCatalogoMaestros(nombre, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveCodigoPostal
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCodigosPostales(cveCodigoPostal: string, page?: number, size?: number, options?: any): AxiosPromise<CatCodigosPostales> {
      return localVarFp.getIdCodigosPostales(cveCodigoPostal, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoAsentamiento
     * @param {string} cveCodigoPostal A unique integer value identifying this cat_codigos_postales.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCodigosPostalesTipoAsentamientos(
      cveTipoAsentamiento: string,
      cveCodigoPostal: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatCodigosPostales> {
      return localVarFp
        .getIdCodigosPostalesTipoAsentamientos(cveTipoAsentamiento, cveCodigoPostal, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveConsejoCertificador A unique integer value identifying this cat_consejo_certificador.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdConsejoCertificadores(
      cveConsejoCertificador: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatConsejoCertificador> {
      return localVarFp.getIdConsejoCertificadores(cveConsejoCertificador, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveDerechoTransito A unique integer value identifying this cat_derecho_transito.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdDerechoTransitos(cveDerechoTransito: string, page?: number, size?: number, options?: any): AxiosPromise<CatDerechoTransito> {
      return localVarFp.getIdDerechoTransitos(cveDerechoTransito, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idDiaNolabConacyt A unique integer value identifying this cat_dia_nolab_conacyt.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdDiaNolabConacyt(idDiaNolabConacyt: string, page?: number, size?: number, options?: any): AxiosPromise<CatDiaNolabConacyt> {
      return localVarFp.getIdDiaNolabConacyt(idDiaNolabConacyt, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveDictamenEvaluacion A unique integer value identifying this cat_dictamen_evaluacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdDictamenEvaluaciones(
      cveDictamenEvaluacion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatDictamenEvaluacion> {
      return localVarFp.getIdDictamenEvaluaciones(cveDictamenEvaluacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idDisciplina
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdDisciplina(idDisciplina: string, page?: number, size?: number, options?: any): AxiosPromise<CatDisciplina> {
      return localVarFp.getIdDisciplina(idDisciplina, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idCampo
     * @param {string} idDisciplina A unique integer value identifying this cat_disciplina.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdDisciplinasCampos(
      idCampo: string,
      idDisciplina: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatDisciplina> {
      return localVarFp.getIdDisciplinasCampos(idCampo, idDisciplina, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveDistincionConacyt A unique integer value identifying this cat_distincion_conacyt.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdDistincionesConacyt(
      cveDistincionConacyt: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatDistincionConacyt> {
      return localVarFp.getIdDistincionesConacyt(cveDistincionConacyt, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idEspecialidadMedica
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdEspecialidadesMedicas(
      idEspecialidadMedica: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatEspecialidadMedica> {
      return localVarFp.getIdEspecialidadesMedicas(idEspecialidadMedica, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idConsejoCertificador
     * @param {string} idEspecialidadMedica A unique integer value identifying this cat_especialidad_medica.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdEspecialidadesMedicasConsejosCertificadores(
      idConsejoCertificador: string,
      idEspecialidadMedica: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatEspecialidadMedica> {
      return localVarFp
        .getIdEspecialidadesMedicasConsejosCertificadores(idConsejoCertificador, idEspecialidadMedica, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveEstadoCivil A unique integer value identifying this cat_estado_civil.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdEstadoCiviles(cveEstadoCivil: string, page?: number, size?: number, options?: any): AxiosPromise<CatEstadoCivil> {
      return localVarFp.getIdEstadoCiviles(cveEstadoCivil, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveEstadoTesis
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdEstadoTesis(cveEstadoTesis: string, page?: number, size?: number, options?: any): AxiosPromise<CatEstadoTesis> {
      return localVarFp.getIdEstadoTesis(cveEstadoTesis, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveEstatusAcademico A unique integer value identifying this cat_estatus_academico.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdEstatusAcademicos(cveEstatusAcademico: string, page?: number, size?: number, options?: any): AxiosPromise<CatEstatusAcademico> {
      return localVarFp.getIdEstatusAcademicos(cveEstatusAcademico, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idEstatusMovGasto A unique integer value identifying this cat_estatus_mov_gasto.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdEstatusMovGasto(idEstatusMovGasto: string, page?: number, size?: number, options?: any): AxiosPromise<CatEstatusMovGasto> {
      return localVarFp.getIdEstatusMovGasto(idEstatusMovGasto, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idEstatusNotificacion A unique integer value identifying this cat_estatus_notificacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdEstatusNotificacion(
      idEstatusNotificacion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatEstatusNotificacion> {
      return localVarFp.getIdEstatusNotificacion(idEstatusNotificacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveFormaTitulacion A unique integer value identifying this cat_forma_titulacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdFormaTitulaciones(cveFormaTitulacion: string, page?: number, size?: number, options?: any): AxiosPromise<CatFormaTitulacion> {
      return localVarFp.getIdFormaTitulaciones(cveFormaTitulacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idFormatoNotificacion A unique integer value identifying this cat_formato_notificacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdFormatoNotificacion(
      idFormatoNotificacion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatFormatoNotificacion> {
      return localVarFp.getIdFormatoNotificacion(idFormatoNotificacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveGenero A unique integer value identifying this cat_genero.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdGeneros(cveGenero: string, page?: number, size?: number, options?: any): AxiosPromise<CatGenero> {
      return localVarFp.getIdGeneros(cveGenero, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveIdentificaAutor A unique integer value identifying this cat_identifica_autor.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdIdentificaAutores(cveIdentificaAutor: string, page?: number, size?: number, options?: any): AxiosPromise<CatIdentificaAutor> {
      return localVarFp.getIdIdentificaAutores(cveIdentificaAutor, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveIdioma A unique integer value identifying this cat_idioma.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdIdiomas(cveIdioma: string, page?: number, size?: number, options?: any): AxiosPromise<CatIdioma> {
      return localVarFp.getIdIdiomas(cveIdioma, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idEntidad
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdInstitucionesEstados(
      idEntidad: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatInstitucion> {
      return localVarFp.getIdInstitucionesEstados(idEntidad, cveInstitucion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idLocalidad
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdInstitucionesLocalidades(
      idLocalidad: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatInstitucion> {
      return localVarFp
        .getIdInstitucionesLocalidades(idLocalidad, cveInstitucion, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idMunicipio
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdInstitucionesMunicipios(
      idMunicipio: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatInstitucion> {
      return localVarFp
        .getIdInstitucionesMunicipios(idMunicipio, cveInstitucion, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cvePais
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdInstitucionesPaises(
      cvePais: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatInstitucion> {
      return localVarFp.getIdInstitucionesPaises(cvePais, cveInstitucion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoInstNivelDos
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdInstitucionesTipoInstNivelDos(
      idTipoInstNivelDos: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatInstitucion> {
      return localVarFp
        .getIdInstitucionesTipoInstNivelDos(idTipoInstNivelDos, cveInstitucion, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoInstNivelTres
     * @param {string} idInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdInstitucionesTipoInstNivelTres(
      idTipoInstNivelTres: string,
      idInstitucion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatInstitucion> {
      return localVarFp
        .getIdInstitucionesTipoInstNivelTres(idTipoInstNivelTres, idInstitucion, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoInstNivelUno
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdInstitucionesTipoInstNivelUno(
      idTipoInstNivelUno: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatInstitucion> {
      return localVarFp
        .getIdInstitucionesTipoInstNivelUno(idTipoInstNivelUno, cveInstitucion, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoInstitucion
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdInstitucionesTipoInstituciones(
      idTipoInstitucion: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatInstitucion> {
      return localVarFp
        .getIdInstitucionesTipoInstituciones(idTipoInstitucion, cveInstitucion, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveLenguaIndigena A unique integer value identifying this cat_lengua_indigena.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdLenguaIndigenas(cveLenguaIndigena: string, page?: number, size?: number, options?: any): AxiosPromise<CatLenguaIndigena> {
      return localVarFp.getIdLenguaIndigenas(cveLenguaIndigena, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveLocalidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdLocalidadesMunicipiosEstados(
      claveAreaGe: string,
      claveAreaGm: string,
      claveLocalidad: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<LocalidadGeoestadistica> {
      return localVarFp
        .getIdLocalidadesMunicipiosEstados(claveAreaGe, claveAreaGm, claveLocalidad, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveMargen A unique integer value identifying this cat_margen.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdMargenes(cveMargen: string, page?: number, size?: number, options?: any): AxiosPromise<CatMargen> {
      return localVarFp.getIdMargenes(cveMargen, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idMomentoEvento A unique integer value identifying this cat_momento_evento.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdMomentoEvento(idMomentoEvento: string, page?: number, size?: number, options?: any): AxiosPromise<CatMomentoEvento> {
      return localVarFp.getIdMomentoEvento(idMomentoEvento, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdMunicipiosEstado(
      claveAreaGe: string,
      claveAreaGm: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<AreaGeoestadisticasMunicipal> {
      return localVarFp.getIdMunicipiosEstado(claveAreaGe, claveAreaGm, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveNacionalidad A unique integer value identifying this cat_nacionalidad.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdNacionalidades(cveNacionalidad: string, page?: number, size?: number, options?: any): AxiosPromise<CatNacionalidad> {
      return localVarFp.getIdNacionalidades(cveNacionalidad, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idNivelPnpc A unique integer value identifying this cat_nivel_pnpc.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdNivelPnpc(idNivelPnpc: string, page?: number, size?: number, options?: any): AxiosPromise<CatNivelPnpc> {
      return localVarFp.getIdNivelPnpc(idNivelPnpc, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveNivelAcademico A unique integer value identifying this cat_nivel_academico.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdNivelesAcademicos(cveNivelAcademico: string, page?: number, size?: number, options?: any): AxiosPromise<CatNivelAcademico> {
      return localVarFp.getIdNivelesAcademicos(cveNivelAcademico, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveNivelDominioLd A unique integer value identifying this cat_nivel_dominio_ld.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdNivelesDominiosLd(cveNivelDominioLd: string, page?: number, size?: number, options?: any): AxiosPromise<CatNivelDominioLd> {
      return localVarFp.getIdNivelesDominiosLd(cveNivelDominioLd, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idOcdeGrupo
     * @param {string} idOcdeClase A unique integer value identifying this cat_ocde_clase.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdOcdeClasesOcdeGrupos(
      idOcdeGrupo: string,
      idOcdeClase: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatOcdeClase> {
      return localVarFp.getIdOcdeClasesOcdeGrupos(idOcdeGrupo, idOcdeClase, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idOcdeDivision
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdOcdeDivisiones(idOcdeDivision: string, page?: number, size?: number, options?: any): AxiosPromise<CatOcdeDivision> {
      return localVarFp.getIdOcdeDivisiones(idOcdeDivision, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idOcdeSector
     * @param {string} idOcdeDivision A unique integer value identifying this cat_ocde_division.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdOcdeDivisionesOcdeSector(
      idOcdeSector: string,
      idOcdeDivision: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatOcdeDivision> {
      return localVarFp
        .getIdOcdeDivisionesOcdeSector(idOcdeSector, idOcdeDivision, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idOcdeGrupo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdOcdeGrupos(idOcdeGrupo: string, page?: number, size?: number, options?: any): AxiosPromise<CatOcdeGrupo> {
      return localVarFp.getIdOcdeGrupos(idOcdeGrupo, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idOcdeDivision
     * @param {string} idOcdeGrupo A unique integer value identifying this cat_ocde_grupo.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdOcdeGruposOcdeDivisiones(
      idOcdeDivision: string,
      idOcdeGrupo: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatOcdeGrupo> {
      return localVarFp
        .getIdOcdeGruposOcdeDivisiones(idOcdeDivision, idOcdeGrupo, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idOcdeSector A unique integer value identifying this cat_ocde_sector.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdOcdeSectores(idOcdeSector: string, page?: number, size?: number, options?: any): AxiosPromise<CatOcdeSector> {
      return localVarFp.getIdOcdeSectores(idOcdeSector, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idOperacionPago A unique integer value identifying this cat_operacion_pago.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdOperacionPago(idOperacionPago: string, page?: number, size?: number, options?: any): AxiosPromise<CatOperacionPago> {
      return localVarFp.getIdOperacionPago(idOperacionPago, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idOperadorComparacion A unique integer value identifying this cat_operador_comparacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdOperadorComparacion(
      idOperadorComparacion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatOperadorComparacion> {
      return localVarFp.getIdOperadorComparacion(idOperadorComparacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cvePais A unique integer value identifying this cat_pais.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdPaises(cvePais: string, page?: number, size?: number, options?: any): AxiosPromise<CatPais> {
      return localVarFp.getIdPaises(cvePais, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idFormatoNotificacion
     * @param {string} idPalabraClave A unique integer value identifying this cat_palabra_clave.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdPalabraClave(
      idFormatoNotificacion: string,
      idPalabraClave: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatPalabraClave> {
      return localVarFp
        .getIdPalabraClave(idFormatoNotificacion, idPalabraClave, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idParametrosSeguridad A unique integer value identifying this cat_parametros_seguridad.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdParametrosSeguridad(
      idParametrosSeguridad: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatParametrosSeguridad> {
      return localVarFp.getIdParametrosSeguridad(idParametrosSeguridad, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cvePartDivulgacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdPartDivulgacion(cvePartDivulgacion: string, page?: number, size?: number, options?: any): AxiosPromise<CatPartDivulgacion> {
      return localVarFp.getIdPartDivulgacion(cvePartDivulgacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveProductoEvaluacion A unique integer value identifying this cat_producto_evaluacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdProductoEvaluaciones(
      cveProductoEvaluacion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatProductoEvaluacion> {
      return localVarFp.getIdProductoEvaluaciones(cveProductoEvaluacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cvePuebloIndigena A unique integer value identifying this cat_pueblo_indigena.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdPueblosIndigenas(cvePuebloIndigena: string, page?: number, size?: number, options?: any): AxiosPromise<CatPuebloIndigena> {
      return localVarFp.getIdPueblosIndigenas(cvePuebloIndigena, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idRecomdsEvalFomix A unique integer value identifying this cat_recomds_eval_fomix.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdRecomdsEvalFomix(idRecomdsEvalFomix: string, page?: number, size?: number, options?: any): AxiosPromise<CatRecomdsEvalFomix> {
      return localVarFp.getIdRecomdsEvalFomix(idRecomdsEvalFomix, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idRolPersFisProgPnpc A unique integer value identifying this cat_rol_pers_fis_prog_pnpc.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdRolPersFisProgPnpc(
      idRolPersFisProgPnpc: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatRolPersFisProgPnpc> {
      return localVarFp.getIdRolPersFisProgPnpc(idRolPersFisProgPnpc, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idScianGrupo
     * @param {string} idScianClase A unique integer value identifying this cat_scian_clase.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdScianClasesScianGrupos(
      idScianGrupo: string,
      idScianClase: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatScianClase> {
      return localVarFp
        .getIdScianClasesScianGrupos(idScianGrupo, idScianClase, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idScianSector
     * @param {string} idScianDivision A unique integer value identifying this cat_scian_division.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdScianDivisionesScianSectores(
      idScianSector: string,
      idScianDivision: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatScianDivision> {
      return localVarFp
        .getIdScianDivisionesScianSectores(idScianSector, idScianDivision, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idScianGrupo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdScianGrupos(idScianGrupo: string, page?: number, size?: number, options?: any): AxiosPromise<CatScianGrupo> {
      return localVarFp.getIdScianGrupos(idScianGrupo, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idScianDivision
     * @param {string} idScianGrupo A unique integer value identifying this cat_scian_grupo.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdScianGruposScianDivisiones(
      idScianDivision: string,
      idScianGrupo: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatScianGrupo> {
      return localVarFp
        .getIdScianGruposScianDivisiones(idScianDivision, idScianGrupo, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idScianSector A unique integer value identifying this cat_scian_sector.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdScianSectores(idScianSector: string, page?: number, size?: number, options?: any): AxiosPromise<CatScianSector> {
      return localVarFp.getIdScianSectores(idScianSector, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idScianSubclase
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdScianSubclases(idScianSubclase: string, page?: number, size?: number, options?: any): AxiosPromise<CatScianSubclase> {
      return localVarFp.getIdScianSubclases(idScianSubclase, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idScianClase
     * @param {string} idScianSubclase A unique integer value identifying this cat_scian_subclase.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdScianSubclasesScianClases(
      idScianClase: string,
      idScianSubclase: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatScianSubclase> {
      return localVarFp
        .getIdScianSubclasesScianClases(idScianClase, idScianSubclase, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveSectorDivulgacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdSectorDivulgacion(cveSectorDivulgacion: string, page?: number, size?: number, options?: any): AxiosPromise<CatSectorDivulgacion> {
      return localVarFp.getIdSectorDivulgacion(cveSectorDivulgacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idSubdisciplina
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdSubDisciplinas(idSubdisciplina: string, page?: number, size?: number, options?: any): AxiosPromise<CatSubDisciplina> {
      return localVarFp.getIdSubDisciplinas(idSubdisciplina, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idDisciplina
     * @param {string} idSubdisciplina A unique integer value identifying this cat_subdisciplina.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdSubDisciplinasDisciplinas(
      idDisciplina: string,
      idSubdisciplina: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatSubDisciplina> {
      return localVarFp
        .getIdSubDisciplinasDisciplinas(idDisciplina, idSubdisciplina, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTerminoGenerico A unique integer value identifying this cat_termino_generico.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTerminoGenericos(cveTerminoGenerico: string, page?: number, size?: number, options?: any): AxiosPromise<CatTerminoGenerico> {
      return localVarFp.getIdTerminoGenericos(cveTerminoGenerico, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoActDivulgacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoActDivulgacion(
      cveTipoActDivulgacion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatTipoActDivulgacion> {
      return localVarFp.getIdTipoActDivulgacion(cveTipoActDivulgacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoAsentamiento A unique integer value identifying this cat_tipo_asentamiento.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoAsentamientos(cveTipoAsentamiento: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoAsentamiento> {
      return localVarFp.getIdTipoAsentamientos(cveTipoAsentamiento, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoContacto A unique integer value identifying this cat_tipo_contacto.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoContactos(cveTipoContacto: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoContacto> {
      return localVarFp.getIdTipoContactos(cveTipoContacto, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoDiscapacidad A unique integer value identifying this cat_tipo_discapacidad.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoDiscapacidades(cveTipoDiscapacidad: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoDiscapacidad> {
      return localVarFp.getIdTipoDiscapacidades(cveTipoDiscapacidad, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoEstancia A unique integer value identifying this cat_tipo_estancia.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoEstancias(cveTipoEstancia: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoEstancia> {
      return localVarFp.getIdTipoEstancias(cveTipoEstancia, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoEvaluacion A unique integer value identifying this cat_tipo_evaluacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoEvaluaciones(cveTipoEvaluacion: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoEvaluacion> {
      return localVarFp.getIdTipoEvaluaciones(cveTipoEvaluacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoEventoUi A unique integer value identifying this cat_tipo_evento_ui.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoEventoUi(idTipoEventoUi: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoEventoUi> {
      return localVarFp.getIdTipoEventoUi(idTipoEventoUi, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoFormacion A unique integer value identifying this cat_tipo_formacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoFormaciones(cveTipoFormacion: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoFormacion> {
      return localVarFp.getIdTipoFormaciones(cveTipoFormacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoInstNivelDos
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoInstNivelDos(cveTipoInstNivelDos: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoInstNivelDos> {
      return localVarFp.getIdTipoInstNivelDos(cveTipoInstNivelDos, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoInstNivelUno
     * @param {string} cveTipoInstNivelDos A unique integer value identifying this cat_tipo_inst_nivel_dos.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoInstNivelDosTipoInstNivelUno(
      idTipoInstNivelUno: string,
      cveTipoInstNivelDos: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatTipoInstNivelDos> {
      return localVarFp
        .getIdTipoInstNivelDosTipoInstNivelUno(idTipoInstNivelUno, cveTipoInstNivelDos, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoInstitucion
     * @param {string} cveTipoInstNivelUno A unique integer value identifying this cat_tipo_inst_nivel_uno.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoInstNivelUnoTipoInstituciones(
      idTipoInstitucion: string,
      cveTipoInstNivelUno: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatTipoInstNivelUno> {
      return localVarFp
        .getIdTipoInstNivelUnoTipoInstituciones(idTipoInstitucion, cveTipoInstNivelUno, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoInstitucion A unique integer value identifying this cat_tipo_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoInstituciones(cveTipoInstitucion: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoInstitucion> {
      return localVarFp.getIdTipoInstituciones(cveTipoInstitucion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoMedioDivulgacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoMedioDivulgacion(
      cveTipoMedioDivulgacion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatTipoMedioDivulgacion> {
      return localVarFp.getIdTipoMedioDivulgacion(cveTipoMedioDivulgacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoNotificacion A unique integer value identifying this cat_tipo_notificacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoNotificacion(idTipoNotificacion: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoNotificacion> {
      return localVarFp.getIdTipoNotificacion(idTipoNotificacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoPartCongreso
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoPartCongreso(cveTipoPartCongreso: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoPartCongreso> {
      return localVarFp.getIdTipoPartCongreso(cveTipoPartCongreso, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoParticipacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoParticipacion7(cveTipoParticipacion: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoParticipacion> {
      return localVarFp.getIdTipoParticipacion7(cveTipoParticipacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoPertenEtnica A unique integer value identifying this cat_tipo_perten_etnica.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoPertenEtnicas(cveTipoPertenEtnica: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoPertenEtnica> {
      return localVarFp.getIdTipoPertenEtnicas(cveTipoPertenEtnica, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoPublicacion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoPublicacion(cveTipoPublicacion: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoPublicacion> {
      return localVarFp.getIdTipoPublicacion(cveTipoPublicacion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoRiesgo A unique integer value identifying this cat_tipo_riesgo.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoRiesgo(idTipoRiesgo: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoRiesgo> {
      return localVarFp.getIdTipoRiesgo(idTipoRiesgo, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoTrayectoriaProf A unique integer value identifying this cat_tipo_trayectoria_prof.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoTrayectoriasProf(
      cveTipoTrayectoriaProf: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatTipoTrayectoriaProf> {
      return localVarFp.getIdTipoTrayectoriasProf(cveTipoTrayectoriaProf, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoVialidad A unique integer value identifying this cat_tipo_vialidad.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTipoVialidades(cveTipoVialidad: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoVialidad> {
      return localVarFp.getIdTipoVialidades(cveTipoVialidad, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoInstNivelUno
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdTiposInstNivelUno(cveTipoInstNivelUno: string, page?: number, size?: number, options?: any): AxiosPromise<CatTipoInstNivelUno> {
      return localVarFp.getIdTiposInstNivelUno(cveTipoInstNivelUno, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveVariante
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdVarAutodenominaciones(cveVariante: string, page?: number, size?: number, options?: any): AxiosPromise<CatVarAutodenominacion> {
      return localVarFp.getIdVarAutodenominaciones(cveVariante, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idPuebloIndigena
     * @param {string} cveVariante A unique integer value identifying this cat_var_autodenominacion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdVarAutodenominacionesPueblosIndigenas(
      idPuebloIndigena: string,
      cveVariante: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatVarAutodenominacion> {
      return localVarFp
        .getIdVarAutodenominacionesPueblosIndigenas(idPuebloIndigena, cveVariante, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveLocalidad
     * @param {string} claveVialidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdVialidadesLocaliidaadesMunicipiosEstados(
      claveAreaGe: string,
      claveAreaGm: string,
      claveLocalidad: string,
      claveVialidad: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<Vialidades> {
      return localVarFp
        .getIdVialidadesLocaliidaadesMunicipiosEstados(claveAreaGe, claveAreaGm, claveLocalidad, claveVialidad, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdentificaAutores(page?: number, size?: number, options?: any): AxiosPromise<GetIdentificaAutores200Response> {
      return localVarFp.getIdentificaAutores(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoInstNivelTres
     * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdinstitucion(
      idTipoInstNivelTres: string,
      cveInstitucion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatInstitucion> {
      return localVarFp
        .getIdinstitucion(idTipoInstNivelTres, cveInstitucion, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdiomas(page?: number, size?: number, options?: any): AxiosPromise<GetIdiomas200Response> {
      return localVarFp.getIdiomas(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idFuncionalidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdsCatDefinicionLogUis(
      idFuncionalidad: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetCatDefinicionLogUis200Response> {
      return localVarFp.getIdsCatDefinicionLogUis(idFuncionalidad, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstituciones(page?: number, size?: number, options?: any): AxiosPromise<GetInstituciones200Response> {
      return localVarFp.getInstituciones(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idEntidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstitucionesEstaados(idEntidad: string, page?: number, size?: number, options?: any): AxiosPromise<GetInstituciones200Response> {
      return localVarFp.getInstitucionesEstaados(idEntidad, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idLocalidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstitucionesLocalidades(
      idLocalidad: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetInstituciones200Response> {
      return localVarFp.getInstitucionesLocalidades(idLocalidad, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idMunicipio
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstitucionesMunicipios(
      idMunicipio: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetInstituciones200Response> {
      return localVarFp.getInstitucionesMunicipios(idMunicipio, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idPais
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstitucionesPaises(idPais: string, page?: number, size?: number, options?: any): AxiosPromise<GetInstituciones200Response> {
      return localVarFp.getInstitucionesPaises(idPais, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoInstNivelDos
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstitucionesTipoInstNivelDos(
      idTipoInstNivelDos: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetInstituciones200Response> {
      return localVarFp.getInstitucionesTipoInstNivelDos(idTipoInstNivelDos, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoInstNivelTres
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstitucionesTipoInstNivelTres(
      idTipoInstNivelTres: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetInstituciones200Response> {
      return localVarFp
        .getInstitucionesTipoInstNivelTres(idTipoInstNivelTres, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoInstNivelUno
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstitucionesTipoInstNivelUno(
      idTipoInstNivelUno: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetInstituciones200Response> {
      return localVarFp.getInstitucionesTipoInstNivelUno(idTipoInstNivelUno, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoInstitucion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstitucionesTipoInstituciones(
      idTipoInstitucion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetInstituciones200Response> {
      return localVarFp.getInstitucionesTipoInstituciones(idTipoInstitucion, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLenguaIndigenas(page?: number, size?: number, options?: any): AxiosPromise<GetLenguaIndigenas200Response> {
      return localVarFp.getLenguaIndigenas(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocalidadesMunicipiosEstados(
      claveAreaGe: string,
      claveAreaGm: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetLocalidadesMunicipiosEstados200Response> {
      return localVarFp
        .getLocalidadesMunicipiosEstados(claveAreaGe, claveAreaGm, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMargenes(page?: number, size?: number, options?: any): AxiosPromise<GetMargenes200Response> {
      return localVarFp.getMargenes(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMunicipiosEstado(claveAreaGe: string, page?: number, size?: number, options?: any): AxiosPromise<GetMunicipiosEstado200Response> {
      return localVarFp.getMunicipiosEstado(claveAreaGe, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNacionalidades(page?: number, size?: number, options?: any): AxiosPromise<GetNacionalidades200Response> {
      return localVarFp.getNacionalidades(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNivelesAcademicos(page?: number, size?: number, options?: any): AxiosPromise<GetNivelesAcademicos200Response> {
      return localVarFp.getNivelesAcademicos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNivelesDominiosLd(page?: number, size?: number, options?: any): AxiosPromise<GetNivelesDominiosLd200Response> {
      return localVarFp.getNivelesDominiosLd(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOcdeClases(page?: number, size?: number, options?: any): AxiosPromise<GetOcdeClasesOcdeGrupos200Response> {
      return localVarFp.getOcdeClases(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idOcdeGrupo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOcdeClasesOcdeGrupos(
      idOcdeGrupo: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetOcdeClasesOcdeGrupos200Response> {
      return localVarFp.getOcdeClasesOcdeGrupos(idOcdeGrupo, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOcdeDivisiones(page?: number, size?: number, options?: any): AxiosPromise<GetOcdeDivisionesOcdeSector200Response> {
      return localVarFp.getOcdeDivisiones(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idOcdeSector
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOcdeDivisionesOcdeSector(
      idOcdeSector: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetOcdeDivisionesOcdeSector200Response> {
      return localVarFp.getOcdeDivisionesOcdeSector(idOcdeSector, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOcdeGrupos(page?: number, size?: number, options?: any): AxiosPromise<GetOcdeGruposOcdeDivisiones200Response> {
      return localVarFp.getOcdeGrupos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idOcdeDivision
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOcdeGruposOcdeDivisiones(
      idOcdeDivision: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetOcdeGruposOcdeDivisiones200Response> {
      return localVarFp.getOcdeGruposOcdeDivisiones(idOcdeDivision, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOcdeSectores(page?: number, size?: number, options?: any): AxiosPromise<GetOcdeSectores200Response> {
      return localVarFp.getOcdeSectores(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaises(page?: number, size?: number, options?: any): AxiosPromise<GetPaises200Response> {
      return localVarFp.getPaises(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductoEvaluaciones(page?: number, size?: number, options?: any): AxiosPromise<GetProductoEvaluaciones200Response> {
      return localVarFp.getProductoEvaluaciones(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPueblosIndigenas(page?: number, size?: number, options?: any): AxiosPromise<GetPueblosIndigenas200Response> {
      return localVarFp.getPueblosIndigenas(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idPreguntaRespuesta
     * @param {string} idListRespPregunta A unique integer value identifying this cat_list_resp_pregunta.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRespPreguntas(
      idPreguntaRespuesta: string,
      idListRespPregunta: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatListRespPregunta> {
      return localVarFp
        .getRespPreguntas(idPreguntaRespuesta, idListRespPregunta, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoEventoUi
     * @param {string} idDefinicionLogUi
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRetrieveCatDefinicionLogUi(
      idTipoEventoUi: string,
      idDefinicionLogUi: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatDefinicionLogUi> {
      return localVarFp
        .getRetrieveCatDefinicionLogUi(idTipoEventoUi, idDefinicionLogUi, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianClases(page?: number, size?: number, options?: any): AxiosPromise<GetScianClases200Response> {
      return localVarFp.getScianClases(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idScianGrupo
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianClasesScianGrupos(idScianGrupo: string, page?: number, size?: number, options?: any): AxiosPromise<GetScianClases200Response> {
      return localVarFp.getScianClasesScianGrupos(idScianGrupo, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianDivisiones(page?: number, size?: number, options?: any): AxiosPromise<GetScianDivisiones200Response> {
      return localVarFp.getScianDivisiones(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idScianSector
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianDivisionesScianSectores(
      idScianSector: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetScianDivisiones200Response> {
      return localVarFp.getScianDivisionesScianSectores(idScianSector, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianGrupos(page?: number, size?: number, options?: any): AxiosPromise<GetScianGrupos200Response> {
      return localVarFp.getScianGrupos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idScianDivision
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianGruposScianDivisiones(
      idScianDivision: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetScianGrupos200Response> {
      return localVarFp.getScianGruposScianDivisiones(idScianDivision, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianSectores(page?: number, size?: number, options?: any): AxiosPromise<GetScianSectores200Response> {
      return localVarFp.getScianSectores(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianSubclases(page?: number, size?: number, options?: any): AxiosPromise<GetScianSubclases200Response> {
      return localVarFp.getScianSubclases(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idScianClase
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScianSubclasesScianClases(
      idScianClase: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetScianSubclases200Response> {
      return localVarFp.getScianSubclasesScianClases(idScianClase, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubDisciplinas(page?: number, size?: number, options?: any): AxiosPromise<GetSubDisciplinas200Response> {
      return localVarFp.getSubDisciplinas(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idDisciplina
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubDisciplinasDisciplinas(
      idDisciplina: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetSubDisciplinas200Response> {
      return localVarFp.getSubDisciplinasDisciplinas(idDisciplina, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTerminoGenericos(page?: number, size?: number, options?: any): AxiosPromise<GetTerminoGenericos200Response> {
      return localVarFp.getTerminoGenericos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoAsentamientos(page?: number, size?: number, options?: any): AxiosPromise<GetTipoAsentamientos200Response> {
      return localVarFp.getTipoAsentamientos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoContactos(page?: number, size?: number, options?: any): AxiosPromise<GetTipoContactos200Response> {
      return localVarFp.getTipoContactos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoDiscapacidades(page?: number, size?: number, options?: any): AxiosPromise<GetTipoDiscapacidades200Response> {
      return localVarFp.getTipoDiscapacidades(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoEstancias(page?: number, size?: number, options?: any): AxiosPromise<GetTipoEstancias200Response> {
      return localVarFp.getTipoEstancias(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoEvaluaciones(page?: number, size?: number, options?: any): AxiosPromise<GetTipoEvaluaciones200Response> {
      return localVarFp.getTipoEvaluaciones(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoFormaciones(page?: number, size?: number, options?: any): AxiosPromise<GetTipoFormaciones200Response> {
      return localVarFp.getTipoFormaciones(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoInstNivelDos
     * @param {string} idTipoInstNivelTres A unique integer value identifying this cat_tipo_inst_nivel_tres.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoIdsInstNivelTres(
      idTipoInstNivelDos: string,
      idTipoInstNivelTres: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatTipoInstNivelTres> {
      return localVarFp
        .getTipoIdsInstNivelTres(idTipoInstNivelDos, idTipoInstNivelTres, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoInstNivelDos(page?: number, size?: number, options?: any): AxiosPromise<GetTipoInstNivelDos200Response> {
      return localVarFp.getTipoInstNivelDos(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoInstNivelUno
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoInstNivelDosTipoInstNivelUno(
      idTipoInstNivelUno: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetTipoInstNivelDos200Response> {
      return localVarFp
        .getTipoInstNivelDosTipoInstNivelUno(idTipoInstNivelUno, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoInstitucion
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoInstNivelUnoTipoInstituciones(
      idTipoInstitucion: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetTiposInstNivelUno200Response> {
      return localVarFp
        .getTipoInstNivelUnoTipoInstituciones(idTipoInstitucion, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoInstituciones(page?: number, size?: number, options?: any): AxiosPromise<GetTipoInstituciones200Response> {
      return localVarFp.getTipoInstituciones(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoPertenEtnicas(page?: number, size?: number, options?: any): AxiosPromise<GetTipoPertenEtnicas200Response> {
      return localVarFp.getTipoPertenEtnicas(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoTrayectoriasProf(page?: number, size?: number, options?: any): AxiosPromise<GetTipoTrayectoriasProf200Response> {
      return localVarFp.getTipoTrayectoriasProf(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTipoVialidades(page?: number, size?: number, options?: any): AxiosPromise<GetTipoVialidades200Response> {
      return localVarFp.getTipoVialidades(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTiposInstNivelUno(page?: number, size?: number, options?: any): AxiosPromise<GetTiposInstNivelUno200Response> {
      return localVarFp.getTiposInstNivelUno(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVarAutodenominaciones(page?: number, size?: number, options?: any): AxiosPromise<GetVarAutodenominaciones200Response> {
      return localVarFp.getVarAutodenominaciones(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idPuebloIndigena
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVarAutodenominacionesPueblosIndigenas(
      idPuebloIndigena: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetVarAutodenominaciones200Response> {
      return localVarFp
        .getVarAutodenominacionesPueblosIndigenas(idPuebloIndigena, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} claveAreaGe
     * @param {string} claveAreaGm
     * @param {string} claveLocalidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVialidadesLocaliidadesMunicipiosEstados(
      claveAreaGe: string,
      claveAreaGm: string,
      claveLocalidad: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetVialidadesLocaliidadesMunicipiosEstados200Response> {
      return localVarFp
        .getVialidadesLocaliidadesMunicipiosEstados(claveAreaGe, claveAreaGm, claveLocalidad, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idFuncionalidad
     * @param {string} idDefinicionLogUi A unique integer value identifying this cat_definicion_log_ui.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsCatDefinicionLogUi(
      idFuncionalidad: string,
      idDefinicionLogUi: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatDefinicionLogUi> {
      return localVarFp
        .getsCatDefinicionLogUi(idFuncionalidad, idDefinicionLogUi, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoEventoUi
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsCatDefinicionLogUis(
      idTipoEventoUi: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetCatDefinicionLogUis200Response> {
      return localVarFp.getsCatDefinicionLogUis(idTipoEventoUi, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idPreguntaRespuesta
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsCatListRespPreguntas(
      idPreguntaRespuesta: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetCatListRespPreguntas200Response> {
      return localVarFp.getsCatListRespPreguntas(idPreguntaRespuesta, page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsCatPalabraClaves(page?: number, size?: number, options?: any): AxiosPromise<GetCatPalabraClaves200Response> {
      return localVarFp.getsCatPalabraClaves(page, size, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} cveTipoInstNivelUno
     * @param {string} cveTipoInstNivelDos
     * @param {string} cveEntidad
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsCveClaveCatNacionalPublico(
      cveTipoInstNivelUno: string,
      cveTipoInstNivelDos: string,
      cveEntidad: string,
      page?: number,
      size?: number,
      search?: string,
      options?: any
    ): AxiosPromise<CatNacionalPublico> {
      return localVarFp
        .getsCveClaveCatNacionalPublico(cveTipoInstNivelUno, cveTipoInstNivelDos, cveEntidad, page, size, search, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoEventoUi
     * @param {string} idDefinicionLogUi A unique integer value identifying this cat_definicion_log_ui.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsDefinicionLogsUis(
      idTipoEventoUi: string,
      idDefinicionLogUi: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<CatDefinicionLogUi> {
      return localVarFp
        .getsDefinicionLogsUis(idTipoEventoUi, idDefinicionLogUi, page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} idTipoEventoUi
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsIdCatDefinicionLogUis(
      idTipoEventoUi: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GetCatDefinicionLogUis200Response> {
      return localVarFp.getsIdCatDefinicionLogUis(idTipoEventoUi, page, size, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getAdministraciones(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getAdministraciones(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idAmbito
   * @param {string} idVialidad A unique integer value identifying this cat_vialidad.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getAmbitoCatVialidadId(idAmbito: string, idVialidad: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getAmbitoCatVialidadId(idAmbito, idVialidad, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getAmbitos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getAmbitos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getAreaConocimiento(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getAreaConocimiento(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getAreaGeoestadisticaEstatal(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getAreaGeoestadisticaEstatal(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} claveAreaGe
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getAsentamientosHumanosEstados(claveAreaGe: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getAsentamientosHumanosEstados(claveAreaGe, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} claveAreaGe
   * @param {string} claveAreaGm
   * @param {string} claveLocalidad
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getAsentamientosHumanosLocalidadesMunicipiosEstados(
    claveAreaGe: string,
    claveAreaGm: string,
    claveLocalidad: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getAsentamientosHumanosLocalidadesMunicipiosEstados(claveAreaGe, claveAreaGm, claveLocalidad, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} claveAreaGe
   * @param {string} claveAreaGm
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getAsentamientosHumanosMunicipiosEstados(
    claveAreaGe: string,
    claveAreaGm: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getAsentamientosHumanosMunicipiosEstados(claveAreaGe, claveAreaGm, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getBaseCartograficas(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getBaseCartograficas(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} claveAsentamiento
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getBaseCartograficasAsentamientos(claveAsentamiento: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getBaseCartograficasAsentamientos(claveAsentamiento, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCampos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCampos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCaracteristContactos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCaracteristContactos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoContacto
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCaracteristContactosTipoContactos(idTipoContacto: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCaracteristContactosTipoContactos(idTipoContacto, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveAreaConocimiento
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatCamposAreasConocimiento(cveAreaConocimiento: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatCamposAreasConocimiento(cveAreaConocimiento, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatClasificacionPatentes(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatClasificacionPatentes(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} codigo
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatCodigosPostalesFilter(codigo: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatCodigosPostalesFilter(codigo, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatDefinicionLogUis(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatDefinicionLogUis(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatDiaNolabConacyts(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatDiaNolabConacyts(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idCampo
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatDisciplinasCampos(idCampo: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatDisciplinasCampos(idCampo, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatEstadoPatentes(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatEstadoPatentes(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatEstadoTesis(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatEstadoTesis(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatEstatusMigratorios(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatEstatusMigratorios(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatEstatusMovGastos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatEstatusMovGastos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatEstatusNotificacions(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatEstatusNotificacions(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatEtapaDesarrollos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatEtapaDesarrollos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatFormatoNotificacions(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatFormatoNotificacions(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoInstNivelTres
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatInstitucions(idTipoInstNivelTres: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatInstitucions(idTipoInstNivelTres, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatListRespPreguntas(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatListRespPreguntas(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatMomentoEventos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatMomentoEventos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatNivelPnpcs(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatNivelPnpcs(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatOperacionPagos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatOperacionPagos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatOperadorComparacions(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatOperadorComparacions(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatOrigenReportes(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatOrigenReportes(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idFormatoNotificacion
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatPalabraClaves(idFormatoNotificacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatPalabraClaves(idFormatoNotificacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatParametrosSeguridads(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatParametrosSeguridads(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatPartDivulgacions(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatPartDivulgacions(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatProductoDivulgacions(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatProductoDivulgacions(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatRecomdsEvalFomixs(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatRecomdsEvalFomixs(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatRedTematicaConacyts(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatRedTematicaConacyts(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatRolPersFisProgPnpcs(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatRolPersFisProgPnpcs(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatSectorDivulgacions(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatSectorDivulgacions(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoActDivulgacions(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoActDivulgacions(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoDesarrollos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoDesarrollos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoDoctoRespaldos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoDoctoRespaldos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoDomicilios(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoDomicilios(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoEventoUis(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoEventoUis(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoFondos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoFondos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoInnovacionOslos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoInnovacionOslos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoInstNivelTres(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoInstNivelTres(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoMedioDivulgacions(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoMedioDivulgacions(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoNotificacions(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoNotificacions(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoPartCongresos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoPartCongresos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoParticipacions(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoParticipacions(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoParticipante
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoParticipante(cveTipoParticipante: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoParticipante(cveTipoParticipante, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoParticipantes(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoParticipantes(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoPatentes(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoPatentes(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoProteccions(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoProteccions(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoProyectos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoProyectos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoPublicacions(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoPublicacions(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoRiesgos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoRiesgos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoSoftwares(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoSoftwares(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTipoTransferencias(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTipoTransferencias(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoInstNivelDos
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatTiposInstNivelTres(idTipoInstNivelDos: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatTiposInstNivelTres(idTipoInstNivelDos, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatalogoDetalles(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatalogoDetalles(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idCatCatalogoMaestro
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatalogoDetallesCatalogosMaestro(idCatCatalogoMaestro: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatalogoDetallesCatalogosMaestro(idCatCatalogoMaestro, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCatalogoMaestros(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCatalogoMaestros(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveClasificacionPatente
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getClaveCatClasificacionPatente(cveClasificacionPatente: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getClaveCatClasificacionPatente(cveClasificacionPatente, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveEstadoPatente
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getClaveCatEstadoPatente(cveEstadoPatente: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getClaveCatEstadoPatente(cveEstadoPatente, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveEtapaDesarrollo
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getClaveCatEtapaDesarrollo(cveEtapaDesarrollo: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getClaveCatEtapaDesarrollo(cveEtapaDesarrollo, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cvePais
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {string} [search] A search term.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getClaveCatExtranjeraPais(cvePais: string, page?: number, size?: number, search?: string, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getClaveCatExtranjeraPais(cvePais, page, size, search, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoProteccion
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getClaveCatTipoProteccion(cveTipoProteccion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getClaveCatTipoProteccion(cveTipoProteccion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoInstNivelUno
   * @param {string} cveTipoInstNivelDos
   * @param {string} cveEntidad
   * @param {string} cveMunicipio
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {string} [search] A search term.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getClaveCveCatNacionalPublico(
    cveTipoInstNivelUno: string,
    cveTipoInstNivelDos: string,
    cveEntidad: string,
    cveMunicipio: string,
    page?: number,
    size?: number,
    search?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getClaveCveCatNacionalPublico(cveTipoInstNivelUno, cveTipoInstNivelDos, cveEntidad, cveMunicipio, page, size, search, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCodigosPostales(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCodigosPostales(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoAsentamiento
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCodigosPostalesTipoAsentamientos(cveTipoAsentamiento: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCodigosPostalesTipoAsentamientos(cveTipoAsentamiento, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getConsejoCertificadores(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getConsejoCertificadores(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveAreaConocimiento
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCveCatMotivacioSnis(cveAreaConocimiento: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCveCatMotivacioSnis(cveAreaConocimiento, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoInstNivelUno
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {string} [search] A search term.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCveCatNacionalPublico(
    cveTipoInstNivelUno: string,
    page?: number,
    size?: number,
    search?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getCveCatNacionalPublico(cveTipoInstNivelUno, page, size, search, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoDesarrollo
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCveCatTipoDesarrollo(cveTipoDesarrollo: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCveCatTipoDesarrollo(cveTipoDesarrollo, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoInnovacionOslo
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCveCatTipoInnovacionOslo(cveTipoInnovacionOslo: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCveCatTipoInnovacionOslo(cveTipoInnovacionOslo, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoPatente
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCveCatTipoPatente(cveTipoPatente: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCveCatTipoPatente(cveTipoPatente, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoSoftware
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCveCatTipoSoftware(cveTipoSoftware: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCveCatTipoSoftware(cveTipoSoftware, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoTransferencia
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCveCatTipoTransferencia(cveTipoTransferencia: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getCveCatTipoTransferencia(cveTipoTransferencia, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoInstNivelUno
   * @param {string} cveTipoInstNivelDos
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {string} [search] A search term.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCveClaveCatNacionalPublico(
    cveTipoInstNivelUno: string,
    cveTipoInstNivelDos: string,
    page?: number,
    size?: number,
    search?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getCveClaveCatNacionalPublico(cveTipoInstNivelUno, cveTipoInstNivelDos, page, size, search, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveAreaConocimiento
   * @param {string} idMotivacionSni A unique integer value identifying this cat_motivacion_sni.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getCveIdCatMotivacioSnis(
    cveAreaConocimiento: string,
    idMotivacionSni: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getCveIdCatMotivacioSnis(cveAreaConocimiento, idMotivacionSni, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getDerechoTransitos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getDerechoTransitos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getDictamenEvaluaciones(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getDictamenEvaluaciones(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getDisciplinas(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getDisciplinas(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getDistincionesConacyt(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getDistincionesConacyt(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getEspecialidadesMedicas(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getEspecialidadesMedicas(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idConsejoCertificador
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getEspecialidadesMedicasConsejosCertificadores(
    idConsejoCertificador: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getEspecialidadesMedicasConsejosCertificadores(idConsejoCertificador, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getEstadoCiviles(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getEstadoCiviles(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getEstatusAcademicos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getEstatusAcademicos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getFormaTitulaciones(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getFormaTitulaciones(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getGeneros(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getGeneros(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveAdministracion A unique integer value identifying this cat_administracion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdAdministraciones(cveAdministracion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdAdministraciones(cveAdministracion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveAmbito A unique integer value identifying this cat_ambito.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdAmbitos(cveAmbito: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdAmbitos(cveAmbito, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} indVersion A unique integer value identifying this cat_area_conocimiento.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdAreaConocimiento(indVersion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdAreaConocimiento(indVersion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} claveAreaGev
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdAreaGeoestadisticaEstatal(claveAreaGev: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdAreaGeoestadisticaEstatal(claveAreaGev, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} claveAreaGe
   * @param {string} claveAsentamiento
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdAsentamientosHumanosEstados(
    claveAreaGe: string,
    claveAsentamiento: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdAsentamientosHumanosEstados(claveAreaGe, claveAsentamiento, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} claveAreaGe
   * @param {string} claveAreaGm
   * @param {string} claveLocalidad
   * @param {string} claveAsentamiento
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdAsentamientosHumanosLocalidadesMunicipiosEstados(
    claveAreaGe: string,
    claveAreaGm: string,
    claveLocalidad: string,
    claveAsentamiento: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdAsentamientosHumanosLocalidadesMunicipiosEstados(
        claveAreaGe,
        claveAreaGm,
        claveLocalidad,
        claveAsentamiento,
        page,
        size,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} claveAreaGe
   * @param {string} claveAreaGm
   * @param {string} claveAsentamiento
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdAsentamientosHumanosMunicipiosEstados(
    claveAreaGe: string,
    claveAreaGm: string,
    claveAsentamiento: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdAsentamientosHumanosMunicipiosEstados(claveAreaGe, claveAreaGm, claveAsentamiento, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveCampo
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCampos(cveCampo: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCampos(cveCampo, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveCaracteristContacto
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCaracteristContactos(cveCaracteristContacto: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCaracteristContactos(cveCaracteristContacto, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoContacto
   * @param {string} cveCaracteristContacto A unique integer value identifying this cat_caracterist_contacto.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCaracteristContactosTipoContactos(
    idTipoContacto: string,
    cveCaracteristContacto: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdCaracteristContactosTipoContactos(idTipoContacto, cveCaracteristContacto, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveAreaConocimiento
   * @param {string} idCampo A unique integer value identifying this cat_campo.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatCamposAreasConocimiento(
    cveAreaConocimiento: string,
    idCampo: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdCatCamposAreasConocimiento(cveAreaConocimiento, idCampo, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idDefinicionLogUi
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatDefinicionLogUi(idDefinicionLogUi: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCatDefinicionLogUi(idDefinicionLogUi, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveEstatusMigratorio
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatEstatusMigratorio(cveEstatusMigratorio: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCatEstatusMigratorio(cveEstatusMigratorio, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idInstitucion
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatInstituciones(idInstitucion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCatInstituciones(idInstitucion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveOrigenReporte
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatOrigenReporte(cveOrigenReporte: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCatOrigenReporte(cveOrigenReporte, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idPalabraClave
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatPalabraClave(idPalabraClave: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCatPalabraClave(idPalabraClave, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveProductoDivulgacion
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatProductoDivulgacion(cveProductoDivulgacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCatProductoDivulgacion(cveProductoDivulgacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveRedTematicaConacyt
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatRedTematicaConacyt(cveRedTematicaConacyt: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCatRedTematicaConacyt(cveRedTematicaConacyt, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoDoctoRespaldo
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatTipoDoctoRespaldo(cveTipoDoctoRespaldo: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCatTipoDoctoRespaldo(cveTipoDoctoRespaldo, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoDomicilio
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatTipoDomicilio(cveTipoDomicilio: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCatTipoDomicilio(cveTipoDomicilio, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoFondo
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatTipoFondo(cveTipoFondo: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCatTipoFondo(cveTipoFondo, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoInstNivelTres
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatTipoInstNivelTres(cveTipoInstNivelTres: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCatTipoInstNivelTres(cveTipoInstNivelTres, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoProyecto
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatTipoProyecto(cveTipoProyecto: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCatTipoProyecto(cveTipoProyecto, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idAmbito
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatVialidads(idAmbito: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCatVialidads(idAmbito, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} clave
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatalogoDetalles(clave: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCatalogoDetalles(clave, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idCatCatalogoMaestro
   * @param {string} clave A unique integer value identifying this cat_catalogo_detalle.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatalogoDetallesCatalogosMaestro(
    idCatCatalogoMaestro: string,
    clave: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdCatalogoDetallesCatalogosMaestro(idCatCatalogoMaestro, clave, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} nombre A unique integer value identifying this cat_catalogo_maestro.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCatalogoMaestros(nombre: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCatalogoMaestros(nombre, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveCodigoPostal
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCodigosPostales(cveCodigoPostal: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdCodigosPostales(cveCodigoPostal, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoAsentamiento
   * @param {string} cveCodigoPostal A unique integer value identifying this cat_codigos_postales.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdCodigosPostalesTipoAsentamientos(
    cveTipoAsentamiento: string,
    cveCodigoPostal: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdCodigosPostalesTipoAsentamientos(cveTipoAsentamiento, cveCodigoPostal, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveConsejoCertificador A unique integer value identifying this cat_consejo_certificador.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdConsejoCertificadores(cveConsejoCertificador: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdConsejoCertificadores(cveConsejoCertificador, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveDerechoTransito A unique integer value identifying this cat_derecho_transito.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdDerechoTransitos(cveDerechoTransito: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdDerechoTransitos(cveDerechoTransito, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idDiaNolabConacyt A unique integer value identifying this cat_dia_nolab_conacyt.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdDiaNolabConacyt(idDiaNolabConacyt: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdDiaNolabConacyt(idDiaNolabConacyt, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveDictamenEvaluacion A unique integer value identifying this cat_dictamen_evaluacion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdDictamenEvaluaciones(cveDictamenEvaluacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdDictamenEvaluaciones(cveDictamenEvaluacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idDisciplina
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdDisciplina(idDisciplina: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdDisciplina(idDisciplina, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idCampo
   * @param {string} idDisciplina A unique integer value identifying this cat_disciplina.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdDisciplinasCampos(idCampo: string, idDisciplina: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdDisciplinasCampos(idCampo, idDisciplina, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveDistincionConacyt A unique integer value identifying this cat_distincion_conacyt.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdDistincionesConacyt(cveDistincionConacyt: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdDistincionesConacyt(cveDistincionConacyt, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idEspecialidadMedica
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdEspecialidadesMedicas(idEspecialidadMedica: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdEspecialidadesMedicas(idEspecialidadMedica, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idConsejoCertificador
   * @param {string} idEspecialidadMedica A unique integer value identifying this cat_especialidad_medica.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdEspecialidadesMedicasConsejosCertificadores(
    idConsejoCertificador: string,
    idEspecialidadMedica: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdEspecialidadesMedicasConsejosCertificadores(idConsejoCertificador, idEspecialidadMedica, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveEstadoCivil A unique integer value identifying this cat_estado_civil.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdEstadoCiviles(cveEstadoCivil: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdEstadoCiviles(cveEstadoCivil, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveEstadoTesis
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdEstadoTesis(cveEstadoTesis: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdEstadoTesis(cveEstadoTesis, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveEstatusAcademico A unique integer value identifying this cat_estatus_academico.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdEstatusAcademicos(cveEstatusAcademico: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdEstatusAcademicos(cveEstatusAcademico, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idEstatusMovGasto A unique integer value identifying this cat_estatus_mov_gasto.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdEstatusMovGasto(idEstatusMovGasto: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdEstatusMovGasto(idEstatusMovGasto, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idEstatusNotificacion A unique integer value identifying this cat_estatus_notificacion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdEstatusNotificacion(idEstatusNotificacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdEstatusNotificacion(idEstatusNotificacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveFormaTitulacion A unique integer value identifying this cat_forma_titulacion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdFormaTitulaciones(cveFormaTitulacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdFormaTitulaciones(cveFormaTitulacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idFormatoNotificacion A unique integer value identifying this cat_formato_notificacion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdFormatoNotificacion(idFormatoNotificacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdFormatoNotificacion(idFormatoNotificacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveGenero A unique integer value identifying this cat_genero.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdGeneros(cveGenero: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdGeneros(cveGenero, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveIdentificaAutor A unique integer value identifying this cat_identifica_autor.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdIdentificaAutores(cveIdentificaAutor: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdIdentificaAutores(cveIdentificaAutor, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveIdioma A unique integer value identifying this cat_idioma.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdIdiomas(cveIdioma: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdIdiomas(cveIdioma, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idEntidad
   * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdInstitucionesEstados(idEntidad: string, cveInstitucion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdInstitucionesEstados(idEntidad, cveInstitucion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idLocalidad
   * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdInstitucionesLocalidades(
    idLocalidad: string,
    cveInstitucion: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdInstitucionesLocalidades(idLocalidad, cveInstitucion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idMunicipio
   * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdInstitucionesMunicipios(
    idMunicipio: string,
    cveInstitucion: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdInstitucionesMunicipios(idMunicipio, cveInstitucion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cvePais
   * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdInstitucionesPaises(cvePais: string, cveInstitucion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdInstitucionesPaises(cvePais, cveInstitucion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoInstNivelDos
   * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdInstitucionesTipoInstNivelDos(
    idTipoInstNivelDos: string,
    cveInstitucion: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdInstitucionesTipoInstNivelDos(idTipoInstNivelDos, cveInstitucion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoInstNivelTres
   * @param {string} idInstitucion A unique integer value identifying this cat_institucion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdInstitucionesTipoInstNivelTres(
    idTipoInstNivelTres: string,
    idInstitucion: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdInstitucionesTipoInstNivelTres(idTipoInstNivelTres, idInstitucion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoInstNivelUno
   * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdInstitucionesTipoInstNivelUno(
    idTipoInstNivelUno: string,
    cveInstitucion: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdInstitucionesTipoInstNivelUno(idTipoInstNivelUno, cveInstitucion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoInstitucion
   * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdInstitucionesTipoInstituciones(
    idTipoInstitucion: string,
    cveInstitucion: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdInstitucionesTipoInstituciones(idTipoInstitucion, cveInstitucion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveLenguaIndigena A unique integer value identifying this cat_lengua_indigena.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdLenguaIndigenas(cveLenguaIndigena: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdLenguaIndigenas(cveLenguaIndigena, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} claveAreaGe
   * @param {string} claveAreaGm
   * @param {string} claveLocalidad
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdLocalidadesMunicipiosEstados(
    claveAreaGe: string,
    claveAreaGm: string,
    claveLocalidad: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdLocalidadesMunicipiosEstados(claveAreaGe, claveAreaGm, claveLocalidad, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveMargen A unique integer value identifying this cat_margen.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdMargenes(cveMargen: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdMargenes(cveMargen, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idMomentoEvento A unique integer value identifying this cat_momento_evento.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdMomentoEvento(idMomentoEvento: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdMomentoEvento(idMomentoEvento, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} claveAreaGe
   * @param {string} claveAreaGm
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdMunicipiosEstado(claveAreaGe: string, claveAreaGm: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdMunicipiosEstado(claveAreaGe, claveAreaGm, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveNacionalidad A unique integer value identifying this cat_nacionalidad.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdNacionalidades(cveNacionalidad: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdNacionalidades(cveNacionalidad, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idNivelPnpc A unique integer value identifying this cat_nivel_pnpc.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdNivelPnpc(idNivelPnpc: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdNivelPnpc(idNivelPnpc, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveNivelAcademico A unique integer value identifying this cat_nivel_academico.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdNivelesAcademicos(cveNivelAcademico: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdNivelesAcademicos(cveNivelAcademico, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveNivelDominioLd A unique integer value identifying this cat_nivel_dominio_ld.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdNivelesDominiosLd(cveNivelDominioLd: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdNivelesDominiosLd(cveNivelDominioLd, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idOcdeGrupo
   * @param {string} idOcdeClase A unique integer value identifying this cat_ocde_clase.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdOcdeClasesOcdeGrupos(idOcdeGrupo: string, idOcdeClase: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdOcdeClasesOcdeGrupos(idOcdeGrupo, idOcdeClase, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idOcdeDivision
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdOcdeDivisiones(idOcdeDivision: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdOcdeDivisiones(idOcdeDivision, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idOcdeSector
   * @param {string} idOcdeDivision A unique integer value identifying this cat_ocde_division.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdOcdeDivisionesOcdeSector(
    idOcdeSector: string,
    idOcdeDivision: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdOcdeDivisionesOcdeSector(idOcdeSector, idOcdeDivision, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idOcdeGrupo
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdOcdeGrupos(idOcdeGrupo: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdOcdeGrupos(idOcdeGrupo, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idOcdeDivision
   * @param {string} idOcdeGrupo A unique integer value identifying this cat_ocde_grupo.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdOcdeGruposOcdeDivisiones(
    idOcdeDivision: string,
    idOcdeGrupo: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdOcdeGruposOcdeDivisiones(idOcdeDivision, idOcdeGrupo, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idOcdeSector A unique integer value identifying this cat_ocde_sector.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdOcdeSectores(idOcdeSector: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdOcdeSectores(idOcdeSector, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idOperacionPago A unique integer value identifying this cat_operacion_pago.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdOperacionPago(idOperacionPago: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdOperacionPago(idOperacionPago, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idOperadorComparacion A unique integer value identifying this cat_operador_comparacion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdOperadorComparacion(idOperadorComparacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdOperadorComparacion(idOperadorComparacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cvePais A unique integer value identifying this cat_pais.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdPaises(cvePais: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdPaises(cvePais, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idFormatoNotificacion
   * @param {string} idPalabraClave A unique integer value identifying this cat_palabra_clave.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdPalabraClave(
    idFormatoNotificacion: string,
    idPalabraClave: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdPalabraClave(idFormatoNotificacion, idPalabraClave, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idParametrosSeguridad A unique integer value identifying this cat_parametros_seguridad.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdParametrosSeguridad(idParametrosSeguridad: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdParametrosSeguridad(idParametrosSeguridad, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cvePartDivulgacion
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdPartDivulgacion(cvePartDivulgacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdPartDivulgacion(cvePartDivulgacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveProductoEvaluacion A unique integer value identifying this cat_producto_evaluacion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdProductoEvaluaciones(cveProductoEvaluacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdProductoEvaluaciones(cveProductoEvaluacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cvePuebloIndigena A unique integer value identifying this cat_pueblo_indigena.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdPueblosIndigenas(cvePuebloIndigena: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdPueblosIndigenas(cvePuebloIndigena, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idRecomdsEvalFomix A unique integer value identifying this cat_recomds_eval_fomix.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdRecomdsEvalFomix(idRecomdsEvalFomix: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdRecomdsEvalFomix(idRecomdsEvalFomix, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idRolPersFisProgPnpc A unique integer value identifying this cat_rol_pers_fis_prog_pnpc.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdRolPersFisProgPnpc(idRolPersFisProgPnpc: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdRolPersFisProgPnpc(idRolPersFisProgPnpc, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idScianGrupo
   * @param {string} idScianClase A unique integer value identifying this cat_scian_clase.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdScianClasesScianGrupos(
    idScianGrupo: string,
    idScianClase: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdScianClasesScianGrupos(idScianGrupo, idScianClase, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idScianSector
   * @param {string} idScianDivision A unique integer value identifying this cat_scian_division.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdScianDivisionesScianSectores(
    idScianSector: string,
    idScianDivision: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdScianDivisionesScianSectores(idScianSector, idScianDivision, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idScianGrupo
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdScianGrupos(idScianGrupo: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdScianGrupos(idScianGrupo, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idScianDivision
   * @param {string} idScianGrupo A unique integer value identifying this cat_scian_grupo.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdScianGruposScianDivisiones(
    idScianDivision: string,
    idScianGrupo: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdScianGruposScianDivisiones(idScianDivision, idScianGrupo, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idScianSector A unique integer value identifying this cat_scian_sector.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdScianSectores(idScianSector: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdScianSectores(idScianSector, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idScianSubclase
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdScianSubclases(idScianSubclase: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdScianSubclases(idScianSubclase, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idScianClase
   * @param {string} idScianSubclase A unique integer value identifying this cat_scian_subclase.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdScianSubclasesScianClases(
    idScianClase: string,
    idScianSubclase: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdScianSubclasesScianClases(idScianClase, idScianSubclase, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveSectorDivulgacion
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdSectorDivulgacion(cveSectorDivulgacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdSectorDivulgacion(cveSectorDivulgacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idSubdisciplina
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdSubDisciplinas(idSubdisciplina: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdSubDisciplinas(idSubdisciplina, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idDisciplina
   * @param {string} idSubdisciplina A unique integer value identifying this cat_subdisciplina.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdSubDisciplinasDisciplinas(
    idDisciplina: string,
    idSubdisciplina: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdSubDisciplinasDisciplinas(idDisciplina, idSubdisciplina, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTerminoGenerico A unique integer value identifying this cat_termino_generico.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTerminoGenericos(cveTerminoGenerico: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTerminoGenericos(cveTerminoGenerico, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoActDivulgacion
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoActDivulgacion(cveTipoActDivulgacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoActDivulgacion(cveTipoActDivulgacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoAsentamiento A unique integer value identifying this cat_tipo_asentamiento.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoAsentamientos(cveTipoAsentamiento: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoAsentamientos(cveTipoAsentamiento, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoContacto A unique integer value identifying this cat_tipo_contacto.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoContactos(cveTipoContacto: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoContactos(cveTipoContacto, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoDiscapacidad A unique integer value identifying this cat_tipo_discapacidad.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoDiscapacidades(cveTipoDiscapacidad: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoDiscapacidades(cveTipoDiscapacidad, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoEstancia A unique integer value identifying this cat_tipo_estancia.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoEstancias(cveTipoEstancia: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoEstancias(cveTipoEstancia, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoEvaluacion A unique integer value identifying this cat_tipo_evaluacion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoEvaluaciones(cveTipoEvaluacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoEvaluaciones(cveTipoEvaluacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoEventoUi A unique integer value identifying this cat_tipo_evento_ui.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoEventoUi(idTipoEventoUi: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoEventoUi(idTipoEventoUi, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoFormacion A unique integer value identifying this cat_tipo_formacion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoFormaciones(cveTipoFormacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoFormaciones(cveTipoFormacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoInstNivelDos
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoInstNivelDos(cveTipoInstNivelDos: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoInstNivelDos(cveTipoInstNivelDos, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoInstNivelUno
   * @param {string} cveTipoInstNivelDos A unique integer value identifying this cat_tipo_inst_nivel_dos.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoInstNivelDosTipoInstNivelUno(
    idTipoInstNivelUno: string,
    cveTipoInstNivelDos: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdTipoInstNivelDosTipoInstNivelUno(idTipoInstNivelUno, cveTipoInstNivelDos, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoInstitucion
   * @param {string} cveTipoInstNivelUno A unique integer value identifying this cat_tipo_inst_nivel_uno.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoInstNivelUnoTipoInstituciones(
    idTipoInstitucion: string,
    cveTipoInstNivelUno: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdTipoInstNivelUnoTipoInstituciones(idTipoInstitucion, cveTipoInstNivelUno, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoInstitucion A unique integer value identifying this cat_tipo_institucion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoInstituciones(cveTipoInstitucion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoInstituciones(cveTipoInstitucion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoMedioDivulgacion
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoMedioDivulgacion(cveTipoMedioDivulgacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoMedioDivulgacion(cveTipoMedioDivulgacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoNotificacion A unique integer value identifying this cat_tipo_notificacion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoNotificacion(idTipoNotificacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoNotificacion(idTipoNotificacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoPartCongreso
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoPartCongreso(cveTipoPartCongreso: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoPartCongreso(cveTipoPartCongreso, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoParticipacion
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoParticipacion7(cveTipoParticipacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoParticipacion7(cveTipoParticipacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoPertenEtnica A unique integer value identifying this cat_tipo_perten_etnica.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoPertenEtnicas(cveTipoPertenEtnica: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoPertenEtnicas(cveTipoPertenEtnica, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoPublicacion
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoPublicacion(cveTipoPublicacion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoPublicacion(cveTipoPublicacion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoRiesgo A unique integer value identifying this cat_tipo_riesgo.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoRiesgo(idTipoRiesgo: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoRiesgo(idTipoRiesgo, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoTrayectoriaProf A unique integer value identifying this cat_tipo_trayectoria_prof.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoTrayectoriasProf(cveTipoTrayectoriaProf: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoTrayectoriasProf(cveTipoTrayectoriaProf, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoVialidad A unique integer value identifying this cat_tipo_vialidad.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTipoVialidades(cveTipoVialidad: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTipoVialidades(cveTipoVialidad, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoInstNivelUno
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdTiposInstNivelUno(cveTipoInstNivelUno: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdTiposInstNivelUno(cveTipoInstNivelUno, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveVariante
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdVarAutodenominaciones(cveVariante: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdVarAutodenominaciones(cveVariante, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idPuebloIndigena
   * @param {string} cveVariante A unique integer value identifying this cat_var_autodenominacion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdVarAutodenominacionesPueblosIndigenas(
    idPuebloIndigena: string,
    cveVariante: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdVarAutodenominacionesPueblosIndigenas(idPuebloIndigena, cveVariante, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} claveAreaGe
   * @param {string} claveAreaGm
   * @param {string} claveLocalidad
   * @param {string} claveVialidad
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdVialidadesLocaliidaadesMunicipiosEstados(
    claveAreaGe: string,
    claveAreaGm: string,
    claveLocalidad: string,
    claveVialidad: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getIdVialidadesLocaliidaadesMunicipiosEstados(claveAreaGe, claveAreaGm, claveLocalidad, claveVialidad, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdentificaAutores(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdentificaAutores(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoInstNivelTres
   * @param {string} cveInstitucion A unique integer value identifying this cat_institucion.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdinstitucion(idTipoInstNivelTres: string, cveInstitucion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdinstitucion(idTipoInstNivelTres, cveInstitucion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdiomas(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdiomas(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idFuncionalidad
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getIdsCatDefinicionLogUis(idFuncionalidad: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getIdsCatDefinicionLogUis(idFuncionalidad, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getInstituciones(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getInstituciones(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idEntidad
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getInstitucionesEstaados(idEntidad: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getInstitucionesEstaados(idEntidad, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idLocalidad
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getInstitucionesLocalidades(idLocalidad: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getInstitucionesLocalidades(idLocalidad, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idMunicipio
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getInstitucionesMunicipios(idMunicipio: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getInstitucionesMunicipios(idMunicipio, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idPais
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getInstitucionesPaises(idPais: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getInstitucionesPaises(idPais, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoInstNivelDos
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getInstitucionesTipoInstNivelDos(idTipoInstNivelDos: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getInstitucionesTipoInstNivelDos(idTipoInstNivelDos, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoInstNivelTres
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getInstitucionesTipoInstNivelTres(idTipoInstNivelTres: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getInstitucionesTipoInstNivelTres(idTipoInstNivelTres, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoInstNivelUno
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getInstitucionesTipoInstNivelUno(idTipoInstNivelUno: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getInstitucionesTipoInstNivelUno(idTipoInstNivelUno, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoInstitucion
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getInstitucionesTipoInstituciones(idTipoInstitucion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getInstitucionesTipoInstituciones(idTipoInstitucion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getLenguaIndigenas(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getLenguaIndigenas(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} claveAreaGe
   * @param {string} claveAreaGm
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getLocalidadesMunicipiosEstados(
    claveAreaGe: string,
    claveAreaGm: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getLocalidadesMunicipiosEstados(claveAreaGe, claveAreaGm, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getMargenes(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getMargenes(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} claveAreaGe
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getMunicipiosEstado(claveAreaGe: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getMunicipiosEstado(claveAreaGe, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getNacionalidades(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getNacionalidades(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getNivelesAcademicos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getNivelesAcademicos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getNivelesDominiosLd(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getNivelesDominiosLd(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getOcdeClases(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getOcdeClases(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idOcdeGrupo
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getOcdeClasesOcdeGrupos(idOcdeGrupo: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getOcdeClasesOcdeGrupos(idOcdeGrupo, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getOcdeDivisiones(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getOcdeDivisiones(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idOcdeSector
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getOcdeDivisionesOcdeSector(idOcdeSector: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getOcdeDivisionesOcdeSector(idOcdeSector, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getOcdeGrupos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getOcdeGrupos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idOcdeDivision
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getOcdeGruposOcdeDivisiones(idOcdeDivision: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getOcdeGruposOcdeDivisiones(idOcdeDivision, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getOcdeSectores(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getOcdeSectores(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getPaises(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getPaises(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getProductoEvaluaciones(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getProductoEvaluaciones(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getPueblosIndigenas(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getPueblosIndigenas(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idPreguntaRespuesta
   * @param {string} idListRespPregunta A unique integer value identifying this cat_list_resp_pregunta.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getRespPreguntas(
    idPreguntaRespuesta: string,
    idListRespPregunta: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getRespPreguntas(idPreguntaRespuesta, idListRespPregunta, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoEventoUi
   * @param {string} idDefinicionLogUi
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getRetrieveCatDefinicionLogUi(
    idTipoEventoUi: string,
    idDefinicionLogUi: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getRetrieveCatDefinicionLogUi(idTipoEventoUi, idDefinicionLogUi, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getScianClases(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getScianClases(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idScianGrupo
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getScianClasesScianGrupos(idScianGrupo: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getScianClasesScianGrupos(idScianGrupo, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getScianDivisiones(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getScianDivisiones(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idScianSector
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getScianDivisionesScianSectores(idScianSector: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getScianDivisionesScianSectores(idScianSector, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getScianGrupos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getScianGrupos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idScianDivision
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getScianGruposScianDivisiones(idScianDivision: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getScianGruposScianDivisiones(idScianDivision, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getScianSectores(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getScianSectores(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getScianSubclases(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getScianSubclases(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idScianClase
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getScianSubclasesScianClases(idScianClase: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getScianSubclasesScianClases(idScianClase, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getSubDisciplinas(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getSubDisciplinas(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idDisciplina
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getSubDisciplinasDisciplinas(idDisciplina: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getSubDisciplinasDisciplinas(idDisciplina, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getTerminoGenericos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getTerminoGenericos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getTipoAsentamientos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getTipoAsentamientos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getTipoContactos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getTipoContactos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getTipoDiscapacidades(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getTipoDiscapacidades(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getTipoEstancias(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getTipoEstancias(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getTipoEvaluaciones(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getTipoEvaluaciones(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getTipoFormaciones(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getTipoFormaciones(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoInstNivelDos
   * @param {string} idTipoInstNivelTres A unique integer value identifying this cat_tipo_inst_nivel_tres.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getTipoIdsInstNivelTres(
    idTipoInstNivelDos: string,
    idTipoInstNivelTres: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getTipoIdsInstNivelTres(idTipoInstNivelDos, idTipoInstNivelTres, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getTipoInstNivelDos(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getTipoInstNivelDos(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoInstNivelUno
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getTipoInstNivelDosTipoInstNivelUno(idTipoInstNivelUno: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getTipoInstNivelDosTipoInstNivelUno(idTipoInstNivelUno, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoInstitucion
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getTipoInstNivelUnoTipoInstituciones(idTipoInstitucion: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getTipoInstNivelUnoTipoInstituciones(idTipoInstitucion, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getTipoInstituciones(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getTipoInstituciones(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getTipoPertenEtnicas(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getTipoPertenEtnicas(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getTipoTrayectoriasProf(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getTipoTrayectoriasProf(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getTipoVialidades(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getTipoVialidades(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getTiposInstNivelUno(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getTiposInstNivelUno(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getVarAutodenominaciones(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getVarAutodenominaciones(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idPuebloIndigena
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getVarAutodenominacionesPueblosIndigenas(idPuebloIndigena: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getVarAutodenominacionesPueblosIndigenas(idPuebloIndigena, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} claveAreaGe
   * @param {string} claveAreaGm
   * @param {string} claveLocalidad
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getVialidadesLocaliidadesMunicipiosEstados(
    claveAreaGe: string,
    claveAreaGm: string,
    claveLocalidad: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getVialidadesLocaliidadesMunicipiosEstados(claveAreaGe, claveAreaGm, claveLocalidad, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idFuncionalidad
   * @param {string} idDefinicionLogUi A unique integer value identifying this cat_definicion_log_ui.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getsCatDefinicionLogUi(
    idFuncionalidad: string,
    idDefinicionLogUi: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getsCatDefinicionLogUi(idFuncionalidad, idDefinicionLogUi, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoEventoUi
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getsCatDefinicionLogUis(idTipoEventoUi: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getsCatDefinicionLogUis(idTipoEventoUi, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idPreguntaRespuesta
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getsCatListRespPreguntas(idPreguntaRespuesta: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getsCatListRespPreguntas(idPreguntaRespuesta, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getsCatPalabraClaves(page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getsCatPalabraClaves(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cveTipoInstNivelUno
   * @param {string} cveTipoInstNivelDos
   * @param {string} cveEntidad
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {string} [search] A search term.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getsCveClaveCatNacionalPublico(
    cveTipoInstNivelUno: string,
    cveTipoInstNivelDos: string,
    cveEntidad: string,
    page?: number,
    size?: number,
    search?: string,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getsCveClaveCatNacionalPublico(cveTipoInstNivelUno, cveTipoInstNivelDos, cveEntidad, page, size, search, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoEventoUi
   * @param {string} idDefinicionLogUi A unique integer value identifying this cat_definicion_log_ui.
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getsDefinicionLogsUis(
    idTipoEventoUi: string,
    idDefinicionLogUi: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ApiApiFp(this.configuration)
      .getsDefinicionLogsUis(idTipoEventoUi, idDefinicionLogUi, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} idTipoEventoUi
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiApi
   */
  public getsIdCatDefinicionLogUis(idTipoEventoUi: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return ApiApiFp(this.configuration)
      .getsIdCatDefinicionLogUis(idTipoEventoUi, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} idPreguntaRespuesta
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatListRespPregunta: async (
      idPreguntaRespuesta: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idPreguntaRespuesta' is not null or undefined
      assertParamExists('getIdCatListRespPregunta', 'idPreguntaRespuesta', idPreguntaRespuesta);
      const localVarPath = `/api/v1/list-resp-preguntas/{id_pregunta_respuesta}`.replace(
        `{${'id_pregunta_respuesta'}}`,
        encodeURIComponent(String(idPreguntaRespuesta))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} idPreguntaRespuesta
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIdCatListRespPregunta(
      idPreguntaRespuesta: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatListRespPregunta>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIdCatListRespPregunta(idPreguntaRespuesta, page, size, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {string} idPreguntaRespuesta
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [size] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIdCatListRespPregunta(idPreguntaRespuesta: string, page?: number, size?: number, options?: any): AxiosPromise<CatListRespPregunta> {
      return localVarFp.getIdCatListRespPregunta(idPreguntaRespuesta, page, size, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {string} idPreguntaRespuesta
   * @param {number} [page] A page number within the paginated result set.
   * @param {number} [size] Number of results to return per page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getIdCatListRespPregunta(idPreguntaRespuesta: string, page?: number, size?: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getIdCatListRespPregunta(idPreguntaRespuesta, page, size, options)
      .then(request => request(this.axios, this.basePath));
  }
}

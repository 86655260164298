var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c(
        "label",
        { attrs: { for: _vm.id } },
        [
          _vm._v("\n    " + _vm._s(_vm.label) + " "),
          _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
          _vm._v(" "),
          _vm.tooltip
            ? _c(
                "span",
                [
                  _c("b-icon", {
                    attrs: { id: _vm.id + "tooltip", icon: "question-circle" },
                  }),
                  _vm._v(" "),
                  _c("b-tooltip", {
                    attrs: {
                      target: _vm.id + "tooltip",
                      title: _vm.tooltip,
                      placement: "right",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("b-tooltip", { attrs: { target: _vm.id, triggers: "hover" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("commonComponent.dateOpen.tooltip")) +
                "\n    "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-input-group",
        { staticClass: "mb-3" },
        [
          _c("b-form-input", {
            attrs: {
              id: _vm.id,
              type: "text",
              trim: "",
              placeholder: _vm.$t("commonComponent.dateOpen.placeholder"),
              autocomplete: "off",
              required: _vm.required,
              "today-button": true,
              "label-today-button": _vm.$t(
                "commonComponent.datepicker.todayLabel"
              ),
              "close-button": true,
              "label-close-button": _vm.$t(
                "commonComponent.datepicker.closeLabel"
              ),
              "label-no-date-selected": _vm.$t(
                "commonComponent.datepicker.noDateSelectedLabel"
              ),
              "label-prev-month": _vm.$t(
                "commonComponent.datepicker.prevMonthLabel"
              ),
              "label-current-month": _vm.$t(
                "commonComponent.datepicker.currMonthLabel"
              ),
              "label-next-month": _vm.$t(
                "commonComponent.datepicker.nextMonthLabel"
              ),
              "label-prev-year": _vm.$t("commonComponent.datepicker.prevYear"),
              "label-next-year": _vm.$t("commonComponent.datepicker.nextYear"),
              "label-help": _vm.$t("commonComponent.datepicker.labelHelp"),
              state: _vm.valid,
              locale: _vm.$t("commonComponent.datepicker.localeLang"),
              max: _vm.maxDate,
              min: _vm.minDate,
            },
            on: {
              change: function ($event) {
                return _vm.cambio($event)
              },
              context: _vm.onContext,
            },
            model: {
              value: _vm.dateValue,
              callback: function ($$v) {
                _vm.dateValue = $$v
              },
              expression: "dateValue",
            },
          }),
          _vm._v(" "),
          _c(
            "b-input-group-append",
            [
              _c("b-form-datepicker", {
                attrs: {
                  "button-only": "",
                  right: "",
                  "aria-controls": "example-input",
                  state: _vm.valid,
                  locale: _vm.$t("commonComponent.datepicker.localeLang"),
                  max: _vm.maxDate,
                },
                on: {
                  context: _vm.onContext,
                  change: function ($event) {
                    return _vm.cambio($event)
                  },
                },
                model: {
                  value: _vm.dateValue,
                  callback: function ($$v) {
                    _vm.dateValue = $$v
                  },
                  expression: "dateValue",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-invalid-feedback",
        [
          _c("validations-commons", {
            attrs: {
              requiredValue: _vm.validationsCommons.requiredValue,
              requiredMessage: _vm.validationsCommons.requiredMessage,
              minValue: _vm.validationsCommons.minValue,
              minMessage: _vm.validationsCommons.minMessage,
              maxValue: _vm.validationsCommons.maxValue,
              maxMessage: _vm.validationsCommons.maxMessage,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }